export default {
    portal: {
        admin: "我的教会",
        shepherd: "牧养系统"
    },
    caption: {
        v2: {
            my: "我的教会",
            soul: "心灵捕手",
            daily: "每日灵粮"
        }
    },
    nav: {
        billboard: {
            index: "公吿栏",
            edit: "公吿内容"
        },
        admin: {
            home: {
                index: "控制面板",
                permission: "控制面板",
                statistics: {
                    client: "会友人数",
                    coming: "会友新增",
                    delete: "会友删除",
                    publish: "上稿次数",
                    notification: "发送推播",
                    bible: "圣经开启",
                    favorite: "收藏次数",
                    comment: "留言次数",
                    share: "分享次数",
                    visit: "文章观看",
                    social: "外连观看",
                    login: "登入次数"
                }
            },
            billboard: {
                index: "公吿管理",
                create: "公吿新增",
                edit: "公吿内容",
                permission: "公吿管理"
            },
            user: {
                index: "管理员帐号管理",
                create: "管理员新增",
                edit: "管理员内容",
                permission: "管理员帐号管理"
            },
            church: {
                index: "教会管理",
                create: "教会新增",
                edit: "教会内容",
                permission: "教会管理"
            },
            client: {
                index: "会友管理",
                create: "会友新增",
                edit: "会友内容",
                permission: "会友管理",
                donate: {
                    index: "奉献纪录",
                    edit: "奉献内容",
                    permission: "会友管理 - 奉献纪录"
                }
            },
            my: {
                index: "我的教会文章管理",
                create: "我的教会文章新增",
                edit: "我的教会文章内容",
                copy: "文章复製",
                mindcatcher: "文章导入",
                permission: "我的教会文章管理",
                comment: {
                    index: "留言纪录",
                    permission: "我的教会文章管理 - 留言纪录"
                },
                notification: {
                    index: "推播纪录",
                    create: "推播新增",
                    edit: "推播内容",
                    permission: "我的教会文章管理 - 推播纪录"
                }
            },
            daily: {
                index: "每日灵粮文章管理",
                create: "每日灵粮文章新增",
                edit: "每日灵粮文章内容",
                copy: "文章复製",
                mindcatcher: "文章导入",
                permission: "每日灵粮文章管理",
                comment: {
                    index: "留言纪录",
                    permission: "每日灵粮文章管理 - 留言纪录"
                },
                notification: {
                    index: "推播纪录",
                    create: "新增推播",
                    edit: "推播内容",
                    permission: "每日灵粮文章管理 - 推播纪录"
                }
            },
            soul: {
                index: "心灵捕手文章管理",
                create: "心灵捕手文章新增",
                edit: "心灵捕手文章内容",
                copy: "文章复製",
                mindcatcher: "文章导入",
                permission: "心灵捕手文章管理 - 文章管理",
                comment: {
                    index: "留言纪录",
                    permission: "心灵捕手文章管理 - 留言纪录"
                },
                notification: {
                    index: "推播纪录",
                    create: "推播新增",
                    edit: "推播内容",
                    permission: "心灵捕手文章管理 - 推播纪录"
                },
                category: {
                    index: "心灵捕手分类管理",
                    permission: "心灵捕手文章管理 - 分类管理"
                }
            },
            gallery: {
                subject: "图库管理",
                index: "图库清单",
                accept: "图库核准",
                alarm: "图库检举",
                permission: {
                    index: "图库管理 - 图库清单",
                    accept: "图库管理 - 图库核准",
                    alarm: "图库管理 - 图库检举"
                }
            },
            notification: {
                index: "推播管理",
                create: "新增推播",
                edit: {
                    subject: "推播内容",
                    automatic: {
                        subject: "自动化纪录",
                        edit: {
                            subject: "纪录内容"
                        }
                    },
                    history: {
                        subject: "推播纪录"
                    }
                },
                permission: "推播管理"
            },
            event: {
                subject: "行事曆管理",
                copy: "复製服事",
                create: "服事新增",
                edit: "服事内容",
                index: "服事清单",
                permission: "行事曆管理 - 服事清单",
                team: {
                    index: "服事群组",
                    create: "群组新增",
                    edit: "群组内容",
                    permission: "行事曆管理 - 服事群组",
                    history: {
                        index: "历史纪录"
                    }
                }
            },
            donate: {
                index: "奉献管理",
                edit: "奉献内容",
                permission: "奉献管理"
            },
            cycle: {
                index: "循环管理",
                create: "循环新增",
                edit: "循环内容",
                permission: "循环管理",
                history: {
                    index: "循环纪录",
                    edit: "纪录内容"
                }
            },
            teach: {
                index: "教学管理",
                create: "教学新增",
                edit: "教学内容",
                permission: "教学管理"
            },
            question: {
                subject: "问题集管理",
                group: {
                    index: "问题群组",
                    create: "问题集新增",
                    edit: "问题集内容",
                    permission: "问题集管理 - 问题群组"
                },
                question: {
                    index: "问题设置",
                    create: "问题设置新增",
                    edit: "设置内容",
                    permission: "问题集管理 - 问题设置"
                },
                option: {
                    index: "回覆选项",
                    create: "回覆选项新增",
                    edit: "选项内容",
                    permission: "问题集管理 - 回覆选项"
                },
                form: {
                    index: "表单纪录",
                    edit: "纪录内容",
                    permission: "问题集管理 - 表单纪录"
                }
            },
            support: {
                subject: "客服管理",
                worker: {
                    index: "维修工单",
                    create: "工单新增",
                    edit: {
                        subject: "工单内容",
                        history: {
                            index: "历史纪录",
                            create: "纪录新增",
                            edit: "纪录内容"
                        },
                        record: {
                            index: "异动纪录"
                        }
                    }
                },
                history: {
                    index: "帐号纪录",
                    edit: "纪录内容",
                    permission: "客服管理 - 帐号纪录"
                },
                feedback: {
                    index: "意见与建议",
                    edit: "反馈内容",
                    permission: "客服管理 - 意见与建议"
                },
                coffee: {
                    index: "请我们喝咖啡",
                    edit: "赞助内容",
                    permission: "客服管理 - 请我们喝咖啡"
                }
            },
            course: {
                subject: "历程管理",
                exception: {
                    index: "拦截纪录",
                    edit: "拦截内容",
                    permission: "历程管理 - 拦截纪录"
                },
                access: {
                    index: "存取纪录",
                    edit: "存取内容",
                    permission: "历程管理 - 存取纪录"
                },
                authorize: {
                    index: "授权纪录",
                    edit: "登入内容",
                    permission: "历程管理 - 授权纪录"
                }
            },
            activity: {
                subject: "活动管理",
                index: "活动清单",
                create: "活动新增",
                edit: {
                    index: "活动内容",
                    register: {
                        index: "后台报名"
                    },
                    registered: {
                        index: "报名清单",
                        edit: {
                            index: "报名内容"
                        }
                    }
                },
                permission: "活动管理 - 活动清单",
                category: {
                    index: "分类管理",
                    create: "分类新增",
                    edit: "分类内容",
                    permission: "活动管理 - 分类管理"
                }
            },
            live: {
                subject: "直播管理",
                index: "直播清单",
                create: "直播新增",
                edit: {
                    subject: "直播内容",
                    comment: {
                        subject: "聊天室发言"
                    },
                    secure: {
                        subject: "私密代祷"
                    }
                },
                permission: "直播管理 - 直播清单",
                category: {
                    index: "分类管理",
                    create: "分类新增",
                    edit: "分类内容",
                    permission: "直播管理 - 分类管理"
                }
            },
            personal: {
                edit: "个人资讯"
            }
        },
        shepherd: {
            home: {
                index: "控制面板",
                permission: "控制面板",
                statistics: {
                    friend: "新朋友数量",
                    client: {
                        total: "会友人数",
                        track: "已跟进会友",
                        stranger: "未跟进会友",
                        archive: "不跟进会友"
                    },
                    team: "族群数量",
                    attend: "聚会数量"
                }
            },
            friend: {
                index: "新朋友管理",
                create: "新朋友新增",
                edit: "新朋友内容",
                permission: "新朋友管理"
            },
            client: {
                index: "会友管理",
                create: "会友新增",
                edit: "会友内容",
                advance: {
                    subject: "进阶功能",
                    permission: "会友管理 - 进阶功能"
                },
                permission: "会友管理",
                attend: {
                    index: "聚会纪录",
                    edit: "聚会内容"
                }
            },
            team: {
                index: "族群管理",
                permission: "族群管理",
                attend: {
                    index: "聚会查询"
                },
                statistics: {
                    index: "牧养跟进",
                    attend: {
                        index: "聚会纪录"
                    },
                    event: {
                        index: "服事参与"
                    },
                    gender: {
                        index: "男女比例"
                    },
                    year: {
                        index: "年龄分佈"
                    },
                    deacon: {
                        index: "五重执事"
                    },
                    education: {
                        index: "教育程度"
                    },
                    occupation: {
                        index: "职业分佈"
                    },
                    marital: {
                        index: "婚姻状况"
                    },
                    baptized: {
                        index: "受洗人数"
                    }
                }
            },
            attend: {
                subject: "聚会管理",
                index: "聚会清单",
                create: "聚会新增",
                edit: "聚会内容",
                permission: "聚会管理 - 聚会清单",
                schedule: {
                    index: "聚会排程",
                    edit: "排程内容"
                },
                history: {
                    index: "聚会纪录",
                    edit: "纪录内容"
                },
                category: {
                    index: "分类管理",
                    create: "分类新增",
                    edit: "分类内容",
                    permission: "聚会管理 - 分类管理"
                }
            },
            setting: {
                permission: "参数管理"
            }
        },
        profile: "个人资料",
        head: "返回管理介面",
        logout: "登出系统"
    },
    month: [
        null,
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
    ],
    weekday: {
        full: [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六"
        ],
        short: [
            "日",
            "一",
            "二",
            "三",
            "四",
            "五",
            "六"
        ]
    },
    component: {
        signature: {
            recipient: {
                move: {
                    left: "双击加入",
                    right: "双击移除"
                },
                subject: "编辑签到名单",
                disable: {
                    available: "请选择加入的项目",
                    selected: "请选择移除的项目"
                },
                changed: "尚未异动项目",
                empty: "请指定协助<br/>使用App签到本活动的会友",
                not: {
                    found: "无法搜寻到<br />您指定条件的会友"
                },
                help: {
                    available: "可选择 <b class=\"cgBase\">%s</b> 个会友",
                    selected: "已选择 <b class=\"cgBase\">%s</b> 个会友"
                },
                th: [
                    "头像",
                    "会友",
                    "操作",
                    "资讯"
                ],
                field: {
                    keyword: "搜寻名单",
                    tag: "标籤"
                },
                holder: {
                    keyword: "搜寻范围: 名字/邮件/编号",
                    tag: "请选择标籤"
                },
                handle: {
                    close: {
                        subject: "即将关闭编辑推播",
                        text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                    }
                },
                tag: {
                    all: "全部",
                    uncategorized: "未分类",
                    administrator: "管理员名单"
                }
            }
        },
        support: {
            v2: {
                worker: {
                    progress: {
                        cost: {
                            subject: "耗用时间"
                        },
                        remaining: {
                            subject: "剩馀时间",
                            expire: {
                                subject: "已经超时"
                            }
                        }
                    },
                    account: {
                        empty: {
                            subject: "请输入异常App帐号"
                        },
                        field: {
                            account: "异常App帐号"
                        },
                        holder: {
                            account: "请输入正确的电子邮件格式, 按 Enter 可新增一笔"
                        },
                        format: {
                            account: "请输入正确的电子邮件格式"
                        },
                        th: {
                            0: {
                                subject: "帐号"
                            },
                            1: {
                                subject: "删除"
                            }
                        },
                        handler: {
                            delete: {
                                subject: "即将删除指定异常帐号",
                                text: ""
                            }
                        }
                    },
                    attachment: {
                        subject: "加入附件",
                        selected: {
                            subject: "已经选择 <b class=\"text-danger ft-16\">%s</b> 个附件"
                        },
                        th: {
                            0: {
                                subject: "类型"
                            },
                            1: {
                                subject: "操作"
                            }
                        },
                        field: {
                            subject: "标题",
                            description: "描述",
                            mime: "类型",
                            url: "附件网址"
                        },
                        holder: {
                            subject: "请输入标题",
                            description: "请输入描述",
                            url: "请输入附件网址"
                        },
                        empty: {
                            subject: "请点击新增进行加入附件"
                        },
                        complete: {
                            not: {
                                subject: "请先完善表单"
                            }
                        },
                        help: {
                            subject: "请输入标题",
                            url: {
                                subject: "请输入附件网址",
                                illegal: {
                                    subject: "请输入正确的附件网址"
                                }
                            }
                        },
                        handler: {
                            delete: {
                                subject: "即将删除指定附件",
                                text: ""
                            }
                        }
                    },
                    history: {
                        attachment: {
                            editor: {
                                subject: "附件内容",
                                field: {
                                    mime: {
                                        subject: "类型"
                                    },
                                    subject: {
                                        subject: "标题",
                                        holder: ""
                                    },
                                    description: {
                                        subject: "描述",
                                        holder: ""
                                    },
                                    url: {
                                        subject: "网址",
                                        holder: ""
                                    }
                                }
                            }
                        },
                        field: {
                            subject: {
                                subject: "标题",
                                holder: "请输入标题"
                            },
                            description: {
                                subject: "描述",
                                holder: "请输入描述"
                            },
                            attachment: {
                                subject: "附件"
                            },
                            creator: {
                                subject: "建立人员",
                                holder: "",
                                stamp: {
                                    subject: "建立时间",
                                    holder: ""
                                }
                            },
                            editor: {
                                subject: "上一次编辑人",
                                holder: "",
                                stamp: {
                                    subject: "上一次编辑时间",
                                    holder: ""
                                }
                            }
                        },
                        help: {
                            freeze: {
                                subject: "无法编辑",
                                text: "非目前状态或者您不是建立人员<br/>不能进行编辑"
                            },
                            edit: {
                                subject: "编辑"
                            },
                            release: {
                                subject: "需要发佈App"
                            },
                            invalid: {
                                subject: "作废",
                                text: "纪录已作废"
                            }
                        }
                    },
                    status: {
                        field: {
                            subject: "状态"
                        }
                    }
                }
            }
        },
        recipient: {
            selector: {
                help: {
                    tag: {
                        subject: "总教会仅有全部与管理员名单"
                    }
                }
            }
        },
        attend: {
            cascader: {
                subject: "请选择族群",
                clear: {
                    subject: "清除"
                },
                error: {
                    1001: {
                        subject: "请至少选择1个族群",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        menu: {
            horizontal: {
                user: {
                    robot: "模拟登入中",
                    account: "帐号名称",
                    timezone: "所在时区",
                    version: {
                        ui: "UI版本",
                        core: "服务版本"
                    },
                    websocket: {
                        communication: "通讯状态",
                        status: {
                            connected: "连线成功",
                            disconnect: "目前断线"
                        }
                    },
                    flush: "释放快取",
                    handler: {
                        flush: {
                            subject: "即将手动释放快取",
                            text: "请确认后执行"
                        }
                    }
                },
                billboard: {
                    subject: "公告栏",
                    unread: "您还有 <b class=\"ft-14 text-danger\">%s</b> 笔公告未读取",
                    segue: "前往公告栏"
                },
                invitation: {
                    subject: "教会邀请码"
                }
            }
        },
        remind: {
            column: {
                on: "已开启",
                off: "未开启"
            },
            editor: {
                help: {
                    total: {
                        on: "最多只能选择 <b class=\"ft-16\">%s</b> 个选项, 还可以选择 <b class=\"ft-16\">%s</b> 个选项",
                        off: "已达到选项上限 <b class=\"ft-16\">%s</b> 个"
                    },
                    add: {
                        custom: "新增自定义",
                        email: "新增电子邮件"
                    },
                    maximum: {
                        custom: "新增自定义最多仅能设定 <b class=\"cgBase\">%s</b> 笔",
                        email: "寄送邮件名单最多仅能设定 <b class=\"cgBase\">%s</b> 笔"
                    }
                },
                field: {
                    regular: "常用选项",
                    custom: "自定义选项",
                    method: "通知方式"
                },
                holder: {
                    email: "请输入电子邮件"
                },
                description: {
                    zero: "活动时间点",
                    minute: "%s 分钟前",
                    hour: "%s 小时前",
                    day: "%s 日前",
                    week: "%s 週前"
                },
                error: {
                    1001: {
                        subject: "请指定通知方式",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "请至少选择一名收件人",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "请输入邮件地址",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "邮件格式错误",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        live: {
            type: {
                name: {
                    1: "YouTube",
                    2: "JustForYou"
                },
                disabled: "这个选项暂时无法使用"
            }
        },
        cycle: {
            type: {
                name: {
                    1: "文章",
                    2: "推播",
                    3: "服事"
                },
                disabled: "这个选项暂时无法使用"
            },
            column: {
                once: "单次",
                day: "每日",
                week: "每週",
                month: "每月",
                year: "每年"
            },
            schedule: {
                subject: "排程预览",
                text: "共计 <b class=\"ft-14 text-success\">%s</b> 个排程",
                th: {
                    0: {
                        subject: "#"
                    },
                    1: {
                        subject: "开始时间"
                    },
                    2: {
                        subject: "结束时间"
                    }
                }
            },
            editor: {
                help: {
                    begin: {
                        weekday: {
                            subject: "首次起始日为星期%s, 不能取消"
                        }
                    },
                    resource: {
                        subject: "目前最长可定义 %s 年週期, 请妥善利用资源"
                    },
                    more: "如果范围中的月份没有第 <b class=\"text-success\">%s</b> 天, 则会自动跳过"
                },
                field: {
                    cycle: "方式",
                    duration: "时长"
                },
                spinner: {
                    day: [
                        "每",
                        "日"
                    ],
                    week: [
                        "每",
                        "週"
                    ],
                    month: [
                        "每",
                        "个月"
                    ],
                    year: [
                        "每",
                        "年"
                    ]
                },
                duration: {
                    specific: "总计次数"
                },
                method: {
                    month: {
                        day: {
                            at: "在第 %s 天重复",
                            last: "在最后一天重复"
                        },
                        week: {
                            at: "在第%s週的%s",
                            last: "在最后一个%s重复"
                        },
                        select: "选取希望重复的日期"
                    },
                    year: {
                        day: {
                            at: "在 %s 的第 %s 天重复",
                            last: "在 %s 的最后一天重复"
                        },
                        week: {
                            at: "在 %s 的第%s週的%s重复",
                            last: "在 %s 的最后一个%s重复"
                        },
                        select: "选取希望在 %s 号重复的月份"
                    }
                },
                error: {
                    1001: {
                        subject: "请指定至少一天",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "请指定选项",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "请指定需要重复的日期",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "请指定需要重复的月份",
                        text: "",
                        description: ""
                    },
                    1005: {
                        subject: "请指定时长",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        qrcode: {
            v2: {
                status: {
                    authorize: {
                        subject: "授权成功",
                        text: "正在准备完成"
                    },
                    inspection: {
                        subject: "验证成功",
                        text: "请在App中进行确认"
                    },
                    expire: {
                        subject: "二维码超时",
                        text: "重新产生二维码"
                    }
                },
                to: {
                    qrcode: {
                        subject: "请使用App扫描确认或切换至",
                        text: "输入登入密码"
                    },
                    passwd: {
                        subject: "请输入登入密码或切换至",
                        text: "App扫描确认"
                    }
                }
            },
            login: {
                subject: "请使用%sApp扫码登入",
                text: "前往下载%sApp"
            },
            mapping: {
                subject: "请使用%sApp扫码进行绑定",
                text: "前往下载%sApp"
            },
            status: {
                authorize: {
                    subject: "授权成功",
                    text: "正在准备完成"
                },
                inspection: {
                    subject: "验证成功",
                    text: "请在App中进行确认"
                },
                expire: {
                    subject: "二维码超时",
                    text: "重新产生二维码"
                }
            }
        },
        billboard: {
            header: {
                total: "全部 %s 笔公告",
                unread: "未读取 %s 笔公告"
            },
            body: {
                field: {
                    placeholder: "请输入标题关键字查询"
                },
                entries: {
                    row: "%s・%s・共计 <b class=\"%s\">%s</b> 次阅读"
                }
            },
            footer: {
                total: {
                    all: "全部 <b class=\"ft-16 %s\">%s</b> 笔公告",
                    filter: "筛选出 <b class=\"ft-16 %s\">%s</b> 笔公告"
                }
            },
            recipient: {
                th: {
                    0: {
                        subject: "#"
                    },
                    1: {
                        subject: "管理员"
                    },
                    2: {
                        subject: "阅读时间"
                    }
                },
                empty: "暂时还没有管理员阅读",
                not: {
                    found: "无法搜寻到<br />您指定条件的管理员"
                }
            }
        },
        church: {
            invitation: {
                subject: "设定教会邀请码",
                incorrect: "请输入符合规范的邀请码",
                description: "<span class=\"ft-12 text-secondary\">邀请码规范 <b class=\"text-danger ft-14\">6</b> 个字元, 可用数字或英文, 不可使用符号</span>"
            }
        },
        chart: {
            period: {
                subject: "图表週期",
                value: [
                    "天",
                    "週",
                    "月",
                    "季",
                    "半年",
                    "年",
                    "三年"
                ],
                short: [
                    "1D",
                    "1W",
                    "1M",
                    "1Q",
                    "2Q",
                    "1Y",
                    "3Y"
                ]
            }
        },
        swal: {
            to: {
                qrcode: {
                    subject: "请使用App扫描确认或切换至",
                    text: "输入登入密码"
                },
                passwd: {
                    subject: "请输入登入密码或切换至",
                    text: "App扫描确认"
                }
            },
            status: {
                success: {
                    subject: "扫码成功",
                    text: "请在App中进行确认"
                },
                expire: {
                    subject: "二维码超时",
                    text: "重新产生二维码"
                }
            },
            field: {}
        },
        shepherd: {
            v2: {
                attend: {
                    group: {
                        selected: {
                            subject: "已选择族群",
                            empty: {
                                subject: "请选择族群"
                            },
                            remove: {
                                subject: "双击标籤快速移除",
                                all: {
                                    subject: "清空"
                                }
                            }
                        },
                        handler: {
                            clear: {
                                subject: "即将清空已选择族群",
                                text: "请确认后执行"
                            }
                        }
                    },
                    choose: {
                        enable: {
                            subject: "启用点名选项",
                            multiple: {
                                subject: "点名选项可复选"
                            }
                        },
                        field: {
                            name: {
                                holder: "请输入点名选项名称"
                            }
                        },
                        join: {
                            subject: "新增点名选项"
                        },
                        submit: {
                            enable: {
                                subject: "启用",
                                reverse: {
                                    subject: "停用"
                                }
                            }
                        },
                        help: {
                            picked: {
                                subject: "已设定%s个点名选项",
                                empty: {
                                    subject: "请设定点名选项"
                                }
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入点名选项名称",
                                text: ""
                            }
                        }
                    }
                }
            },
            client: {
                partake: {
                    help: {
                        lifted: {
                            empty: "请先选择需要加入的族群",
                            exist: "已经加入该族群"
                        },
                        archive: {
                            on: "已经设定不跟进该会友",
                            off: "重新跟进"
                        }
                    },
                    empty: "该会友尚未加入任何族群",
                    lifted: {
                        remove: "移除族群",
                        archive: "不跟进",
                        on: "解除族群",
                        off: "復原解除"
                    },
                    field: {
                        increase: "加入新族群",
                        team: "族群"
                    },
                    holder: {
                        increase: "请选择需要加入的新族群",
                        team: "请选择需要加入的族群"
                    },
                    handler: {
                        archive: {
                            subject: "即将不跟进该名会友",
                            text: "所有群族将<span class=\"text-danger\">全数解除及取消标注</span><br />若要再次进入群族被关心<br />请前往[不跟进会友]重新加入族群, 请确认后执行<br/><span class=\"ft-14 text-warning\">请注意, 储存后才会生效</span>"
                        }
                    }
                }
            },
            team: {
                editor: {
                    archive: {
                        subject: "不跟进",
                        text: "不跟进会友无法选择"
                    },
                    top: "最上层",
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改"
                    },
                    move: {
                        left: "双击加入",
                        right: "双击移除"
                    },
                    subject: {
                        head: "族群内容",
                        add: "族群新增",
                        edit: "族群编辑"
                    },
                    disable: {
                        selected: "请选择移除的项目"
                    },
                    empty: {
                        recipient: "请至少选择<br />1位会友作为託管人员",
                        manager: "请先选择族群成员<br />再进行託管人员选择"
                    },
                    not: {
                        available: "族群中没有会友<br />请先加入会友到族群中",
                        found: "无法搜寻到<br />您指定条件的会友"
                    },
                    th: [
                        null,
                        "会友"
                    ],
                    field: {
                        manager: "託管人员",
                        name: "族群名称",
                        belong: "所属族群",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        manager: "请至少选择 <b class=\"ft-14 cgBase\">1</b> 位託管人员",
                        maximum: ", 最多能选择 <b class=\"ft-14 cgBase\">%s</b> 位託管人员",
                    },
                    handler: {
                        close: {
                            add: {
                                subject: "即将关闭族群新增",
                                text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                            },
                            edit: {
                                subject: "即将关闭族群编辑",
                                text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                            }
                        },
                        store: {
                            subject: "即将新增族群",
                            text: "请确认后执行",
                            description: ""
                        },
                        update: {
                            subject: "即将更新族群内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入族群名称",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择族群成员",
                            text: "至少需要 <b class=\"ft-20 text-danger\">1</b> 位族群成员",
                            description: ""
                        },
                        1003: {
                            subject: "託管人员不足最低限制",
                            text: "至少需要 <b class=\"ft-20 text-danger\">1</b> 位託管人员",
                            description: ""
                        },
                        1004: {
                            subject: "託管人员超过最高限制",
                            text: "最多只能选择 <b class=\"ft-20 text-danger\">%s</b> 位託管人员",
                            description: ""
                        }
                    }
                }
            },
            attend: {
                history: {
                    recipient: {
                        th: {
                            1: "到",
                            2: "头像",
                            3: "资讯",
                            4: "报到",
                            5: "查看"
                        },
                        vacation: {
                            subject: "本週无聚会"
                        },
                        rank: {
                            subject: "自评",
                            not: {
                                yet: {
                                    subject: "未自评"
                                }
                            }
                        },
                        empty: "名单中没有成员",
                        note: {
                            subject: "留言",
                            not: {
                                yet: {
                                    subject: "未留言"
                                }
                            }
                        },
                        register: {
                            not: {
                                yet: {
                                    subject: "未报到"
                                }
                            }
                        },
                        edit: {
                            view: "查看此会友资讯"
                        }
                    }
                }
            }
        },
        connect: {
            advance: {
                delegate: {
                    done: {
                        subject: "批次委派成功",
                        text: "本次委派批次完成<br />共计 <b class=\"ft-16 text-success\">%s</b> 位会友"
                    },
                    empty: "请至少选择<br />1位会友进行委派",
                    mark: "已标注",
                    status: [
                        "不跟进",
                        "未跟进",
                        "已跟进"
                    ]
                },
                import: {
                    done: {
                        subject: "会友汇入成功",
                        text: "本次会友汇入完成<br />共计 <b class=\"ft-16 text-success\">%s</b> 位会友"
                    },
                    th: [
                        "姓名",
                        "电子邮件",
                        "称谓",
                        "状态"
                    ],
                    status: "新朋友",
                    subject: "请将汇入文件拖至此处",
                    text: "或点击上传",
                    description: "只能上传Excel文件(档案格式为xlsx)文件，且不超过<b class=\"text-danger opacity-75\">10MB</b>容量"
                },
                export: {
                    subject: "即将汇出全部会友",
                    text: "请确认后执行"
                },
                destroy: {
                    subject: "即将删除全部会友",
                    text: "操作无法返回, 请确认后执行"
                }
            }
        },
        client: {
            tooltip: [
                "连结失败",
                "等待连结",
                "连结成功"
            ]
        },
        editor: {
            help: [
                "温馨提示",
                "若您从 WORD档案/其他软体/其他网页转载 进行复製贴上, 容易造成App显示介面的跑版或不显示等状况",
                "建议您先将内容复製到「记事本」, 再从「记事本」中贴至内文中, 请您注意使用"
            ],
            disable: "请输入连结文字与网址",
            subject: {
                insert: "插入连结",
                edit: "编辑连结"
            },
            release: "释放连结",
            hyperlink: "前往连结",
            link: "插入连结",
            clear: "清空内文",
            summary: {
                empty: "<span class=\"a1\">未输入文字</span>",
                total: "合计约 <b class=\"cgBase\">%s</b> 个文字"
            },
            field: {
                hyperlink: {
                    url: "连结网址",
                    text: "连结文字"
                }
            },
            holder: {
                hyperlink: {
                    url: "请输入连结网址",
                    text: "请输入连结文字"
                }
            }
        },
        avatar: {
            display: "显示完整图片"
        },
        breadcrumb: {
            category: {
                index: "分类管理",
                edit: "分类内容",
                create: "分类新增"
            },
            index: {
                index: "文章管理",
                edit: "文章内容",
                create: "文章新增"
            }
        },
        article: {
            integrate: {
                enable: {
                    subject: "已启用"
                }
            },
            preview: {
                publish: "发布于 %s．%s 人次浏览",
                empty: "请输入文章内容",
                footer: {
                    favorite: "收藏",
                    size: "字级",
                    comment: "前往留言"
                }
            },
            mindcatcher: {
                authorize: "分享文章由 <b class=\"text-black\">%s</b> 授权使用"
            },
            type: {
                name: {
                    1: "图文",
                    2: "影音",
                    3: "MP3",
                    4: "直播",
                    5: "WEB"
                },
                disabled: "这个选项暂时无法使用",
                tip: {
                    live: {
                        disabled: "次分类为活动, 将无法使用直播类型"
                    }
                }
            },
            schedule: [
                "排程中",
                "上架中",
                "已下架"
            ]
        },
        tag: {
            subject: "编辑标籤",
            help: "送出新增标籤",
            exit: "离开编辑",
            empty: "无可用标籤",
            menu: {
                on: "开启选单",
                off: "关闭选单"
            },
            summary: {
                total: "筛选出 <span class=\"cgBase\">%s</span> 个会友",
                checked: ", 已选取 <span class=\"cgBase\">%s</span> 个会友"
            },
            status: [
                "已停用",
                "启用中"
            ],
            off: {
                all: "请选择先筛选会友",
                subject: "请先选择标籤",
                join: "加入(请选择标籤与指定会友)",
                remove: "移除(请选择标籤与指定会友)"
            },
            field: {
                tag: "",
                keyword: "关键字"
            },
            th: {
                avatar: "头像",
                name: "名字",
                email: "电子邮件",
                status: "状态"
            },
            holder: {
                tag: "请输入标籤名称(可用逗号分隔)",
                keyword: "搜寻范围: 名字/邮件/会友编号/标籤"
            },
            handler: {
                join: {
                    subject: "即将批次加入标籤",
                    text: "请确认后执行"
                },
                remove: {
                    subject: "即将批次移除标籤",
                    text: "请确认后执行"
                },
                dbclick: {
                    subject: "即将移除指定标籤",
                    text: "请确认后执行"
                },
                confirm: {
                    subject: "即将移除标籤",
                    text: "请确认后执行"
                }
            }
        },
        upload: {
            submit: "上传",
            summary: "已选择 <b class=\"text-success ft-15\">%s</b> 张图片, 最多可以上传 <b class=\"text-success ft-15\">%s</b> 张图片",
            run: {
                out: "已经选完 <b class=\"text-danger ft-15\">%s</b> 张图片"
            },
            gallery: {
                subject: "图库",
                disabled: "请先选择图"
            },
            history: {
                subject: "历史图"
            },
            upload: {
                subject: "上传图",
                disabled: "图片处理中, 请稍候"
            },
            destroy: "清除全部",
            clear: "清除",
            handler: {
                cancel: {
                    subject: "即将取消选择图",
                    text: "已经编辑的内容将会重设, 请确认后执行"
                },
                clear: {
                    subject: "即将清除已经设定的图",
                    text: "已经编辑的内容将会重设, 请确认后执行"
                }
            }
        },
        cropper: {
            help: {
                close: "关闭",
                reset: "重设"
            },
            tool: {
                illegal: "举报图片",
                refresh: "重设编辑",
                prev: {
                    has: "上一张",
                    no: "没有上一张了"
                },
                next: {
                    has: "下一张",
                    no: "没有下一张了"
                },
                cancel: "取消编辑"
            },
            feature: {
                text: "编辑文字",
                size: "文字大小",
                color: "文字颜色",
                position: "文字位置",
                vertical: "上下微调",
                horizontal: "左右微调"
            },
            position: [
                "左上",
                "右上",
                "置中",
                "左下",
                "右下"
            ],
            field: {
                text: {
                    subject: "填充文字"
                },
                size: {
                    subject: "文字大小"
                },
                color: {
                    subject: "文字颜色"
                },
                position: {
                    subject: "文字位置"
                },
                vertical: {
                    subject: "垂直微调"
                },
                horizontal: {
                    subject: "水平微调"
                }
            },
            holder: {
                text: {
                    subject: "请输入填充文字"
                },
                size: {
                    subject: "请选择文字大小"
                },
                color: {
                    subject: "请选择文字颜色"
                },
                position: {
                    subject: "请选择文字位置"
                },
                vertical: {
                    subject: "请选择垂直微调"
                },
                horizontal: {
                    subject: "请选择水平微调"
                }
            }
        },
        lightbox: {
            v2: {
                zoom: {
                    in: {
                        subject: "放大"
                    },
                    out: {
                        subject: "缩小"
                    }
                },
                resize: {
                    subject: "还原缩放"
                },
                download: {
                    subject: "下载"
                },
                rotate: {
                    left: {
                        subject: "逆时针旋转"
                    },
                    right: {
                        subject: "顺时针旋转"
                    }
                }
            },
            zoom: {
                in: "放大",
                out: "缩小"
            },
            resize: "还原缩放",
            download: "下载",
            rotate: {
                left: "逆时针旋转",
                right: "顺时针旋转"
            }
        },
        permission: {
            name: "名称",
            empty: "搜寻不到指定的权限",
            head: "仅总教会",
            portal: [
                "我的教会",
                "牧养系统"
            ],
            feature: [
                {
                    subject: "总览"
                },
                {
                    subject: "新增"
                },
                {
                    subject: "储存"
                },
                {
                    subject: "编辑"
                },
                {
                    subject: "更新"
                },
                {
                    subject: "删除"
                },
                {
                    subject: "汇出"
                }
            ]
        },
        theme: {
            light: "浅色主题",
            dark: "深色主题"
        },
        notification: {
            recipient: {
                complete: {
                    subject: "已送达",
                    text: "送达时间 %04d-%02d-%02d %02d:%02d"
                },
                read: {
                    subject: "已读取",
                    yet: {
                        subject: "未读取"
                    }
                },
                broadcast: {
                    subject: "群体推播无法编辑名单"
                },
                move: {
                    left: "双击加入",
                    right: "双击移除"
                },
                subject: "编辑推播名单",
                disable: {
                    available: "请选择加入的项目",
                    selected: "请选择移除的项目"
                },
                changed: "尚未异动项目",
                empty: "请至少选择<br />1位会友进行推播",
                not: {
                    found: "无法搜寻到<br />您指定条件的会友"
                },
                help: {
                    available: "可选择 <b class=\"cgBase\">%s</b> 个会友",
                    selected: "已选择 <b class=\"cgBase\">%s</b> 个会友"
                },
                th: [
                    "头像",
                    "会友",
                    "操作",
                    "进度",
                    "读取"
                ],
                status: [
                    "未读",
                    "已读"
                ],
                field: {
                    keyword: "搜寻名单",
                    tag: "标籤"
                },
                holder: {
                    keyword: "搜寻范围: 名字/邮件/编号",
                    tag: "请选择标籤"
                },
                handle: {
                    close: {
                        subject: "即将关闭编辑推播",
                        text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                    }
                },
                tag: {
                    all: "全部",
                    uncategorized: "未分类",
                    administrator: "管理员名单"
                }
            },
            status: [
                null,
                "排程中",
                "进行中",
                "已完成"
            ],
            status_name: {
                0: {
                    subject: "准备中"
                },
                1: {
                    subject: "已送达"
                },
                2: {
                    subject: "无法推播"
                },
                9: {
                    subject: "用户删除"
                }
            },
            type: {
                1: "指定",
                2: "全体"
            },
            destroy: [
                "删除",
                "推播进行中, 无法删除",
                "推播已经完成, 无法删除"
            ],
            exclude: {
                label: "排除机构",
                help: {
                    subject: "选中的机构将会排除并不发送推播讯息",
                    all: {
                        subject: "将不排除任何机构"
                    }
                }
            }
        },
        event: {
            team: {
                recipient: {
                    move: {
                        left: "双击加入",
                        right: "双击移除"
                    },
                    subject: "编辑服事群组名单",
                    disable: {
                        available: "请选择加入的项目",
                        selected: "请选择移除的项目"
                    },
                    changed: "尚未异动项目",
                    field: {
                        keyword: "搜寻名单",
                        tag: "标籤"
                    },
                    holder: {
                        keyword: "搜寻范围: 名字/邮件/编号",
                        tag: "请选择标籤"
                    },
                    handle: {
                        close: {
                            subject: "即将关闭编辑服事群组",
                            text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                        }
                    },
                    th: [
                        "头像",
                        "会友",
                        "操作"
                    ],
                    tag: {
                        all: "全部",
                        uncategorized: "未分类",
                        administrator: "管理员名单"
                    },
                    empty: "请至少选择<br />1位会友参与服事群组",
                    not: {
                        found: "无法搜寻到<br />您指定条件的会友"
                    },
                    help: {
                        available: "可选择 <b class=\"cgBase\">%s</b> 个会友",
                        selected: "已选择 <b class=\"cgBase\">%s</b> 个会友"
                    }
                }
            },
            recipient: {
                move: {
                    left: "双击加入",
                    right: "双击移除"
                },
                subject: "编辑服事名单",
                disable: {
                    available: "请选择加入的项目",
                    selected: "请选择移除的项目"
                },
                changed: "尚未异动项目",
                field: {
                    keyword: "搜寻名单",
                    team: "服事群组"
                },
                holder: {
                    keyword: "搜寻范围: 名字/邮件/编号",
                    team: "请选择服事群组"
                },
                handle: {
                    close: {
                        subject: "即将关闭编辑服事名单",
                        text: "<span class=\"text-danger\">异动项目将会清除, 请确认后执行</span>"
                    }
                },
                th: [
                    "头像",
                    "会友",
                    "操作"
                ],
                tag: {
                    all: "全部",
                    uncategorized: "未分类",
                    administrator: "管理员名单"
                },
                remove: {
                    group: "移除整个群组"
                },
                empty: "请至少选择<br />1位会友参与服事",
                not: {
                    found: "无法搜寻到<br />您指定条件的会友"
                },
                help: {
                    available: "可选择 <b class=\"cgBase\">%s</b> 个会友",
                    selected: "已选择 <b class=\"cgBase\">%s</b> 个会友"
                }
            }
        },
        message: {
            clone: {
                subject: "资料复製成功",
                text: "右键贴上即可以直接使用",
                description: ""
            },
            download: {
                subject: "档案下载成功",
                text: "",
                description: ""
            }
        },
        input: {
            feature: {
                clone: "复製内容"
            }
        },
        password: {
            feature: {
                show: "显示密码",
                hide: "隐藏密码"
            }
        },
        select: {
            category: {
                create: "请先前往建立分类",
                ajax: {
                    first: "请先选择指定教会",
                    empty: "该教会尚未建立分类"
                }
            },
            option: {
                church: {
                    all: "全部教会"
                },
                empty: {
                    blank: "未选择",
                    subject: "没有可用的选项",
                    city: "请先选择所在区域",
                    target: "请先选择类型"
                }
            }
        },
        autocomplete: {
            subject: "编辑个人标记",
            incorrect: "请先选择需要移除的标记",
            selected: "已经选取 <b class=\"cgBase\">%s</b> 个标记",
            th: [
                null,
                "标记"
            ],
            handler: {
                destroy: {
                    subject: "即将删除指定个人标记",
                    text: "删除后无法復原, 请确认后执行",
                    description: ""
                }
            }
        },
        form: {
            v2: {
                edit: {
                    tag: {
                        quantity: {
                            subject: "共计 %s 名"
                        }
                    },
                    user: {
                        0: {
                            subject: "建立人员",
                            holder: "由系统自动产生",
                            stamp: {
                                subject: "建立时间",
                                holder: ""
                            }
                        },
                        1: {
                            subject: "上次编辑人员",
                            holder: "内容未经过编辑",
                            stamp: {
                                subject: "上次编辑时间",
                                holder: "内容未经过编辑"
                            }
                        }
                    },
                    cycle: {
                        subject: "循环资讯",
                        item: {
                            0: {
                                subject: "循环代号"
                            },
                            1: {
                                subject: "提醒代号"
                            },
                            2: {
                                subject: "最后循环开始时间"
                            }
                        }
                    },
                    error: {
                        code: {
                            subject: "请先清除表单错误"
                        }
                    },
                    alert: {
                        store: {
                            subject: "您没有建立的权限"
                        },
                        update: {
                            subject: "您没有更新的权限",
                            head: {
                                subject: "总教会仅供查看资料, 无法更新内容"
                            }
                        },
                        cycle: {
                            monitor: {
                                modified: {
                                    subject: "聚会时间已经变动, 请您留意"
                                }
                            },
                            schedule: {
                                last: {
                                    complete: {
                                        subject: "设定的所有循环均已完成, 将不会再继续生成相关单据与提醒"
                                    }
                                }
                            }
                        }
                    },
                    handler: {
                        reset: {
                            subject: "即将重设内容",
                            text: "异动内容将不会保存, 请确认后执行"
                        },
                        entry: {
                            subject: "即将返回列表",
                            text: "异动内容将不会保存, 请确认后执行"
                        },
                        cycle: {
                            monitor: {
                                subject: "聚会时间已经变动",
                                text: "建议您重新检视循环与提醒设定"
                            }
                        }
                    }
                },
                table: {
                    search: {
                        field: {
                            belong: {
                                subject: "所属教会",
                                holder: "请选择所属教会",
                                help: "仅总教会"
                            },
                            keyword: {
                                subject: "关键字",
                                holder: "请输入关键字",
                                help: "同时搜寻名字/邮件/会友编号"
                            },
                            on: {
                                subject: "开始时间(起)",
                                holder: "请选择开始时间(起)",
                                end: {
                                    subject: "开始时间(迄)",
                                    holder: "请选择开始时间(迄)"
                                }
                            },
                            off: {
                                subject: "结束时间(起)",
                                holder: "请选择结束时间(起)",
                                end: {
                                    subject: "结束时间(迄)",
                                    holder: "请选择结束时间(迄)"
                                }
                            },
                            submit: {
                                subject: "建立时间(起)",
                                holder: "请选择建立时间(起)",
                                end: {
                                    subject: "建立时间(迄)",
                                    holder: "请选择建立时间(迄)"
                                }
                            }
                        },
                        help: {
                            begin: {
                                subject: "(≥)指定时间"
                            },
                            end: {
                                subject: "(≤)指定时间"
                            }
                        }
                    },
                    action: {
                        add: {
                            subject: "新增",
                            not: {
                                allowed: {
                                    subject: "无新增权限"
                                }
                            }
                        },
                        move: {
                            top: {
                                subject: "移动至顶部"
                            },
                            left: {
                                subject: "移动至左方"
                            },
                            right: {
                                subject: "移动至右方"
                            }
                        },
                        search: {
                            subject: "搜寻"
                        },
                        statistics: {
                            subject: "统计"
                        },
                        checkbox: {
                            subject: "多选"
                        },
                        reset: {
                            subject: "重设"
                        },
                        refresh: {
                            subject: "刷新"
                        },
                        loading: {
                            subject: "读取中"
                        },
                        download: {
                            subject: "汇出",
                            not: {
                                allowed: {
                                    subject: "无汇出权限"
                                }
                            }
                        },
                        pagination: {
                            subject: "每页 <b>%s</b> 笔资料"
                        }
                    },
                    footer: {
                        subject: "目前于 第 <b class=\"text-justforyou-base ft-16\">%s</b> 页 共 <b class=\"text-justforyou-base ft-16\">%s</b> 页 全部 <b class=\"text-justforyou-base ft-16\">%s</b> 笔资料"
                    },
                    readonly: {
                        subject: "列表仅供读取",
                        head: {
                            subject: "总教会仅供查看资料, 无法新增修改"
                        }
                    },
                    empty: {
                        subject: "找不到指定的资料"
                    },
                    field: {
                        belong: {
                            subject: "所属教会"
                        },
                        head: {
                            subject: "主动",
                            text: "由总教会发起的主动推播"
                        },
                        cycle: {
                            subject: "循环",
                            cycle: {
                                1: {
                                    subject: "单次"
                                },
                                2: {
                                    subject: "每日"
                                },
                                3: {
                                    subject: "每週"
                                },
                                4: {
                                    subject: "每月"
                                },
                                5: {
                                    subject: "每年"
                                }
                            }
                        },
                        remind: {
                            subject: "提醒",
                            status: {
                                subject: "已设定%s笔提醒",
                                disable: {
                                    subject: "未设定提醒"
                                }
                            }
                        },
                        edit: {
                            subject: "操作",
                            view: {
                                subject: "查看"
                            }
                        }
                    },
                    off: {
                        canvas: {
                            statistics: {
                                subject: "统计"
                            },
                            search: {
                                subject: "搜寻"
                            }
                        }
                    },
                    permission: {
                        view: {
                            not: {
                                allowed: {
                                    subject: "无查看权限"
                                }
                            }
                        },
                        edit: {
                            not: {
                                allowed: {
                                    subject: "无编辑权限"
                                }
                            }
                        }
                    },
                    edit: {
                        subject: "编辑",
                        more: {
                            subject: "更多",
                            copy: {
                                subject: "复製"
                            },
                            enable: {
                                subject: "启用"
                            },
                            disable: {
                                subject: "停用"
                            },
                            destroy: {
                                subject: "删除"
                            }
                        },
                        view: {
                            subject: "检视"
                        }
                    }
                },
                history: {
                    action: {
                        add: {
                            subject: "新增",
                            not: {
                                allowed: {
                                    subject: "无新增权限"
                                }
                            }
                        },
                        search: {
                            subject: "搜寻"
                        }
                    },
                    footer: {
                        subject: "已载入 <b class=\"text-justforyou-base ft-16\">%s</b> 笔数据 共 <b class=\"text-justforyou-base ft-16\">%s</b> 笔数据",
                        complete: {
                            subject: "载入完成"
                        }
                    },
                    readonly: {
                        subject: "列表仅供读取"
                    },
                    empty: {
                        subject: "找不到指定的资料"
                    },
                    off: {
                        canvas: {
                            statistics: {
                                subject: "统计"
                            },
                            search: {
                                subject: "搜寻"
                            }
                        }
                    }
                },
                membership: {
                    editor: {
                        subject: "收件人",
                        option: {
                            all: {
                                subject: "全部"
                            }
                        },
                        dblclick: {
                            subject: "双击加入",
                            remove: {
                                subject: "双击移除"
                            }
                        },
                        summary: {
                            total: {
                                subject: "可选 <b class=\"ft-15 text-base\">%s</b> 名会友",
                                right: {
                                    subject: "已选 <b class=\"ft-15 text-base\">%s</b> 名会友"
                                },
                                filtered: {
                                    subject: "可选 <b class=\"ft-15 text-base\">%s</b> 名会友, 筛选出 <b class=\"ft-15 text-base\">%s</b> 名会友",
                                    right: {
                                        subject: "已选 <b class=\"ft-15 text-base\">%s</b> 名会友, 筛选出 <b class=\"ft-15 text-base\">%s</b> 名会友"
                                    }
                                }
                            }
                        },
                        th: [
                            "",
                            "头像",
                            "会友"
                        ],
                        handler: {
                            modified: {
                                subject: "即将退出选择编辑",
                                text: "异动内容将不会保存, 请确认后执行"
                            }
                        }
                    },
                    option: {
                        all: {
                            subject: "全部"
                        }
                    },
                    th: [
                        "头像",
                        "会友",
                        "操作"
                    ]
                },
                cycle: {
                    schedule: {
                        subject: "排程预览",
                        th: {
                            0: {
                                subject: "#"
                            },
                            1: {
                                subject: "开始时间"
                            },
                            2: {
                                subject: "结束时间"
                            }
                        },
                        footer: {
                            summary: {
                                subject: "合计 <b class=\"ft-16 text-base\">%s</b> 次循环排程"
                            }
                        }
                    },
                    field: {
                        cycle: {
                            subject: "方式"
                        },
                        duration: {
                            subject: "时长"
                        }
                    },
                    at: {
                        subject: "每",
                        text: {
                            1: "日",
                            2: "週",
                            3: "个月",
                            4: "年"
                        },
                        forever: {
                            subject: "永远"
                        },
                        until: {
                            subject: "直到"
                        },
                        repeat: {
                            subject: "重复",
                            text: "次"
                        }
                    },
                    week: {
                        begin: {
                            subject: "首次起始日<br/>为星期%s不能取消"
                        },
                        day: [
                            {
                                subject: "日",
                                text: "星期天"
                            },
                            {
                                subject: "一",
                                text: "星期一"
                            },
                            {
                                subject: "二",
                                text: "星期二"
                            },
                            {
                                subject: "三",
                                text: "星期三"
                            },
                            {
                                subject: "四",
                                text: "星期四"
                            },
                            {
                                subject: "五",
                                text: "星期五"
                            },
                            {
                                subject: "六",
                                text: "星期六"
                            }
                        ]
                    },
                    method: {
                        1: {
                            subject: "在第%s天重复",
                            year: {
                                subject: "在 %s 的第%s天重复"
                            }
                        },
                        2: {
                            subject: "在第%s週的%s重复",
                            year: {
                                subject: "在 %s 的第%s週的%s重复"
                            }
                        },
                        3: {
                            subject: "在最后一个%s重复",
                            year: {
                                subject: "在 %s 的最后一个%s重复"
                            }
                        },
                        4: {
                            subject: "在最后一天重复",
                            year: {
                                subject: "在 %s 的最后一天重复"
                            }
                        },
                        5: {
                            subject: "选取希望重复的日期",
                            year: {
                                subject: "选取希望在%s号重复的月份"
                            }
                        }
                    },
                    month: [
                        {
                            subject: "1月"
                        },
                        {
                            subject: "2月"
                        },
                        {
                            subject: "3月"
                        },
                        {
                            subject: "4月"
                        },
                        {
                            subject: "5月"
                        },
                        {
                            subject: "6月"
                        },
                        {
                            subject: "7月"
                        },
                        {
                            subject: "8月"
                        },
                        {
                            subject: "9月"
                        },
                        {
                            subject: "10月"
                        },
                        {
                            subject: "11月"
                        },
                        {
                            subject: "12月"
                        }
                    ],
                    help: {
                        duration: {
                            subject: "目前最长可定义 %s 年週期, 请妥善利用资源",
                            until: {
                                subject: "请选择重复结束时间"
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择条件"
                        },
                        1002: {
                            subject: "请选取希望重复的日期"
                        },
                        1003: {
                            subject: "请选取希望重复的月份"
                        },
                        1004: {
                            subject: "请选择重复结束时间"
                        }
                    }
                },
                remind: {
                    field: {
                        common: {
                            subject: "常用选项"
                        },
                        custom: {
                            subject: "自定义选项",
                            join: {
                                subject: "新增自定义选项"
                            }
                        },
                        notify: {
                            subject: "通知方式",
                            email: {
                                holder: "请输入电子邮件",
                                join: {
                                    subject: "新增电子邮件"
                                }
                            }
                        }
                    },
                    at: [
                        {
                            subject: "活动时间点"
                        },
                        {
                            subject: "%s 分钟前"
                        },
                        {
                            subject: "%s 小时前"
                        },
                        {
                            subject: "%s 天前"
                        },
                        {
                            subject: "%s 週前"
                        }
                    ],
                    beforehand: {
                        subject: "系统内建提醒无法取消"
                    },
                    help: {
                        picked: {
                            subject: "已选%s个选项",
                            email: {
                                subject: "已设定%s个电子邮件"
                            }
                        },
                        selected: {
                            subject: "最多只能选择 <b class=\"ft-16\">%s</b> 个选项, 还可以选择 <b class=\"ft-16\">%s</b> 个选项",
                            full: {
                                subject: "已达到选项上限 <b class=\"ft-16\">%s</b> 个"
                            }
                        },
                        custom: {
                            subject: "自定义选项最多仅能设定 %s 笔"
                        },
                        notify: {
                            email: {
                                subject: "邮件名单最多仅能设定 %s 笔",
                                empty: {
                                    subject: "请输入电子邮件"
                                }
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入正确的电子邮件"
                        }
                    }
                }
            },
            help: {
                must: "标记为 <b class=\"cgRed\">*</b> 为必填栏位"
            },
            handler: {
                reset: {
                    subject: "即将清除表单",
                    text: "<span class=\"text-danger\">已经填写的资料将会清除, 请确认后执行</span>",
                    description: ""
                }
            },
            select: {
                head: {
                    church: {
                        label: "指定教会",
                        holder: "请选择指定教会",
                        help: "请谨慎使用指定教会功能",
                        broadcast: {
                            subject: "全部教会"
                        }
                    }
                }
            }
        },
        offCanvas: {
            subject: {
                search: "搜寻条件"
            },
            submit: {
                close: "关闭"
            }
        },
        table: {
            tip: {
                readonly: "总教会仅供查看资料, 无法新增修改"
            },
            mode: {
                on: "自行维护",
                off: "委託管理"
            },
            feature: {
                seq: {
                    view: "前往查看",
                    edit: "前往编辑"
                },
                friend: {
                    convert: "转入牧养会友",
                    add: "标记为待转入",
                    clear: "标记为不转入"
                },
                unauthorized: "未授权编辑",
                raise: "转交爱无界团队",
                copy: "复製",
                view: "查看",
                donate: "奉献纪录",
                edit: "编辑",
                more: "更多",
                enable: "启用",
                disable: "停用",
                paid: "标示已付款",
                integrate: {
                    not: {
                        allow: "整合文章无法复製"
                    }
                },
                comment: "留言内容",
                notification: {
                    entry: "推播纪录",
                    create: "新增推播"
                },
                destroy: {
                    event: "该群组下仍有 %s 个会友, 无法删除",
                    soul: "该分类下仍有 %s 篇文章, 无法删除",
                    activity: "该分类下仍有 %s 场活动, 无法删除",
                    live: "该分类下仍有 %s 场直播, 无法删除",
                    shepherd: {
                        attend: "该分类下仍有 %s 个聚会, 无法删除"
                    },
                    enable: "删除",
                    disable: "删除前请先停用"
                },
                cycle: {
                    history: "循环纪录"
                },
                attend: {
                    history: "聚会纪录",
                    schedule: "聚会排程"
                },
                simulation: "模拟登入",
                repair: "重建模拟"
            },
            field: {
                belong: "所属教会",
                check: "选取",
                created: "建立时间",
                creator: "建立人员",
                updated: "最后编辑时间",
                editor: "最后编辑人员",
                accessed: "最后存取时间",
                prompt: "提醒",
                period: "循环",
                status: "状态",
                view: "查看",
                edit: "操作"
            },
            holder: {
                status: "请选择状态"
            }
        },
        pagination: {
            length: "每页 %s 项资料",
            summary: "目前显示 第 <b class=\"cgBase\">%s</b> 页 共 <b class=\"cgBase\">%s</b> 页 全部共 <b class=\"cgBase\">%s</b> 项资料",
            first: "最前页",
            prev: "上一页",
            next: "下一页",
            last: "最后页",
            search: "搜寻",
            download: "汇出",
            increase: "新增",
            xhring: "资料读取中",
            reset: "重设",
            refresh: "刷新",
            empty: {
                subject: "抱歉...😔",
                text: "没有找到指定的资料",
                summary: "全部共 <b class=\"cgBase\">0</b> 项资料"
            },
            loading: {
                subject: "正在读取...请稍候"
            }
        },
        sortable: {
            empty: "目前暂时<br />没有可用排序的项目",
            th: [
                "封面图",
                "名称",
                "移动"
            ],
            move: "拖曳进行排序"
        },
        footer: {
            description: "系统由 <a target=\"_new\" href=\"%s\">中华爱无界全人关怀协会</a> <br/> 提供和维护 / <a target=\"_new\" href=\"%s\">使用教学</a>"
        },
        choice: {
            editor: {
                enable: {
                    subject: "启用点名选项"
                },
                multiple: {
                    subject: "选项可复选"
                },
                create: {
                    subject: "新增点名选项"
                },
                holder: {
                    subject: "请输入选项名称"
                },
                error: {
                    1001: {
                        subject: "请至少建立1笔选项"
                    },
                    1002: {
                        subject: "请输入选项名称"
                    }
                }
            }
        },
        scroller: {
            top: "回到顶端",
            left: "往左翻动",
            right: "往右翻动",
            end: "翻至编辑"
        },
        activity: {
            echelon: {
                editor: {
                    subject: "第 <b class=\"ft-20 text-danger\">%s</b> 场",
                    canvas: {
                        echelon: {
                            create: {
                                subject: "新增场次"
                            }
                        }
                    },
                    help: {
                        add: {
                            custom: "新增场次"
                        },
                        message: "请依照升序方式编辑或删除, 避免日期设定错误"
                    },
                    field: {
                        subject: "场次名称",
                        begin: "开始时间",
                        end: "结束时间",
                        description: "描述",
                        every: {
                            subject: "第 <b class=\"m-l-5 m-r-5 ft-28\">%s</b> 场",
                            text: "%s 至 %s"
                        }
                    },
                    holder: {
                        subject: "请输入课程名称",
                        begin: "请选择开始时间",
                        end: "请选择结束时间",
                        description: "请输入描述"
                    },
                    handler: {
                        remove: {
                            subject: "即将删除场次",
                            text: "请确认后执行"
                        }
                    },
                    submit: {
                        up: "上移",
                        down: "下移",
                        edit: "编辑",
                        remove: "删除"
                    },
                    error: {
                        1001: {
                            subject: "请输入场次名称",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            register: {
                editor: {
                    form: {
                        1: {
                            holder: "请输入电子邮件"
                        },
                        2: {
                            holder: "请输入%s"
                        },
                        3: {
                            holder: "请输入%s"
                        },
                        4: {
                            active: "是",
                            inactive: "否"
                        },
                        5: {
                            holder: "请输入%s的描述"
                        },
                        6: {
                            holder: "请选择%s的描述"
                        },
                        7: {
                            holder: "请选择%s"
                        },
                        8: {
                            holder: "请输入姓名"
                        },
                        rank: "第 <b class=\"ft-16 text-success\">%s</b> 位报名人员",
                        validator: {
                            1: {
                                subject: "必填"
                            },
                            2: {
                                subject: "电子邮件格式错误"
                            },
                            3: {
                                subject: "请至少选择一个选项"
                            }
                        }
                    },
                    canvas: {
                        discount: {
                            not: {
                                allow: "请依照顺序编辑"
                            },
                            create: {
                                subject: "新增报名优惠"
                            },
                            edit: {
                                subject: "编辑报名优惠"
                            }
                        }
                    },
                    tip: {
                        edit: "请依照升序方式编辑或删除, 避免日期与优惠价格设定错误"
                    },
                    help: {
                        add: {
                            custom: "新增团报"
                        },
                        discount: {
                            custom: "新增报名优惠",
                            maximum: "最多仅能建立 <b class=\"cgBase\">%s</b> 笔报名优惠",
                            not: {
                                next: "优惠价格已经达到最高值, 无法再继续设定优惠价格"
                            }
                        },
                        external: "若关闭外部报名, 仅能用App报名并且无法使用团体报名"
                    },
                    enable: {
                        external: {
                            on: "开启",
                            off: "关闭"
                        },
                        charge: {
                            on: "需收费",
                            off: "免费"
                        },
                        restrict: {
                            on: "需限制",
                            off: "不限制"
                        },
                        personal: {
                            on: "开启",
                            off: "关闭"
                        },
                        group: {
                            on: "开启",
                            off: "关闭"
                        },
                        qrcode: {
                            on: "开启",
                            off: "关闭"
                        },
                        discount: {
                            on: "开启",
                            off: "关闭"
                        },
                        payment: {
                            on: "开启",
                            off: "关闭"
                        }
                    },
                    field: {
                        external: {
                            subject: "外部报名"
                        },
                        restrict: {
                            subject: "人数上限",
                            field: "人数"
                        },
                        charge: {
                            subject: "费用",
                            field: "金额"
                        },
                        qrcode: {
                            subject: "扫码报到"
                        },
                        method: {
                            subject: "报名方式",
                            personal: {
                                subject: "个人报名"
                            },
                            group: {
                                subject: "团体报名",
                                minimum: "团体最少报名人数",
                                maximum: "团体最多报名人数"
                            }
                        },
                        discount: {
                            th: "优惠内容",
                            subject: "报名优惠",
                            price: "优惠价格",
                            begin: "开始时间",
                            end: "结束时间",
                            description: {
                                subject: "描述内容",
                                text: "可以直接编辑"
                            }
                        },
                        payment: {
                            subject: "线上支付",
                            yet: {
                                subject: "未付款"
                            },
                            paid: {
                                subject: "已付款"
                            }
                        }
                    },
                    text: {
                        discount: {
                            first: "<span class=\"text-danger\">(包含往前日期)</span>"
                        }
                    },
                    method: {
                        note: {
                            subject: "温馨提示",
                            text: "人数上限未达团体报名最低限制 <b class=\"text-danger ft-16\">%s</b> 人, 无法使用团体报名, 仅能使用个人报名"
                        }
                    },
                    payment: {
                        note: {
                            subject: "备注"
                        },
                        free: {
                            subject: "温馨提示",
                            text: "已经设定活动免费, 无法启用线上支付功能"
                        },
                        newebpay: {
                            subject: "温馨提示",
                            text: "贵教会未开启线上奉献功能, 无法启用线上支付功能"
                        },
                        description: {
                            1: {
                                subject: "当报名费用低于 <b class=\"text-success ft-16\">30</b> 或 超过 <b class=\"text-success ft-16\">20,000</b> 时, 将无法使用 <b class=\"text-success\">超商代码缴费</b> 方式支付"
                            },
                            2: {
                                subject: "当报名费用低于 <b class=\"text-success ft-16\">20</b> 或 超过 <b class=\"text-success ft-16\">40,000</b> 时, 将无法使用 <b class=\"text-success\">超商条码缴费</b> 方式支付"
                            },
                            3: {
                                subject: "当报名费用超过 <b class=\"text-success ft-16\">49,999</b> 时, 将无法使用 <b class=\"text-success\">WebATM</b>, <b class=\"text-success\">ATM转帐</b> 方式支付"
                            },
                            4: {
                                subject: "<b class=\"text-success\">WebATM</b>, <b class=\"text-success\">ATM转帐</b> 支付方式, 仅支援 <b class=\"text-success\">台湾银行</b> 与 <b class=\"text-success\">华南银行</b> 之银行户头"
                            },
                            5: {
                                subject: "以上单位均为 <b class=\"text-success\">新台币</b>"
                            }
                        }
                    },
                    holder: {
                        restrict: "请输入人数",
                        charge: "请输入金额",
                        method: {
                            group: {
                                minimum: "请输入团体最少报名人数",
                                maximum: "请输入团体最多报名人数"
                            }
                        },
                        discount: {
                            price: "请输入优惠价格",
                            begin: "请选择开始时间",
                            end: "请选择结束时间",
                            description: "请输入描述内容"
                        }
                    },
                    handler: {
                        remove: {
                            subject: "即将删除优惠价格",
                            text: "请确认后执行"
                        },
                        charge: {
                            off: {
                                subject: "即将清空优惠价格设定",
                                text: "您已经有设定优惠价格, 请确认后执行"
                            }
                        }
                    },
                    echelon: {
                        edit: {
                            subject: "变更场次",
                            sort: {
                                subject: "第 <b class=\"ft-22\">%s</b> 场"
                            },
                            between: {
                                subject: "%s 至 %s"
                            },
                            handler: {
                                update: {
                                    subject: "即将变更场次",
                                    text: "请确认后执行"
                                }
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入金额",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请至少加入一笔报名优惠",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入开始时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入结束时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入优惠价格",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请输入人数",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请至少选择一种报名方式",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "优惠价格设定异常",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入最少报名人数",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "低于最少报名人数限制",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入最多报名人数",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "高于报名限制人数",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            condition: {
                editor: {
                    subject: "栏位设定",
                    canvas: {
                        field: {
                            create: {
                                subject: "新增栏位",
                                option: "新增选项"
                            }
                        }
                    },
                    tip: {
                        not: {
                            remove: "系统条件无法移除"
                        },
                        option: {
                            empty: "请至少新增一笔选项",
                            append: "附加输入框"
                        }
                    },
                    help: {
                        add: {
                            custom: "新增栏位"
                        }
                    },
                    kind: {
                        1: "电子邮件",
                        2: "文字区块",
                        3: "文字",
                        4: "是否",
                        5: "复选",
                        6: "单选",
                        7: "日期",
                        8: "姓名"
                    },
                    field: {
                        detail: "栏位设定",
                        option: "选项值",
                        show: "显示",
                        must: "必填",
                        subject: "名称",
                        description: "描述"
                    },
                    holder: {
                        subject: "请输入显示名称",
                        description: "请输入描述",
                        option: "请输入选项值"
                    },
                    handler: {
                        remove: {
                            subject: "即将删除栏位",
                            text: "请确认后执行"
                        }
                    },
                    submit: {
                        up: "上移",
                        down: "下移",
                        remove: "移除"
                    },
                    error: {
                        1001: {
                            subject: "请至少选择一个显示栏位",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入选项值",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请至少新增一笔选项",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            remind: {
                editor: {
                    help: {
                        notification: "推播至活动建立者",
                        add: {
                            email: "新增电子邮件"
                        },
                        maximum: {
                            email: "寄送邮件名单最多仅能设定 <b class=\"cgBase\">%s</b> 笔"
                        }
                    },
                    subject: "递交报名后提醒的传达方式",
                    field: {
                        notification: "推播通知",
                        email: "电子邮件"
                    },
                    holder: {
                        email: "请输入电子邮件"
                    },
                    error: {
                        1001: {
                            subject: "请指定通知方式",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请至少选择一名收件人",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入邮件地址",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "邮件格式错误",
                            text: "",
                            description: ""
                        }
                    }
                }
            }
        },
        integrate: {
            editor: {
                help: {
                    early: {
                        subject: "需要早于直拨开始时间",
                        activity: {
                            subject: "需要早于活动开始时间"
                        }
                    },
                    async: {
                        subject: "将自动同步直拨标题",
                        activity: {
                            subject: "将自动同步文章标题"
                        }
                    },
                    copy: "复製直播描述",
                    share: "前往设定分享描述"
                },
                label: {
                    live: "直播",
                    on: "启用",
                    off: "关闭"
                },
                field: {
                    mode: "整合",
                    kind: "种类",
                    category: "主分类",
                    secondary: "次分类",
                    subject: "标题",
                    publish: {
                        on: "上架时间",
                        off: "下架时间"
                    },
                    hyperlink: {
                        media: "Youtube连结",
                        music: "Google云端MP3"
                    },
                    status: "状态",
                    share: "转发分享",
                    content: "内容"
                },
                holder: {
                    category: "请选择主分类",
                    secondary: "请选择次分类",
                    subject: "标题",
                    publish: {
                        on: "请选择上架时间",
                        off: "请选择下架时间"
                    },
                    hyperlink: {
                        media: "请输入Youtube连结",
                        music: "请输入Google云端MP3"
                    },
                    status: "请选择状态",
                    share: "请选择转发分享",
                    content: "请输入内容"
                },
                error: {
                    1001: {
                        subject: "请选择主分类",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "请选择次分类",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "请输入标题",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "请选择上架时间",
                        text: "",
                        description: ""
                    },
                    1005: {
                        subject: "上架时间需要小于直播开始时间",
                        text: "",
                        description: ""
                    },
                    1006: {
                        subject: "下架时间必须大于上架时间",
                        text: "",
                        description: ""
                    },
                    1007: {
                        subject: "请选择状态",
                        text: "",
                        description: ""
                    },
                    1008: {
                        subject: "请选择转发分享",
                        text: "",
                        description: ""
                    },
                    1009: {
                        subject: "请选择种类",
                        text: "",
                        description: ""
                    },
                    1010: {
                        subject: "请输入Youtube连结",
                        text: "",
                        description: ""
                    },
                    1011: {
                        subject: "请输入Google云端MP3",
                        text: "",
                        description: ""
                    }
                }
            }
        }
    },
    page: {
        app: {
            subject: "领袖的好帮手, 会友的好工具",
            text: "一个专属教会的APP, 是教会的媒体与资讯发佈工具",
            description: "教会讯息、活动不漏接, 随时随地跟着读经、祷告",
            button: {
                apple: "Apple用户端",
                google: "Android用户端"
            }
        },
        mapping: {
            subject: "系统安全性增强请求",
            text: "请使用App上的扫描功能, 进行管理员绑定",
            description: "如未绑定将暂时无法使用管理功能"
        },
        login: {
            subject: "APP管理系统",
            feature: {
                authorized: {
                    qrcode: "扫码登入",
                    passwd: "帐密登入"
                },
                forgot: "忘记密码"
            },
            field: {
                account: "登入帐号",
                passwd: "登入密码",
                captcha: "验证码"
            },
            holder: {
                account: "请输入登入帐号",
                passwd: "请输入登入密码",
                captcha: "请输入图形中的文字"
            },
            error: {
                1000: {
                    subject: "暂时无法使用",
                    text: "请稍后再试或请联络管理人员"
                },
                1001: {
                    subject: "请输入登入帐号",
                    text: ""
                },
                1002: {
                    subject: "请输入登入密码",
                    text: ""
                },
                1003: {
                    subject: "请输入图形中的文字",
                    text: ""
                },
                1004: {
                    subject: "验证码应为6个字元",
                    text: ""
                },
                1005: {
                    subject: "验证码错误",
                    text: ""
                }
            }
        },
        forgot: {
            verification: {
                reset: {
                    subject: "重设登入密码",
                    feature: {
                        back: "返回登入"
                    },
                    expire: {
                        subject: "本次重设密码令牌将在 <b class=\"ft-14 text-danger\">%s</b> 秒后失效",
                        text: "本次重设密码令牌已经失效"
                    },
                    field: {
                        passwd: {
                            create: "新密码",
                            confirm: "确认新密码"
                        }
                    },
                    holder: {
                        passwd: {
                            create: "请输入新密码",
                            confirm: "请再输入一次新密码进行确认"
                        }
                    },
                    handler: {
                        success: {
                            subject: "密码重设成功",
                            text: "请使用您设定的新密码登入"
                        },
                        destroy: {
                            subject: "是否确定放弃重设密码",
                            text: "确认后将返回登入页面"
                        }
                    },
                    error: {
                        1000: {
                            subject: "暂时无法使用",
                            text: "请稍后再试或请联络管理人员"
                        },
                        1001: {
                            subject: "请输入新密码",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "新密码强度不足",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请再输入新密码进行确认",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "新密码确认失败",
                            text: "两次密码输入不一致",
                            description: ""
                        },
                        9999: {
                            subject: "重设密码令牌已失效",
                            text: "请重新进行忘记密码程序"
                        }
                    }
                },
                subject: "确认邮件验证码",
                text: "请输入电子邮件中的 6 位数字验证码",
                expire: {
                    subject: "本次重设密码令牌将在 <b class=\"ft-14 text-danger\">%s</b> 秒后失效",
                    text: "本次重设密码令牌已经失效"
                },
                feature: {
                    back: "返回登入"
                },
                handler: {
                    destroy: {
                        subject: "是否确定放弃重设密码",
                        text: "确认后将返回登入页面"
                    }
                },
                error: {
                    1000: {
                        subject: "暂时无法使用",
                        text: "请稍后再试或请联络管理人员"
                    },
                    1001: {
                        subject: "验证码空白",
                        text: "请输入您的验证码"
                    },
                    1002: {
                        subject: "验证码长度异常",
                        text: "验证码长度应为 <b class=\"ft-18 text-danger\">6</b> 个数字"
                    },
                    1003: {
                        subject: "验证码错误",
                        text: "请确认您的验证码"
                    },
                    9999: {
                        subject: "重设密码令牌已失效",
                        text: "请重新进行忘记密码程序"
                    }
                }
            },
            subject: "忘记密码",
            feature: {
                back: "返回登入"
            },
            field: {
                account: "帐号",
                email: "电子邮件",
                captcha: "验证码"
            },
            holder: {
                account: "请输入管理员帐号",
                email: "请输入管理员电子邮件",
                captcha: "请输入图形中的文字"
            },
            error: {
                1000: {
                    subject: "暂时无法使用",
                    text: "请稍后再试或请联络管理人员"
                },
                1001: {
                    subject: "请输入帐号",
                    text: ""
                },
                1002: {
                    subject: "帐号长度不符合规则",
                    text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 个字元"
                },
                1003: {
                    subject: "请输入管理员电子邮件",
                    text: ""
                },
                1004: {
                    subject: "电子邮件格式错误",
                    text: ""
                },
                1005: {
                    subject: "请输入图形中的文字",
                    text: ""
                },
                1006: {
                    subject: "验证码应为6个字元",
                    text: ""
                },
                1007: {
                    subject: "验证码错误",
                    text: ""
                },
                1008: {
                    subject: "找不到指定的管理员",
                    text: ""
                }
            }
        },
        billboard: {
            index: {
                uncheck: "未读",
                empty: "暂时没有任何公告",
                not: {
                    found: "找不到指定的公告"
                }
            },
            edit: {
                read: "%s・共计 <b class=\"%s\">%s</b> 次阅读"
            }
        },
        admin: {
            billboard: {
                index: {
                    mode: {
                        on: "置顶",
                        off: "未置顶"
                    },
                    feature: {
                        create: {
                            subject: "新增公吿"
                        }
                    },
                    field: {
                        avatar: "头像",
                        subject: "名称",
                        top: "置顶",
                        summary: {
                            read: "已读取"
                        }
                    },
                    holder: {
                        subject: "请输入名称"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用公告",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用公告",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除公告, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        }
                    },
                    field: {
                        subject: "标题",
                        content: "内容",
                        top: "置顶"
                    },
                    holder: {
                        subject: "请输入标题",
                        content: "请输入内容",
                        top: "请选择是否置顶"
                    },
                    handler: {
                        store: {
                            subject: "即将新增公吿",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择是否置顶",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "阅读"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    field: {
                        subject: "标题",
                        content: "内容",
                        top: "置顶",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        subject: "请输入标题",
                        content: "请输入内容",
                        top: "请选择是否置顶",
                        keyword: "搜寻范围: 名字/邮件/会友编号"
                    },
                    handler: {
                        update: {
                            subject: "即将更新公告内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择是否置顶",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            dashboard: {
                help: {
                    updated: "最后更新时间",
                    original: "预设区间",
                    loading: "正在计算统计数据..."
                },
                field: {
                    church: "指定教会",
                    begin: "开始时间",
                    end: "结束时间",
                    statistics: {
                        client: "会友人数",
                        coming: "会友新增",
                        delete: "会友删除",
                        publish: "上稿次数",
                        notification: "发送推播",
                        bible: "圣经开启",
                        favorite: "收藏次数",
                        comment: "留言次数",
                        share: "分享次数",
                        visit: "文章观看",
                        social: "外连观看",
                        login: "登入次数"
                    }
                },
                holder: {
                    church: "请选择指定教会"
                }
            },
            user: {
                index: {
                    feature: {
                        create: {
                            subject: "新增管理员"
                        }
                    },
                    field: {
                        avatar: "头像",
                        name: "名字",
                        account: "帐号",
                        email: "电子邮件"
                    },
                    holder: {
                        name: "请输入名字",
                        account: "请输入帐号",
                        email: "请输入电子邮件",
                        empty: {
                            email: "未输入"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即将启用管理员",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用管理员",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除管理员, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "权限"
                        }
                    },
                    subject: {
                        passwd: "设置密码"
                    },
                    field: {
                        account: "帐号",
                        name: "名字",
                        passwd: {
                            generate: "登入密码",
                            validation: "确认登入密码"
                        },
                        email: "电子邮件",
                        phone: "联络电话"
                    },
                    holder: {
                        account: "请输入帐号",
                        name: "请输入名字",
                        passwd: {
                            generate: "请输入登入密码",
                            validation: "请再次输入登入密码进行确认"
                        },
                        email: "请输入电子邮件",
                        phone: "请输入联络电话",
                        search: "请输入关键字或权限代号, 进行权限名称搜寻"
                    },
                    handler: {
                        store: {
                            subject: "即将新增管理员",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入帐号",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "帐号长度不符合规则",
                            text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 个字元",
                            description: ""
                        },
                        1003: {
                            subject: "帐号已经使用",
                            text: "请更换一个帐号",
                            description: ""
                        },
                        1004: {
                            subject: "请输入名字",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入电子邮件",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "电子邮件格式错误",
                            text: "请输入正确的电子邮件格式",
                            description: ""
                        },
                        1007: {
                            subject: "请输入联络电话",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入登入密码",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "登入密码强度不足",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请再输入登入密码进行确认",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "密码确认失败",
                            text: "两次密码输入不一致",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        passwd: "如果不需要变更密码, 不用填写",
                        root: "系统帐号, 不得变更权限",
                        disabled: "请注意, 这名管理员已经停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "权限"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    subject: {
                        passwd: "变更密码"
                    },
                    field: {
                        avatar: "头像",
                        account: "帐号",
                        name: "名字",
                        passwd: {
                            current: "目前当前密码",
                            generate: "登入密码",
                            validation: "确认登入密码"
                        },
                        email: "电子邮件",
                        phone: "联络电话"
                    },
                    holder: {
                        account: "请输入帐号",
                        name: "请输入名字",
                        passwd: {
                            current: "请输入目前当前密码",
                            generate: "请输入登入密码",
                            validation: "请再次输入登入密码进行确认"
                        },
                        email: "请输入电子邮件",
                        phone: "请输入联络电话",
                        search: "请输入关键字或权限代号, 进行权限名称搜寻"
                    },
                    handler: {
                        update: {
                            subject: "即将更新管理员内容",
                            text: "请确认后执行",
                            description: "",
                            warning: {
                                subject: "即将更新管理员内容",
                                text: "<span class=\"text-danger ft18\">您即将移除您编辑管理员权限的功能<br />移除后你将无法编辑管理员, 请确认后执行</span>",
                                description: ""
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入帐号",
                            text: ""
                        },
                        1002: {
                            subject: "帐号长度不符合规则",
                            text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 个字元"
                        },
                        1003: {
                            subject: "帐号已经使用",
                            text: "请更换一个帐号"
                        },
                        1004: {
                            subject: "请输入名字",
                            text: ""
                        },
                        1005: {
                            subject: "请输入电子邮件",
                            text: ""
                        },
                        1006: {
                            subject: "电子邮件格式错误",
                            text: "请输入正确的电子邮件格式"
                        },
                        1007: {
                            subject: "请输入联络电话",
                            text: ""
                        },
                        1008: {
                            subject: "请选择状态",
                            text: ""
                        },
                        1009: {
                            subject: "请输入当前密码",
                            text: ""
                        },
                        1010: {
                            subject: "当前密码错误",
                            text: ""
                        },
                        1011: {
                            subject: "请输入登入密码",
                            text: ""
                        },
                        1012: {
                            subject: "登入密码强度不足",
                            text: ""
                        },
                        1013: {
                            subject: "请再输入登入密码进行确认",
                            text: ""
                        },
                        1014: {
                            subject: "密码确认失败",
                            text: "两次密码输入不一致"
                        },
                        9999: {
                            subject: "即将返回首页",
                            text: "您已经自行移除管理员管理员权限"
                        }
                    }
                }
            },
            church: {
                index: {
                    download: {
                        name: "档案汇出_教会管理"
                    },
                    feature: {
                        create: {
                            subject: "新增教会"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        avatar: "主图",
                        no: "编号",
                        name: "名称",
                        client: "会友人数",
                        soul: "心灵捕手状态",
                        daily: "每日灵粮状态"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        begin: "请选择建立日期(起)",
                        end: "请选择建立日期(迄)",
                        no: "请输入编号",
                        name: "请输入名称",
                        soul: "请选择心灵捕手状态",
                        daily: "请选择每日灵粮状态"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用教会",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用教会",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行教会汇出, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "联络人"
                        },
                        2: {
                            subject: "奉献"
                        },
                        3: {
                            subject: "模组"
                        }
                    },
                    help: {
                        church: "请谨慎使用指定教会功能"
                    },
                    field: {
                        name: "名称",
                        no: "编号",
                        location: {
                            country: "所在地区",
                            city: "所在城市"
                        },
                        address: "地址",
                        contact: [
                            {
                                subject: "主要联络人",
                                name: "姓名",
                                phone: "联络电话",
                                email: "电子邮件"
                            },
                            {
                                subject: "次要联络人",
                                name: "姓名",
                                phone: "联络电话",
                                email: "电子邮件"
                            }
                        ],
                        timezone: {
                            subject: [
                                "时区设定",
                                "时区与机构设定"
                            ],
                            zone: "所在时区",
                            organization: "机构"
                        },
                        control: {
                            subject: "控制项",
                            my: {
                                subject: "我的教会标题名称",
                                text: "(尺寸为 %sx%s 像素)",
                                avatar: "标题图"
                            },
                            daily: {
                                mode: "系统模式",
                                subject: "每日灵粮标题名称",
                                text: "(尺寸为 %sx%s 像素)",
                                avatar: "标题图"
                            },
                            soul: {
                                mode: "系统模式",
                                subject: "心灵捕手标题名称"
                            },
                            query: "查询长度"
                        },
                        donate: {
                            mode: "奉献方式",
                            newebpay: {
                                merchant: "蓝新金流Merchant ID",
                                key: "蓝新金流Hash KEY",
                                iv: "蓝新金流Hash IV"
                            },
                            hyperlink: {
                                text: "奉献连结文字",
                                url: "奉献连结",
                                description: "奉献描述内容"
                            }
                        },
                        other: {
                            subject: "其他设定",
                            offset: "App首页最新消息笔数",
                            period: "查询区间",
                            invitation: {
                                customize: "邀请码模式",
                                code: "教会邀请码"
                            }
                        }
                    },
                    holder: {
                        name: "请输入名称",
                        no: "请输入编号",
                        location: {
                            country: "请选择所在地区",
                            city: "请选择所在城市"
                        },
                        address: "请输入地址",
                        contact: [
                            {
                                name: "请输入姓名",
                                phone: "请输入联络电话",
                                email: "请输入电子邮件"
                            },
                            {
                                name: "请输入姓名",
                                phone: "请输入联络电话",
                                email: "请输入电子邮件"
                            }
                        ],
                        timezone: {
                            zone: "请选择时区",
                            organization: "请选择机构"
                        },
                        control: {
                            my: {
                                subject: "请输入我的教会标题名称"
                            },
                            daily: {
                                subject: "请输入每日灵粮标题名称"
                            },
                            soul: {
                                subject: "请输入心灵捕手标题名称"
                            },
                            query: "请选择查询长度"
                        },
                        donate: {
                            newebpay: {
                                merchant: "请输入蓝新金流Merchant ID",
                                key: "请输入蓝新金流Hash KEY",
                                iv: "请输入蓝新金流Hash IV"
                            },
                            hyperlink: {
                                text: "请输入奉献连结文字",
                                url: "请输入奉献连结",
                                description: "请输入奉献描述内容"
                            }
                        },
                        other: {
                            offset: "请选择App首页最新消息笔数",
                            period: "请选择查询区间",
                            invitation: {
                                code: [
                                    "请点击图示产生随机邀请码",
                                    "请点击图示自行输入邀请码"
                                ]
                            }
                        }
                    },
                    handler: {
                        store: {
                            subject: "即将新增教会",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "异常发生",
                            text: "无法停用目前登入的教会",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择所在区域",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择所在城市",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择所在时区",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择机构",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入我的教会标题名称",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入心灵捕手标题名称",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请选择心灵捕手系统模式",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入每日灵粮标题名称",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请选择每日灵粮系统模式",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请输入地址",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "请输入编号",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "编号已经存在",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "请选择查询长度",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "请选择邀请码模式",
                            text: "",
                            description: ""
                        },
                        1018: {
                            subject: "请选择App首页最新消息笔数",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请输入主要联络人姓名",
                            text: "",
                            description: ""
                        },
                        2002: {
                            subject: "请输入主要联络人联络电话",
                            text: "",
                            description: ""
                        },
                        2003: {
                            subject: "请输入主要联络人电子邮件",
                            text: "",
                            description: ""
                        },
                        2004: {
                            subject: "主要联络人电子邮件格式错误",
                            text: "请输入正确的电子邮件格式",
                            description: ""
                        },
                        2005: {
                            subject: "次要联络人电子邮件格式错误",
                            text: "请输入正确的电子邮件格式",
                            description: ""
                        },
                        3001: {
                            subject: "请选择奉献系统模式",
                            text: "",
                            description: ""
                        },
                        3002: {
                            subject: "请输入蓝新金流Merchant ID",
                            text: "",
                            description: ""
                        },
                        3003: {
                            subject: "请输入蓝新金流Hash KEY",
                            text: "",
                            description: ""
                        },
                        3004: {
                            subject: "请输入蓝新金流Hash IV",
                            text: "",
                            description: ""
                        },
                        3005: {
                            subject: "请输入奉献连结",
                            text: "",
                            description: ""
                        },
                        3006: {
                            subject: "请输入奉献连结文字",
                            text: "",
                            description: ""
                        },
                        3007: {
                            subject: "请输入奉献描述内容",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "请注意, 这间教会已经停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "联络人"
                        },
                        2: {
                            subject: "奉献"
                        },
                        3: {
                            subject: "模组"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    generate: [
                        "随机产生",
                        "自行输入",
                        "清除邀请码"
                    ],
                    field: {
                        name: "名称",
                        no: "编号",
                        location: {
                            country: "所在地区",
                            city: "所在城市"
                        },
                        address: "地址",
                        description: {
                            subject: "简介标题",
                            text: "简介内容"
                        },
                        hyperlink: {
                            text: "连结文字",
                            url: "连结网址"
                        },
                        contact: [
                            {
                                subject: "主要联络人",
                                name: "姓名",
                                phone: "联络电话",
                                email: "电子邮件"
                            },
                            {
                                subject: "次要联络人",
                                name: "姓名",
                                phone: "联络电话",
                                email: "电子邮件"
                            }
                        ],
                        timezone: {
                            subject: [
                                "时区设定",
                                "时区与机构设定"
                            ],
                            zone: "所在时区",
                            organization: "机构"
                        },
                        control: {
                            subject: "控制项",
                            my: {
                                subject: "我的教会标题名称",
                                text: "(尺寸为 %sx%s 像素)",
                                avatar: "标题图"
                            },
                            daily: {
                                mode: "系统模式",
                                subject: "每日灵粮标题名称",
                                text: "(尺寸为 %sx%s 像素)",
                                avatar: "标题图"
                            },
                            soul: {
                                mode: "系统模式",
                                subject: "心灵捕手标题名称"
                            }
                        },
                        donate: {
                            mode: "奉献方式",
                            newebpay: {
                                merchant: "蓝新金流Merchant ID",
                                key: "蓝新金流Hash KEY",
                                iv: "蓝新金流Hash IV"
                            },
                            hyperlink: {
                                text: "奉献连结文字",
                                url: "奉献连结",
                                description: "奉献描述内容"
                            }
                        },
                        other: {
                            subject: "其他设定",
                            offset: "App首页最新消息笔数",
                            period: "查询区间",
                            invitation: {
                                customize: "邀请码模式",
                                code: "教会邀请码"
                            }
                        }
                    },
                    holder: {
                        name: "请输入名称",
                        no: "请输入编号",
                        location: {
                            country: "请选择所在地区",
                            city: "请选择所在城市"
                        },
                        address: "请输入地址",
                        description: {
                            subject: "请输入简介标题",
                            text: "请输入简介内容"
                        },
                        hyperlink: {
                            text: "请输入连结文字",
                            url: "请输入连结网址"
                        },
                        contact: [
                            {
                                name: "请输入姓名",
                                phone: "请输入联络电话",
                                email: "请输入电子邮件"
                            },
                            {
                                name: "请输入姓名",
                                phone: "请输入联络电话",
                                email: "请输入电子邮件"
                            }
                        ],
                        timezone: {
                            zone: "请选择时区",
                            organization: "请选择机构"
                        },
                        control: {
                            my: {
                                subject: "请输入我的教会标题名称"
                            },
                            daily: {
                                subject: "请输入每日灵粮标题名称"
                            },
                            soul: {
                                subject: "请输入心灵捕手标题名称"
                            }
                        },
                        donate: {
                            newebpay: {
                                merchant: "请输入蓝新金流Merchant ID",
                                key: "请输入蓝新金流Hash KEY",
                                iv: "请输入蓝新金流Hash IV"
                            },
                            hyperlink: {
                                text: "请输入奉献连结文字",
                                url: "请输入奉献连结",
                                description: "请输入奉献描述内容"
                            }
                        },
                        other: {
                            offset: "请选择App首页最新消息笔数",
                            period: "请选择查询区间",
                            invitation: {
                                code: [
                                    "请点击图示产生随机邀请码",
                                    "请点击图示自行输入邀请码"
                                ]
                            }
                        }
                    },
                    handler: {
                        invitation: {
                            clear: {
                                subject: "即将清除教会邀请码",
                                text: "<span class=\"text-danger\">清除后储存, 将无法使用教会邀请码功能<br />请确认后执行</span>",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即将更新教会内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "异常发生",
                            text: "无法停用目前登入的教会",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择所在区域",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择所在城市",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择所在时区",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择机构",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入我的教会标题名称",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入心灵捕手标题名称",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请选择心灵捕手系统模式",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入每日灵粮标题名称",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请选择每日灵粮系统模式",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请输入地址",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "请选择查询长度",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "请选择邀请码模式",
                            text: "",
                            description: ""
                        },
                        1018: {
                            subject: "请选择App首页最新消息笔数",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请输入主要联络人姓名",
                            text: "",
                            description: ""
                        },
                        2002: {
                            subject: "请输入主要联络人联络电话",
                            text: "",
                            description: ""
                        },
                        2003: {
                            subject: "请输入主要联络人电子邮件",
                            text: "",
                            description: ""
                        },
                        2004: {
                            subject: "主要联络人电子邮件格式错误",
                            text: "请输入正确的电子邮件格式",
                            description: ""
                        },
                        2005: {
                            subject: "次要联络人电子邮件格式错误",
                            text: "请输入正确的电子邮件格式",
                            description: ""
                        },
                        3001: {
                            subject: "请选择奉献系统模式",
                            text: "",
                            description: ""
                        },
                        3002: {
                            subject: "请输入蓝新金流Merchant ID",
                            text: "",
                            description: ""
                        },
                        3003: {
                            subject: "请输入蓝新金流Hash KEY",
                            text: "",
                            description: ""
                        },
                        3004: {
                            subject: "请输入蓝新金流Hash IV",
                            text: "",
                            description: ""
                        },
                        3005: {
                            subject: "请输入奉献连结",
                            text: "",
                            description: ""
                        },
                        3006: {
                            subject: "请输入奉献连结文字",
                            text: "",
                            description: ""
                        },
                        3007: {
                            subject: "请输入奉献描述内容",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            client: {
                index: {
                    tag: {
                        help: {
                            empty: "请先选取指定会友",
                            edit: "编辑个人标记",
                            join: "附加标籤到指定会友",
                            replace: "清除并附加标籤到指定会友",
                            remove: "删除指定会友的标籤",
                            reset: "重设已输入标籤值"
                        }
                    },
                    download: {
                        name: "档案汇出_会友管理"
                    },
                    feature: {
                        create: {
                            subject: "新增会友"
                        },
                        tag: {
                            on: "开启编辑标籤",
                            off: "关闭编辑标籤"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        avatar: "头像",
                        name: "名字",
                        email: "电子邮件",
                        account: "帐号",
                        commitment: "委身编号",
                        keyword: "标籤"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        begin: "请选择建立日期(起)",
                        end: "请选择建立日期(迄)",
                        name: "请输入名字",
                        email: "请输入电子邮件",
                        belong: "请选择所属教会",
                        empty: {
                            commitment: "未填写"
                        },
                        keyword: "请输入标籤,可用逗号分隔连续输入并按enter输入"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用会友",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用会友",
                            text: "请确认后执行",
                            description: ""
                        },
                        tag: {
                            store: {
                                subject: "即将对指定的会友加入标籤",
                                text: "请确认后执行",
                                description: ""
                            },
                            replace: {
                                subject: "即将对指定的会友取代标籤",
                                text: "请确认后执行",
                                description: ""
                            },
                            remove: {
                                subject: "即将对指定的会友移除标籤",
                                text: "请确认后执行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行会友汇出, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行全部会友删除, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "请注意, 这名会友已经停用"
                    },
                    commitment: {
                        empty: "未填写"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "标籤"
                        },
                        2: {
                            subject: "统计资讯"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        donate: {
                            subject: "奉献纪录"
                        },
                        share: {
                            subject: "分享次数"
                        },
                        visit: {
                            subject: "文章观看"
                        },
                        comment: {
                            subject: "文章回覆"
                        },
                        social: {
                            subject: "外连观看"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        avatar: "头像",
                        email: "电子邮件",
                        name: "名字",
                        title: "称谓",
                        birthday: "生日",
                        phone: "联络电话",
                        location: {
                            country: "所在地区",
                            city: "所在城市"
                        },
                        address: "联络地址",
                        occupation: "职业",
                        baptized: "受洗时间",
                        commitment: "委身编号",
                        created: "建立时间",
                        accessed: "最后存取时间",
                        deleted: "删除时间",
                        tag: {
                            subject: "标籤"
                        }
                    },
                    holder: {
                        head: "未输入",
                        belong: {
                            name: "",
                            no: ""
                        },
                        email: "请输入电子邮件",
                        name: "请输入名字",
                        title: "未输入称谓",
                        birthday: "未输入生日",
                        phone: "未输入联络电话",
                        location: {
                            country: "未输入所在地区",
                            city: "未输入所在城市"
                        },
                        address: "未输入联络地址",
                        occupation: "未输入职业",
                        baptized: "未输入受洗时间",
                        commitment: "请输入委身编号",
                        created: "",
                        accessed: ""
                    },
                    handler: {
                        update: {
                            subject: "即将更新会友内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                }
            },
            cycle: {
                index: {
                    feature: {
                        create: {
                            subject: "新增循环"
                        }
                    },
                    field: {
                        ref: "类型",
                        subject: "名称",
                        period: "週期"
                    },
                    holder: {
                        subject: "请输入名称"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用循环",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用循环",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "循环"
                        }
                    },
                    tip: {
                        create: "建立后类型与目标无法修改, 请确认"
                    },
                    help: {
                        church: "请谨慎使用指定教会功能",
                        live: {
                            subject: "点击查看说明",
                            text: "部分文章无法设定循环"
                        }
                    },
                    field: {
                        church: "指定教会",
                        ref: "种类",
                        kind: "类型",
                        subject: "名称",
                        selection: {
                            article: {
                                main: "主分类",
                                sub: "次分类"
                            }
                        },
                        item: {
                            subject: "标题",
                            begin: {
                                article: "上架时间",
                                notification: "排程时间",
                                other: "开始时间"
                            },
                            end: {
                                article: "下架时间",
                                other: "结束时间"
                            }
                        },
                        target: "项目"
                    },
                    holder: {
                        church: "请选择指定教会",
                        kind: "请选择类型",
                        subject: "请输入名称",
                        selection: {
                            article: {
                                main: "请选择主分类",
                                sub: "请选择次分类"
                            }
                        },
                        item: {
                            subject: "未填写标题",
                            begin: {
                                article: "未选择上架时间",
                                notification: "未选择排程时间",
                                other: "未选择开始时间"
                            },
                            end: {
                                article: "未选择下架时间",
                                other: "未选择结束时间"
                            }
                        },
                        target: "请选择项目"
                    },
                    handler: {
                        store: {
                            subject: "即将新增循环",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择类型",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择主分类",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择次分类",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择项目",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择指定教会",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "请注意, 这笔循环已经停用",
                        create: "建立后类型与目标无法修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "循环"
                        },
                        2: {
                            subject: "历程纪录"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        history: {
                            subject: "循环纪录"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        kind: "类型",
                        subject: "名称",
                        selection: {
                            article: {
                                main: "主分类",
                                sub: "次分类"
                            }
                        },
                        item: {
                            subject: "标题",
                            begin: {
                                article: "上架时间",
                                notification: "排程时间",
                                other: "开始时间"
                            },
                            end: {
                                article: "下架时间",
                                other: "结束时间"
                            }
                        },
                        target: "项目"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        kind: "请选择类型",
                        subject: "请输入名称",
                        selection: {
                            article: {
                                main: "请选择主分类",
                                sub: "请选择次分类"
                            }
                        },
                        item: {
                            subject: "未填写标题",
                            begin: {
                                article: "未选择上架时间",
                                notification: "未选择排程时间",
                                other: "未选择开始时间"
                            },
                            end: {
                                article: "未选择下架时间",
                                other: "未选择结束时间"
                            }
                        },
                        target: "请选择项目"
                    },
                    handler: {
                        update: {
                            subject: "即将更新循环内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                }
            },
            article: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分类"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            avatar: "封面图",
                            name: "名称",
                            quantity: "文章数量",
                            sort: "排序",
                            created: "建立时间"
                        },
                        holder: {
                            head: {
                                church: "请选择教会"
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            enable: {
                                subject: "即将启用分类",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将停用分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将删除分类, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    create: {
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        help: {
                            church: "请谨慎使用指定教会功能"
                        },
                        field: {
                            church: {
                                subject: "指定教会",
                                text: "仅能指定自行维护心灵捕手的教会"
                            },
                            avatar: {
                                subject: "封面图",
                                text: "(尺寸为 %sx%s 像素)"
                            },
                            name: "名称"
                        },
                        holder: {
                            church: "请选择指定教会",
                            name: "请输入名称"
                        },
                        handler: {
                            store: {
                                subject: "即将新增分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请选择封面图",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            1004: {
                                subject: "请指定教会",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "请注意, 这个分类已经停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "排序"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            belong: {
                                no: "所属教会ID",
                                name: "所属教会名称"
                            },
                            avatar: {
                                subject: "封面图",
                                text: "(尺寸为 %sx%s 像素)"
                            },
                            name: "名称"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            update: {
                                subject: "即将更新分类内容",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请选择封面图",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    integrate: {
                        badge: {
                            subject: "文章整合"
                        }
                    },
                    mindcatcher: {
                        badge: {
                            subject: "心灵捕手"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "查看留言纪录"
                        },
                        notification: {
                            subject: "查看推播纪录"
                        }
                    },
                    feature: {
                        create: {
                            subject: "新增文章"
                        },
                        mindcatcher: {
                            subject: "分享连结"
                        }
                    },
                    top: [
                        "未置顶",
                        "已置顶"
                    ],
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        category: "分类",
                        created: "建立时间"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        top: "请选择置顶",
                        begin: "请选择建立日期(起)",
                        end: "请选择建立日期(迄)",
                        category: {
                            subject: "请选择分类",
                            empty: "请先选择指定教会"
                        },
                        kind: "请选择类型"
                    },
                    handler: {
                        mindcatcher: {
                            subject: "我的教会x心灵捕手",
                            text: "<span class=\"text-success\">请贴上您的分享连结</span>",
                            description: "请输入分享连结"
                        },
                        top: {
                            enable: {
                                subject: "即将文章置顶",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将文章取消置顶",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        enable: {
                            subject: "即将启用文章",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用文章",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除文章, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "图片"
                        },
                        2: {
                            subject: "分享"
                        },
                        3: {
                            subject: "活动"
                        },
                        4: {
                            subject: "直播"
                        }
                    },
                    tip: {
                        live: "类型为直播, 无法使用活动次分类"
                    },
                    help: {
                        church: "请谨慎使用指定教会功能",
                        share: "前往设定分享描述"
                    },
                    field: {
                        church: {
                            my: {
                                subject: "指定教会",
                                text: ""
                            },
                            soul: {
                                subject: "指定教会",
                                text: "仅能指定自行维护心灵捕手的教会"
                            },
                            daily: {
                                subject: "指定教会",
                                text: "仅能指定自行维护每日灵粮的教会"
                            }
                        },
                        kind: "类型",
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        category: "分类",
                        subject: "标题",
                        publish: {
                            on: "上架时间",
                            off: "下架时间"
                        },
                        cycle: {
                            kind: "循环方式",
                            day: "循环日",
                            date: "日期",
                            time: "时间"
                        },
                        hyperlink: {
                            media: "Youtube连结",
                            music: "Google云端MP3",
                            live: "直播连结",
                            activity: "外部活动连结",
                            web: "外部网站连结"
                        },
                        content: "内容",
                        collection: "文章图片",
                        description: "分享内文",
                        status: "状态",
                        share: "转发分享"
                    },
                    holder: {
                        church: "请选择指定教会",
                        kind: "请选择类型",
                        category: {
                            subject: "请选择分类",
                            empty: "请先选择指定教会"
                        },
                        subject: "请输入标题",
                        publish: {
                            begin: "请选择上架时间",
                            end: "请选择下架时间"
                        },
                        cycle: {
                            kind: "请选择循环方式",
                            day: "请选择循环日",
                            date: "请选择日期",
                            time: "请选择时间"
                        },
                        hyperlink: {
                            media: "请输入Youtube连结",
                            music: "请输入Google云端MP3",
                            live: "请输入直播连结",
                            activity: "请输入外部活动连结",
                            web: "请输入外部网站连结"
                        },
                        content: "请输入内容",
                        collection: "",
                        description: "请输入分享内文",
                        status: "请选择状态",
                        share: "请选择转发分享"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即将新增文章",
                            text: "请确认后执行",
                            description: ""
                        },
                        copy: {
                            subject: "即将复製文章",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择类型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择上架时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "下架时间必须大于上架时间",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请输入外部活动连结",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入Youtube连结",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入Google云端MP3",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请输入直播连结",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入外部网站连结",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请选择转发分享",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "请指定教会",
                            text: "",
                            description: ""
                        },
                        3001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "此文章无法复製",
                            text: "已经启用整合功能",
                            description: ""
                        }
                    }
                },
                edit: {
                    comment: {
                        feature: {
                            calculate: {
                                subject: "重计留言数量"
                            }
                        },
                        subject: "留言纪录",
                        th: {
                            0: "头像",
                            1: "姓名",
                            2: "内容",
                            3: "时间"
                        },
                        delete: {
                            empty: {
                                subject: "请先选择需要删除的留言"
                            }
                        },
                        handler: {
                            calculate: {
                                subject: "即将重新计算文章留言数量",
                                text: "请确认后执行",
                                description: ""
                            },
                            delete: {
                                subject: "即将删除文章留言",
                                text: "请确认后执行",
                                description: ""
                            }
                        }
                    },
                    tip: {
                        disabled: "请注意, 这篇文章已经停用",
                        live: "类型为直播, 无法使用活动次分类"
                    },
                    help: {
                        share: "前往设定分享描述"
                    },
                    integrate: {
                        live: {
                            subject: "已启用直播文章整合, 无法编辑"
                        },
                        activity: {
                            subject: "已启用活动文章整合, 无法编辑"
                        },
                        mindcatcher: {
                            subject: "心灵捕手外部文章整合, 无法编辑"
                        },
                        hyperlink: "前往修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "图片"
                        },
                        2: {
                            subject: "分享"
                        },
                        3: {
                            subject: "活动"
                        },
                        4: {
                            subject: "直播"
                        },
                        5: {
                            subject: "统计资讯"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "留言纪录"
                        },
                        notification: {
                            subject: "推播纪录"
                        },
                        visit: {
                            subject: "观看次数"
                        },
                        social: {
                            subject: "外连观看"
                        },
                        favorite: {
                            subject: "收藏次数"
                        }
                    },
                    field: {
                        belong: {
                            no: "所属教会编号",
                            name: "所属教会名称"
                        },
                        kind: "类型",
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        category: "分类",
                        subject: "标题",
                        publish: {
                            on: "上架时间",
                            off: "下架时间"
                        },
                        cycle: {
                            kind: "循环",
                            day: "循环日",
                            date: "日期",
                            time: "时间"
                        },
                        content: "内容",
                        collection: "文章图片",
                        description: "分享内文",
                        status: "状态",
                        share: "转发分享"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "请选择类型",
                        category: "请选择分类",
                        subject: "请输入标题",
                        publish: {
                            on: "请选择上架时间",
                            off: "请选择下架时间"
                        },
                        cycle: {
                            kind: "请选择循环方式",
                            day: "请选择循环日",
                            date: "请选择日期",
                            time: "请选择时间"
                        },
                        content: "请输入内容",
                        collection: "",
                        description: "请输入分享内文",
                        status: "请选择状态",
                        share: "请选择转发分享"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即将更新文章内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择类型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择上架时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "下架时间必须大于上架时间",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请输入外部活动连结",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入Youtube连结",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入Google云端MP3",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请输入直播连结",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入外部网站连结",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请选择转发分享",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        },
                        3001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            gallery: {
                accept: {
                    empty: "此分类下找不到图片",
                    disable: "请先选择需要处理的图片",
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 张图片",
                    handler: {
                        reload: {
                            subject: "即将重新整理",
                            text: "已选择的项目将会重设, 请确认后执行",
                            description: ""
                        },
                        approved: {
                            subject: "即将核准指定的图片",
                            text: "请确认后执行",
                            description: ""
                        },
                        reject: {
                            subject: "即将拒绝指定的图片",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                },
                alarm: {
                    empty: "此分类下找不到图片",
                    disable: "请先选择需要处理的图片",
                    submit: {
                        reject: "核实检举",
                        revoke: "撤销检举"
                    },
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 张图片",
                    handler: {
                        reload: {
                            subject: "即将重新整理",
                            text: "已选择的项目将会重设, 请确认后执行",
                            description: ""
                        },
                        reject: {
                            subject: "即将核实检举指定的图片",
                            text: "请确认后执行",
                            description: ""
                        },
                        revoke: {
                            subject: "即将撤销检举指定的图片",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                },
                index: {
                    empty: "此分类下找不到图片",
                    disable: "请先选择需要处理的图片",
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 张图片",
                    handler: {
                        reload: {
                            subject: "即将重新整理",
                            text: "已选择的项目将会重设, 请确认后执行",
                            description: ""
                        },
                        destroy: {
                            subject: "即将拒绝指定的图片",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                }
            },
            notification: {
                v2: {
                    create: {
                        membership: {
                            tag: {
                                field: {
                                    keyword: {
                                        subject: "关键字",
                                        holder: "请输入关键字",
                                        text: "同时搜寻名字/邮件/会友编号"
                                    },
                                    tag: {
                                        subject: "标籤",
                                        holder: "请选择标籤"
                                    }
                                }
                            },
                            editor: {
                                subject: "请选择会友进行推播通知",
                                submit: {
                                    maximum: {
                                        subject: "最多只能选择%s位会友进行推播通知"
                                    },
                                    minimum: {
                                        subject: "请选择至少%s位会友进行推播通知"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "关键字",
                                    holder: "请输入关键字",
                                    text: "同时搜寻名字/邮件/会友编号"
                                },
                                tag: {
                                    subject: "标籤",
                                    holder: "请选择标籤"
                                }
                            },
                            empty: {
                                subject: "请选择会友进行推播通知"
                            },
                            not: {
                                found: {
                                    subject: "搜寻不到指定的推播通知会友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            }
                        },
                        field: {
                            type: {
                                subject: "类型",
                                holder: "请选择类型",
                                help: "类型于建立之后不能变更"
                            },
                            subject: {
                                subject: "标题",
                                holder: "请输入标题"
                            },
                            begin: {
                                subject: "排程时间",
                                holder: "请选择排程时间"
                            },
                            content: {
                                subject: "简述",
                                holder: "请输入简述"
                            },
                            description: {
                                subject: "内容",
                                holder: "请输入内容"
                            },
                            status: {
                                subject: "状态",
                                holder: "请选择状态"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "推播纪录"
                            }
                        },
                        alert: {
                            0: {
                                subject: "推播目前正在排程中"
                            },
                            1: {
                                subject: "目前推播正在进行中无法修改, 进度 %s"
                            },
                            2: {
                                subject: "推播已经全部完成无法修改"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将储存内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "编辑名单"
                            },
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请选择类型",
                                text: ""
                            },
                            1020: {
                                subject: "请选择指定教会",
                                text: ""
                            },
                            1030: {
                                subject: "请选择排除机构",
                                text: ""
                            },
                            1031: {
                                subject: "不能排除所有机构",
                                text: ""
                            },
                            1040: {
                                subject: "请输入标题",
                                text: ""
                            },
                            1050: {
                                subject: "请选择开始时间",
                                text: ""
                            },
                            1051: {
                                subject: "排程时间必须大于现在时间",
                                text: ""
                            },
                            1060: {
                                subject: "请输入简述",
                                text: ""
                            },
                            1070: {
                                subject: "请输入内容",
                                text: ""
                            },
                            2010: {
                                subject: "请至少选择1位会友进行推播",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        membership: {
                            tag: {
                                field: {
                                    keyword: {
                                        subject: "关键字",
                                        holder: "请输入关键字",
                                        text: "同时搜寻名字/邮件/会友编号"
                                    },
                                    tag: {
                                        subject: "标籤",
                                        holder: "请选择标籤"
                                    }
                                }
                            },
                            editor: {
                                subject: "请选择会友进行推播通知",
                                submit: {
                                    maximum: {
                                        subject: "最多只能选择%s位会友进行推播通知"
                                    },
                                    minimum: {
                                        subject: "请选择至少%s位会友进行推播通知"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "关键字",
                                    holder: "请输入关键字",
                                    text: "同时搜寻名字/邮件/会友编号"
                                },
                                tag: {
                                    subject: "标籤",
                                    holder: "请选择标籤"
                                }
                            },
                            empty: {
                                subject: "请选择会友进行推播通知"
                            },
                            not: {
                                found: {
                                    subject: "搜寻不到指定的推播通知会友"
                                }
                            }
                        },
                        history: {
                            field: {
                                recipient: {
                                    subject: "收件人"
                                },
                                read: {
                                    subject: "读取",
                                    value: {
                                        0: {
                                            subject: "未读取"
                                        },
                                        1: {
                                            subject: "已读取"
                                        }
                                    }
                                },
                                status: {
                                    subject: "状态",
                                    value: {
                                        0: {
                                            subject: "准备中"
                                        },
                                        1: {
                                            subject: "已送达"
                                        },
                                        2: {
                                            subject: "无法推播"
                                        },
                                        9: {
                                            subject: "用户删除"
                                        }
                                    }
                                },
                                created: {
                                    subject: "送达时间"
                                }
                            },
                            search: {
                                read: {
                                    subject: "读取",
                                    holder: "请选择读取"
                                },
                                status: {
                                    subject: "状态",
                                    holder: "请选择状态"
                                }
                            }
                        },
                        automatic: {
                            edit: {
                                tab: {
                                    0: {
                                        subject: "基本"
                                    },
                                    99: {
                                        subject: "相关资讯"
                                    }
                                },
                                field: {
                                    created: {
                                        subject: "建立时间",
                                        holder: ""
                                    },
                                    stamp: {
                                        subject: "执行时间",
                                        holder: ""
                                    },
                                    received: {
                                        subject: "生成时间",
                                        holder: ""
                                    }
                                }
                            },
                            field: {
                                recipient: {
                                    subject: "收件人"
                                },
                                id: {
                                    subject: "识别ID"
                                },
                                supplier: {
                                    subject: "支援厂商",
                                    value: {
                                        1: {
                                            subject: "APNS"
                                        },
                                        2: {
                                            subject: "FCM"
                                        }
                                    }
                                },
                                status: {
                                    subject: "状态",
                                    value: {
                                        0: {
                                            subject: "未对应"
                                        },
                                        1: {
                                            subject: "已对应"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立时间"
                                },
                                stamp: {
                                    subject: "执行时间"
                                },
                                received: {
                                    subject: "生成时间"
                                }
                            },
                            search: {
                                supplier: {
                                    subject: "推播类型",
                                    holder: "请选择推播类型"
                                },
                                status: {
                                    subject: "状态",
                                    holder: "请选择状态"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            },
                            98: {
                                subject: "统计资讯"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            type: {
                                subject: "类型",
                                holder: "请选择类型",
                                help: "类型于建立之后不能变更"
                            },
                            subject: {
                                subject: "标题",
                                holder: "请输入标题"
                            },
                            begin: {
                                subject: "排程时间",
                                holder: "请选择排程时间"
                            },
                            content: {
                                subject: "简述",
                                holder: "请输入简述"
                            },
                            description: {
                                subject: "内容",
                                holder: "请输入内容"
                            },
                            status: {
                                subject: "状态",
                                holder: "请选择状态"
                            }
                        },
                        statistics: {
                            automatic: {
                                subject: "自动化纪录"
                            },
                            history: {
                                subject: "推播纪录"
                            }
                        },
                        alert: {
                            0: {
                                subject: "推播目前正在排程中"
                            },
                            1: {
                                subject: "目前推播正在进行中无法修改, 进度 %s"
                            },
                            2: {
                                subject: "推播已经全部完成无法修改"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将更新内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "编辑名单"
                            },
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请选择类型",
                                text: ""
                            },
                            1030: {
                                subject: "请选择排除机构",
                                text: ""
                            },
                            1031: {
                                subject: "不能排除所有机构",
                                text: ""
                            },
                            1040: {
                                subject: "请输入标题",
                                text: ""
                            },
                            1050: {
                                subject: "请选择开始时间",
                                text: ""
                            },
                            1051: {
                                subject: "排程时间必须大于现在时间",
                                text: ""
                            },
                            1060: {
                                subject: "请输入简述",
                                text: ""
                            },
                            1070: {
                                subject: "请输入内容",
                                text: ""
                            },
                            2010: {
                                subject: "请至少选择1位会友进行推播",
                                text: ""
                            }
                        }
                    },
                    field: {
                        type: {
                            subject: "对象",
                            value: {
                                1: {
                                    subject: "指定推播"
                                },
                                2: {
                                    subject: "全体推播"
                                }
                            }
                        },
                        subject: {
                            subject: "标题"
                        },
                        summary: {
                            total: {
                                subject: "推播数量"
                            }
                        },
                        status: {
                            subject: "状态",
                            progress: {
                                subject: "<br/>进度 %s"
                            },
                            value: {
                                0: {
                                    subject: "排程中"
                                },
                                1: {
                                    subject: "发送中"
                                },
                                2: {
                                    subject: "已完成"
                                }
                            }
                        },
                        begin: {
                            subject: "排程时间"
                        },
                        created: {
                            subject: "建立时间"
                        }
                    },
                    search: {
                        type: {
                            subject: "对象",
                            holder: "请选择对象"
                        },
                        subject: {
                            subject: "标题",
                            holder: "请输入标题"
                        },
                        on: {
                            subject: "排程时间(起)",
                            holder: "请选择排程时间(起)",
                            end: {
                                subject: "排程时间(迄)",
                                holder: "请选择排程时间(迄)"
                            }
                        },
                        status: {
                            subject: "状态",
                            holder: "请选择状态"
                        }
                    },
                    handler: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除推播通知, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增推播"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        create: {
                            begin: "建立日期(起)",
                            end: "建立日期(迄)"
                        },
                        send: {
                            begin: "排程日期(起)",
                            end: "排程日期(迄)"
                        },
                        subject: "标题",
                        quantity: "数量",
                        schedule: "排程时间",
                        ref: "类型",
                        kind: "对象",
                        progress: "进度"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        subject: "请输入标题",
                        create: {
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)"
                        },
                        send: {
                            begin: "请选择排程日期(起)",
                            end: "请选择排程日期(迄)"
                        },
                        ref: "请选择类型",
                        kind: "请选择对象"
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除推播, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tip: {
                        kind: "请注意, 推播类型建立后将无法变更"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名单"
                        }
                    },
                    help: {
                        church: "请谨慎使用指定教会功能"
                    },
                    option: {
                        all: "全部会友名单"
                    },
                    field: {
                        church: "指定教会",
                        excluded: "排除机构",
                        belong: {
                            no: "所属教会ID",
                            name: "所属教会名称"
                        },
                        kind: "对象",
                        subject: "标题",
                        schedule: "排程时间",
                        text: "简述",
                        content: "内容",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        church: "请选择指定教会",
                        excluded: "选中的机构将不会发送推播讯息",
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "请选择对象",
                        subject: "请输入标题",
                        schedule: "请选择排程时间",
                        text: "请输入简述",
                        content: "请输入内容",
                        keyword: "搜寻范围: 名字/邮件/会友编号"
                    },
                    handler: {
                        store: {
                            subject: "即将新增推播内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择对象",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择排程时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入简述",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请指定教会",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请至少选择 <b class=\"ft-24 text-danger\">1</b> 位会友进行推播",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        progress: "系统已经正在推播, 无法编辑或删除",
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名单"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    field: {
                        belong: {
                            no: "所属教会ID",
                            name: "所属教会名称"
                        },
                        excluded: "排除机构",
                        kind: "对象",
                        subject: "标题",
                        schedule: "排程时间",
                        text: "简述",
                        content: "内容",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        excluded: "选中的机构将不会发送推播讯息",
                        kind: "请选择对象",
                        subject: "请输入标题",
                        schedule: "请选择排程时间",
                        text: "请输入简述",
                        content: "请输入内容",
                        keyword: "搜寻范围: 名字/邮件/会友编号"
                    },
                    handler: {
                        update: {
                            subject: "即将更新推播内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择对象",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入标题",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择排程时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入简述",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入内容",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请指定教会",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请至少选择 <b class=\"ft-24 text-danger\">1</b> 位会友进行推播",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            event: {
                v2: {
                    create: {
                        membership: {
                            team: {
                                field: {
                                    keyword: {
                                        subject: "关键字",
                                        holder: "请输入关键字",
                                        text: "同时搜寻名字/邮件/会友编号"
                                    },
                                    team: {
                                        subject: "服事群组",
                                        holder: "请选择服事群组"
                                    }
                                },
                                help: {
                                    team: {
                                        empty: {
                                            subject: "请先建立服事群组"
                                        }
                                    }
                                }
                            },
                            editor: {
                                subject: "选择会友参与服事",
                                submit: {
                                    maximum: {
                                        subject: "最多只能选择%s位会友参与服事"
                                    },
                                    minimum: {
                                        subject: "请选择至少%s位会友参与服事"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "关键字",
                                    holder: "请输入关键字",
                                    text: "同时搜寻名字/邮件/会友编号"
                                },
                                team: {
                                    subject: "参与群组",
                                    holder: "请选择参与群组"
                                }
                            },
                            empty: {
                                subject: "请选择会友参与服事"
                            },
                            not: {
                                found: {
                                    subject: "搜寻不到指定的参与服事会友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            },
                            2: {
                                subject: "提醒"
                            },
                            3: {
                                subject: "循环"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将储存内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "编辑名单"
                            },
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "请选择开始时间",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "请选择结束时间",
                                text: ""
                            },
                            1031: {
                                subject: "结束时间必须大于开始时间",
                                text: ""
                            },
                            1040: {
                                subject: "请输入说明",
                                text: ""
                            },
                            1050: {
                                subject: "请选择状态",
                                text: ""
                            },
                            2010: {
                                subject: "请至少选择1位会友参与服事",
                                text: ""
                            },
                            3010: {
                                subject: "请完善提醒的参数设定",
                                text: ""
                            },
                            4010: {
                                subject: "请完善循环的参数设定",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        membership: {
                            team: {
                                field: {
                                    keyword: {
                                        subject: "关键字",
                                        holder: "请输入关键字",
                                        text: "同时搜寻名字/邮件/会友编号"
                                    },
                                    team: {
                                        subject: "服事群组",
                                        holder: "请选择服事群组"
                                    }
                                },
                                help: {
                                    team: {
                                        empty: {
                                            subject: "请先建立服事群组"
                                        }
                                    }
                                }
                            },
                            editor: {
                                subject: "选择会友参与服事",
                                submit: {
                                    maximum: {
                                        subject: "最多只能选择%s位会友参与服事"
                                    },
                                    minimum: {
                                        subject: "请选择至少%s位会友参与服事"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "关键字",
                                    holder: "请输入关键字",
                                    text: "同时搜寻名字/邮件/会友编号"
                                },
                                team: {
                                    subject: "参与群组",
                                    holder: "请选择参与群组"
                                }
                            },
                            empty: {
                                subject: "请选择会友参与服事"
                            },
                            not: {
                                found: {
                                    subject: "搜寻不到指定的参与服事会友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            },
                            2: {
                                subject: "提醒"
                            },
                            3: {
                                subject: "循环"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前服事处于仅读取状态无法更新"
                            },
                            disable: {
                                subject: "目前服事处于停用状态"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将更新内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "编辑名单"
                            },
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "请选择开始时间",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "请选择结束时间",
                                text: ""
                            },
                            1031: {
                                subject: "结束时间必须大于开始时间",
                                text: ""
                            },
                            1040: {
                                subject: "请输入说明",
                                text: ""
                            },
                            1050: {
                                subject: "请选择状态",
                                text: ""
                            },
                            2010: {
                                subject: "请至少选择1位会友参与服事",
                                text: ""
                            },
                            3010: {
                                subject: "请完善提醒的参数设定",
                                text: ""
                            },
                            4010: {
                                subject: "请完善循环的参数设定",
                                text: ""
                            }
                        }
                    },
                    field: {
                        subject: {
                            subject: "名称"
                        },
                        summary: {
                            partake: {
                                subject: "参与人数"
                            }
                        },
                        status: {
                            subject: "状态",
                            value: {
                                0: {
                                    subject: "已停用"
                                },
                                1: {
                                    subject: "启用中"
                                }
                            }
                        },
                        begin: {
                            subject: "聚会开始时间"
                        },
                        created: {
                            subject: "建立时间"
                        }
                    },
                    search: {
                        subject: {
                            subject: "名称",
                            holder: "请输入名称"
                        },
                        status: {
                            subject: "状态",
                            holder: "请选择状态"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即将启用服事",
                            text: "请确认后执行"
                        },
                        disable: {
                            subject: "即将停用服事",
                            text: "请确认后执行"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除服事, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                team: {
                    index: {
                        tip: {
                            readonly: "总教会仅供查看资料, 无法新增修改"
                        },
                        feature: {
                            create: {
                                subject: "新增群组"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            name: "名称",
                            quantity: "人数"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            enable: {
                                subject: "即将启用群组",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将停用群组",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将删除群组, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    create: {
                        tip: {
                            readonly: "总教会仅供查看资料, 无法新增修改"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            }
                        },
                        field: {
                            name: "名称",
                            keyword: "搜寻名单"
                        },
                        holder: {
                            name: "请输入名称",
                            keyword: "搜寻范围: 名字/邮件/会友编号"
                        },
                        handler: {
                            store: {
                                subject: "即将新增群组",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            2001: {
                                subject: "请至少选择1位人员参与服事",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            readonly: "总教会仅供查看资料, 无法新增修改",
                            disabled: "请注意, 这个群组已经停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            },
                            2: {
                                subject: "统计资讯"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        summary: {
                            history: {
                                subject: "历史纪录"
                            }
                        },
                        field: {
                            belong: {
                                no: "所属教会ID",
                                name: "所属教会名称"
                            },
                            name: "名称",
                            keyword: "搜寻名单"
                        },
                        holder: {
                            head: "未输入",
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "请输入名称",
                            keyword: "搜寻范围: 名字/邮件/会友编号"
                        },
                        handler: {
                            update: {
                                subject: "即将更新群组内容",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            2001: {
                                subject: "请至少选择1位人员参与服事",
                                text: "",
                                description: ""
                            }
                        },
                        history: {
                            advance: {
                                week: "周内新增",
                                month: "月内新增",
                                leave: "离开人数"
                            },
                            description: {
                                created: "群组建立",
                                partake: "加入群组",
                                leave: "从群组中离开"
                            },
                            field: {
                                begin: "开始时间(起)",
                                end: "结束时间(迄)",
                                description: "描述",
                                created: "时间"
                            },
                            holder: {
                                begin: "请选择开始时间(起)",
                                end: "请选择结束时间(迄)"
                            }
                        }
                    }
                },
                index: {
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改"
                    },
                    feature: {
                        create: {
                            subject: "新增服事"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        subject: "名称",
                        start: "开始时间",
                        begin: {
                            begin: "开始日期(起)",
                            end: "开始日期(迄)"
                        },
                        end: {
                            begin: "结束日期(起)",
                            end: "结束日期(迄)"
                        },
                        create: {
                            begin: "建立日期(起)",
                            end: "建立日期(迄)"
                        },
                        quantity: "参与人数"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        subject: "请输入名称",
                        begin: {
                            begin: "请选择开始日期(起)",
                            end: "请选择开始日期(迄)"
                        },
                        end: {
                            begin: "请选择结束日期(起)",
                            end: "请选择结束日期(迄)"
                        },
                        create: {
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即将启用服事",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用服事",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除服事, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名单"
                        },
                        2: {
                            subject: "提醒"
                        }
                    },
                    field: {
                        subject: "标题",
                        begin: "开始时间",
                        end: "结束时间",
                        text: "说明",
                        location: "地点",
                        note: "备注",
                        description: "其他资讯",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        subject: "请输入标题",
                        begin: "请选择开始时间",
                        end: "请选择结束时间",
                        text: "请输入说明",
                        location: "请输入地点",
                        note: "请输入备注",
                        description: "请输入其他资讯",
                        keyword: "搜寻范围: 名字/邮件/会友编号"
                    },
                    handler: {
                        store: {
                            subject: "即将新增服事",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入说明",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请至少选择1位人员参与服事",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改",
                        disabled: "请注意, 这个服事已经停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名单"
                        },
                        2: {
                            subject: "提醒"
                        },
                        98: {
                            subject: "循环资讯"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    detail: {
                        cycle: {
                            subject: "循环代号"
                        },
                        remind: {
                            subject: "提醒代号"
                        }
                    },
                    field: {
                        belong: {
                            no: "所属教会ID",
                            name: "所属教会名称"
                        },
                        subject: "标题",
                        begin: "开始时间",
                        end: "结束时间",
                        text: "说明",
                        location: "地点",
                        note: "备注",
                        description: "其他资讯",
                        keyword: "搜寻名单"
                    },
                    holder: {
                        head: "未输入",
                        belong: {
                            no: "",
                            name: ""
                        },
                        subject: "请输入标题",
                        begin: "请选择开始时间",
                        end: "请选择结束时间",
                        text: "请输入说明",
                        location: "请输入地点",
                        note: "请输入备注",
                        description: "请输入其他资讯",
                        keyword: "搜寻范围: 名字/邮件/会友编号"
                    },
                    handler: {
                        update: {
                            subject: "即将更新服事内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入说明",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "请至少选择1位人员参与服事",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            donate: {
                index: {
                    download: {
                        name: "奉献汇出_会友管理"
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "奉献日期(起)",
                        end: "奉献日期(迄)",
                        avatar: "头像",
                        name: "名字",
                        order: "订单号",
                        amount: "奉献金额"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        begin: "请选择奉献日期(起)",
                        end: "请选择奉献日期(迄)",
                        name: "请输入名字",
                        order: "请输入订单号"
                    },
                    factory: {
                        export: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行奉献汇出, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "回应"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        avatar: "头像",
                        created: "建立时间",
                        payed: "付款时间",
                        order: "订单号",
                        id: "会友ID",
                        no: "会友编号",
                        name: "会友姓名",
                        account: "会友帐号",
                        amount: "奉献金额",
                        phone: "联络电话",
                        payload: "结果",
                        status: "金流状态"
                    },
                    holder: {
                        phone: "未填写联络电话"
                    }
                }
            },
            teach: {
                index: {
                    feature: {
                        create: {
                            subject: "新增教学"
                        }
                    },
                    field: {
                        avatar: "封面图",
                        name: "名称",
                        sort: "排序",
                        created: "建立时间"
                    },
                    holder: {
                        name: "请输入名字"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用教学",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用教学",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除教学, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        }
                    },
                    field: {
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        name: "名称"
                    },
                    holder: {
                        name: "请输入名称"
                    },
                    handler: {
                        store: {
                            subject: "即将新增分类",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "请注意, 这个教学已经停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "排序"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    field: {
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        name: "名称"
                    },
                    holder: {
                        name: "请输入名称"
                    },
                    handler: {
                        update: {
                            subject: "即将更新分类内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            course: {
                access: {
                    index: {
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "头像",
                            name: "名字",
                            role: "来源"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)",
                            name: "请输入名字",
                            empty: {
                                email: "未输入"
                            }
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        tip: {
                            unknown: "此纪录为未知来源"
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            avatar: "头像",
                            seq: "流水号",
                            name: "名字",
                            account: "帐号",
                            role: "来源",
                            method: "存取方式",
                            address: "远端位置",
                            uri: "存取路径",
                            created: "建立时间"
                        },
                        holder: {},
                        handler: {}
                    }
                },
                authorize: {
                    index: {
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "头像",
                            name: "名字",
                            role: "来源",
                            method: "方式",
                            result: "结果"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)",
                            name: "请输入名字",
                            method: "请输入方式",
                            result: "请选择结果",
                            empty: {
                                email: "未输入"
                            }
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        tip: {
                            unknown: "此纪录为未知来源"
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            avatar: "头像",
                            seq: "流水号",
                            name: "名字",
                            account: "帐号",
                            role: "来源",
                            method: "方式",
                            result: "结果",
                            address: "远端位置",
                            created: "建立时间"
                        },
                        holder: {},
                        handler: {}
                    }
                },
                exception: {
                    index: {
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "头像",
                            name: "名字",
                            role: "来源",
                            level: "等级",
                            code: "结果代码",
                            exception: "拦截",
                            module: "模组"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)",
                            name: "请输入名字",
                            empty: {
                                email: "未输入"
                            },
                            module: "请选择模组"
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        tip: {
                            unknown: "此纪录为未知来源"
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            avatar: "头像",
                            seq: "流水号",
                            name: "名字",
                            account: "帐号",
                            role: "来源",
                            level: "等级",
                            ref: {
                                name: "拦截呼叫",
                                church: "标的教会",
                                seq: "标的物件流水号"
                            },
                            module: "拦截类",
                            method: "拦截函式",
                            code: "结果代码",
                            rollback: "回滚事务",
                            subject: "标题",
                            text: "副标题",
                            description: "描述",
                            payload: "提交参数",
                            created: "建立时间"
                        },
                        holder: {
                            subject: "无标题",
                            text: "无副标题",
                            description: "无描述"
                        },
                        handler: {}
                    }
                }
            },
            support: {
                v2: {
                    worker: {
                        create: {
                            tab: {
                                0: {
                                    subject: "基本"
                                },
                                1: {
                                    subject: "帐号"
                                },
                                2: {
                                    subject: "附件"
                                }
                            },
                            field: {
                                sample: {
                                    subject: "样本ID",
                                    holder: "请输入样本ID"
                                },
                                subject: {
                                    subject: "标题",
                                    holder: "请输入标题"
                                },
                                description: {
                                    subject: "描述",
                                    holder: "请输入描述"
                                },
                                role: {
                                    subject: "角色",
                                    holder: "请选择角色"
                                },
                                type: {
                                    subject: "类型",
                                    holder: "请选择类型"
                                }
                            },
                            handler: {
                                submit: {
                                    subject: "即将新增工单",
                                    text: "请确认后执行"
                                }
                            },
                            error: {
                                1010: {
                                    subject: "请选择角色",
                                    text: ""
                                },
                                1020: {
                                    subject: "请输入样本ID",
                                    text: ""
                                },
                                1021: {
                                    subject: "样本ID请输入数字",
                                    text: ""
                                },
                                1030: {
                                    subject: "请选择类型",
                                    text: ""
                                },
                                1040: {
                                    subject: "请输入标题",
                                    text: ""
                                },
                                1050: {
                                    subject: "请输入描述",
                                    text: ""
                                }
                            }
                        },
                        edit: {
                            history: {
                                create: {
                                    tab: {
                                        0: {
                                            subject: "基本"
                                        },
                                        1: {
                                            subject: "附件"
                                        }
                                    },
                                    field: {
                                        subject: {
                                            subject: "标题",
                                            holder: "请输入标题"
                                        },
                                        description: {
                                            subject: "描述",
                                            holder: "请输入描述"
                                        },
                                        release: {
                                            subject: "发佈App",
                                            holder: "请选择发佈App"
                                        },
                                        status: {
                                            subject: "状态",
                                            holder: "请选择状态"
                                        }
                                    },
                                    handler: {
                                        submit: {
                                            subject: "即将新增纪录",
                                            text: "请确认后执行"
                                        }
                                    },
                                    error: {
                                        1010: {
                                            subject: "请选择状态",
                                            text: ""
                                        },
                                        1020: {
                                            subject: "请输入标题",
                                            text: ""
                                        },
                                        1030: {
                                            subject: "请输入描述",
                                            text: ""
                                        },
                                        1040: {
                                            subject: "请选择发佈App",
                                            text: ""
                                        }
                                    }
                                },
                                edit: {
                                    tab: {
                                        0: {
                                            subject: "基本"
                                        },
                                        1: {
                                            subject: "附件"
                                        },
                                        99: {
                                            subject: "相关资讯"
                                        }
                                    },
                                    field: {
                                        subject: {
                                            subject: "标题",
                                            holder: "请输入标题"
                                        },
                                        description: {
                                            subject: "描述",
                                            holder: "请输入描述"
                                        },
                                        release: {
                                            subject: "发佈App",
                                            holder: "请选择发佈App"
                                        },
                                        status: {
                                            subject: "状态",
                                            holder: "请选择状态"
                                        }
                                    },
                                    handler: {
                                        submit: {
                                            subject: "即将更新纪录",
                                            text: "请确认后执行"
                                        }
                                    },
                                    error: {
                                        1010: {
                                            subject: "请选择状态",
                                            text: ""
                                        },
                                        1020: {
                                            subject: "请输入标题",
                                            text: ""
                                        },
                                        1030: {
                                            subject: "请输入描述",
                                            text: ""
                                        },
                                        1040: {
                                            subject: "请选择发佈App",
                                            text: ""
                                        }
                                    }
                                },
                                field: {
                                    subject: {
                                        subject: "标题",
                                        holder: "请输入标题"
                                    },
                                    description: {
                                        subject: "描述",
                                        holder: "请输入描述"
                                    },
                                    keyword: {
                                        subject: "关键字",
                                        holder: "请输入关键字",
                                        help: "同时搜寻标题与描述"
                                    },
                                    begin: {
                                        subject: "开始时间(起)",
                                        holder: "请选择开始时间"
                                    },
                                    end: {
                                        subject: "结束时间(迄)",
                                        holder: "请选择结束时间"
                                    },
                                    invalid: {
                                        subject: "作废",
                                        holder: "请选择作废"
                                    },
                                    creator: {
                                        subject: "建立人",
                                        holder: "请选择建立人"
                                    },
                                    editor: {
                                        subject: "上一次编辑人",
                                        holder: "请选择上一次编辑人"
                                    },
                                    status: {
                                        subject: "状态",
                                        holder: "请选择状态"
                                    }
                                },
                                alert: {
                                    readonly: {
                                        subject: "工单已经标示完成无法进行修改"
                                    }
                                },
                                handler: {
                                    destroy: {
                                        subject: "安全性二级验证",
                                        text: "<span class=\"cgRed\">即将作废纪录, 请确认后执行</span>",
                                        description: "请输入您目前的登入密码"
                                    }
                                }
                            },
                            tab: {
                                0: {
                                    subject: "基本"
                                },
                                1: {
                                    subject: "帐号"
                                },
                                2: {
                                    subject: "附件"
                                },
                                98: {
                                    subject: "统计资讯"
                                },
                                99: {
                                    subject: "相关资讯"
                                }
                            },
                            action: {
                                history: {
                                    create: {
                                        subject: "新增纪录"
                                    }
                                }
                            },
                            field: {
                                no: {
                                    subject: "单号",
                                    holder: ""
                                },
                                sample: {
                                    subject: "样本ID",
                                    holder: "请输入样本ID"
                                },
                                subject: {
                                    subject: "标题",
                                    holder: "请输入标题"
                                },
                                description: {
                                    subject: "描述",
                                    holder: "请输入描述"
                                },
                                role: {
                                    subject: "角色",
                                    holder: "请选择角色"
                                },
                                type: {
                                    subject: "类型",
                                    holder: "请选择类型"
                                }
                            },
                            statistics: {
                                history: {
                                    subject: "历史纪录"
                                },
                                record: {
                                    subject: "异动纪录"
                                }
                            },
                            alert: {
                                readonly: {
                                    subject: "工单已经标示完成无法进行修改"
                                }
                            },
                            handler: {
                                submit: {
                                    subject: "即将更新工单",
                                    text: "请确认后执行"
                                }
                            },
                            error: {
                                1010: {
                                    subject: "请选择角色",
                                    text: ""
                                },
                                1020: {
                                    subject: "请输入样本ID",
                                    text: ""
                                },
                                1021: {
                                    subject: "样本ID请输入数字",
                                    text: ""
                                },
                                1030: {
                                    subject: "请选择类型",
                                    text: ""
                                },
                                1040: {
                                    subject: "请输入标题",
                                    text: ""
                                },
                                1050: {
                                    subject: "请输入描述",
                                    text: ""
                                }
                            }
                        },
                        field: {
                            role: {
                                subject: "角色"
                            },
                            type: {
                                subject: "类型"
                            },
                            no: {
                                subject: "单号"
                            },
                            subject: {
                                subject: "标题"
                            },
                            summary: {
                                account: {
                                    subject: "异常帐号"
                                },
                                attachment: {
                                    subject: "附件"
                                },
                                history: {
                                    subject: "历史纪录"
                                }
                            },
                            expire: {
                                subject: "超时"
                            },
                            back: {
                                subject: "返工"
                            },
                            status: {
                                subject: "状态"
                            },
                            created: {
                                subject: "建立时间"
                            }
                        },
                        search: {
                            role: {
                                subject: "角色",
                                holder: "请输入角色"
                            },
                            type: {
                                subject: "类型",
                                holder: "请输入类型"
                            },
                            no: {
                                subject: "单号",
                                holder: "请输入单号"
                            },
                            subject: {
                                subject: "标题",
                                holder: "请输入标题"
                            },
                            description: {
                                subject: "描述",
                                holder: "请输入描述"
                            },
                            keyword: {
                                subject: "关键字",
                                holder: "请输入关键字",
                                help: "同时搜寻标题与描述"
                            },
                            begin: {
                                subject: "开始时间(起)",
                                holder: "请选择开始时间"
                            },
                            end: {
                                subject: "结束时间(迄)",
                                holder: "请选择结束时间"
                            },
                            creator: {
                                subject: "建立人",
                                holder: "请选择建立人"
                            },
                            editor: {
                                subject: "上一次编辑人",
                                holder: "请选择上一次编辑人"
                            },
                            expire: {
                                subject: "超时",
                                holder: "请选择超时"
                            },
                            back: {
                                subject: "返工",
                                holder: "请选择返工"
                            },
                            status: {
                                subject: "状态",
                                holder: "请选择状态"
                            }
                        }
                    }
                },
                feedback: {
                    index: {
                        text: {
                            group: "管理团队"
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            avatar: "头像",
                            name: "名字",
                            content: "内容",
                            level: "层级"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            name: "请输入名字",
                            level: "请选择层级"
                        },
                        handler: {
                            raise: {
                                subject: "即将反馈至爱无界管理团队",
                                text: "请认后执行",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            unread: "请注意, 这个反馈还未标示已读取"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        text: {
                            group: "管理团队"
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            avatar: "头像",
                            level: "层级",
                            id: "会友ID",
                            no: "会友编号",
                            name: "会友姓名",
                            account: "会友帐号",
                            content: "反馈内容",
                            created: "建立时间",
                            status: "状态"
                        },
                        holder: {},
                        handler: {
                            update: {
                                subject: "即将更新反馈内容",
                                text: "请确认后执行",
                                description: "",
                                raise: {
                                    subject: "即将更新反馈内容",
                                    text: "<b class=\"ft-20 text-danger\">即将反馈至爱无界管理团队, 请认后执行</b>",
                                    description: ""
                                }
                            }
                        },
                        error: {
                            1001: {
                                subject: "请选择层级",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                coffee: {
                    index: {
                        download: {
                            name: "请我们喝咖啡汇出_客服管理"
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            begin: "赞助日期(起)",
                            end: "赞助日期(迄)",
                            avatar: "头像",
                            name: "名字",
                            order: "订单号",
                            amount: "赞助金额"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            begin: "请选择赞助日期(起)",
                            end: "请选择赞助日期(迄)",
                            name: "请输入名字",
                            order: "请输入订单号"
                        },
                        factory: {
                            export: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将进行请我们喝咖啡汇出, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "回应"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            avatar: "头像",
                            created: "建立时间",
                            payed: "付款时间",
                            order: "订单号",
                            id: "会友ID",
                            no: "会友编号",
                            name: "会友姓名",
                            account: "会友帐号",
                            amount: "赞助金额",
                            phone: "联络电话",
                            payload: "结果",
                            status: "金流状态"
                        },
                        holder: {
                            phone: "未填写联络电话"
                        }
                    }
                },
                history: {
                    index: {
                        tabs: [
                            {
                                subject: "查询"
                            }
                        ],
                        help: {
                            query: "请输入完整的电子邮件",
                            disabled: "会友已经停用",
                            destroy: "会友已于 <b>%s</b> 自行删除",
                            church: {
                                disabled: "教会已经停用",
                                destroy: "教会已经删除",
                                organization: {
                                    disabled: "机构已经停用",
                                    destroy: "机构已经删除"
                                }
                            }
                        },
                        notification: {
                            status: {
                                waiting: "排程中",
                                progress: "发送中",
                                complete: "已送达"
                            }
                        },
                        result: {
                            success: "查询到 <b class=\"ft-18 text-success\">%s</b> 笔纪录",
                            empty: "查询不到任何结果"
                        },
                        field: {
                            notification: {
                                subject: "最后 <b class=\"text-success ft-18\">10</b> 笔推播通知",
                                text: "(依照预定推播时间降幂排序)",
                                empty: "没有推播通知纪录",
                                field: {
                                    id: "推播ID",
                                    schedule: "排定发送时间",
                                    delivery: "送达时间"
                                }
                            },
                            device: {
                                supplier: "装置支援商",
                                brand: "装置製造商",
                                model: "装置型号",
                                os: "装置操作系统",
                                version: "装置App版本",
                                token: {
                                    subject: "登入权杖",
                                    text: "(sys_token.token)",
                                    push: {
                                        subject: "推播识别码",
                                        text: "(core_admin_client_notification.key)"
                                    }
                                }
                            },
                            belong: {
                                organization: {
                                    id: "所属机构ID",
                                    name: "所属机构名称"
                                },
                                id: "所属教会ID",
                                no: "所属教会编号",
                                name: "所属教会名称"
                            },
                            id: "会友ID",
                            no: "会友编号",
                            name: "会友姓名",
                            account: "会友帐号",
                            email: "电子邮件",
                            avatar: "头像",
                            status: "状态",
                            created: "建立时间",
                            updated: "更新时间"
                        },
                        holder: {
                            email: "请输入完整的电子邮件进行查询"
                        },
                        error: {
                            1001: {
                                subject: "请输入电子邮件",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "电子邮件格式错误",
                                text: "请输入正确的电子邮件格式",
                                description: ""
                            }
                        }
                    }
                }
            },
            live: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分类"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            name: "名称",
                            quantity: "直播数量",
                            sort: "排序",
                            created: "建立时间"
                        },
                        holder: {
                            head: {
                                church: "请选择教会"
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            enable: {
                                subject: "即将启用分类",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将停用分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将删除分类, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "请谨慎使用指定教会功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教会",
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            church: "请选择指定教会",
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            store: {
                                subject: "即将新增分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "请选择指定教会",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "请注意, 这个分类已经停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            belong: {
                                no: "所属教会编号",
                                name: "所属教会名称"
                            },
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            update: {
                                subject: "即将更新分类内容",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增直播"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        subject: "标题",
                        avatar: "封面图",
                        quantity: "直播数量",
                        category: "分类",
                        integrate: "文章整合",
                        sort: "排序",
                        created: "建立时间"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        begin: "请选择建立日期(起)",
                        end: "请选择建立日期(迄)",
                        integrate: "请选择文章整合",
                        subject: "请输入名称",
                        category: "请选择分类"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用直播",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用直播",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除直播, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    help: {
                        church: "请谨慎使用指定教会功能"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "文章"
                        },
                        2: {
                            subject: "图片"
                        },
                        3: {
                            subject: "分享"
                        }
                    },
                    field: {
                        kind: "类型",
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        church: "指定教会",
                        category: "分类",
                        subject: "标题",
                        hyperlink: "YouTube网址",
                        activity: {
                            on: "开始时间",
                            off: "结束时间"
                        },
                        tab: {
                            lobby: "标籤 - 大厅",
                            prayer: "标籤 - 代祷",
                            about: "标籤 - 关于"
                        },
                        welcome: {
                            lobby: "欢迎词 - 大厅",
                            prayer: "欢迎词 - 代祷"
                        },
                        reply: {
                            prayer: "回应词 - 代祷"
                        },
                        collection: "文章图片",
                        description: "描述",
                        status: "状态"
                    },
                    holder: {
                        kind: "请选择类型",
                        church: "请选择指定教会",
                        category: "请选择分类",
                        subject: "请输入名称",
                        hyperlink: "请输入YouTube网址",
                        activity: {
                            on: "请输入开始时间",
                            off: "请输入结束时间"
                        },
                        tab: {
                            lobby: "请输入标籤(大厅)",
                            prayer: "请输入标籤(代祷)",
                            about: "请输入标籤(关于)"
                        },
                        welcome: {
                            lobby: "请输入欢迎词(大厅)",
                            prayer: "请输入欢迎词(代祷)"
                        },
                        reply: {
                            prayer: "请输入回应词(代祷)"
                        },
                        collection: "",
                        description: "请输入描述",
                        status: "请选择状态"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即将新增直播",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择类型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入标籤(大厅)",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入标籤(代祷)",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请输入标籤(关于)",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入欢迎词(大厅)",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请输入欢迎词(代祷)",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请输入回应词(代祷)",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "请输入描述",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "请选择指定教会",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "请输入YouTube连结",
                            text: "",
                            description: ""
                        },
                        4001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    comment: {
                        subject: "聊天室发言内容",
                        th: {
                            0: "头像",
                            1: "姓名",
                            2: "内容",
                            3: "时间"
                        },
                        passenger: {
                            subject: "访客"
                        }
                    },
                    secure: {
                        subject: "私密代祷内容",
                        th: {
                            0: "头像",
                            1: "姓名",
                            2: "内容",
                            3: "时间"
                        },
                        passenger: {
                            subject: "访客"
                        },
                        handler: {
                            secure: {
                                subject: "您即将查看会友私密个人资讯",
                                text: "我将妥善与谨慎使用相关资讯"
                            }
                        }
                    },
                    tip: {
                        disabled: "请注意, 这个直播已经停用"
                    },
                    feature: {
                        0: "复製直播网址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "文章"
                        },
                        2: {
                            subject: "图片"
                        },
                        3: {
                            subject: "分享"
                        },
                        4: {
                            subject: "统计资讯"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "聊天室发言"
                        },
                        secure: {
                            subject: "私密代祷"
                        }
                    },
                    field: {
                        belong: {
                            no: "所属教会编号",
                            name: "所属教会名称"
                        },
                        kind: "类型",
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        category: "分类",
                        subject: "标题",
                        hyperlink: "YouTube网址",
                        activity: {
                            on: "开始时间",
                            off: "结束时间"
                        },
                        tab: {
                            lobby: "标籤 - 大厅",
                            prayer: "标籤 - 代祷",
                            about: "标籤 - 关于"
                        },
                        welcome: {
                            lobby: "欢迎词 - 大厅",
                            prayer: "欢迎词 - 代祷"
                        },
                        reply: {
                            prayer: "回应词 - 代祷"
                        },
                        collection: "文章图片",
                        description: "描述",
                        status: "状态"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "请选择类型",
                        category: "请选择分类",
                        subject: "请输入名称",
                        hyperlink: "请输入YouTube网址",
                        activity: {
                            on: "请输入开始时间",
                            off: "请输入结束时间"
                        },
                        tab: {
                            lobby: "请输入标籤(大厅)",
                            prayer: "请输入标籤(代祷)",
                            about: "请输入标籤(关于)"
                        },
                        welcome: {
                            lobby: "请输入欢迎词(大厅)",
                            prayer: "请输入欢迎词(代祷)"
                        },
                        reply: {
                            prayer: "请输入回应词(代祷)"
                        },
                        collection: "",
                        description: "请输入描述",
                        status: "请选择状态"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即将更新直播内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择类型",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请输入标籤(大厅)",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请输入标籤(代祷)",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请输入标籤(关于)",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请输入欢迎词(大厅)",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入欢迎词(代祷)",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请输入回应词(代祷)",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "请输入描述",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "请选择指定教会",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "请输入YouTube连结",
                            text: "",
                            description: ""
                        },
                        4001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            activity: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分类"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            name: "名称",
                            quantity: "活动数量",
                            sort: "排序",
                            created: "建立时间"
                        },
                        holder: {
                            head: {
                                church: "请选择教会"
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            enable: {
                                subject: "即将启用分类",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将停用分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将删除分类, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "请谨慎使用指定教会功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教会",
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            church: "请选择指定教会",
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            store: {
                                subject: "即将新增分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "请选择指定教会",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "请注意, 这个分类已经停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            belong: {
                                no: "所属教会编号",
                                name: "所属教会名称"
                            },
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            update: {
                                subject: "即将更新分类内容",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增活动"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        subject: "标题",
                        avatar: "封面图",
                        quantity: "活动数量",
                        category: "分类",
                        integrate: "文章整合",
                        registered: {
                            subject: "已报名",
                            text: "含未付费"
                        },
                        sort: "排序",
                        created: "建立时间"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        begin: "请选择建立日期(起)",
                        end: "请选择建立日期(迄)",
                        integrate: "请选择文章整合",
                        subject: "请输入名称",
                        category: "请选择分类"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用活动",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用活动",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除活动, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tip: {
                        signature: "活动建立人员将会自动拥有本活动App签到功能, 可不指定其他协助签到人员"
                    },
                    help: {
                        church: "请谨慎使用指定教会功能",
                        discount: {
                            enable: "已经开启报名优惠功能, 无法修改日期"
                        }
                    },
                    field: {
                        keyword: "搜寻名单",
                        kind: "类型",
                        subject: "标题",
                        activity: {
                            on: "活动开始时间",
                            off: "活动结束时间"
                        },
                        respond: {
                            avatar: {
                                subject: "封面图",
                                text: "(尺寸为 %sx%s 像素)"
                            },
                            description: "描述"
                        },
                        collection: "文章图片",
                        description: "内容",
                        status: "状态"
                    },
                    holder: {
                        keyword: "搜寻范围: 名字/邮件/会友编号",
                        kind: "请选择类型",
                        church: "请选择指定教会",
                        category: "请选择分类",
                        subject: "请输入名称",
                        activity: {
                            on: "请输入活动开始时间",
                            off: "请输入活动结束时间"
                        },
                        respond: {
                            description: "请输入内容"
                        },
                        collection: "",
                        description: "请输入描述",
                        status: "请选择状态"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即将新增活动",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入描述",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请选择指定教会",
                            text: "",
                            description: ""
                        },
                        4002: {
                            subject: "请输入回条描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    register: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "团报"
                            },
                            2: {
                                subject: "表单"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        tip: {
                            organization: {
                                subject: "团体报名人数最少 <b class=\"ft-16\">%s</b> 位, 最多 <b class=\"ft-16\">%s</b> 位",
                                left: {
                                    subject: ", 还需要 <b class=\"ft-16\">%s</b> 位报名"
                                },
                                satisfy: {
                                    minimum: {
                                        subject: ", 最多还可以报名 <b class=\"ft-16\">%s</b> 位"
                                    },
                                    negative: {
                                        subject: ", 已经达到活动报名人数上限"
                                    },
                                    maximum: {
                                        subject: ", 已达到团报最高人数限制"
                                    }
                                }
                            },
                            summary: {
                                subject: "报名总览"
                            }
                        },
                        form: {
                            empty: "请先增加团体报名人员",
                            rank: "合计 <b class=\"ft-16 text-success\">%s</b> 位报名",
                            th: {
                                0: "#",
                                1: "姓名",
                                2: "电子邮件",
                                3: "操作"
                            },
                            field: {
                                name: "姓名",
                                email: "电子邮件"
                            }
                        },
                        discount: {
                            original: "原价",
                            before: "%s 之前",
                            between: "%s 至 %s"
                        },
                        canvas: {
                            create: {
                                subject: "新增团报人员"
                            },
                            edit: {
                                subject: "编辑团报人员"
                            }
                        },
                        field: {
                            method: "报名方式",
                            discount: "费用",
                            echelon: "活动场次",
                            payment: "支付状态",
                            organization: {
                                name: "教会(机构)名称",
                                city: "教会(机构)所在城市",
                                contact: {
                                    name: "联络人姓名",
                                    phone: "联络人电话",
                                    email: "联络人电子邮件"
                                },
                                zip: "邮递区号",
                                address: "通讯地址"
                            }
                        },
                        holder: {
                            organization: {
                                name: "请输入教会(机构)名称",
                                city: "请输入教会(机构)所在城市",
                                contact: {
                                    name: "请输入联络人姓名",
                                    phone: "请输入联络人电话",
                                    email: "请输入联络人电子邮件"
                                },
                                zip: "请输入邮递区号",
                                address: "请输入通讯地址"
                            }
                        },
                        handler: {
                            store: {
                                subject: "即将储存后台报名",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请选择报名方式",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "异常发生",
                                text: "指定的报名方式不存在",
                                description: ""
                            },
                            1003: {
                                subject: "请选择活动场次",
                                text: "",
                                description: ""
                            },
                            1004: {
                                subject: "异常发生",
                                text: "指定的活动场次不存在",
                                description: ""
                            },
                            1005: {
                                subject: "异常发生",
                                text: "指定的价格不存在",
                                description: ""
                            },
                            1006: {
                                subject: "请选择支付状态",
                                text: "",
                                description: ""
                            },
                            1007: {
                                subject: "异常发生",
                                text: "指定的付款状态不存在",
                                description: ""
                            },
                            1998: {
                                subject: "总教会不能进行后台报名",
                                text: "即将返回报名内容页面",
                                description: ""
                            },
                            1999: {
                                subject: "活动报名已经额满",
                                text: "即将返回报名内容页面",
                                description: ""
                            },
                            2001: {
                                subject: "请输入教会(机构)名称",
                                text: "",
                                description: ""
                            },
                            2002: {
                                subject: "请输入教会(机构)所在城市",
                                text: "",
                                description: ""
                            },
                            2003: {
                                subject: "请输入联络人姓名",
                                text: "",
                                description: ""
                            },
                            2004: {
                                subject: "请输入联络人电话",
                                text: "",
                                description: ""
                            },
                            2005: {
                                subject: "请输入联络人电子邮件",
                                text: "",
                                description: ""
                            },
                            2006: {
                                subject: "联络人电子邮件格式错误",
                                text: "",
                                description: ""
                            },
                            2007: {
                                subject: "请输入邮递区号",
                                text: "",
                                description: ""
                            },
                            2008: {
                                subject: "请输入通讯地址",
                                text: "",
                                description: ""
                            },
                            3001: {
                                subject: "请完善表单内容",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    registered: {
                        advance: {
                            charge: "需要收费",
                            restrict: "限制人数",
                            available: "报名上限",
                            wait: "等待付款",
                            paid: "报名完成",
                            remain: "剩馀名额"
                        },
                        edit: {
                            help: {
                                backend: "后台编辑"
                            },
                            field: {
                                group: {
                                    name: "教会(机构)名称",
                                    city: "教会(机构)所在城市",
                                    zip: "邮递区号",
                                    address: "通讯地址",
                                    contact: {
                                        name: "联络人姓名",
                                        phone: "联络人电话号码",
                                        email: "联络人电子邮件"
                                    }
                                },
                                respond: "结果",
                                avatar: "头像",
                                qrcode: "报到二维码",
                                echelon: {
                                    subject: "报名场次",
                                    begin: {
                                        subject: "场次开始"
                                    },
                                    end: {
                                        subject: "场次结束"
                                    },
                                    total: {
                                        subject: "全部 <b>%s</b> 场"
                                    },
                                    edit: {
                                        subject: "变更场次"
                                    }
                                },
                                external: "报名来源",
                                organization: "报名方式",
                                no: "订单号",
                                name: "姓名",
                                email: "电子邮件",
                                paid: "已付款",
                                status: "状态"
                            },
                            holder: {
                                group: {
                                    name: "请输入教会(机构)名称",
                                    city: "请输入教会(机构)所在城市",
                                    zip: "请输入邮递区号",
                                    address: "请输入通讯地址",
                                    contact: {
                                        name: "请输入联络人姓名",
                                        phone: "请输入联络人电话号码",
                                        email: "请输入联络人电子邮件"
                                    }
                                },
                                organization: "请选择报名方式",
                                external: "请选择报名来源",
                                no: "请输入订单号",
                                name: "请输入姓名",
                                email: "请输入电子邮件",
                                paid: "请选择已付款",
                                status: "请选择状态"
                            },
                            handler: {
                                update: {
                                    subject: "即将更新报名内容",
                                    text: "请确认后执行",
                                    description: ""
                                }
                            },
                            error: {
                                1001: {
                                    subject: "请选择已付款",
                                    text: "",
                                    description: ""
                                },
                                1002: {
                                    subject: "请选择状态",
                                    text: "",
                                    description: ""
                                }
                            }
                        },
                        field: {
                            no: "订单号",
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "头像",
                            organization: "报名方式",
                            registered: {
                                subject: "资讯",
                                organization: {
                                    group: {
                                        subject: "团体报名"
                                    },
                                    subject: "个人报名"
                                },
                                external: {
                                    0: {
                                        subject: "App"
                                    },
                                    1: {
                                        subject: "外部"
                                    },
                                    2: {
                                        subject: "后台"
                                    }
                                },
                                paid: {
                                    not: {
                                        subject: "未付款"
                                    },
                                    subject: "已付款"
                                }
                            },
                            external: "报名来源",
                            paid: "已付款",
                            sort: "排序",
                            created: "建立时间"
                        },
                        holder: {
                            no: "请输入订单号",
                            organization: "请选择报名方式",
                            external: "请选择报名来源",
                            paid: "请选择已付款",
                            begin: "请选择建立日期(起)",
                            end: "请选择建立日期(迄)"
                        },
                        factory: {
                            paid: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将手动标记付款, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    tip: {
                        signature: "活动建立人员将会自动拥有本活动App签到功能, 可不指定其他协助签到人员",
                        disabled: "请注意, 这个活动已经停用",
                        backend: "请注意, 总教会登入无法使用后台报名",
                        full: "请注意, 这个活动报名已经额满",
                        summary: "请注意, 这个活动已经有 <b class=\"ft-15\">%s</b> 名会友已付费报名, 部分栏位<b>无法修改</b>, 请注意"
                    },
                    help: {
                        discount: {
                            enable: "已经开启报名优惠功能, 无法修改日期"
                        },
                        activity: {
                            register: {
                                expire: {
                                    subject: "活动已经开始, 报名截止"
                                }
                            }
                        }
                    },
                    feature: {
                        0: "下载二维码",
                        1: "后台报名",
                        2: "报到工具",
                        3: "显示二维码",
                        4: "复製报名网址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "报名"
                        },
                        2: {
                            subject: "场次"
                        },
                        3: {
                            subject: "回条"
                        },
                        4: {
                            subject: "栏位"
                        },
                        5: {
                            subject: "提醒"
                        },
                        6: {
                            subject: "文章"
                        },
                        7: {
                            subject: "图片"
                        },
                        8: {
                            subject: "分享"
                        },
                        9: {
                            subject: "报名资讯"
                        },
                        10: {
                            subject: "签到"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        url: {
                            subject: "报名网址"
                        },
                        register: {
                            subject: "后台报名"
                        },
                        summary: {
                            subject: "报名清单",
                            text: "含未付费"
                        }
                    },
                    field: {
                        keyword: "搜寻名单",
                        belong: {
                            no: "所属教会编号",
                            name: "所属教会名称"
                        },
                        kind: "类型",
                        avatar: {
                            subject: "封面图",
                            text: "(尺寸为 %sx%s 像素)"
                        },
                        category: "分类",
                        subject: "标题",
                        activity: {
                            on: "活动开始时间",
                            off: "活动结束时间"
                        },
                        respond: {
                            avatar: {
                                subject: "封面图",
                                text: "(尺寸为 %sx%s 像素)"
                            },
                            description: "描述"
                        },
                        collection: "文章图片",
                        description: "内容",
                        status: "状态"
                    },
                    holder: {
                        keyword: "搜寻范围: 名字/邮件/会友编号",
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "请选择类型",
                        category: "请选择分类",
                        subject: "请输入名称",
                        activity: {
                            on: "请输入活动开始时间",
                            off: "请输入活动结束时间"
                        },
                        respond: {
                            description: "请输入内容"
                        },
                        collection: "",
                        description: "请输入描述",
                        status: "请选择状态"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即将清除所有文章图片",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即将更新活动内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择封面图",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请输入描述",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "请选择指定教会",
                            text: "",
                            description: ""
                        },
                        4002: {
                            subject: "请输入回条描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "请输入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            personal: {
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "密码"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    handler: {
                        update: {
                            subject: "即将更新个人内容",
                            text: "请确认后执行",
                            description: "",
                            passwd: {
                                subject: "即将更新个人内容",
                                text: "<b class=\"text-danger\">更新密码成功后将自动登出<br/>请您使用新密码登入, 请确认后执行</b>",
                                description: ""
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "请输入名字",
                            text: ""
                        },
                        1002: {
                            subject: "请输入电子邮件",
                            text: ""
                        },
                        1003: {
                            subject: "电子邮件格式错误",
                            text: "请输入正确的电子邮件格式"
                        },
                        1004: {
                            subject: "请输入电话",
                            text: ""
                        },
                        2001: {
                            subject: "请输入当前密码",
                            text: ""
                        },
                        2002: {
                            subject: "当前密码错误",
                            text: ""
                        },
                        2003: {
                            subject: "请输入登入密码",
                            text: ""
                        },
                        2004: {
                            subject: "登入密码强度不足",
                            text: ""
                        },
                        2005: {
                            subject: "请再输入登入密码进行确认",
                            text: ""
                        },
                        2006: {
                            subject: "密码确认失败",
                            text: "两次密码输入不一致"
                        }
                    }
                }
            }
        },
        shepherd: {
            dashboard: {
                help: {
                    updated: "最后更新时间",
                    original: "预设区间",
                    loading: "正在计算统计数据..."
                },
                field: {
                    church: "指定教会",
                    begin: "开始时间",
                    end: "结束时间",
                    statistics: {
                        friend: "新朋友数量",
                        client: {
                            total: "会友人数",
                            track: "已跟进会友",
                            stranger: "未跟进会友",
                            archive: "不跟进会友"
                        },
                        team: "族群数量",
                        attend: "聚会数量"
                    }
                },
                holder: {
                    church: "请选择指定教会"
                }
            },
            friend: {
                index: {
                    feature: {
                        create: {
                            subject: "新增新朋友"
                        }
                    },
                    text: {
                        register: "自行注册"
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        avatar: "头像",
                        name: "名字",
                        email: "电子邮件",
                        from: "来源",
                        relationship: "关係"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        name: "请输入名字",
                        email: "请输入电子邮件"
                    },
                    handler: {
                        add: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">标记为待转入</span>",
                            text: "请确认后执行"
                        },
                        clear: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">标记为不转入</span>",
                            text: "请确认后执行"
                        },
                        convert: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">转入至牧养会友</span>",
                            text: "请确认后执行"
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改",
                        disabled: "请注意, 这名新朋友已经标记为不转入"
                    },
                    commitment: {
                        empty: "未填写"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "来源"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        avatar: "头像",
                        email: "电子邮件",
                        name: "名字",
                        title: "称谓",
                        birthday: "生日",
                        phone: "联络电话",
                        location: {
                            country: "所在地区",
                            city: "所在城市"
                        },
                        address: "联络地址",
                        occupation: "职业",
                        baptized: "受洗时间",
                        commitment: "委身编号",
                        created: "建立时间",
                        relationship: "关係"
                    },
                    holder: {
                        head: "未输入",
                        belong: {
                            name: "",
                            no: ""
                        },
                        email: "请输入电子邮件",
                        name: "请输入名字",
                        title: "未输入称谓",
                        birthday: "未输入生日",
                        phone: "未输入联络电话",
                        location: {
                            country: "未选择所在地区",
                            city: "未选择所在城市"
                        },
                        address: "未输入联络地址",
                        occupation: "未输入职业",
                        baptized: "未输入受洗时间",
                        commitment: "请输入委身编号",
                        created: "",
                        accessed: ""
                    },
                    handler: {
                        add: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">标记为待转入</span>",
                            text: "请确认后执行"
                        },
                        clear: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">标记为不转入</span>",
                            text: "请确认后执行"
                        },
                        convert: {
                            subject: "请确认将指定的会友<span class=\"text-danger\">转入至牧养会友</span>",
                            text: "请确认后执行"
                        }
                    }
                }
            },
            client: {
                index: {
                    download: {
                        name: "档案汇出_会友管理"
                    },
                    more: {
                        on: "更多选项",
                        off: "收起选项"
                    },
                    help: {
                        manager: "为该族群的託管人员",
                        stranger: "未跟进",
                        archive: "不跟进"
                    },
                    feature: {
                        create: {
                            subject: "新增会友"
                        },
                        advance: {
                            subject: "进阶功能"
                        }
                    },
                    advance: {
                        track: "已跟进会友",
                        stranger: "未跟进会友",
                        mark: "已标注会友",
                        archive: "不跟进会友"
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        avatar: "头像",
                        name: "名字",
                        email: "电子邮件",
                        team: "族群",
                        mark: "加注",
                        connect: "连结",
                        title: "称谓",
                        country: "所在区域",
                        city: "所在城市",
                        occupation: "职业",
                        education: "教育程度",
                        deacon: "五重执事",
                        marital: "婚姻状况"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        name: "请输入名字",
                        email: "请输入电子邮件",
                        team: "请选择需要筛选的族群",
                        title: "请选择称谓",
                        country: "请选择所在区域",
                        city: "请选择所在城市",
                        occupation: "请选择职业",
                        education: "请选择教育程度",
                        deacon: "请选择五重执事",
                        marital: "请选择婚姻状况"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用会友",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用会友",
                            text: "请确认后执行",
                            description: ""
                        },
                        mark: {
                            add: {
                                subject: "即将对此会友进行加注",
                                text: "请确认后执行",
                                description: ""
                            },
                            remove: {
                                subject: "即将对此会友移除加注",
                                text: "请确认后执行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行会友汇出, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除会友, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                edit: {
                    attend: {
                        index: {
                            field: {
                                attend: "聚会",
                                team: "族群",
                                begin: "开始时间",
                                end: "结束时间"
                            },
                            holder: {
                                attend: "请选择聚会",
                                team: "请选择族群",
                                begin: "请输入开始时间",
                                end: "请输入结束时间"
                            }
                        }
                    },
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改",
                        disabled: "请注意, 这名会友已经停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "族群"
                        },
                        2: {
                            subject: "相关历程"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    history: {
                        course: {
                            subject: "课程"
                        },
                        reunion: {
                            subject: "聚会"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        avatar: "头像",
                        name: "名字",
                        nickname: "英文名字/暱称",
                        birthday: "生日",
                        email: "电子邮件",
                        deacon: "五重执事",
                        title: "称谓",
                        education: "教育程度",
                        marital: "婚姻状况",
                        occupation: "职业",
                        mobile: "手机",
                        contact: "其他电话",
                        country: "所在区域",
                        city: "所在城市",
                        address: "居住地址",
                        school: "最高学校名称",
                        consort: {
                            name: "配偶名字",
                            contact: "配偶联络方式"
                        },
                        commitment: "委身编号",
                        guardianship: {
                            name: "监护人名字",
                            relationship: "监护人关係",
                            contact: "监护人联络方式"
                        },
                        church: {
                            enter: {
                                date: "进入教会日期"
                            },
                            leave: {
                                date: "离开教会日期",
                                reason: "离开教会原因"
                            }
                        },
                        baptized: {
                            is: "是否受洗",
                            date: "受洗日期",
                            church: "受洗教会"
                        },
                        note: "备注"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        head: "未输入",
                        name: "请输入名字",
                        nickname: "请输入英文名字/暱称",
                        birthday: "请选择生日",
                        email: "请输入电子邮件",
                        deacon: "请选择五重执事",
                        title: "请选择称谓",
                        education: "请选择教育程度",
                        marital: "请选择婚姻状况",
                        occupation: "请选择职业",
                        mobile: "请输入手机",
                        commitment: "请输入委身编号",
                        contact: "请输入其他电话",
                        country: "请选择所在区域",
                        city: "请选择所在城市",
                        address: "请输入居住地址",
                        school: "请输入最高学校名称",
                        consort: {
                            name: "请输入配偶名字",
                            contact: "请输入配偶联络方式"
                        },
                        guardianship: {
                            name: "请输入监护人名字",
                            relationship: "请输入监护人关係",
                            contact: "请输入监护人联络方式"
                        },
                        church: {
                            enter: {
                                date: "请选择进入教会日期"
                            },
                            leave: {
                                date: "请选择离开教会日期",
                                reason: "请输入离开教会原因"
                            }
                        },
                        baptized: {
                            is: "请填写是否受洗",
                            date: "请选择受洗日期",
                            church: "请输入受洗教会"
                        },
                        note: "请输入备注"
                    },
                    handler: {
                        update: {
                            subject: "即将更新会友内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    }
                },
                advance: {
                    tabs: {
                        0: {
                            subject: "批次委派"
                        },
                        1: {
                            subject: "会友汇入"
                        },
                        2: {
                            subject: "会友汇出"
                        },
                        3: {
                            subject: "全部删除"
                        }
                    },
                    step: {
                        delegate: [
                            {
                                subject: "步骤1",
                                text: "选择前往族群",
                                description: "请先指定一个需要前往的族群"
                            },
                            {
                                subject: "步骤2",
                                text: "选择会友",
                                description: "请选择需要前往的族群的会友"
                            },
                            {
                                subject: "步骤3",
                                text: "完成委派",
                                description: ""
                            }
                        ]
                    },
                    submit: {
                        delegate: {
                            prev: "上一步",
                            next: {
                                off: "请先选择族群",
                                subject: "下一步"
                            },
                            accept: {
                                off: "请先选择会友",
                                subject: "确认委派"
                            },
                            reset: "重新委派"
                        },
                        import: {
                            name: "汇入会友档案范例_完整",
                            template: "下载范例",
                            off: "请先汇入会友资讯",
                            refresh: "重新汇入",
                            accept: "执行汇入"
                        },
                        export: {
                            accept: "执行汇出"
                        },
                        destroy: {
                            accept: "执行删除"
                        }
                    },
                    field: {
                        category: "类别",
                        keyword: "关键字"
                    },
                    holder: {
                        category: "请选择类别",
                        keyword: "请输入关键字"
                    },
                    handler: {
                        delegate: {
                            subject: "即将进行批次委派",
                            text: "请确认后执行",
                            description: ""
                        },
                        import: {
                            reset: {
                                subject: "即将进行重新汇入",
                                text: "请确认后执行",
                                description: ""
                            },
                            upload: {
                                subject: "即将进行会友汇入",
                                text: "请确认后执行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行会友汇出, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将进行全部会友删除, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                }
            },
            attend: {
                v2: {
                    create: {
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "选项"
                            },
                            2: {
                                subject: "名单"
                            },
                            3: {
                                subject: "提醒"
                            },
                            4: {
                                subject: "循环"
                            }
                        },
                        field: {
                            category: {
                                subject: "分类",
                                holder: "请选择分类",
                                help: "请先建立聚会分类"
                            },
                            name: {
                                subject: "名称",
                                holder: "请输入名称"
                            },
                            should: {
                                subject: "签到工具显示应到会友",
                                holder: "请选择签到工具显示应到会友"
                            },
                            register: {
                                subject: "允许会友自行签到",
                                holder: "请选择允许会友自行签到",
                                help: {
                                    subject: "会友可自行于牧养对话中签到",
                                    off: {
                                        subject: "需要透过签到工具或小组长出示扫描会友二维码进行签到"
                                    }
                                }
                            },
                            place: {
                                subject: "地点",
                                holder: "请输入地点"
                            },
                            note: {
                                subject: "备注",
                                holder: "请输入备注"
                            },
                            status: {
                                subject: "状态",
                                holder: "请选择状态"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "聚会纪录"
                            },
                            schedule: {
                                subject: "聚会排程"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前聚会处于仅读取状态无法更新"
                            },
                            disable: {
                                subject: "目前聚会处于停用状态"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将储存内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请选择分类",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "请选择开始时间",
                                text: "",
                                description: ""
                            },
                            1040: {
                                subject: "请选择结束时间",
                                text: ""
                            },
                            1041: {
                                subject: "结束时间必须大于开始时间",
                                text: ""
                            },
                            1050: {
                                subject: "请选择签到工具显示应到会友",
                                text: ""
                            },
                            1060: {
                                subject: "请选择自行签到",
                                text: ""
                            },
                            1070: {
                                subject: "请选择状态",
                                text: ""
                            },
                            2010: {
                                subject: "请完善选项的参数设定",
                                text: ""
                            },
                            3010: {
                                subject: "请至少选择1个族群",
                                text: ""
                            },
                            4010: {
                                subject: "请完善提醒的参数设定",
                                text: ""
                            },
                            5010: {
                                subject: "请完善循环的参数设定",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        history: {
                            field: {
                                name: {
                                    subject: "族群名称"
                                },
                                begin: {
                                    subject: "开始时间"
                                },
                                end: {
                                    subject: "结束时间"
                                },
                                status: {
                                    subject: "状态",
                                    value: {
                                        0: {
                                            subject: "未开始"
                                        },
                                        1: {
                                            subject: "进行中"
                                        },
                                        2: {
                                            subject: "已结束"
                                        },
                                        3: {
                                            subject: "无聚会"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立时间"
                                }
                            },
                            search: {
                                name: {
                                    subject: "群族名称",
                                    holder: "请输入群族名称"
                                },
                                status: {
                                    subject: "状态",
                                    holder: "请选择状态"
                                }
                            }
                        },
                        schedule: {
                            field: {
                                begin: {
                                    subject: "开始时间"
                                },
                                end: {
                                    subject: "结束时间"
                                },
                                status: {
                                    subject: "状态",
                                    value: {
                                        0: {
                                            subject: "等待生成"
                                        },
                                        1: {
                                            subject: "已生成"
                                        },
                                        2: {
                                            subject: "生成逾期"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立时间"
                                },
                                stamp: {
                                    subject: "预计生成时间"
                                },
                                completed: {
                                    subject: "实际生成时间"
                                },
                                notified: {
                                    subject: "推送时间"
                                },
                                mailed: {
                                    subject: "寄送邮件时间"
                                }
                            },
                            search: {
                                status: {
                                    subject: "状态",
                                    holder: "请选择状态"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "选项"
                            },
                            2: {
                                subject: "名单"
                            },
                            3: {
                                subject: "提醒"
                            },
                            4: {
                                subject: "循环"
                            },
                            98: {
                                subject: "统计资讯"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            category: {
                                subject: "分类",
                                holder: "请选择分类",
                                help: "请先建立聚会分类"
                            },
                            name: {
                                subject: "名称",
                                holder: "请输入名称"
                            },
                            should: {
                                subject: "签到工具显示应到会友",
                                holder: "请选择签到工具显示应到会友"
                            },
                            register: {
                                subject: "允许会友自行签到",
                                holder: "请选择允许会友自行签到",
                                help: {
                                    subject: "会友可自行于牧养对话中签到",
                                    off: {
                                        subject: "需要透过签到工具或小组长出示扫描会友二维码进行签到"
                                    }
                                }
                            },
                            place: {
                                subject: "地点",
                                holder: "请输入地点"
                            },
                            note: {
                                subject: "备注",
                                holder: "请输入备注"
                            },
                            status: {
                                subject: "状态",
                                holder: "请选择状态"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "聚会纪录"
                            },
                            schedule: {
                                subject: "聚会排程"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前聚会处于仅读取状态无法更新"
                            },
                            disable: {
                                subject: "目前聚会处于停用状态"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即将更新内容",
                                text: "请确认后执行"
                            }
                        },
                        submit: {
                            cycle: {
                                subject: "预览排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "请选择分类",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "请选择开始时间",
                                text: "",
                                description: ""
                            },
                            1040: {
                                subject: "请选择结束时间",
                                text: ""
                            },
                            1041: {
                                subject: "结束时间必须大于开始时间",
                                text: ""
                            },
                            1050: {
                                subject: "请选择签到工具显示应到会友",
                                text: ""
                            },
                            1060: {
                                subject: "请选择自行签到",
                                text: ""
                            },
                            1070: {
                                subject: "请选择状态",
                                text: ""
                            },
                            2010: {
                                subject: "请完善选项的参数设定",
                                text: ""
                            },
                            3010: {
                                subject: "请至少选择1个族群",
                                text: ""
                            },
                            4010: {
                                subject: "请完善提醒的参数设定",
                                text: ""
                            },
                            5010: {
                                subject: "请完善循环的参数设定",
                                text: ""
                            }
                        }
                    },
                    field: {
                        name: {
                            subject: "名称"
                        },
                        summary: {
                            team: {
                                subject: "族群数量"
                            }
                        },
                        status: {
                            subject: "状态",
                            value: {
                                0: {
                                    subject: "已停用"
                                },
                                1: {
                                    subject: "启用中"
                                }
                            }
                        },
                        begin: {
                            subject: "开始时间"
                        },
                        created: {
                            subject: "建立时间"
                        }
                    },
                    more: {
                        history: {
                            subject: "聚会纪录"
                        },
                        schedule: {
                            subject: "聚会排程"
                        }
                    },
                    search: {
                        category: {
                            subject: "分类",
                            holder: "请选择分类",
                            help: "请先选择所属教会"
                        },
                        name: {
                            subject: "名称",
                            holder: "请输入名称"
                        },
                        status: {
                            subject: "状态",
                            holder: "请选择状态"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即将启用聚会",
                            text: "请确认后执行"
                        },
                        disable: {
                            subject: "即将停用聚会",
                            text: "请确认后执行"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除聚会, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增类别"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            name: "名称",
                            quantity: "聚会数量"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            name: "请输入名称"
                        },
                        handler: {
                            enable: {
                                subject: "即将启用分类",
                                text: "请确认后执行",
                                description: ""
                            },
                            disable: {
                                subject: "即将停用分类",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二级验证",
                                text: "<span class=\"cgRed\">即将删除分类, 请确认后执行</span>",
                                description: "请输入您目前的登入密码"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "请谨慎使用指定教会功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教会",
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            church: "请选择指定教会",
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            store: {
                                subject: "即将新增类别",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "请选择指定教会",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            readonly: "总教会仅供查看资料, 无法新增修改",
                            disabled: "请注意, 这个分类已经停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        field: {
                            belong: {
                                no: "所属教会编号",
                                name: "所属教会名称"
                            },
                            name: "名称",
                            status: "状态"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "请输入名称",
                            status: "请选择状态"
                        },
                        handler: {
                            update: {
                                subject: "即将更新类别内容",
                                text: "请确认后执行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "请输入名称",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "请选择状态",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增聚会"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教会"
                        },
                        name: "名称",
                        category: "分类",
                        cycle: "循环",
                        team: "族群数量"
                    },
                    holder: {
                        head: {
                            church: "请选择指定教会"
                        },
                        name: "请输入名称",
                        category: "请选择分类",
                        cycle: "请选择循环方式"
                    },
                    handler: {
                        enable: {
                            subject: "即将启用聚会",
                            text: "请确认后执行",
                            description: ""
                        },
                        disable: {
                            subject: "即将停用聚会",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除聚会, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "选项"
                        },
                        2: {
                            subject: "名单"
                        },
                        3: {
                            subject: "提醒"
                        },
                        4: {
                            subject: "循环"
                        }
                    },
                    help: {
                        church: "请谨慎使用指定教会功能",
                        register: {
                            off: "需要透过签到工具或小组长出示/扫描会友二维码进行签到",
                            on: "会友可自行于牧养对话中签到"
                        }
                    },
                    field: {
                        church: "指定教会",
                        name: "名称",
                        category: "分类",
                        begin: {
                            subject: "开始时间",
                            text: "首次"
                        },
                        end: {
                            subject: "结束时间",
                            text: "首次"
                        },
                        location: "地点",
                        note: "备注",
                        register: "允许会友自行签到",
                        should: "签到工具显示应到会友",
                        status: "状态"
                    },
                    holder: {
                        church: "请选择指定教会",
                        name: "请输入名称",
                        category: {
                            subject: "请选择分类",
                            empty: "请先选择指定教会"
                        },
                        begin: "请选择开始时间",
                        end: "请选择结束时间",
                        location: "请输入地点",
                        note: "请输入备注",
                        register: "请选择是否允许会友自行签到",
                        should: "请选择签到工具是否显示应到会友",
                        status: "请选择状态"
                    },
                    handler: {
                        store: {
                            subject: "即将新增聚会",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择是否允许会友自行签到",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请选择签到工具是否显示应到会友",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改",
                        disabled: "请注意, 这个聚会已经停用"
                    },
                    feature: {
                        0: "下载报到介面二维码",
                        2: "前往报到介面",
                        3: "显示报到介面二维码",
                        4: "复製报到介面网址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "选项"
                        },
                        2: {
                            subject: "名单"
                        },
                        3: {
                            subject: "提醒"
                        },
                        4: {
                            subject: "循环"
                        },
                        5: {
                            subject: "统计资讯"
                        },
                        98: {
                            subject: "循环资讯"
                        },
                        99: {
                            subject: "相关资讯"
                        }
                    },
                    summary: {
                        schedule: {
                            subject: "聚会排程"
                        },
                        history: {
                            subject: "聚会纪录"
                        }
                    },
                    detail: {
                        cycle: {
                            subject: "循环代号"
                        },
                        remind: {
                            subject: "提醒代号"
                        }
                    },
                    help: {
                        register: {
                            off: "需要透过签到工具或小组长出示/扫描会友二维码进行签到",
                            on: "会友可自行于牧养对话中签到"
                        }
                    },
                    field: {
                        belong: {
                            name: "所属教会名称",
                            no: "所属教会ID"
                        },
                        name: "名称",
                        category: "分类",
                        begin: {
                            subject: "开始时间",
                            text: "首次"
                        },
                        end: {
                            subject: "结束时间",
                            text: "首次"
                        },
                        location: "地点",
                        note: "备注",
                        register: "允许会友自行签到",
                        should: "签到工具显示应到会友",
                        status: "状态"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        name: "请输入名称",
                        category: "请选择分类",
                        begin: "请选择开始时间",
                        end: "请选择结束时间",
                        location: "请输入地点",
                        note: "请输入备注",
                        register: "请选择是否允许会友自行签到",
                        should: "请选择签到工具是否显示应到会友",
                        status: "请选择状态"
                    },
                    handler: {
                        update: {
                            subject: "即将更新聚会内容",
                            text: "请确认后执行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "请选择分类",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "请输入名称",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "请选择开始时间",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "请选择结束时间",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "结束时间必须大于开始时间",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "请选择状态",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "请选择是否允许会友自行签到",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "请选择签到工具是否显示应到会友",
                            text: "",
                            description: ""
                        }
                    }
                },
                history: {
                    index: {
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            name: "族群名称",
                            subject: "聚会标题",
                            begin: "开始时间",
                            end: "结束时间"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            },
                            name: "请输入名字"
                        }
                    },
                    edit: {
                        tip: {
                            yet: {
                                subject: "聚会尚未开始"
                            },
                            ing: {
                                subject: "聚会正在进行"
                            },
                            ended: {
                                subject: "聚会已经结束"
                            }
                        },
                        feature: {
                            0: "下载报到介面二维码",
                            1: "重製点名单",
                            2: "前往报到介面",
                            3: "显示报到介面二维码",
                            4: "复製报到介面网址",
                            11: "聚会已经结束, 无法重製点名单"
                        },
                        download: {
                            subject: "%s_报到介面二维码"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名单"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        summary: {
                            history: {
                                subject: "聚会纪录"
                            }
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            qrcode: {
                                subject: "APP专用报到二维码",
                                text: "点击下载"
                            },
                            name: "名称",
                            category: "分类",
                            begin: "开始时间",
                            end: "结束时间",
                            location: "地点",
                            note: "备注",
                            status: "状态"
                        },
                        holder: {
                            belong: {
                                name: "",
                                no: ""
                            },
                            name: "请输入名称",
                            category: "请选择分类",
                            begin: "请选择开始时间",
                            end: "请选择结束时间",
                            location: "请输入地点",
                            note: "请输入备注",
                            status: "请选择状态"
                        },
                        handler: {
                            rebuild: {
                                subject: "重製点名单",
                                text: "若您发现点名单成员不正确<br />可进行点名单重製, 请确认后执行"
                            }
                        }
                    }
                },
                schedule: {
                    index: {
                        field: {
                            head: {
                                church: "指定教会"
                            },
                            begin: "开始时间",
                            end: "结束时间"
                        },
                        holder: {
                            head: {
                                church: "请选择指定教会"
                            }
                        }
                    },
                    edit: {
                        help: {
                            remind: {
                                subject: "请参考提醒设定值"
                            }
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相关资讯"
                            }
                        },
                        boolean: {
                            false: {
                                subject: "否"
                            },
                            true: {
                                subject: "是"
                            }
                        },
                        remind: {
                            0: {
                                subject: "不发送",
                                text: "不发送"
                            },
                            1: {
                                subject: "等待发送",
                                text: "正在准备发送"
                            },
                            2: {
                                subject: "发送完成"
                            }
                        },
                        field: {
                            belong: {
                                name: "所属教会名称",
                                no: "所属教会ID"
                            },
                            created: "建立时间",
                            begin: "开始时间",
                            end: "结束时间",
                            stamp: "预定生成时间",
                            completed: "实际生成时间",
                            beforehand: "预设提前生成",
                            remind: {
                                notification: {
                                    status: "推送状态",
                                    stamp: "推送完成时间"
                                },
                                email: {
                                    status: "寄送邮件状态",
                                    stamp: "寄送邮件完成时间"
                                }
                            },
                            status: "状态"
                        },
                        holder: {
                            belong: {
                                name: "",
                                no: ""
                            },
                            begin: "请选择开始时间",
                            end: "请选择结束时间",
                            stamp: "请选择预定生成时间",
                            status: "请选择状态"
                        }
                    }
                }
            },
            team: {
                index: {
                    feature: {
                        statistics: "牧养跟进",
                        expand: {
                            on: "展开全部统计",
                            off: "收起全部统计"
                        },
                        root: "新增族群",
                        add: "新增同阶族群",
                        children: "新增子族群",
                        undo: "回上一阶",
                        architecture: {
                            active: "组织图",
                            inactive: "无子族群供显示组织图"
                        },
                        hierarchy: {
                            off: "无下一阶",
                            subject: "下一阶"
                        },
                        edit: {
                            subject: "编辑",
                            text: "该族群缺少託管人员, 请尽快编辑设定"
                        },
                        attend: "查看聚会",
                        view: "查看",
                        destroy: {
                            subject: "删除",
                            attend: "已设定聚会, 无法删除<br/>请先删除已设定聚会",
                            children: "已设定有子族群, 无法删除<br/>请先删除所有子族群"
                        }
                    },
                    tip: {
                        readonly: "总教会仅供查看资料, 无法新增修改"
                    },
                    help: {
                        updated: "最后更新时间"
                    },
                    selection: "请先选择指定教会",
                    quantity: {
                        attend: "聚会数量",
                        children: "族群数量",
                        manager: {
                            current: "託管人员",
                            recursive: "託管人员 - 合计"
                        },
                        partake: {
                            current: "族群成员",
                            recursive: "族群成员 - 合计"
                        }
                    },
                    field: {
                        church: "指定教会"
                    },
                    holder: {
                        church: "请选择指定教会"
                    },
                    factory: {
                        reset: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将重设该教会牧养相关功能<br/>包括牧养对话群组与聚会等相关功能<br/>重设将无法恢復, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        },
                        destroy: {
                            subject: "安全性二级验证",
                            text: "<span class=\"cgRed\">即将删除族群, 删除后牧养对话群组将会解散<br/>并无法恢復, 请确认后执行</span>",
                            description: "请输入您目前的登入密码"
                        }
                    }
                },
                statistics: {
                    item: {
                        attend: {
                            subject: "聚会纪录",
                            field: {
                                subject: "族群",
                                total: "合计次数",
                                last: "最后更新时间"
                            }
                        },
                        event: {
                            subject: "服事参与",
                            help: {
                                updated: "最后更新时间"
                            },
                            empty: {
                                subject: "暂无资料"
                            },
                            tip: {
                                team: "列表对象为群组第一层全教会各佔比，点选群组名称可查阅下一级群组<br/><span class=\"text-danger\">该牧区计算: 参与服事人数 / 总人数 = 全教会服事比例</span>"
                            },
                            header: {
                                team: {
                                    subject: "统计服事对象"
                                },
                                temperature: {
                                    subject: "温度服事对象",
                                    info: "30天以上未参加",
                                    warning: "60天以上未参加",
                                    danger: "90天以上未参加",
                                    total: {
                                        all: {
                                            subject: "合计 <b class=\"text-success ft-15\">%s</b> 位"
                                        },
                                        section: {
                                            subject: "共 <b class=\"text-%s ft-15\">%s</b> 位"
                                        }
                                    }
                                },
                                leave: {
                                    subject: "离开服事对象",
                                    total: {
                                        all: {
                                            subject: "合计 <b class=\"text-danger ft-15\">%s</b> 位"
                                        }
                                    }
                                },
                                join: {
                                    month: {
                                        subject: "新增服事人数 <span class=\"ft-14\">(月)</span>",
                                        total: {
                                            all: {
                                                subject: "合计 <b class=\"text-success ft-15\">%s</b> 位"
                                            }
                                        }
                                    },
                                    week: {
                                        subject: "新增服事人数 <span class=\"ft-14\">(週)</span>",
                                        total: {
                                            all: {
                                                subject: "合计 <b class=\"text-success ft-15\">%s</b> 位"
                                            }
                                        }
                                    }
                                },
                                event: {
                                    team: {
                                        subject: "参与群组",
                                        total: {
                                            all: {
                                                subject: "合计 <b class=\"text-info ft-15\">%s</b> 位"
                                            }
                                        }
                                    }
                                }
                            },
                            summary: {
                                total: {
                                    subject: "总人数"
                                },
                                having: {
                                    subject: "参与服事人数"
                                },
                                nothing: {
                                    subject: "未参与服事人数"
                                },
                                percent: {
                                    current: {
                                        subject: "参与服事比例 <span class=\"ft-12\">(%)</span>"
                                    },
                                    prev: {
                                        subject: "前月参与服事比例 <span class=\"ft-12\">(%)</span>"
                                    }
                                },
                                join: {
                                    month: {
                                        subject: "新增服事人数 <span class=\"ft-12\">(月)</span>"
                                    },
                                    week: {
                                        subject: "新增服事人数 <span class=\"ft-12\">(週)</span>"
                                    }
                                },
                                leave: {
                                    subject: "离开服事人数"
                                }
                            }
                        },
                        gender: {
                            subject: "男女比例",
                            field: {
                                subject: "性别",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                1: "弟兄",
                                2: "姐妹",
                                9999: "未登记"
                            }
                        },
                        year: {
                            subject: "年龄分佈",
                            field: {
                                subject: "年龄",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                0: "0-9岁",
                                1: "10-19岁",
                                2: "20-29岁",
                                3: "30-39岁",
                                4: "40-49岁",
                                5: "50-59岁",
                                6: "60-69岁",
                                7: "70-79岁",
                                8: "80-89岁",
                                9: "90-99岁",
                                10: "100-109岁",
                                11: "110-119岁",
                                12: "120-129岁",
                                9999: "未登记"
                            }
                        },
                        deacon: {
                            subject: "五重执事",
                            field: {
                                subject: "执事",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                1: "使徒",
                                2: "先知",
                                3: "传福音",
                                4: "牧师",
                                5: "教师",
                                9999: "未登记"
                            }
                        },
                        education: {
                            subject: "教育程度",
                            field: {
                                subject: "程度",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                1: "小学",
                                2: "中学",
                                3: "专科",
                                4: "高中",
                                5: "高职/高商",
                                6: "大学",
                                7: "硕士",
                                8: "博士",
                                9999: "未登记"
                            }
                        },
                        occupation: {
                            subject: "职业分佈",
                            field: {
                                subject: "职业",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                1: "行政经营",
                                2: "业务行销",
                                3: "人事法务",
                                4: "财会金融",
                                5: "广告美编",
                                6: "客户服务",
                                7: "电脑硬体",
                                8: "资讯软体",
                                9: "品管製造",
                                10: "技术服务",
                                11: "营建职业",
                                12: "传播媒体",
                                13: "娱乐演艺",
                                14: "教育学术",
                                15: "医疗美容",
                                16: "保全警卫",
                                17: "家事服务",
                                18: "农林鱼牧",
                                19: "慈善宗教",
                                20: "交通及物流服务",
                                21: "餐饮旅游运动",
                                22: "美容美髮",
                                23: "军公教",
                                56: "家管",
                                57: "儿童",
                                58: "待业",
                                59: "学生",
                                9999: "未登记"
                            }
                        },
                        marital: {
                            subject: "婚姻状况",
                            field: {
                                subject: "状况",
                                quantity: "数量",
                                percent: "佔比",
                                total: "总和",
                                last: "最后更新时间"
                            },
                            option: {
                                1: "已婚",
                                2: "未婚",
                                3: "丧偶",
                                4: "再婚",
                                5: "离婚",
                                9999: "未登记"
                            }
                        },
                        baptized: {
                            subject: "受洗人数"
                        }
                    }
                }
            }
        }
    },
    handler: {
        session: {
            repair: {
                subject: "安全性二级验证",
                text: "<span class=\"cgRed\">即将进行重建模拟, 请确认后执行</span>",
                description: "请输入您目前的登入密码"
            },
            simulation: {
                subject: "安全性二级验证",
                text: "<span class=\"cgRed\">即将进行模拟登入, 请确认后执行</span>",
                description: "请输入您目前的登入密码"
            },
            head: {
                subject: "即将返回管理介面",
                text: "",
                description: ""
            },
            destroy: {
                subject: "即将登出系统",
                text: "请确认登出",
                description: ""
            }
        }
    },
    error: {
        400: {
            subject: "连线权杖逾时",
            text: "Oops! 🤕🤕 请您重新登入系统",
            description: "请稍后再试或请联络管理人员"
        },
        401: {
            subject: "未授权的存取",
            text: "Oops! 😳😳 您可能没有存取的权限",
            description: "请稍后再试或请联络管理人员"
        },
        404: {
            subject: "页面不存在",
            text: "Oops! 😱😱 您访问的页面不存在或暂时不可用",
            description: "请稍后再试或请联络管理人员"
        },
        405: {
            subject: "这出了点小问题",
            text: "Oops! 😵😵 无法正确连结至伺服器",
            description: "请稍后再试或请联络管理人员"
        },
        413: {
            subject: "这出了点小问题",
            text: "Oops! 🙁🙁 您上传的档案容量超出限制",
            description: "请稍后再试或请联络管理人员"
        },
        500: {
            subject: "这出了点小问题",
            text: "Oops! 🥺🥺 伺服器好像罢工了",
            description: "请稍后再试或请联络管理人员"
        },
        502: {
            subject: "这出了点小问题",
            text: "Oops! 😵😵 无法正确连结至伺服器",
            description: "请稍后再试或请联络管理人员"
        },
        504: {
            subject: "这出了点小问题",
            text: "Oops! 😵‍💫😵‍💫 连结到伺服器超出预期时间",
            description: "请稍后再试或请联络管理人员"
        },
        901: {
            subject: "上传档案发生错误",
            text: "单个档案超过 <b class=\"ft-20 text-danger\">%s</b> %s限制",
            description: ""
        },
        902: {
            subject: "上传档案发生错误",
            text: "档案合计超过 <b class=\"ft-20 text-danger\">%s</b> %s限制",
            description: ""
        },
        903: {
            subject: "产生预览图失败",
            text: "上传档案中包含不支援的图片编码",
            description: ""
        },
        904: {
            subject: "分享连结出现错误",
            text: "请确认您的分享连结是否正确",
            description: ""
        },
        998: {
            subject: "二级验证失败",
            text: "请确认您的登入密码是否正确",
            description: "请确认您的登入密码是否正确"
        },
        999: {
            subject: "这出了点小问题",
            text: "Oops! 😲😲 发生未知错误",
            description: "请稍后再试或请联络管理人员"
        }
    },
    submit: {
        status: {
            enable: "启用",
            disable: "停用"
        },
        simulation: "模拟登入",
        repair: "重建模拟",
        download: "下载",
        recipient: "编辑名单",
        approved: "核准",
        reject: "拒绝",
        preview: "预览",
        copy: "复製",
        insert: "插入",
        back: "返回",
        join: "加入",
        remove: "移除",
        select: "选择",
        attachment: "附件",
        home: "返回首页",
        toEntry: "回列表",
        reLogin: "重新登入",
        login: "登入",
        forgot: {
            found: "找回密码",
            verification: "确认送出",
            reset: "重设密码"
        },
        waiting: "登入中",
        success: "登入成功...请稍候",
        ok: "好",
        top: "置顶",
        toTop: "回到顶部",
        save: "储存",
        change: "变更",
        search: "搜寻",
        query: "查询",
        close: "关闭",
        accept: "确定",
        refresh: "刷新",
        reload: "重新读取",
        upload: "上传",
        clear: "清除",
        reset: "重设",
        cancel: "取消",
        send: "送出",
        add: "加入",
        edit: "编辑",
        delete: "删除",
        done: "完成",
        fetch: "立即更新",
        relocation: "重新整理",
        replace: "取代",
        schedule: "排程预览",
        view: "查看",
        segue: "前往"
    },
    bool: {
        basic: [
            "否",
            "是"
        ],
        result: [
            "失败",
            "成功"
        ]
    },
    belong: {
        belong: {
            no: {
                subject: "所属教会编号"
            },
            name: {
                subject: "所属教会名称"
            }
        },
        delegate: {
            no: {
                subject: "执行教会编号"
            },
            name: {
                subject: "执行教会名称"
            }
        }
    },
    ago: {
        just: "刚刚",
        minute: "%s分钟前",
        hour: "%s小时前",
        day: "%s天前"
    },
    datepicker: {
        panel: {
            year: "选择年",
            month: "选择月"
        },
        prev: {
            year: "往前 1 年",
            month: "往前 1 个月",
            ten: {
                year: "往前 10 年"
            }
        },
        next: {
            year: "往后 1 年",
            month: "往后 1 个月",
            ten: {
                year: "往后 10 年"
            }
        },
        timer: {
            subject: "点击使用滑杆模式",
            increase: {
                hour: "增加 1 小时",
                minute: "增加 %s 分钟"
            },
            decrease: {
                hour: "减少 1 小时",
                minute: "减少 %s 分钟"
            }
        },
        error: {
            1001: {
                subject: "小于最早时间限制",
                text: "最早时间限制为 <b class=\"ft-20 text-danger\">%s</b><br />请您重新选择",
                description: ""
            },
            1002: {
                subject: "大于最晚时间限制",
                text: "最晚时间限制为 <b class=\"ft-20 text-danger\">%s</b><br />请您重新选择",
                description: ""
            }
        },
        day: [
            "日",
            "一",
            "二",
            "三",
            "四",
            "五",
            "六"
        ],
        month: [
            "01月",
            "02月",
            "03月",
            "04月",
            "05月",
            "06月",
            "07月",
            "08月",
            "09月",
            "10月",
            "11月",
            "12月"
        ]
    },
    maintenance: {
        701: {
            subject: "牧养相关功能暂停使用",
            text: "该功能目前处于维护状态暂时无法使用<br/>开放时间将另行通知, 造成您的不便请多见谅"
        }
    },
    select: {
        v2: {
            all: {
                subject: "全部"
            }
        }
    },
    options: {
        baptized: [
            {
                value: 0,
                label: "否"
            },
            {
                value: 1,
                label: "是"
            }
        ],
        schedule: [
            {
                value: 1,
                label: "等待上架"
            },
            {
                value: 2,
                label: "已上架"
            },
            {
                value: 3,
                label: "已下架"
            },
            {
                value: 99,
                label: "全部"
            }
        ],
        invitation: {
            customize: [
                {
                    value: 1,
                    label: "自行输入"
                },
                {
                    value: 0,
                    label: "随机产生"
                }
            ]
        },
        share: [
            {
                value: 1,
                label: "允许"
            },
            {
                value: 0,
                label: "禁止"
            }
        ],
        gender: [
            {
                value: 0,
                label: "女性"
            },
            {
                value: 1,
                label: "男性"
            },
            {
                value: 99,
                label: "不愿意透露"
            }
        ],
        entrust: [
            {
                value: 0,
                label: "委託管理"
            },
            {
                value: 1,
                label: "自行维护"
            },
            {
                value: 99,
                label: "全部"
            }
        ],
        donate: [
            {
                value: 0,
                label: "关闭奉献"
            },
            {
                value: 1,
                label: "线上奉献"
            },
            {
                value: 2,
                label: "奉献连结"
            }
        ],
        unsplash: {
            category: [
                {
                    label: "全部",
                    value: 0
                },
                {
                    label: "基督信仰",
                    value: 1
                },
                {
                    label: "自然生态",
                    value: 2
                },
                {
                    label: "日常生活",
                    value: 3
                },
                {
                    label: "纹理图案",
                    value: 4
                },
                {
                    label: "人事物景",
                    value: 5
                },
                {
                    label: "艺术色彩",
                    value: 6
                },
                {
                    label: "健康食物",
                    value: 7
                },
                {
                    label: "动物植物",
                    value: 8
                },
                {
                    label: "街头摄影",
                    value: 9
                }
            ],
            status: [
                {
                    value: 0,
                    label: "未核准"
                },
                {
                    value: 1,
                    label: "已核准"
                },
                {
                    value: 2,
                    label: "已拒绝"
                }
            ]
        }
    },
    status: {
        code: {
            200: {
                subject: "操作成功",
                text: "",
                description: ""
            },
            201: {
                subject: "服务降级保护中",
                text: "服务目前处于降级保护的状态, 请稍后再试",
                description: ""
            },
            202: {
                subject: "服务流控管制中",
                text: "服务目前处于流控管制的状态, 请稍后再试",
                description: ""
            },
            203: {
                subject: "服务热点限制中",
                text: "服务目前处于热点限制的状态, 请稍后再试",
                description: ""
            },
            204: {
                subject: "系统规则不满足要求",
                text: "请稍后再试",
                description: ""
            },
            205: {
                subject: "授权规则不通过",
                text: "请稍后再试",
                description: ""
            },
            401: {
                subject: "连线权杖逾时或不正确",
                text: "Oops! 🤕🤕 请您重新登入系统",
                description: "请稍后再试或请联络管理人员"
            },
            402: {
                subject: "公司授权已经到期",
                text: "请向我们的销售人员联络, 谢谢",
                description: ""
            },
            403: {
                subject: "无存取权限",
                text: "Oops! 😳😳 您可能没有存取的权限",
                description: "请稍后再试或请联络管理人员"
            },
            404: {
                subject: "页面不存在",
                text: "Oops! 😱😱 您访问的页面不存在或暂时不可用",
                description: "请稍后再试或请联络管理人员"
            },
            405: {
                subject: "这出了点小问题",
                text: "Oops! 😵😵 无法正确连结至伺服器",
                description: "请稍后再试或请联络管理人员"
            },
            413: {
                subject: "这出了点小问题",
                text: "Oops! 🙁🙁 您上传的档案容量超出限制",
                description: "请稍后再试或请联络管理人员"
            },
            500: {
                subject: "这出了点小问题",
                text: "Oops! 🥺🥺 伺服器好像罢工了",
                description: "请稍后再试或请联络管理人员"
            },
            502: {
                subject: "这出了点小问题",
                text: "Oops! 😵😵 无法正确连结至伺服器",
                description: "请稍后再试或请联络管理人员"
            },
            503: {
                subject: "这出了点小问题",
                text: "Oops! 😵😵 存取的服务存在异常",
                description: "请稍后再试或请联络管理人员"
            },
            504: {
                subject: "这出了点小问题",
                text: "Oops! 😵😵 连结到伺服器超出预期时间",
                description: "请稍后再试或请联络管理人员"
            },
            700: {
                subject: "这出了点小问题",
                text: "Oops! 😵😵 系统模组连接异常",
                description: "请稍后再试或请联络管理人员"
            },
            901: {
                subject: "上传档案发生错误",
                text: "单个档案超过 <b class=\"ft-20 text-danger\">%s</b> %s限制",
                description: ""
            },
            902: {
                subject: "上传档案发生错误",
                text: "档案合计超过 <b class=\"ft-20 text-danger\">%s</b> %s限制",
                description: ""
            },
            903: {
                subject: "产生预览图失败",
                text: "上传档案中包含不支援的图片编码",
                description: ""
            },
            904: {
                subject: "分享连结出现错误",
                text: "请确认您的分享连结是否正确",
                description: ""
            },
            998: {
                subject: "二级验证失败",
                text: "请确认您的登入密码是否正确",
                description: "请确认您的登入密码是否正确"
            },
            999: {
                subject: "这出了点小问题",
                text: "Oops! 😲😲 发生未知错误",
                description: "请稍后再试或请联络管理人员"
            }
        }
    }
}
