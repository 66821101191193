export default {
    portal: {
        admin: "我的教會",
        shepherd: "牧養系統"
    },
    caption: {
        v2: {
            my: "我的教會",
            soul: "心靈捕手",
            daily: "每日靈糧"
        }
    },
    nav: {
        billboard: {
            index: "公吿欄",
            edit: "公吿內容"
        },
        admin: {
            home: {
                index: "控制面板",
                permission: "控制面板",
                statistics: {
                    client: "會友人數",
                    coming: "會友新增",
                    delete: "會友刪除",
                    publish: "上稿次數",
                    notification: "發送推播",
                    bible: "聖經開啟",
                    favorite: "收藏次數",
                    comment: "留言次數",
                    share: "分享次數",
                    visit: "文章觀看",
                    social: "外連觀看",
                    login: "登入次數"
                }
            },
            billboard: {
                index: "公吿管理",
                create: "公吿新增",
                edit: "公吿內容",
                permission: "公吿管理"
            },
            user: {
                index: "管理員帳號管理",
                create: "管理員新增",
                edit: "管理員內容",
                permission: "管理員帳號管理"
            },
            church: {
                index: "教會管理",
                create: "教會新增",
                edit: "教會內容",
                permission: "教會管理"
            },
            client: {
                index: "會友管理",
                create: "會友新增",
                edit: "會友內容",
                permission: "會友管理",
                donate: {
                    index: "奉獻紀錄",
                    edit: "奉獻內容",
                    permission: "會友管理 - 奉獻紀錄"
                }
            },
            my: {
                index: "我的教會文章管理",
                create: "我的教會文章新增",
                edit: "我的教會文章內容",
                copy: "文章複製",
                mindcatcher: "文章導入",
                permission: "我的教會文章管理",
                comment: {
                    index: "留言紀錄",
                    permission: "我的教會文章管理 - 留言紀錄"
                },
                notification: {
                    index: "推播紀錄",
                    create: "推播新增",
                    edit: "推播內容",
                    permission: "我的教會文章管理 - 推播紀錄"
                }
            },
            daily: {
                index: "每日靈糧文章管理",
                create: "每日靈糧文章新增",
                edit: "每日靈糧文章內容",
                copy: "文章複製",
                mindcatcher: "文章導入",
                permission: "每日靈糧文章管理",
                comment: {
                    index: "留言紀錄",
                    permission: "每日靈糧文章管理 - 留言紀錄"
                },
                notification: {
                    index: "推播紀錄",
                    create: "新增推播",
                    edit: "推播內容",
                    permission: "每日靈糧文章管理 - 推播紀錄"
                }
            },
            soul: {
                index: "心靈捕手文章管理",
                create: "心靈捕手文章新增",
                edit: "心靈捕手文章內容",
                copy: "文章複製",
                mindcatcher: "文章導入",
                permission: "心靈捕手文章管理 - 文章管理",
                comment: {
                    index: "留言紀錄",
                    permission: "心靈捕手文章管理 - 留言紀錄"
                },
                notification: {
                    index: "推播紀錄",
                    create: "推播新增",
                    edit: "推播內容",
                    permission: "心靈捕手文章管理 - 推播紀錄"
                },
                category: {
                    index: "心靈捕手分類管理",
                    permission: "心靈捕手文章管理 - 分類管理"
                }
            },
            gallery: {
                subject: "圖庫管理",
                index: "圖庫清單",
                accept: "圖庫核准",
                alarm: "圖庫檢舉",
                permission: {
                    index: "圖庫管理 - 圖庫清單",
                    accept: "圖庫管理 - 圖庫核准",
                    alarm: "圖庫管理 - 圖庫檢舉"
                }
            },
            notification: {
                index: "推播管理",
                create: "新增推播",
                edit: {
                    subject: "推播內容",
                    automatic: {
                        subject: "自動化紀錄",
                        edit: {
                            subject: "紀錄內容"
                        }
                    },
                    history: {
                        subject: "推播紀錄"
                    }
                },
                permission: "推播管理"
            },
            event: {
                subject: "行事曆管理",
                copy: "複製服事",
                create: "服事新增",
                edit: "服事內容",
                index: "服事清單",
                permission: "行事曆管理 - 服事清單",
                team: {
                    index: "服事群組",
                    create: "群組新增",
                    edit: "群組內容",
                    permission: "行事曆管理 - 服事群組",
                    history: {
                        index: "歷史紀錄"
                    }
                }
            },
            donate: {
                index: "奉獻管理",
                edit: "奉獻內容",
                permission: "奉獻管理"
            },
            cycle: {
                index: "循環管理",
                create: "循環新增",
                edit: "循環內容",
                permission: "循環管理",
                history: {
                    index: "循環紀錄",
                    edit: "紀錄內容"
                }
            },
            teach: {
                index: "教學管理",
                create: "教學新增",
                edit: "教學內容",
                permission: "教學管理"
            },
            question: {
                subject: "問題集管理",
                group: {
                    index: "問題群組",
                    create: "問題集新增",
                    edit: "問題集內容",
                    permission: "問題集管理 - 問題群組"
                },
                question: {
                    index: "問題設置",
                    create: "問題設置新增",
                    edit: "設置內容",
                    permission: "問題集管理 - 問題設置"
                },
                option: {
                    index: "回覆選項",
                    create: "回覆選項新增",
                    edit: "選項內容",
                    permission: "問題集管理 - 回覆選項"
                },
                form: {
                    index: "表單紀錄",
                    edit: "紀錄內容",
                    permission: "問題集管理 - 表單紀錄"
                }
            },
            support: {
                subject: "客服管理",
                worker: {
                    index: "維修工單",
                    create: "工單新增",
                    edit: {
                        subject: "工單內容",
                        history: {
                            index: "歷史紀錄",
                            create: "紀錄新增",
                            edit: "紀錄內容"
                        },
                        record: {
                            index: "異動紀錄"
                        }
                    }
                },
                history: {
                    index: "帳號紀錄",
                    edit: "紀錄內容",
                    permission: "客服管理 - 帳號紀錄"
                },
                feedback: {
                    index: "意見與建議",
                    edit: "反饋內容",
                    permission: "客服管理 - 意見與建議"
                },
                coffee: {
                    index: "請我們喝咖啡",
                    edit: "贊助內容",
                    permission: "客服管理 - 請我們喝咖啡"
                }
            },
            course: {
                subject: "歷程管理",
                exception: {
                    index: "攔截紀錄",
                    edit: "攔截內容",
                    permission: "歷程管理 - 攔截紀錄"
                },
                access: {
                    index: "存取紀錄",
                    edit: "存取內容",
                    permission: "歷程管理 - 存取紀錄"
                },
                authorize: {
                    index: "授權紀錄",
                    edit: "登入內容",
                    permission: "歷程管理 - 授權紀錄"
                }
            },
            activity: {
                subject: "活動管理",
                index: "活動清單",
                create: "活動新增",
                edit: {
                    index: "活動內容",
                    register: {
                        index: "後台報名"
                    },
                    registered: {
                        index: "報名清單",
                        edit: {
                            index: "報名內容"
                        }
                    }
                },
                permission: "活動管理 - 活動清單",
                category: {
                    index: "分類管理",
                    create: "分類新增",
                    edit: "分類內容",
                    permission: "活動管理 - 分類管理"
                }
            },
            live: {
                subject: "直播管理",
                index: "直播清單",
                create: "直播新增",
                edit: {
                    subject: "直播內容",
                    comment: {
                        subject: "聊天室發言"
                    },
                    secure: {
                        subject: "私密代禱"
                    }
                },
                permission: "直播管理 - 直播清單",
                category: {
                    index: "分類管理",
                    create: "分類新增",
                    edit: "分類內容",
                    permission: "直播管理 - 分類管理"
                }
            },
            personal: {
                edit: "個人資訊"
            }
        },
        shepherd: {
            home: {
                index: "控制面板",
                permission: "控制面板",
                statistics: {
                    friend: "新朋友數量",
                    client: {
                        total: "會友人數",
                        track: "已跟進會友",
                        stranger: "未跟進會友",
                        archive: "不跟進會友"
                    },
                    team: "族群數量",
                    attend: "聚會數量"
                }
            },
            friend: {
                index: "新朋友管理",
                create: "新朋友新增",
                edit: "新朋友內容",
                permission: "新朋友管理"
            },
            client: {
                index: "會友管理",
                create: "會友新增",
                edit: "會友內容",
                advance: {
                    subject: "進階功能",
                    permission: "會友管理 - 進階功能"
                },
                permission: "會友管理",
                attend: {
                    index: "聚會紀錄",
                    edit: "聚會內容"
                }
            },
            team: {
                index: "族群管理",
                permission: "族群管理",
                attend: {
                    index: "聚會查詢"
                },
                statistics: {
                    index: "牧養跟進",
                    attend: {
                        index: "聚會紀錄"
                    },
                    event: {
                        index: "服事參與"
                    },
                    gender: {
                        index: "男女比例"
                    },
                    year: {
                        index: "年齡分佈"
                    },
                    deacon: {
                        index: "五重執事"
                    },
                    education: {
                        index: "教育程度"
                    },
                    occupation: {
                        index: "職業分佈"
                    },
                    marital: {
                        index: "婚姻狀況"
                    },
                    baptized: {
                        index: "受洗人數"
                    }
                }
            },
            attend: {
                subject: "聚會管理",
                index: "聚會清單",
                create: "聚會新增",
                edit: "聚會內容",
                permission: "聚會管理 - 聚會清單",
                schedule: {
                    index: "聚會排程",
                    edit: "排程內容"
                },
                history: {
                    index: "聚會紀錄",
                    edit: "紀錄內容"
                },
                category: {
                    index: "分類管理",
                    create: "分類新增",
                    edit: "分類內容",
                    permission: "聚會管理 - 分類管理"
                }
            },
            setting: {
                permission: "參數管理"
            }
        },
        profile: "個人資料",
        head: "返回管理介面",
        logout: "登出系統"
    },
    month: [
        null,
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
    ],
    weekday: {
        full: [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六"
        ],
        short: [
            "日",
            "一",
            "二",
            "三",
            "四",
            "五",
            "六"
        ]
    },
    component: {
        signature: {
            recipient: {
                move: {
                    left: "雙擊加入",
                    right: "雙擊移除"
                },
                subject: "編輯簽到名單",
                disable: {
                    available: "請選擇加入的項目",
                    selected: "請選擇移除的項目"
                },
                changed: "尚未異動項目",
                empty: "請指定協助<br/>使用App簽到本活動的會友",
                not: {
                    found: "無法搜尋到<br />您指定條件的會友"
                },
                help: {
                    available: "可選擇 <b class=\"cgBase\">%s</b> 個會友",
                    selected: "已選擇 <b class=\"cgBase\">%s</b> 個會友"
                },
                th: [
                    "頭像",
                    "會友",
                    "操作",
                    "資訊"
                ],
                field: {
                    keyword: "搜尋名單",
                    tag: "標籤"
                },
                holder: {
                    keyword: "搜尋範圍: 名字/郵件/編號",
                    tag: "請選擇標籤"
                },
                handle: {
                    close: {
                        subject: "即將關閉編輯推播",
                        text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                    }
                },
                tag: {
                    all: "全部",
                    uncategorized: "未分類",
                    administrator: "管理員名單"
                }
            }
        },
        support: {
            v2: {
                worker: {
                    progress: {
                        cost: {
                            subject: "耗用時間"
                        },
                        remaining: {
                            subject: "剩餘時間",
                            expire: {
                                subject: "已經超時"
                            }
                        }
                    },
                    account: {
                        empty: {
                            subject: "請輸入異常App帳號"
                        },
                        field: {
                            account: "異常App帳號"
                        },
                        holder: {
                            account: "請輸入正確的電子郵件格式, 按 Enter 可新增一筆"
                        },
                        format: {
                            account: "請輸入正確的電子郵件格式"
                        },
                        th: {
                            0: {
                                subject: "帳號"
                            },
                            1: {
                                subject: "刪除"
                            }
                        },
                        handler: {
                            delete: {
                                subject: "即將刪除指定異常帳號",
                                text: ""
                            }
                        }
                    },
                    attachment: {
                        subject: "加入附件",
                        selected: {
                            subject: "已經選擇 <b class=\"text-danger ft-16\">%s</b> 個附件"
                        },
                        th: {
                            0: {
                                subject: "類型"
                            },
                            1: {
                                subject: "操作"
                            }
                        },
                        field: {
                            subject: "標題",
                            description: "描述",
                            mime: "類型",
                            url: "附件網址"
                        },
                        holder: {
                            subject: "請輸入標題",
                            description: "請輸入描述",
                            url: "請輸入附件網址"
                        },
                        empty: {
                            subject: "請點擊新增進行加入附件"
                        },
                        complete: {
                            not: {
                                subject: "請先完善表單"
                            }
                        },
                        help: {
                            subject: "請輸入標題",
                            url: {
                                subject: "請輸入附件網址",
                                illegal: {
                                    subject: "請輸入正確的附件網址"
                                }
                            }
                        },
                        handler: {
                            delete: {
                                subject: "即將刪除指定附件",
                                text: ""
                            }
                        }
                    },
                    history: {
                        attachment: {
                            editor: {
                                subject: "附件內容",
                                field: {
                                    mime: {
                                        subject: "類型"
                                    },
                                    subject: {
                                        subject: "標題",
                                        holder: ""
                                    },
                                    description: {
                                        subject: "描述",
                                        holder: ""
                                    },
                                    url: {
                                        subject: "網址",
                                        holder: ""
                                    }
                                }
                            }
                        },
                        field: {
                            subject: {
                                subject: "標題",
                                holder: "請輸入標題"
                            },
                            description: {
                                subject: "描述",
                                holder: "請輸入描述"
                            },
                            attachment: {
                                subject: "附件"
                            },
                            creator: {
                                subject: "建立人員",
                                holder: "",
                                stamp: {
                                    subject: "建立時間",
                                    holder: ""
                                }
                            },
                            editor: {
                                subject: "上一次編輯人",
                                holder: "",
                                stamp: {
                                    subject: "上一次編輯時間",
                                    holder: ""
                                }
                            }
                        },
                        help: {
                            freeze: {
                                subject: "無法編輯",
                                text: "非目前狀態或者您不是建立人員<br/>不能進行編輯"
                            },
                            edit: {
                                subject: "編輯"
                            },
                            release: {
                                subject: "需要發佈App"
                            },
                            invalid: {
                                subject: "作廢",
                                text: "紀錄已作廢"
                            }
                        }
                    },
                    status: {
                        field: {
                            subject: "狀態"
                        }
                    }
                }
            }
        },
        recipient: {
            selector: {
                help: {
                    tag: {
                        subject: "總教會僅有全部與管理員名單"
                    }
                }
            }
        },
        attend: {
            cascader: {
                subject: "請選擇族群",
                clear: {
                    subject: "清除"
                },
                error: {
                    1001: {
                        subject: "請至少選擇1個族群",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        menu: {
            horizontal: {
                user: {
                    robot: "模擬登入中",
                    account: "帳號名稱",
                    timezone: "所在時區",
                    version: {
                        ui: "UI版本",
                        core: "服務版本"
                    },
                    websocket: {
                        communication: "通訊狀態",
                        status: {
                            connected: "連線成功",
                            disconnect: "目前斷線"
                        }
                    },
                    flush: "釋放快取",
                    handler: {
                        flush: {
                            subject: "即將手動釋放快取",
                            text: "請確認後執行"
                        }
                    }
                },
                billboard: {
                    subject: "公告欄",
                    unread: "您還有 <b class=\"ft-14 text-danger\">%s</b> 筆公告未讀取",
                    segue: "前往公告欄"
                },
                invitation: {
                    subject: "教會邀請碼"
                }
            }
        },
        remind: {
            column: {
                on: "已開啟",
                off: "未開啟"
            },
            editor: {
                help: {
                    total: {
                        on: "最多只能選擇 <b class=\"ft-16\">%s</b> 個選項, 還可以選擇 <b class=\"ft-16\">%s</b> 個選項",
                        off: "已達到選項上限 <b class=\"ft-16\">%s</b> 個"
                    },
                    add: {
                        custom: "新增自定義",
                        email: "新增電子郵件"
                    },
                    maximum: {
                        custom: "新增自定義最多僅能設定 <b class=\"cgBase\">%s</b> 筆",
                        email: "寄送郵件名單最多僅能設定 <b class=\"cgBase\">%s</b> 筆"
                    }
                },
                field: {
                    regular: "常用選項",
                    custom: "自定義選項",
                    method: "通知方式"
                },
                holder: {
                    email: "請輸入電子郵件"
                },
                description: {
                    zero: "活動時間點",
                    minute: "%s 分鐘前",
                    hour: "%s 小時前",
                    day: "%s 日前",
                    week: "%s 週前"
                },
                error: {
                    1001: {
                        subject: "請指定通知方式",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "請至少選擇一名收件人",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "請輸入郵件地址",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "郵件格式錯誤",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        live: {
            type: {
                name: {
                    1: "YouTube",
                    2: "JustForYou"
                },
                disabled: "這個選項暫時無法使用"
            }
        },
        cycle: {
            type: {
                name: {
                    1: "文章",
                    2: "推播",
                    3: "服事"
                },
                disabled: "這個選項暫時無法使用"
            },
            column: {
                once: "單次",
                day: "每日",
                week: "每週",
                month: "每月",
                year: "每年"
            },
            schedule: {
                subject: "排程預覽",
                text: "共計 <b class=\"ft-14 text-success\">%s</b> 個排程",
                th: {
                    0: {
                        subject: "#"
                    },
                    1: {
                        subject: "開始時間"
                    },
                    2: {
                        subject: "結束時間"
                    }
                }
            },
            editor: {
                help: {
                    begin: {
                        weekday: {
                            subject: "首次起始日為星期%s, 不能取消"
                        }
                    },
                    resource: {
                        subject: "目前最長可定義 %s 年週期, 請妥善利用資源"
                    },
                    more: "如果範圍中的月份沒有第 <b class=\"text-success\">%s</b> 天, 則會自動跳過"
                },
                field: {
                    cycle: "方式",
                    duration: "時長"
                },
                spinner: {
                    day: [
                        "每",
                        "日"
                    ],
                    week: [
                        "每",
                        "週"
                    ],
                    month: [
                        "每",
                        "個月"
                    ],
                    year: [
                        "每",
                        "年"
                    ]
                },
                duration: {
                    specific: "總計次數"
                },
                method: {
                    month: {
                        day: {
                            at: "在第 %s 天重複",
                            last: "在最後一天重複"
                        },
                        week: {
                            at: "在第%s週的%s",
                            last: "在最後一個%s重複"
                        },
                        select: "選取希望重複的日期"
                    },
                    year: {
                        day: {
                            at: "在 %s 的第 %s 天重複",
                            last: "在 %s 的最後一天重複"
                        },
                        week: {
                            at: "在 %s 的第%s週的%s重複",
                            last: "在 %s 的最後一個%s重複"
                        },
                        select: "選取希望在 %s 號重複的月份"
                    }
                },
                error: {
                    1001: {
                        subject: "請指定至少一天",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "請指定選項",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "請指定需要重複的日期",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "請指定需要重複的月份",
                        text: "",
                        description: ""
                    },
                    1005: {
                        subject: "請指定時長",
                        text: "",
                        description: ""
                    }
                }
            }
        },
        qrcode: {
            v2: {
                status: {
                    authorize: {
                        subject: "授權成功",
                        text: "正在準備完成"
                    },
                    inspection: {
                        subject: "驗證成功",
                        text: "請在App中進行確認"
                    },
                    expire: {
                        subject: "二維碼超時",
                        text: "重新產生二維碼"
                    }
                },
                to: {
                    qrcode: {
                        subject: "請使用App掃描確認或切換至",
                        text: "輸入登入密碼"
                    },
                    passwd: {
                        subject: "請輸入登入密碼或切換至",
                        text: "App掃描確認"
                    }
                }
            },
            login: {
                subject: "請使用%sApp掃碼登入",
                text: "前往下載%sApp"
            },
            mapping: {
                subject: "請使用%sApp掃碼進行綁定",
                text: "前往下載%sApp"
            },
            status: {
                authorize: {
                    subject: "授權成功",
                    text: "正在準備完成"
                },
                inspection: {
                    subject: "驗證成功",
                    text: "請在App中進行確認"
                },
                expire: {
                    subject: "二維碼超時",
                    text: "重新產生二維碼"
                }
            }
        },
        billboard: {
            header: {
                total: "全部 %s 筆公告",
                unread: "未讀取 %s 筆公告"
            },
            body: {
                field: {
                    placeholder: "請輸入標題關鍵字查詢"
                },
                entries: {
                    row: "%s・%s・共計 <b class=\"%s\">%s</b> 次閱讀"
                }
            },
            footer: {
                total: {
                    all: "全部 <b class=\"ft-16 %s\">%s</b> 筆公告",
                    filter: "篩選出 <b class=\"ft-16 %s\">%s</b> 筆公告"
                }
            },
            recipient: {
                th: {
                    0: {
                        subject: "#"
                    },
                    1: {
                        subject: "管理員"
                    },
                    2: {
                        subject: "閱讀時間"
                    }
                },
                empty: "暫時還沒有管理員閱讀",
                not: {
                    found: "無法搜尋到<br />您指定條件的管理員"
                }
            }
        },
        church: {
            invitation: {
                subject: "設定教會邀請碼",
                incorrect: "請輸入符合規範的邀請碼",
                description: "<span class=\"ft-12 text-secondary\">邀請碼規範 <b class=\"text-danger ft-14\">6</b> 個字元, 可用數字或英文, 不可使用符號</span>"
            }
        },
        chart: {
            period: {
                subject: "圖表週期",
                value: [
                    "天",
                    "週",
                    "月",
                    "季",
                    "半年",
                    "年",
                    "三年"
                ],
                short: [
                    "1D",
                    "1W",
                    "1M",
                    "1Q",
                    "2Q",
                    "1Y",
                    "3Y"
                ]
            }
        },
        swal: {
            to: {
                qrcode: {
                    subject: "請使用App掃描確認或切換至",
                    text: "輸入登入密碼"
                },
                passwd: {
                    subject: "請輸入登入密碼或切換至",
                    text: "App掃描確認"
                }
            },
            status: {
                success: {
                    subject: "掃碼成功",
                    text: "請在App中進行確認"
                },
                expire: {
                    subject: "二維碼超時",
                    text: "重新產生二維碼"
                }
            },
            field: {}
        },
        shepherd: {
            v2: {
                attend: {
                    group: {
                        selected: {
                            subject: "已選擇族群",
                            empty: {
                                subject: "請選擇族群"
                            },
                            remove: {
                                subject: "雙擊標籤快速移除",
                                all: {
                                    subject: "清空"
                                }
                            }
                        },
                        handler: {
                            clear: {
                                subject: "即將清空已選擇族群",
                                text: "請確認後執行"
                            }
                        }
                    },
                    choose: {
                        enable: {
                            subject: "啟用點名選項",
                            multiple: {
                                subject: "點名選項可複選"
                            }
                        },
                        field: {
                            name: {
                                holder: "請輸入點名選項名稱"
                            }
                        },
                        join: {
                            subject: "新增點名選項"
                        },
                        submit: {
                            enable: {
                                subject: "啟用",
                                reverse: {
                                    subject: "停用"
                                }
                            }
                        },
                        help: {
                            picked: {
                                subject: "已設定%s個點名選項",
                                empty: {
                                    subject: "請設定點名選項"
                                }
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入點名選項名稱",
                                text: ""
                            }
                        }
                    }
                }
            },
            client: {
                partake: {
                    help: {
                        lifted: {
                            empty: "請先選擇需要加入的族群",
                            exist: "已經加入該族群"
                        },
                        archive: {
                            on: "已經設定不跟進該會友",
                            off: "重新跟進"
                        }
                    },
                    empty: "該會友尚未加入任何族群",
                    lifted: {
                        remove: "移除族群",
                        archive: "不跟進",
                        on: "解除族群",
                        off: "復原解除"
                    },
                    field: {
                        increase: "加入新族群",
                        team: "族群"
                    },
                    holder: {
                        increase: "請選擇需要加入的新族群",
                        team: "請選擇需要加入的族群"
                    },
                    handler: {
                        archive: {
                            subject: "即將不跟進該名會友",
                            text: "所有群族將<span class=\"text-danger\">全數解除及取消標註</span><br />若要再次進入群族被關心<br />請前往[不跟進會友]重新加入族群, 請確認後執行<br/><span class=\"ft-14 text-warning\">請注意, 儲存後才會生效</span>"
                        }
                    }
                }
            },
            team: {
                editor: {
                    archive: {
                        subject: "不跟進",
                        text: "不跟進會友無法選擇"
                    },
                    top: "最上層",
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改"
                    },
                    move: {
                        left: "雙擊加入",
                        right: "雙擊移除"
                    },
                    subject: {
                        head: "族群內容",
                        add: "族群新增",
                        edit: "族群編輯"
                    },
                    disable: {
                        selected: "請選擇移除的項目"
                    },
                    empty: {
                        recipient: "請至少選擇<br />1位會友作為託管人員",
                        manager: "請先選擇族群成員<br />再進行託管人員選擇"
                    },
                    not: {
                        available: "族群中沒有會友<br />請先加入會友到族群中",
                        found: "無法搜尋到<br />您指定條件的會友"
                    },
                    th: [
                        null,
                        "會友"
                    ],
                    field: {
                        manager: "託管人員",
                        name: "族群名稱",
                        belong: "所屬族群",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        manager: "請至少選擇 <b class=\"ft-14 cgBase\">1</b> 位託管人員",
                        maximum: ", 最多能選擇 <b class=\"ft-14 cgBase\">%s</b> 位託管人員",
                    },
                    handler: {
                        close: {
                            add: {
                                subject: "即將關閉族群新增",
                                text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                            },
                            edit: {
                                subject: "即將關閉族群編輯",
                                text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                            }
                        },
                        store: {
                            subject: "即將新增族群",
                            text: "請確認後執行",
                            description: ""
                        },
                        update: {
                            subject: "即將更新族群內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入族群名稱",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇族群成員",
                            text: "至少需要 <b class=\"ft-20 text-danger\">1</b> 位族群成員",
                            description: ""
                        },
                        1003: {
                            subject: "託管人員不足最低限制",
                            text: "至少需要 <b class=\"ft-20 text-danger\">1</b> 位託管人員",
                            description: ""
                        },
                        1004: {
                            subject: "託管人員超過最高限制",
                            text: "最多只能選擇 <b class=\"ft-20 text-danger\">%s</b> 位託管人員",
                            description: ""
                        }
                    }
                }
            },
            attend: {
                history: {
                    recipient: {
                        th: {
                            1: "到",
                            2: "頭像",
                            3: "資訊",
                            4: "報到",
                            5: "查看"
                        },
                        vacation: {
                            subject: "本週無聚會"
                        },
                        rank: {
                            subject: "自評",
                            not: {
                                yet: {
                                    subject: "未自評"
                                }
                            }
                        },
                        empty: "名單中沒有成員",
                        note: {
                            subject: "留言",
                            not: {
                                yet: {
                                    subject: "未留言"
                                }
                            }
                        },
                        register: {
                            not: {
                                yet: {
                                    subject: "未報到"
                                }
                            }
                        },
                        edit: {
                            view: "查看此會友資訊"
                        }
                    }
                }
            }
        },
        connect: {
            advance: {
                delegate: {
                    done: {
                        subject: "批次委派成功",
                        text: "本次委派批次完成<br />共計 <b class=\"ft-16 text-success\">%s</b> 位會友"
                    },
                    empty: "請至少選擇<br />1位會友進行委派",
                    mark: "已標註",
                    status: [
                        "不跟進",
                        "未跟進",
                        "已跟進"
                    ]
                },
                import: {
                    done: {
                        subject: "會友匯入成功",
                        text: "本次會友匯入完成<br />共計 <b class=\"ft-16 text-success\">%s</b> 位會友"
                    },
                    th: [
                        "姓名",
                        "電子郵件",
                        "稱謂",
                        "狀態"
                    ],
                    status: "新朋友",
                    subject: "請將匯入文件拖至此處",
                    text: "或點擊上傳",
                    description: "只能上傳Excel文件(檔案格式為xlsx)文件，且不超過<b class=\"text-danger opacity-75\">10MB</b>容量"
                },
                export: {
                    subject: "即將匯出全部會友",
                    text: "請確認後執行"
                },
                destroy: {
                    subject: "即將刪除全部會友",
                    text: "操作無法返回, 請確認後執行"
                }
            }
        },
        client: {
            tooltip: [
                "連結失敗",
                "等待連結",
                "連結成功"
            ]
        },
        editor: {
            help: [
                "溫馨提示",
                "若您從 WORD檔案/其他軟體/其他網頁轉載 進行複製貼上, 容易造成App顯示介面的跑版或不顯示等狀況",
                "建議您先將內容複製到「記事本」, 再從「記事本」中貼至內文中, 請您注意使用"
            ],
            disable: "請輸入連結文字與網址",
            subject: {
                insert: "插入連結",
                edit: "編輯連結"
            },
            release: "釋放連結",
            hyperlink: "前往連結",
            link: "插入連結",
            clear: "清空內文",
            summary: {
                empty: "<span class=\"a1\">未輸入文字</span>",
                total: "合計約 <b class=\"cgBase\">%s</b> 個文字"
            },
            field: {
                hyperlink: {
                    url: "連結網址",
                    text: "連結文字"
                }
            },
            holder: {
                hyperlink: {
                    url: "請輸入連結網址",
                    text: "請輸入連結文字"
                }
            }
        },
        avatar: {
            display: "顯示完整圖片"
        },
        breadcrumb: {
            category: {
                index: "分類管理",
                edit: "分類內容",
                create: "分類新增"
            },
            index: {
                index: "文章管理",
                edit: "文章內容",
                create: "文章新增"
            }
        },
        article: {
            integrate: {
                enable: {
                    subject: "已啟用"
                }
            },
            preview: {
                publish: "發布於 %s．%s 人次瀏覽",
                empty: "請輸入文章內容",
                footer: {
                    favorite: "收藏",
                    size: "字級",
                    comment: "前往留言"
                }
            },
            mindcatcher: {
                authorize: "分享文章由 <b class=\"text-black\">%s</b> 授權使用"
            },
            type: {
                name: {
                    1: "圖文",
                    2: "影音",
                    3: "MP3",
                    4: "直播",
                    5: "WEB"
                },
                disabled: "這個選項暫時無法使用",
                tip: {
                    live: {
                        disabled: "次分類為活動, 將無法使用直播類型"
                    }
                }
            },
            schedule: [
                "排程中",
                "上架中",
                "已下架"
            ]
        },
        tag: {
            subject: "編輯標籤",
            help: "送出新增標籤",
            exit: "離開編輯",
            empty: "無可用標籤",
            menu: {
                on: "開啟選單",
                off: "關閉選單"
            },
            summary: {
                total: "篩選出 <span class=\"cgBase\">%s</span> 個會友",
                checked: ", 已選取 <span class=\"cgBase\">%s</span> 個會友"
            },
            status: [
                "已停用",
                "啟用中"
            ],
            off: {
                all: "請選擇先篩選會友",
                subject: "請先選擇標籤",
                join: "加入(請選擇標籤與指定會友)",
                remove: "移除(請選擇標籤與指定會友)"
            },
            field: {
                tag: "",
                keyword: "關鍵字"
            },
            th: {
                avatar: "頭像",
                name: "名字",
                email: "電子郵件",
                status: "狀態"
            },
            holder: {
                tag: "請輸入標籤名稱(可用逗號分隔)",
                keyword: "搜尋範圍: 名字/郵件/會友編號/標籤"
            },
            handler: {
                join: {
                    subject: "即將批次加入標籤",
                    text: "請確認後執行"
                },
                remove: {
                    subject: "即將批次移除標籤",
                    text: "請確認後執行"
                },
                dbclick: {
                    subject: "即將移除指定標籤",
                    text: "請確認後執行"
                },
                confirm: {
                    subject: "即將移除標籤",
                    text: "請確認後執行"
                }
            }
        },
        upload: {
            submit: "上傳",
            summary: "已選擇 <b class=\"text-success ft-15\">%s</b> 張圖片, 最多可以上傳 <b class=\"text-success ft-15\">%s</b> 張圖片",
            run: {
                out: "已經選完 <b class=\"text-danger ft-15\">%s</b> 張圖片"
            },
            gallery: {
                subject: "圖庫",
                disabled: "請先選擇圖"
            },
            history: {
                subject: "歷史圖"
            },
            upload: {
                subject: "上傳圖",
                disabled: "圖片處理中, 請稍候"
            },
            destroy: "清除全部",
            clear: "清除",
            handler: {
                cancel: {
                    subject: "即將取消選擇圖",
                    text: "已經編輯的內容將會重設, 請確認後執行"
                },
                clear: {
                    subject: "即將清除已經設定的圖",
                    text: "已經編輯的內容將會重設, 請確認後執行"
                }
            }
        },
        cropper: {
            help: {
                close: "關閉",
                reset: "重設"
            },
            tool: {
                illegal: "舉報圖片",
                refresh: "重設編輯",
                prev: {
                    has: "上一張",
                    no: "沒有上一張了"
                },
                next: {
                    has: "下一張",
                    no: "沒有下一張了"
                },
                cancel: "取消編輯"
            },
            feature: {
                text: "編輯文字",
                size: "文字大小",
                color: "文字顏色",
                position: "文字位置",
                vertical: "上下微調",
                horizontal: "左右微調"
            },
            position: [
                "左上",
                "右上",
                "置中",
                "左下",
                "右下"
            ],
            field: {
                text: {
                    subject: "填充文字"
                },
                size: {
                    subject: "文字大小"
                },
                color: {
                    subject: "文字顏色"
                },
                position: {
                    subject: "文字位置"
                },
                vertical: {
                    subject: "垂直微調"
                },
                horizontal: {
                    subject: "水平微調"
                }
            },
            holder: {
                text: {
                    subject: "請輸入填充文字"
                },
                size: {
                    subject: "請選擇文字大小"
                },
                color: {
                    subject: "請選擇文字顏色"
                },
                position: {
                    subject: "請選擇文字位置"
                },
                vertical: {
                    subject: "請選擇垂直微調"
                },
                horizontal: {
                    subject: "請選擇水平微調"
                }
            }
        },
        lightbox: {
            v2: {
                zoom: {
                    in: {
                        subject: "放大"
                    },
                    out: {
                        subject: "縮小"
                    }
                },
                resize: {
                    subject: "還原縮放"
                },
                download: {
                    subject: "下載"
                },
                rotate: {
                    left: {
                        subject: "逆時針旋轉"
                    },
                    right: {
                        subject: "順時針旋轉"
                    }
                }
            },
            zoom: {
                in: "放大",
                out: "縮小"
            },
            resize: "還原縮放",
            download: "下載",
            rotate: {
                left: "逆時針旋轉",
                right: "順時針旋轉"
            }
        },
        permission: {
            name: "名稱",
            empty: "搜尋不到指定的權限",
            head: "僅總教會",
            portal: [
                "我的教會",
                "牧養系統"
            ],
            feature: [
                {
                    subject: "總覽"
                },
                {
                    subject: "新增"
                },
                {
                    subject: "儲存"
                },
                {
                    subject: "編輯"
                },
                {
                    subject: "更新"
                },
                {
                    subject: "刪除"
                },
                {
                    subject: "匯出"
                }
            ]
        },
        theme: {
            light: "淺色主題",
            dark: "深色主題"
        },
        notification: {
            recipient: {
                complete: {
                    subject: "已送達",
                    text: "送達時間 %04d-%02d-%02d %02d:%02d"
                },
                read: {
                    subject: "已讀取",
                    yet: {
                        subject: "未讀取"
                    }
                },
                broadcast: {
                    subject: "群體推播無法編輯名單"
                },
                move: {
                    left: "雙擊加入",
                    right: "雙擊移除"
                },
                subject: "編輯推播名單",
                disable: {
                    available: "請選擇加入的項目",
                    selected: "請選擇移除的項目"
                },
                changed: "尚未異動項目",
                empty: "請至少選擇<br />1位會友進行推播",
                not: {
                    found: "無法搜尋到<br />您指定條件的會友"
                },
                help: {
                    available: "可選擇 <b class=\"cgBase\">%s</b> 個會友",
                    selected: "已選擇 <b class=\"cgBase\">%s</b> 個會友"
                },
                th: [
                    "頭像",
                    "會友",
                    "操作",
                    "進度",
                    "讀取"
                ],
                status: [
                    "未讀",
                    "已讀"
                ],
                field: {
                    keyword: "搜尋名單",
                    tag: "標籤"
                },
                holder: {
                    keyword: "搜尋範圍: 名字/郵件/編號",
                    tag: "請選擇標籤"
                },
                handle: {
                    close: {
                        subject: "即將關閉編輯推播",
                        text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                    }
                },
                tag: {
                    all: "全部",
                    uncategorized: "未分類",
                    administrator: "管理員名單"
                }
            },
            status: [
                null,
                "排程中",
                "進行中",
                "已完成"
            ],
            status_name: {
                0: {
                    subject: "準備中"
                },
                1: {
                    subject: "已送達"
                },
                2: {
                    subject: "無法推播"
                },
                9: {
                    subject: "用戶刪除"
                }
            },
            type: {
                1: "指定",
                2: "全體"
            },
            destroy: [
                "刪除",
                "推播進行中, 無法刪除",
                "推播已經完成, 無法刪除"
            ],
            exclude: {
                label: "排除機構",
                help: {
                    subject: "選中的機構將會排除並不發送推播訊息",
                    all: {
                        subject: "將不排除任何機構"
                    }
                }
            }
        },
        event: {
            team: {
                recipient: {
                    move: {
                        left: "雙擊加入",
                        right: "雙擊移除"
                    },
                    subject: "編輯服事群組名單",
                    disable: {
                        available: "請選擇加入的項目",
                        selected: "請選擇移除的項目"
                    },
                    changed: "尚未異動項目",
                    field: {
                        keyword: "搜尋名單",
                        tag: "標籤"
                    },
                    holder: {
                        keyword: "搜尋範圍: 名字/郵件/編號",
                        tag: "請選擇標籤"
                    },
                    handle: {
                        close: {
                            subject: "即將關閉編輯服事群組",
                            text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                        }
                    },
                    th: [
                        "頭像",
                        "會友",
                        "操作"
                    ],
                    tag: {
                        all: "全部",
                        uncategorized: "未分類",
                        administrator: "管理員名單"
                    },
                    empty: "請至少選擇<br />1位會友參與服事群組",
                    not: {
                        found: "無法搜尋到<br />您指定條件的會友"
                    },
                    help: {
                        available: "可選擇 <b class=\"cgBase\">%s</b> 個會友",
                        selected: "已選擇 <b class=\"cgBase\">%s</b> 個會友"
                    }
                }
            },
            recipient: {
                move: {
                    left: "雙擊加入",
                    right: "雙擊移除"
                },
                subject: "編輯服事名單",
                disable: {
                    available: "請選擇加入的項目",
                    selected: "請選擇移除的項目"
                },
                changed: "尚未異動項目",
                field: {
                    keyword: "搜尋名單",
                    team: "服事群組"
                },
                holder: {
                    keyword: "搜尋範圍: 名字/郵件/編號",
                    team: "請選擇服事群組"
                },
                handle: {
                    close: {
                        subject: "即將關閉編輯服事名單",
                        text: "<span class=\"text-danger\">異動項目將會清除, 請確認後執行</span>"
                    }
                },
                th: [
                    "頭像",
                    "會友",
                    "操作"
                ],
                tag: {
                    all: "全部",
                    uncategorized: "未分類",
                    administrator: "管理員名單"
                },
                remove: {
                    group: "移除整個群組"
                },
                empty: "請至少選擇<br />1位會友參與服事",
                not: {
                    found: "無法搜尋到<br />您指定條件的會友"
                },
                help: {
                    available: "可選擇 <b class=\"cgBase\">%s</b> 個會友",
                    selected: "已選擇 <b class=\"cgBase\">%s</b> 個會友"
                }
            }
        },
        message: {
            clone: {
                subject: "資料複製成功",
                text: "右鍵貼上即可以直接使用",
                description: ""
            },
            download: {
                subject: "檔案下載成功",
                text: "",
                description: ""
            }
        },
        input: {
            feature: {
                clone: "複製內容"
            }
        },
        password: {
            feature: {
                show: "顯示密碼",
                hide: "隱藏密碼"
            }
        },
        select: {
            category: {
                create: "請先前往建立分類",
                ajax: {
                    first: "請先選擇指定教會",
                    empty: "該教會尚未建立分類"
                }
            },
            option: {
                church: {
                    all: "全部教會"
                },
                empty: {
                    blank: "未選擇",
                    subject: "沒有可用的選項",
                    city: "請先選擇所在區域",
                    target: "請先選擇類型"
                }
            }
        },
        autocomplete: {
            subject: "編輯個人標記",
            incorrect: "請先選擇需要移除的標記",
            selected: "已經選取 <b class=\"cgBase\">%s</b> 個標記",
            th: [
                null,
                "標記"
            ],
            handler: {
                destroy: {
                    subject: "即將刪除指定個人標記",
                    text: "刪除後無法復原, 請確認後執行",
                    description: ""
                }
            }
        },
        form: {
            v2: {
                edit: {
                    tag: {
                        quantity: {
                            subject: "共計 %s 名"
                        }
                    },
                    user: {
                        0: {
                            subject: "建立人員",
                            holder: "由系統自動產生",
                            stamp: {
                                subject: "建立時間",
                                holder: ""
                            }
                        },
                        1: {
                            subject: "上次編輯人員",
                            holder: "內容未經過編輯",
                            stamp: {
                                subject: "上次編輯時間",
                                holder: "內容未經過編輯"
                            }
                        }
                    },
                    cycle: {
                        subject: "循環資訊",
                        item: {
                            0: {
                                subject: "循環代號"
                            },
                            1: {
                                subject: "提醒代號"
                            },
                            2: {
                                subject: "最後循環開始時間"
                            }
                        }
                    },
                    error: {
                        code: {
                            subject: "請先清除表單錯誤"
                        }
                    },
                    alert: {
                        store: {
                            subject: "您沒有建立的權限"
                        },
                        update: {
                            subject: "您沒有更新的權限",
                            head: {
                                subject: "總教會僅供查看資料, 無法更新內容"
                            }
                        },
                        cycle: {
                            monitor: {
                                modified: {
                                    subject: "聚會時間已經變動, 請您留意"
                                }
                            },
                            schedule: {
                                last: {
                                    complete: {
                                        subject: "設定的所有循環均已完成, 將不會再繼續生成相關單據與提醒"
                                    }
                                }
                            }
                        }
                    },
                    handler: {
                        reset: {
                            subject: "即將重設內容",
                            text: "異動內容將不會保存, 請確認後執行"
                        },
                        entry: {
                            subject: "即將返回列表",
                            text: "異動內容將不會保存, 請確認後執行"
                        },
                        cycle: {
                            monitor: {
                                subject: "聚會時間已經變動",
                                text: "建議您重新檢視循環與提醒設定"
                            }
                        }
                    }
                },
                table: {
                    search: {
                        field: {
                            belong: {
                                subject: "所屬教會",
                                holder: "請選擇所屬教會",
                                help: "僅總教會"
                            },
                            keyword: {
                                subject: "關鍵字",
                                holder: "請輸入關鍵字",
                                help: "同時搜尋名字/郵件/會友編號"
                            },
                            on: {
                                subject: "開始時間(起)",
                                holder: "請選擇開始時間(起)",
                                end: {
                                    subject: "開始時間(迄)",
                                    holder: "請選擇開始時間(迄)"
                                }
                            },
                            off: {
                                subject: "結束時間(起)",
                                holder: "請選擇結束時間(起)",
                                end: {
                                    subject: "結束時間(迄)",
                                    holder: "請選擇結束時間(迄)"
                                }
                            },
                            submit: {
                                subject: "建立時間(起)",
                                holder: "請選擇建立時間(起)",
                                end: {
                                    subject: "建立時間(迄)",
                                    holder: "請選擇建立時間(迄)"
                                }
                            }
                        },
                        help: {
                            begin: {
                                subject: "(≥)指定時間"
                            },
                            end: {
                                subject: "(≤)指定時間"
                            }
                        }
                    },
                    action: {
                        add: {
                            subject: "新增",
                            not: {
                                allowed: {
                                    subject: "無新增權限"
                                }
                            }
                        },
                        move: {
                            top: {
                                subject: "移動至頂部"
                            },
                            left: {
                                subject: "移動至左方"
                            },
                            right: {
                                subject: "移動至右方"
                            }
                        },
                        search: {
                            subject: "搜尋"
                        },
                        statistics: {
                            subject: "統計"
                        },
                        checkbox: {
                            subject: "多選"
                        },
                        reset: {
                            subject: "重設"
                        },
                        refresh: {
                            subject: "刷新"
                        },
                        loading: {
                            subject: "讀取中"
                        },
                        download: {
                            subject: "匯出",
                            not: {
                                allowed: {
                                    subject: "無匯出權限"
                                }
                            }
                        },
                        pagination: {
                            subject: "每頁 <b>%s</b> 筆資料"
                        }
                    },
                    footer: {
                        subject: "目前於 第 <b class=\"text-justforyou-base ft-16\">%s</b> 頁 共 <b class=\"text-justforyou-base ft-16\">%s</b> 頁 全部 <b class=\"text-justforyou-base ft-16\">%s</b> 筆資料"
                    },
                    readonly: {
                        subject: "列表僅供讀取",
                        head: {
                            subject: "總教會僅供查看資料, 無法新增修改"
                        }
                    },
                    empty: {
                        subject: "找不到指定的資料"
                    },
                    field: {
                        belong: {
                            subject: "所屬教會"
                        },
                        head: {
                            subject: "主動",
                            text: "由總教會發起的主動推播"
                        },
                        cycle: {
                            subject: "循環",
                            cycle: {
                                1: {
                                    subject: "單次"
                                },
                                2: {
                                    subject: "每日"
                                },
                                3: {
                                    subject: "每週"
                                },
                                4: {
                                    subject: "每月"
                                },
                                5: {
                                    subject: "每年"
                                }
                            }
                        },
                        remind: {
                            subject: "提醒",
                            status: {
                                subject: "已設定%s筆提醒",
                                disable: {
                                    subject: "未設定提醒"
                                }
                            }
                        },
                        edit: {
                            subject: "操作",
                            view: {
                                subject: "查看"
                            }
                        }
                    },
                    off: {
                        canvas: {
                            statistics: {
                                subject: "統計"
                            },
                            search: {
                                subject: "搜尋"
                            }
                        }
                    },
                    permission: {
                        view: {
                            not: {
                                allowed: {
                                    subject: "無查看權限"
                                }
                            }
                        },
                        edit: {
                            not: {
                                allowed: {
                                    subject: "無編輯權限"
                                }
                            }
                        }
                    },
                    edit: {
                        subject: "編輯",
                        more: {
                            subject: "更多",
                            copy: {
                                subject: "複製"
                            },
                            enable: {
                                subject: "啟用"
                            },
                            disable: {
                                subject: "停用"
                            },
                            destroy: {
                                subject: "刪除"
                            }
                        },
                        view: {
                            subject: "檢視"
                        }
                    }
                },
                history: {
                    action: {
                        add: {
                            subject: "新增",
                            not: {
                                allowed: {
                                    subject: "無新增權限"
                                }
                            }
                        },
                        search: {
                            subject: "搜尋"
                        }
                    },
                    footer: {
                        subject: "已載入 <b class=\"text-justforyou-base ft-16\">%s</b> 筆數據 共 <b class=\"text-justforyou-base ft-16\">%s</b> 筆數據",
                        complete: {
                            subject: "載入完成"
                        }
                    },
                    readonly: {
                        subject: "列表僅供讀取"
                    },
                    empty: {
                        subject: "找不到指定的資料"
                    },
                    off: {
                        canvas: {
                            statistics: {
                                subject: "統計"
                            },
                            search: {
                                subject: "搜尋"
                            }
                        }
                    }
                },
                membership: {
                    editor: {
                        subject: "收件人",
                        option: {
                            all: {
                                subject: "全部"
                            }
                        },
                        dblclick: {
                            subject: "雙擊加入",
                            remove: {
                                subject: "雙擊移除"
                            }
                        },
                        summary: {
                            total: {
                                subject: "可選 <b class=\"ft-15 text-base\">%s</b> 名會友",
                                right: {
                                    subject: "已選 <b class=\"ft-15 text-base\">%s</b> 名會友"
                                },
                                filtered: {
                                    subject: "可選 <b class=\"ft-15 text-base\">%s</b> 名會友, 篩選出 <b class=\"ft-15 text-base\">%s</b> 名會友",
                                    right: {
                                        subject: "已選 <b class=\"ft-15 text-base\">%s</b> 名會友, 篩選出 <b class=\"ft-15 text-base\">%s</b> 名會友"
                                    }
                                }
                            }
                        },
                        th: [
                            "",
                            "頭像",
                            "會友"
                        ],
                        handler: {
                            modified: {
                                subject: "即將退出選擇編輯",
                                text: "異動內容將不會保存, 請確認後執行"
                            }
                        }
                    },
                    option: {
                        all: {
                            subject: "全部"
                        }
                    },
                    th: [
                        "頭像",
                        "會友",
                        "操作"
                    ]
                },
                cycle: {
                    schedule: {
                        subject: "排程預覽",
                        th: {
                            0: {
                                subject: "#"
                            },
                            1: {
                                subject: "開始時間"
                            },
                            2: {
                                subject: "結束時間"
                            }
                        },
                        footer: {
                            summary: {
                                subject: "合計 <b class=\"ft-16 text-base\">%s</b> 次循環排程"
                            }
                        }
                    },
                    field: {
                        cycle: {
                            subject: "方式"
                        },
                        duration: {
                            subject: "時長"
                        }
                    },
                    at: {
                        subject: "每",
                        text: {
                            1: "日",
                            2: "週",
                            3: "個月",
                            4: "年"
                        },
                        forever: {
                            subject: "永遠"
                        },
                        until: {
                            subject: "直到"
                        },
                        repeat: {
                            subject: "重複",
                            text: "次"
                        }
                    },
                    week: {
                        begin: {
                            subject: "首次起始日<br/>為星期%s不能取消"
                        },
                        day: [
                            {
                                subject: "日",
                                text: "星期天"
                            },
                            {
                                subject: "一",
                                text: "星期一"
                            },
                            {
                                subject: "二",
                                text: "星期二"
                            },
                            {
                                subject: "三",
                                text: "星期三"
                            },
                            {
                                subject: "四",
                                text: "星期四"
                            },
                            {
                                subject: "五",
                                text: "星期五"
                            },
                            {
                                subject: "六",
                                text: "星期六"
                            }
                        ]
                    },
                    method: {
                        1: {
                            subject: "在第%s天重複",
                            year: {
                                subject: "在 %s 的第%s天重複"
                            }
                        },
                        2: {
                            subject: "在第%s週的%s重複",
                            year: {
                                subject: "在 %s 的第%s週的%s重複"
                            }
                        },
                        3: {
                            subject: "在最後一個%s重複",
                            year: {
                                subject: "在 %s 的最後一個%s重複"
                            }
                        },
                        4: {
                            subject: "在最後一天重複",
                            year: {
                                subject: "在 %s 的最後一天重複"
                            }
                        },
                        5: {
                            subject: "選取希望重複的日期",
                            year: {
                                subject: "選取希望在%s號重複的月份"
                            }
                        }
                    },
                    month: [
                        {
                            subject: "1月"
                        },
                        {
                            subject: "2月"
                        },
                        {
                            subject: "3月"
                        },
                        {
                            subject: "4月"
                        },
                        {
                            subject: "5月"
                        },
                        {
                            subject: "6月"
                        },
                        {
                            subject: "7月"
                        },
                        {
                            subject: "8月"
                        },
                        {
                            subject: "9月"
                        },
                        {
                            subject: "10月"
                        },
                        {
                            subject: "11月"
                        },
                        {
                            subject: "12月"
                        }
                    ],
                    help: {
                        duration: {
                            subject: "目前最長可定義 %s 年週期, 請妥善利用資源",
                            until: {
                                subject: "請選擇重複結束時間"
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇條件"
                        },
                        1002: {
                            subject: "請選取希望重複的日期"
                        },
                        1003: {
                            subject: "請選取希望重複的月份"
                        },
                        1004: {
                            subject: "請選擇重複結束時間"
                        }
                    }
                },
                remind: {
                    field: {
                        common: {
                            subject: "常用選項"
                        },
                        custom: {
                            subject: "自定義選項",
                            join: {
                                subject: "新增自定義選項"
                            }
                        },
                        notify: {
                            subject: "通知方式",
                            email: {
                                holder: "請輸入電子郵件",
                                join: {
                                    subject: "新增電子郵件"
                                }
                            }
                        }
                    },
                    at: [
                        {
                            subject: "活動時間點"
                        },
                        {
                            subject: "%s 分鐘前"
                        },
                        {
                            subject: "%s 小時前"
                        },
                        {
                            subject: "%s 天前"
                        },
                        {
                            subject: "%s 週前"
                        }
                    ],
                    beforehand: {
                        subject: "系統內建提醒無法取消"
                    },
                    help: {
                        picked: {
                            subject: "已選%s個選項",
                            email: {
                                subject: "已設定%s個電子郵件"
                            }
                        },
                        selected: {
                            subject: "最多只能選擇 <b class=\"ft-16\">%s</b> 個選項, 還可以選擇 <b class=\"ft-16\">%s</b> 個選項",
                            full: {
                                subject: "已達到選項上限 <b class=\"ft-16\">%s</b> 個"
                            }
                        },
                        custom: {
                            subject: "自定義選項最多僅能設定 %s 筆"
                        },
                        notify: {
                            email: {
                                subject: "郵件名單最多僅能設定 %s 筆",
                                empty: {
                                    subject: "請輸入電子郵件"
                                }
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入正確的電子郵件"
                        }
                    }
                }
            },
            help: {
                must: "標記為 <b class=\"cgRed\">*</b> 為必填欄位"
            },
            handler: {
                reset: {
                    subject: "即將清除表單",
                    text: "<span class=\"text-danger\">已經填寫的資料將會清除, 請確認後執行</span>",
                    description: ""
                }
            },
            select: {
                head: {
                    church: {
                        label: "指定教會",
                        holder: "請選擇指定教會",
                        help: "請謹慎使用指定教會功能",
                        broadcast: {
                            subject: "全部教會"
                        }
                    }
                }
            }
        },
        offCanvas: {
            subject: {
                search: "搜尋條件"
            },
            submit: {
                close: "關閉"
            }
        },
        table: {
            tip: {
                readonly: "總教會僅供查看資料, 無法新增修改"
            },
            mode: {
                on: "自行維護",
                off: "委託管理"
            },
            feature: {
                seq: {
                    view: "前往查看",
                    edit: "前往編輯"
                },
                friend: {
                    convert: "轉入牧養會友",
                    add: "標記為待轉入",
                    clear: "標記為不轉入"
                },
                unauthorized: "未授權編輯",
                raise: "轉交愛無界團隊",
                copy: "複製",
                view: "查看",
                donate: "奉獻紀錄",
                edit: "編輯",
                more: "更多",
                enable: "啟用",
                disable: "停用",
                paid: "標示已付款",
                integrate: {
                    not: {
                        allow: "整合文章無法複製"
                    }
                },
                comment: "留言內容",
                notification: {
                    entry: "推播紀錄",
                    create: "新增推播"
                },
                destroy: {
                    event: "該群組下仍有 %s 個會友, 無法刪除",
                    soul: "該分類下仍有 %s 篇文章, 無法刪除",
                    activity: "該分類下仍有 %s 場活動, 無法刪除",
                    live: "該分類下仍有 %s 場直播, 無法刪除",
                    shepherd: {
                        attend: "該分類下仍有 %s 個聚會, 無法刪除"
                    },
                    enable: "刪除",
                    disable: "刪除前請先停用"
                },
                cycle: {
                    history: "循環紀錄"
                },
                attend: {
                    history: "聚會紀錄",
                    schedule: "聚會排程"
                },
                simulation: "模擬登入",
                repair: "重建模擬"
            },
            field: {
                belong: "所屬教會",
                check: "選取",
                created: "建立時間",
                creator: "建立人員",
                updated: "最後編輯時間",
                editor: "最後編輯人員",
                accessed: "最後存取時間",
                prompt: "提醒",
                period: "循環",
                status: "狀態",
                view: "查看",
                edit: "操作"
            },
            holder: {
                status: "請選擇狀態"
            }
        },
        pagination: {
            length: "每頁 %s 項資料",
            summary: "目前顯示 第 <b class=\"cgBase\">%s</b> 頁 共 <b class=\"cgBase\">%s</b> 頁 全部共 <b class=\"cgBase\">%s</b> 項資料",
            first: "最前頁",
            prev: "上一頁",
            next: "下一頁",
            last: "最後頁",
            search: "搜尋",
            download: "匯出",
            increase: "新增",
            xhring: "資料讀取中",
            reset: "重設",
            refresh: "刷新",
            empty: {
                subject: "抱歉...😔",
                text: "沒有找到指定的資料",
                summary: "全部共 <b class=\"cgBase\">0</b> 項資料"
            },
            loading: {
                subject: "正在讀取...請稍候"
            }
        },
        sortable: {
            empty: "目前暫時<br />沒有可用排序的項目",
            th: [
                "封面圖",
                "名稱",
                "移動"
            ],
            move: "拖曳進行排序"
        },
        footer: {
            description: "系統由 <a target=\"_new\" href=\"%s\">中華愛無界全人關懷協會</a> <br/> 提供和維護 / <a target=\"_new\" href=\"%s\">使用教學</a>"
        },
        choice: {
            editor: {
                enable: {
                    subject: "啟用點名選項"
                },
                multiple: {
                    subject: "選項可複選"
                },
                create: {
                    subject: "新增點名選項"
                },
                holder: {
                    subject: "請輸入選項名稱"
                },
                error: {
                    1001: {
                        subject: "請至少建立1筆選項"
                    },
                    1002: {
                        subject: "請輸入選項名稱"
                    }
                }
            }
        },
        scroller: {
            top: "回到頂端",
            left: "往左翻動",
            right: "往右翻動",
            end: "翻至編輯"
        },
        activity: {
            echelon: {
                editor: {
                    subject: "第 <b class=\"ft-20 text-danger\">%s</b> 場",
                    canvas: {
                        echelon: {
                            create: {
                                subject: "新增場次"
                            }
                        }
                    },
                    help: {
                        add: {
                            custom: "新增場次"
                        },
                        message: "請依照升序方式編輯或刪除, 避免日期設定錯誤"
                    },
                    field: {
                        subject: "場次名稱",
                        begin: "開始時間",
                        end: "結束時間",
                        description: "描述",
                        every: {
                            subject: "第 <b class=\"m-l-5 m-r-5 ft-28\">%s</b> 場",
                            text: "%s 至 %s"
                        }
                    },
                    holder: {
                        subject: "請輸入課程名稱",
                        begin: "請選擇開始時間",
                        end: "請選擇結束時間",
                        description: "請輸入描述"
                    },
                    handler: {
                        remove: {
                            subject: "即將刪除場次",
                            text: "請確認後執行"
                        }
                    },
                    submit: {
                        up: "上移",
                        down: "下移",
                        edit: "編輯",
                        remove: "刪除"
                    },
                    error: {
                        1001: {
                            subject: "請輸入場次名稱",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            register: {
                editor: {
                    form: {
                        1: {
                            holder: "請輸入電子郵件"
                        },
                        2: {
                            holder: "請輸入%s"
                        },
                        3: {
                            holder: "請輸入%s"
                        },
                        4: {
                            active: "是",
                            inactive: "否"
                        },
                        5: {
                            holder: "請輸入%s的描述"
                        },
                        6: {
                            holder: "請選擇%s的描述"
                        },
                        7: {
                            holder: "請選擇%s"
                        },
                        8: {
                            holder: "請輸入姓名"
                        },
                        rank: "第 <b class=\"ft-16 text-success\">%s</b> 位報名人員",
                        validator: {
                            1: {
                                subject: "必填"
                            },
                            2: {
                                subject: "電子郵件格式錯誤"
                            },
                            3: {
                                subject: "請至少選擇一個選項"
                            }
                        }
                    },
                    canvas: {
                        discount: {
                            not: {
                                allow: "請依照順序編輯"
                            },
                            create: {
                                subject: "新增報名優惠"
                            },
                            edit: {
                                subject: "編輯報名優惠"
                            }
                        }
                    },
                    tip: {
                        edit: "請依照升序方式編輯或刪除, 避免日期與優惠價格設定錯誤"
                    },
                    help: {
                        add: {
                            custom: "新增團報"
                        },
                        discount: {
                            custom: "新增報名優惠",
                            maximum: "最多僅能建立 <b class=\"cgBase\">%s</b> 筆報名優惠",
                            not: {
                                next: "優惠價格已經達到最高值, 無法再繼續設定優惠價格"
                            }
                        },
                        external: "若關閉外部報名, 僅能用App報名並且無法使用團體報名"
                    },
                    enable: {
                        external: {
                            on: "開啟",
                            off: "關閉"
                        },
                        charge: {
                            on: "需收費",
                            off: "免費"
                        },
                        restrict: {
                            on: "需限制",
                            off: "不限制"
                        },
                        personal: {
                            on: "開啟",
                            off: "關閉"
                        },
                        group: {
                            on: "開啟",
                            off: "關閉"
                        },
                        qrcode: {
                            on: "開啟",
                            off: "關閉"
                        },
                        discount: {
                            on: "開啟",
                            off: "關閉"
                        },
                        payment: {
                            on: "開啟",
                            off: "關閉"
                        }
                    },
                    field: {
                        external: {
                            subject: "外部報名"
                        },
                        restrict: {
                            subject: "人數上限",
                            field: "人數"
                        },
                        charge: {
                            subject: "費用",
                            field: "金額"
                        },
                        qrcode: {
                            subject: "掃碼報到"
                        },
                        method: {
                            subject: "報名方式",
                            personal: {
                                subject: "個人報名"
                            },
                            group: {
                                subject: "團體報名",
                                minimum: "團體最少報名人數",
                                maximum: "團體最多報名人數"
                            }
                        },
                        discount: {
                            th: "優惠內容",
                            subject: "報名優惠",
                            price: "優惠價格",
                            begin: "開始時間",
                            end: "結束時間",
                            description: {
                                subject: "描述內容",
                                text: "可以直接編輯"
                            }
                        },
                        payment: {
                            subject: "線上支付",
                            yet: {
                                subject: "未付款"
                            },
                            paid: {
                                subject: "已付款"
                            }
                        }
                    },
                    text: {
                        discount: {
                            first: "<span class=\"text-danger\">(包含往前日期)</span>"
                        }
                    },
                    method: {
                        note: {
                            subject: "溫馨提示",
                            text: "人數上限未達團體報名最低限制 <b class=\"text-danger ft-16\">%s</b> 人, 無法使用團體報名, 僅能使用個人報名"
                        }
                    },
                    payment: {
                        note: {
                            subject: "備註"
                        },
                        free: {
                            subject: "溫馨提示",
                            text: "已經設定活動免費, 無法啟用線上支付功能"
                        },
                        newebpay: {
                            subject: "溫馨提示",
                            text: "貴教會未開啟線上奉獻功能, 無法啟用線上支付功能"
                        },
                        description: {
                            1: {
                                subject: "當報名費用低於 <b class=\"text-success ft-16\">30</b> 或 超過 <b class=\"text-success ft-16\">20,000</b> 時, 將無法使用 <b class=\"text-success\">超商代碼繳費</b> 方式支付"
                            },
                            2: {
                                subject: "當報名費用低於 <b class=\"text-success ft-16\">20</b> 或 超過 <b class=\"text-success ft-16\">40,000</b> 時, 將無法使用 <b class=\"text-success\">超商條碼繳費</b> 方式支付"
                            },
                            3: {
                                subject: "當報名費用超過 <b class=\"text-success ft-16\">49,999</b> 時, 將無法使用 <b class=\"text-success\">WebATM</b>, <b class=\"text-success\">ATM轉帳</b> 方式支付"
                            },
                            4: {
                                subject: "<b class=\"text-success\">WebATM</b>, <b class=\"text-success\">ATM轉帳</b> 支付方式, 僅支援 <b class=\"text-success\">台灣銀行</b> 與 <b class=\"text-success\">華南銀行</b> 之銀行戶頭"
                            },
                            5: {
                                subject: "以上單位均為 <b class=\"text-success\">新臺幣</b>"
                            }
                        }
                    },
                    holder: {
                        restrict: "請輸入人數",
                        charge: "請輸入金額",
                        method: {
                            group: {
                                minimum: "請輸入團體最少報名人數",
                                maximum: "請輸入團體最多報名人數"
                            }
                        },
                        discount: {
                            price: "請輸入優惠價格",
                            begin: "請選擇開始時間",
                            end: "請選擇結束時間",
                            description: "請輸入描述內容"
                        }
                    },
                    handler: {
                        remove: {
                            subject: "即將刪除優惠價格",
                            text: "請確認後執行"
                        },
                        charge: {
                            off: {
                                subject: "即將清空優惠價格設定",
                                text: "您已經有設定優惠價格, 請確認後執行"
                            }
                        }
                    },
                    echelon: {
                        edit: {
                            subject: "變更場次",
                            sort: {
                                subject: "第 <b class=\"ft-22\">%s</b> 場"
                            },
                            between: {
                                subject: "%s 至 %s"
                            },
                            handler: {
                                update: {
                                    subject: "即將變更場次",
                                    text: "請確認後執行"
                                }
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入金額",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請至少加入一筆報名優惠",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入開始時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入結束時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入優惠價格",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請輸入人數",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請至少選擇一種報名方式",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "優惠價格設定異常",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入最少報名人數",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "低於最少報名人數限制",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入最多報名人數",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "高於報名限制人數",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            condition: {
                editor: {
                    subject: "欄位設定",
                    canvas: {
                        field: {
                            create: {
                                subject: "新增欄位",
                                option: "新增選項"
                            }
                        }
                    },
                    tip: {
                        not: {
                            remove: "系統條件無法移除"
                        },
                        option: {
                            empty: "請至少新增一筆選項",
                            append: "附加輸入框"
                        }
                    },
                    help: {
                        add: {
                            custom: "新增欄位"
                        }
                    },
                    kind: {
                        1: "電子郵件",
                        2: "文字區塊",
                        3: "文字",
                        4: "是否",
                        5: "複選",
                        6: "單選",
                        7: "日期",
                        8: "姓名"
                    },
                    field: {
                        detail: "欄位設定",
                        option: "選項值",
                        show: "顯示",
                        must: "必填",
                        subject: "名稱",
                        description: "描述"
                    },
                    holder: {
                        subject: "請輸入顯示名稱",
                        description: "請輸入描述",
                        option: "請輸入選項值"
                    },
                    handler: {
                        remove: {
                            subject: "即將刪除欄位",
                            text: "請確認後執行"
                        }
                    },
                    submit: {
                        up: "上移",
                        down: "下移",
                        remove: "移除"
                    },
                    error: {
                        1001: {
                            subject: "請至少選擇一個顯示欄位",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入選項值",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請至少新增一筆選項",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            remind: {
                editor: {
                    help: {
                        notification: "推播至活動建立者",
                        add: {
                            email: "新增電子郵件"
                        },
                        maximum: {
                            email: "寄送郵件名單最多僅能設定 <b class=\"cgBase\">%s</b> 筆"
                        }
                    },
                    subject: "遞交報名後提醒的傳達方式",
                    field: {
                        notification: "推播通知",
                        email: "電子郵件"
                    },
                    holder: {
                        email: "請輸入電子郵件"
                    },
                    error: {
                        1001: {
                            subject: "請指定通知方式",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請至少選擇一名收件人",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入郵件地址",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "郵件格式錯誤",
                            text: "",
                            description: ""
                        }
                    }
                }
            }
        },
        integrate: {
            editor: {
                help: {
                    early: {
                        subject: "需要早於直撥開始時間",
                        activity: {
                            subject: "需要早於活動開始時間"
                        }
                    },
                    async: {
                        subject: "將自動同步直撥標題",
                        activity: {
                            subject: "將自動同步文章標題"
                        }
                    },
                    copy: "複製直播描述",
                    share: "前往設定分享描述"
                },
                label: {
                    live: "直播",
                    on: "啟用",
                    off: "關閉"
                },
                field: {
                    mode: "整合",
                    kind: "種類",
                    category: "主分類",
                    secondary: "次分類",
                    subject: "標題",
                    publish: {
                        on: "上架時間",
                        off: "下架時間"
                    },
                    hyperlink: {
                        media: "Youtube連結",
                        music: "Google雲端MP3"
                    },
                    status: "狀態",
                    share: "轉發分享",
                    content: "內容"
                },
                holder: {
                    category: "請選擇主分類",
                    secondary: "請選擇次分類",
                    subject: "標題",
                    publish: {
                        on: "請選擇上架時間",
                        off: "請選擇下架時間"
                    },
                    hyperlink: {
                        media: "請輸入Youtube連結",
                        music: "請輸入Google雲端MP3"
                    },
                    status: "請選擇狀態",
                    share: "請選擇轉發分享",
                    content: "請輸入內容"
                },
                error: {
                    1001: {
                        subject: "請選擇主分類",
                        text: "",
                        description: ""
                    },
                    1002: {
                        subject: "請選擇次分類",
                        text: "",
                        description: ""
                    },
                    1003: {
                        subject: "請輸入標題",
                        text: "",
                        description: ""
                    },
                    1004: {
                        subject: "請選擇上架時間",
                        text: "",
                        description: ""
                    },
                    1005: {
                        subject: "上架時間需要小於直播開始時間",
                        text: "",
                        description: ""
                    },
                    1006: {
                        subject: "下架時間必須大於上架時間",
                        text: "",
                        description: ""
                    },
                    1007: {
                        subject: "請選擇狀態",
                        text: "",
                        description: ""
                    },
                    1008: {
                        subject: "請選擇轉發分享",
                        text: "",
                        description: ""
                    },
                    1009: {
                        subject: "請選擇種類",
                        text: "",
                        description: ""
                    },
                    1010: {
                        subject: "請輸入Youtube連結",
                        text: "",
                        description: ""
                    },
                    1011: {
                        subject: "請輸入Google雲端MP3",
                        text: "",
                        description: ""
                    }
                }
            }
        }
    },
    page: {
        app: {
            subject: "領袖的好幫手, 會友的好工具",
            text: "一個專屬教會的APP, 是教會的媒體與資訊發佈工具",
            description: "教會訊息、活動不漏接, 隨時隨地跟著讀經、禱告",
            button: {
                apple: "Apple用戶端",
                google: "Android用戶端"
            }
        },
        mapping: {
            subject: "系統安全性增強請求",
            text: "請使用App上的掃描功能, 進行管理員綁定",
            description: "如未綁定將暫時無法使用管理功能"
        },
        login: {
            subject: "APP管理系統",
            feature: {
                authorized: {
                    qrcode: "掃碼登入",
                    passwd: "帳密登入"
                },
                forgot: "忘記密碼"
            },
            field: {
                account: "登入帳號",
                passwd: "登入密碼",
                captcha: "驗證碼"
            },
            holder: {
                account: "請輸入登入帳號",
                passwd: "請輸入登入密碼",
                captcha: "請輸入圖形中的文字"
            },
            error: {
                1000: {
                    subject: "暫時無法使用",
                    text: "請稍後再試或請聯絡管理人員"
                },
                1001: {
                    subject: "請輸入登入帳號",
                    text: ""
                },
                1002: {
                    subject: "請輸入登入密碼",
                    text: ""
                },
                1003: {
                    subject: "請輸入圖形中的文字",
                    text: ""
                },
                1004: {
                    subject: "驗證碼應為6個字元",
                    text: ""
                },
                1005: {
                    subject: "驗證碼錯誤",
                    text: ""
                }
            }
        },
        forgot: {
            verification: {
                reset: {
                    subject: "重設登入密碼",
                    feature: {
                        back: "返回登入"
                    },
                    expire: {
                        subject: "本次重設密碼令牌將在 <b class=\"ft-14 text-danger\">%s</b> 秒後失效",
                        text: "本次重設密碼令牌已經失效"
                    },
                    field: {
                        passwd: {
                            create: "新密碼",
                            confirm: "確認新密碼"
                        }
                    },
                    holder: {
                        passwd: {
                            create: "請輸入新密碼",
                            confirm: "請再輸入一次新密碼進行確認"
                        }
                    },
                    handler: {
                        success: {
                            subject: "密碼重設成功",
                            text: "請使用您設定的新密碼登入"
                        },
                        destroy: {
                            subject: "是否確定放棄重設密碼",
                            text: "確認後將返回登入頁面"
                        }
                    },
                    error: {
                        1000: {
                            subject: "暫時無法使用",
                            text: "請稍後再試或請聯絡管理人員"
                        },
                        1001: {
                            subject: "請輸入新密碼",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "新密碼強度不足",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請再輸入新密碼進行確認",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "新密碼確認失敗",
                            text: "兩次密碼輸入不一致",
                            description: ""
                        },
                        9999: {
                            subject: "重設密碼令牌已失效",
                            text: "請重新進行忘記密碼程序"
                        }
                    }
                },
                subject: "確認郵件驗證碼",
                text: "請輸入電子郵件中的 6 位數字驗證碼",
                expire: {
                    subject: "本次重設密碼令牌將在 <b class=\"ft-14 text-danger\">%s</b> 秒後失效",
                    text: "本次重設密碼令牌已經失效"
                },
                feature: {
                    back: "返回登入"
                },
                handler: {
                    destroy: {
                        subject: "是否確定放棄重設密碼",
                        text: "確認後將返回登入頁面"
                    }
                },
                error: {
                    1000: {
                        subject: "暫時無法使用",
                        text: "請稍後再試或請聯絡管理人員"
                    },
                    1001: {
                        subject: "驗證碼空白",
                        text: "請輸入您的驗證碼"
                    },
                    1002: {
                        subject: "驗證碼長度異常",
                        text: "驗證碼長度應為 <b class=\"ft-18 text-danger\">6</b> 個數字"
                    },
                    1003: {
                        subject: "驗證碼錯誤",
                        text: "請確認您的驗證碼"
                    },
                    9999: {
                        subject: "重設密碼令牌已失效",
                        text: "請重新進行忘記密碼程序"
                    }
                }
            },
            subject: "忘記密碼",
            feature: {
                back: "返回登入"
            },
            field: {
                account: "帳號",
                email: "電子郵件",
                captcha: "驗證碼"
            },
            holder: {
                account: "請輸入管理員帳號",
                email: "請輸入管理員電子郵件",
                captcha: "請輸入圖形中的文字"
            },
            error: {
                1000: {
                    subject: "暫時無法使用",
                    text: "請稍後再試或請聯絡管理人員"
                },
                1001: {
                    subject: "請輸入帳號",
                    text: ""
                },
                1002: {
                    subject: "帳號長度不符合規則",
                    text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 個字元"
                },
                1003: {
                    subject: "請輸入管理員電子郵件",
                    text: ""
                },
                1004: {
                    subject: "電子郵件格式錯誤",
                    text: ""
                },
                1005: {
                    subject: "請輸入圖形中的文字",
                    text: ""
                },
                1006: {
                    subject: "驗證碼應為6個字元",
                    text: ""
                },
                1007: {
                    subject: "驗證碼錯誤",
                    text: ""
                },
                1008: {
                    subject: "找不到指定的管理員",
                    text: ""
                }
            }
        },
        billboard: {
            index: {
                uncheck: "未讀",
                empty: "暫時沒有任何公告",
                not: {
                    found: "找不到指定的公告"
                }
            },
            edit: {
                read: "%s・共計 <b class=\"%s\">%s</b> 次閱讀"
            }
        },
        admin: {
            billboard: {
                index: {
                    mode: {
                        on: "置頂",
                        off: "未置頂"
                    },
                    feature: {
                        create: {
                            subject: "新增公吿"
                        }
                    },
                    field: {
                        avatar: "頭像",
                        subject: "名稱",
                        top: "置頂",
                        summary: {
                            read: "已讀取"
                        }
                    },
                    holder: {
                        subject: "請輸入名稱"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用公告",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用公告",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除公告, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        }
                    },
                    field: {
                        subject: "標題",
                        content: "內容",
                        top: "置頂"
                    },
                    holder: {
                        subject: "請輸入標題",
                        content: "請輸入內容",
                        top: "請選擇是否置頂"
                    },
                    handler: {
                        store: {
                            subject: "即將新增公吿",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇是否置頂",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "閱讀"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    field: {
                        subject: "標題",
                        content: "內容",
                        top: "置頂",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        subject: "請輸入標題",
                        content: "請輸入內容",
                        top: "請選擇是否置頂",
                        keyword: "搜尋範圍: 名字/郵件/會友編號"
                    },
                    handler: {
                        update: {
                            subject: "即將更新公告內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇是否置頂",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            dashboard: {
                help: {
                    updated: "最後更新時間",
                    original: "預設區間",
                    loading: "正在計算統計數據..."
                },
                field: {
                    church: "指定教會",
                    begin: "開始時間",
                    end: "結束時間",
                    statistics: {
                        client: "會友人數",
                        coming: "會友新增",
                        delete: "會友刪除",
                        publish: "上稿次數",
                        notification: "發送推播",
                        bible: "聖經開啟",
                        favorite: "收藏次數",
                        comment: "留言次數",
                        share: "分享次數",
                        visit: "文章觀看",
                        social: "外連觀看",
                        login: "登入次數"
                    }
                },
                holder: {
                    church: "請選擇指定教會"
                }
            },
            user: {
                index: {
                    feature: {
                        create: {
                            subject: "新增管理員"
                        }
                    },
                    field: {
                        avatar: "頭像",
                        name: "名字",
                        account: "帳號",
                        email: "電子郵件"
                    },
                    holder: {
                        name: "請輸入名字",
                        account: "請輸入帳號",
                        email: "請輸入電子郵件",
                        empty: {
                            email: "未輸入"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用管理員",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用管理員",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除管理員, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "權限"
                        }
                    },
                    subject: {
                        passwd: "設置密碼"
                    },
                    field: {
                        account: "帳號",
                        name: "名字",
                        passwd: {
                            generate: "登入密碼",
                            validation: "確認登入密碼"
                        },
                        email: "電子郵件",
                        phone: "聯絡電話"
                    },
                    holder: {
                        account: "請輸入帳號",
                        name: "請輸入名字",
                        passwd: {
                            generate: "請輸入登入密碼",
                            validation: "請再次輸入登入密碼進行確認"
                        },
                        email: "請輸入電子郵件",
                        phone: "請輸入聯絡電話",
                        search: "請輸入關鍵字或權限代號, 進行權限名稱搜尋"
                    },
                    handler: {
                        store: {
                            subject: "即將新增管理員",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入帳號",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "帳號長度不符合規則",
                            text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 個字元",
                            description: ""
                        },
                        1003: {
                            subject: "帳號已經使用",
                            text: "請更換一個帳號",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入名字",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入電子郵件",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式",
                            description: ""
                        },
                        1007: {
                            subject: "請輸入聯絡電話",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入登入密碼",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "登入密碼強度不足",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請再輸入登入密碼進行確認",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "密碼確認失敗",
                            text: "兩次密碼輸入不一致",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        passwd: "如果不需要變更密碼, 不用填寫",
                        root: "系統帳號, 不得變更權限",
                        disabled: "請注意, 這名管理員已經停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "權限"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    subject: {
                        passwd: "變更密碼"
                    },
                    field: {
                        avatar: "頭像",
                        account: "帳號",
                        name: "名字",
                        passwd: {
                            current: "目前當前密碼",
                            generate: "登入密碼",
                            validation: "確認登入密碼"
                        },
                        email: "電子郵件",
                        phone: "聯絡電話"
                    },
                    holder: {
                        account: "請輸入帳號",
                        name: "請輸入名字",
                        passwd: {
                            current: "請輸入目前當前密碼",
                            generate: "請輸入登入密碼",
                            validation: "請再次輸入登入密碼進行確認"
                        },
                        email: "請輸入電子郵件",
                        phone: "請輸入聯絡電話",
                        search: "請輸入關鍵字或權限代號, 進行權限名稱搜尋"
                    },
                    handler: {
                        update: {
                            subject: "即將更新管理員內容",
                            text: "請確認後執行",
                            description: "",
                            warning: {
                                subject: "即將更新管理員內容",
                                text: "<span class=\"text-danger ft18\">您即將移除您編輯管理員權限的功能<br />移除後你將無法編輯管理員, 請確認後執行</span>",
                                description: ""
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入帳號",
                            text: ""
                        },
                        1002: {
                            subject: "帳號長度不符合規則",
                            text: "至少需要 <b class=\"ft-20 text-danger\">%s</b> 個字元"
                        },
                        1003: {
                            subject: "帳號已經使用",
                            text: "請更換一個帳號"
                        },
                        1004: {
                            subject: "請輸入名字",
                            text: ""
                        },
                        1005: {
                            subject: "請輸入電子郵件",
                            text: ""
                        },
                        1006: {
                            subject: "電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式"
                        },
                        1007: {
                            subject: "請輸入聯絡電話",
                            text: ""
                        },
                        1008: {
                            subject: "請選擇狀態",
                            text: ""
                        },
                        1009: {
                            subject: "請輸入當前密碼",
                            text: ""
                        },
                        1010: {
                            subject: "當前密碼錯誤",
                            text: ""
                        },
                        1011: {
                            subject: "請輸入登入密碼",
                            text: ""
                        },
                        1012: {
                            subject: "登入密碼強度不足",
                            text: ""
                        },
                        1013: {
                            subject: "請再輸入登入密碼進行確認",
                            text: ""
                        },
                        1014: {
                            subject: "密碼確認失敗",
                            text: "兩次密碼輸入不一致"
                        },
                        9999: {
                            subject: "即將返回首頁",
                            text: "您已經自行移除管理員管理員權限"
                        }
                    }
                }
            },
            church: {
                index: {
                    download: {
                        name: "檔案匯出_教會管理"
                    },
                    feature: {
                        create: {
                            subject: "新增教會"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        avatar: "主圖",
                        no: "編號",
                        name: "名稱",
                        client: "會友人數",
                        soul: "心靈捕手狀態",
                        daily: "每日靈糧狀態"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        begin: "請選擇建立日期(起)",
                        end: "請選擇建立日期(迄)",
                        no: "請輸入編號",
                        name: "請輸入名稱",
                        soul: "請選擇心靈捕手狀態",
                        daily: "請選擇每日靈糧狀態"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用教會",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用教會",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行教會匯出, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "聯絡人"
                        },
                        2: {
                            subject: "奉獻"
                        },
                        3: {
                            subject: "模組"
                        }
                    },
                    help: {
                        church: "請謹慎使用指定教會功能"
                    },
                    field: {
                        name: "名稱",
                        no: "編號",
                        location: {
                            country: "所在地區",
                            city: "所在城市"
                        },
                        address: "地址",
                        contact: [
                            {
                                subject: "主要聯絡人",
                                name: "姓名",
                                phone: "聯絡電話",
                                email: "電子郵件"
                            },
                            {
                                subject: "次要聯絡人",
                                name: "姓名",
                                phone: "聯絡電話",
                                email: "電子郵件"
                            }
                        ],
                        timezone: {
                            subject: [
                                "時區設定",
                                "時區與機構設定"
                            ],
                            zone: "所在時區",
                            organization: "機構"
                        },
                        control: {
                            subject: "控制項",
                            my: {
                                subject: "我的教會標題名稱",
                                text: "(尺寸為 %sx%s 像素)",
                                avatar: "標題圖"
                            },
                            daily: {
                                mode: "系統模式",
                                subject: "每日靈糧標題名稱",
                                text: "(尺寸為 %sx%s 像素)",
                                avatar: "標題圖"
                            },
                            soul: {
                                mode: "系統模式",
                                subject: "心靈捕手標題名稱"
                            },
                            query: "查詢長度"
                        },
                        donate: {
                            mode: "奉獻方式",
                            newebpay: {
                                merchant: "藍新金流Merchant ID",
                                key: "藍新金流Hash KEY",
                                iv: "藍新金流Hash IV"
                            },
                            hyperlink: {
                                text: "奉獻連結文字",
                                url: "奉獻連結",
                                description: "奉獻描述內容"
                            }
                        },
                        other: {
                            subject: "其他設定",
                            offset: "App首頁最新消息筆數",
                            period: "查詢區間",
                            invitation: {
                                customize: "邀請碼模式",
                                code: "教會邀請碼"
                            }
                        }
                    },
                    holder: {
                        name: "請輸入名稱",
                        no: "請輸入編號",
                        location: {
                            country: "請選擇所在地區",
                            city: "請選擇所在城市"
                        },
                        address: "請輸入地址",
                        contact: [
                            {
                                name: "請輸入姓名",
                                phone: "請輸入聯絡電話",
                                email: "請輸入電子郵件"
                            },
                            {
                                name: "請輸入姓名",
                                phone: "請輸入聯絡電話",
                                email: "請輸入電子郵件"
                            }
                        ],
                        timezone: {
                            zone: "請選擇時區",
                            organization: "請選擇機構"
                        },
                        control: {
                            my: {
                                subject: "請輸入我的教會標題名稱"
                            },
                            daily: {
                                subject: "請輸入每日靈糧標題名稱"
                            },
                            soul: {
                                subject: "請輸入心靈捕手標題名稱"
                            },
                            query: "請選擇查詢長度"
                        },
                        donate: {
                            newebpay: {
                                merchant: "請輸入藍新金流Merchant ID",
                                key: "請輸入藍新金流Hash KEY",
                                iv: "請輸入藍新金流Hash IV"
                            },
                            hyperlink: {
                                text: "請輸入奉獻連結文字",
                                url: "請輸入奉獻連結",
                                description: "請輸入奉獻描述內容"
                            }
                        },
                        other: {
                            offset: "請選擇App首頁最新消息筆數",
                            period: "請選擇查詢區間",
                            invitation: {
                                code: [
                                    "請點擊圖示產生隨機邀請碼",
                                    "請點擊圖示自行輸入邀請碼"
                                ]
                            }
                        }
                    },
                    handler: {
                        store: {
                            subject: "即將新增教會",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "異常發生",
                            text: "無法停用目前登入的教會",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇所在區域",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇所在城市",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇所在時區",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇機構",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入我的教會標題名稱",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入心靈捕手標題名稱",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請選擇心靈捕手系統模式",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入每日靈糧標題名稱",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請選擇每日靈糧系統模式",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請輸入地址",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "請輸入編號",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "編號已經存在",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "請選擇查詢長度",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "請選擇邀請碼模式",
                            text: "",
                            description: ""
                        },
                        1018: {
                            subject: "請選擇App首頁最新消息筆數",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請輸入主要聯絡人姓名",
                            text: "",
                            description: ""
                        },
                        2002: {
                            subject: "請輸入主要聯絡人聯絡電話",
                            text: "",
                            description: ""
                        },
                        2003: {
                            subject: "請輸入主要聯絡人電子郵件",
                            text: "",
                            description: ""
                        },
                        2004: {
                            subject: "主要聯絡人電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式",
                            description: ""
                        },
                        2005: {
                            subject: "次要聯絡人電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式",
                            description: ""
                        },
                        3001: {
                            subject: "請選擇奉獻系統模式",
                            text: "",
                            description: ""
                        },
                        3002: {
                            subject: "請輸入藍新金流Merchant ID",
                            text: "",
                            description: ""
                        },
                        3003: {
                            subject: "請輸入藍新金流Hash KEY",
                            text: "",
                            description: ""
                        },
                        3004: {
                            subject: "請輸入藍新金流Hash IV",
                            text: "",
                            description: ""
                        },
                        3005: {
                            subject: "請輸入奉獻連結",
                            text: "",
                            description: ""
                        },
                        3006: {
                            subject: "請輸入奉獻連結文字",
                            text: "",
                            description: ""
                        },
                        3007: {
                            subject: "請輸入奉獻描述內容",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "請注意, 這間教會已經停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "聯絡人"
                        },
                        2: {
                            subject: "奉獻"
                        },
                        3: {
                            subject: "模組"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    generate: [
                        "隨機產生",
                        "自行輸入",
                        "清除邀請碼"
                    ],
                    field: {
                        name: "名稱",
                        no: "編號",
                        location: {
                            country: "所在地區",
                            city: "所在城市"
                        },
                        address: "地址",
                        description: {
                            subject: "簡介標題",
                            text: "簡介內容"
                        },
                        hyperlink: {
                            text: "連結文字",
                            url: "連結網址"
                        },
                        contact: [
                            {
                                subject: "主要聯絡人",
                                name: "姓名",
                                phone: "聯絡電話",
                                email: "電子郵件"
                            },
                            {
                                subject: "次要聯絡人",
                                name: "姓名",
                                phone: "聯絡電話",
                                email: "電子郵件"
                            }
                        ],
                        timezone: {
                            subject: [
                                "時區設定",
                                "時區與機構設定"
                            ],
                            zone: "所在時區",
                            organization: "機構"
                        },
                        control: {
                            subject: "控制項",
                            my: {
                                subject: "我的教會標題名稱",
                                text: "(尺寸為 %sx%s 像素)",
                                avatar: "標題圖"
                            },
                            daily: {
                                mode: "系統模式",
                                subject: "每日靈糧標題名稱",
                                text: "(尺寸為 %sx%s 像素)",
                                avatar: "標題圖"
                            },
                            soul: {
                                mode: "系統模式",
                                subject: "心靈捕手標題名稱"
                            }
                        },
                        donate: {
                            mode: "奉獻方式",
                            newebpay: {
                                merchant: "藍新金流Merchant ID",
                                key: "藍新金流Hash KEY",
                                iv: "藍新金流Hash IV"
                            },
                            hyperlink: {
                                text: "奉獻連結文字",
                                url: "奉獻連結",
                                description: "奉獻描述內容"
                            }
                        },
                        other: {
                            subject: "其他設定",
                            offset: "App首頁最新消息筆數",
                            period: "查詢區間",
                            invitation: {
                                customize: "邀請碼模式",
                                code: "教會邀請碼"
                            }
                        }
                    },
                    holder: {
                        name: "請輸入名稱",
                        no: "請輸入編號",
                        location: {
                            country: "請選擇所在地區",
                            city: "請選擇所在城市"
                        },
                        address: "請輸入地址",
                        description: {
                            subject: "請輸入簡介標題",
                            text: "請輸入簡介內容"
                        },
                        hyperlink: {
                            text: "請輸入連結文字",
                            url: "請輸入連結網址"
                        },
                        contact: [
                            {
                                name: "請輸入姓名",
                                phone: "請輸入聯絡電話",
                                email: "請輸入電子郵件"
                            },
                            {
                                name: "請輸入姓名",
                                phone: "請輸入聯絡電話",
                                email: "請輸入電子郵件"
                            }
                        ],
                        timezone: {
                            zone: "請選擇時區",
                            organization: "請選擇機構"
                        },
                        control: {
                            my: {
                                subject: "請輸入我的教會標題名稱"
                            },
                            daily: {
                                subject: "請輸入每日靈糧標題名稱"
                            },
                            soul: {
                                subject: "請輸入心靈捕手標題名稱"
                            }
                        },
                        donate: {
                            newebpay: {
                                merchant: "請輸入藍新金流Merchant ID",
                                key: "請輸入藍新金流Hash KEY",
                                iv: "請輸入藍新金流Hash IV"
                            },
                            hyperlink: {
                                text: "請輸入奉獻連結文字",
                                url: "請輸入奉獻連結",
                                description: "請輸入奉獻描述內容"
                            }
                        },
                        other: {
                            offset: "請選擇App首頁最新消息筆數",
                            period: "請選擇查詢區間",
                            invitation: {
                                code: [
                                    "請點擊圖示產生隨機邀請碼",
                                    "請點擊圖示自行輸入邀請碼"
                                ]
                            }
                        }
                    },
                    handler: {
                        invitation: {
                            clear: {
                                subject: "即將清除教會邀請碼",
                                text: "<span class=\"text-danger\">清除後儲存, 將無法使用教會邀請碼功能<br />請確認後執行</span>",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即將更新教會內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "異常發生",
                            text: "無法停用目前登入的教會",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇所在區域",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇所在城市",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇所在時區",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇機構",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入我的教會標題名稱",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入心靈捕手標題名稱",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請選擇心靈捕手系統模式",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入每日靈糧標題名稱",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請選擇每日靈糧系統模式",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請輸入地址",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "請選擇查詢長度",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "請選擇邀請碼模式",
                            text: "",
                            description: ""
                        },
                        1018: {
                            subject: "請選擇App首頁最新消息筆數",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請輸入主要聯絡人姓名",
                            text: "",
                            description: ""
                        },
                        2002: {
                            subject: "請輸入主要聯絡人聯絡電話",
                            text: "",
                            description: ""
                        },
                        2003: {
                            subject: "請輸入主要聯絡人電子郵件",
                            text: "",
                            description: ""
                        },
                        2004: {
                            subject: "主要聯絡人電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式",
                            description: ""
                        },
                        2005: {
                            subject: "次要聯絡人電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式",
                            description: ""
                        },
                        3001: {
                            subject: "請選擇奉獻系統模式",
                            text: "",
                            description: ""
                        },
                        3002: {
                            subject: "請輸入藍新金流Merchant ID",
                            text: "",
                            description: ""
                        },
                        3003: {
                            subject: "請輸入藍新金流Hash KEY",
                            text: "",
                            description: ""
                        },
                        3004: {
                            subject: "請輸入藍新金流Hash IV",
                            text: "",
                            description: ""
                        },
                        3005: {
                            subject: "請輸入奉獻連結",
                            text: "",
                            description: ""
                        },
                        3006: {
                            subject: "請輸入奉獻連結文字",
                            text: "",
                            description: ""
                        },
                        3007: {
                            subject: "請輸入奉獻描述內容",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            client: {
                index: {
                    tag: {
                        help: {
                            empty: "請先選取指定會友",
                            edit: "編輯個人標記",
                            join: "附加標籤到指定會友",
                            replace: "清除並附加標籤到指定會友",
                            remove: "刪除指定會友的標籤",
                            reset: "重設已輸入標籤值"
                        }
                    },
                    download: {
                        name: "檔案匯出_會友管理"
                    },
                    feature: {
                        create: {
                            subject: "新增會友"
                        },
                        tag: {
                            on: "開啟編輯標籤",
                            off: "關閉編輯標籤"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        avatar: "頭像",
                        name: "名字",
                        email: "電子郵件",
                        account: "帳號",
                        commitment: "委身編號",
                        keyword: "標籤"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        begin: "請選擇建立日期(起)",
                        end: "請選擇建立日期(迄)",
                        name: "請輸入名字",
                        email: "請輸入電子郵件",
                        belong: "請選擇所屬教會",
                        empty: {
                            commitment: "未填寫"
                        },
                        keyword: "請輸入標籤,可用逗號分隔連續輸入並按enter輸入"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用會友",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用會友",
                            text: "請確認後執行",
                            description: ""
                        },
                        tag: {
                            store: {
                                subject: "即將對指定的會友加入標籤",
                                text: "請確認後執行",
                                description: ""
                            },
                            replace: {
                                subject: "即將對指定的會友取代標籤",
                                text: "請確認後執行",
                                description: ""
                            },
                            remove: {
                                subject: "即將對指定的會友移除標籤",
                                text: "請確認後執行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行會友匯出, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行全部會友刪除, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "請注意, 這名會友已經停用"
                    },
                    commitment: {
                        empty: "未填寫"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "標籤"
                        },
                        2: {
                            subject: "統計資訊"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        donate: {
                            subject: "奉獻紀錄"
                        },
                        share: {
                            subject: "分享次數"
                        },
                        visit: {
                            subject: "文章觀看"
                        },
                        comment: {
                            subject: "文章回覆"
                        },
                        social: {
                            subject: "外連觀看"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        avatar: "頭像",
                        email: "電子郵件",
                        name: "名字",
                        title: "稱謂",
                        birthday: "生日",
                        phone: "聯絡電話",
                        location: {
                            country: "所在地區",
                            city: "所在城市"
                        },
                        address: "聯絡地址",
                        occupation: "職業",
                        baptized: "受洗時間",
                        commitment: "委身編號",
                        created: "建立時間",
                        accessed: "最後存取時間",
                        deleted: "刪除時間",
                        tag: {
                            subject: "標籤"
                        }
                    },
                    holder: {
                        head: "未輸入",
                        belong: {
                            name: "",
                            no: ""
                        },
                        email: "請輸入電子郵件",
                        name: "請輸入名字",
                        title: "未輸入稱謂",
                        birthday: "未輸入生日",
                        phone: "未輸入聯絡電話",
                        location: {
                            country: "未輸入所在地區",
                            city: "未輸入所在城市"
                        },
                        address: "未輸入聯絡地址",
                        occupation: "未輸入職業",
                        baptized: "未輸入受洗時間",
                        commitment: "請輸入委身編號",
                        created: "",
                        accessed: ""
                    },
                    handler: {
                        update: {
                            subject: "即將更新會友內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                }
            },
            cycle: {
                index: {
                    feature: {
                        create: {
                            subject: "新增循環"
                        }
                    },
                    field: {
                        ref: "類型",
                        subject: "名稱",
                        period: "週期"
                    },
                    holder: {
                        subject: "請輸入名稱"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用循環",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用循環",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "循環"
                        }
                    },
                    tip: {
                        create: "建立後類型與目標無法修改, 請確認"
                    },
                    help: {
                        church: "請謹慎使用指定教會功能",
                        live: {
                            subject: "點擊查看說明",
                            text: "部分文章無法設定循環"
                        }
                    },
                    field: {
                        church: "指定教會",
                        ref: "種類",
                        kind: "類型",
                        subject: "名稱",
                        selection: {
                            article: {
                                main: "主分類",
                                sub: "次分類"
                            }
                        },
                        item: {
                            subject: "標題",
                            begin: {
                                article: "上架時間",
                                notification: "排程時間",
                                other: "開始時間"
                            },
                            end: {
                                article: "下架時間",
                                other: "結束時間"
                            }
                        },
                        target: "項目"
                    },
                    holder: {
                        church: "請選擇指定教會",
                        kind: "請選擇類型",
                        subject: "請輸入名稱",
                        selection: {
                            article: {
                                main: "請選擇主分類",
                                sub: "請選擇次分類"
                            }
                        },
                        item: {
                            subject: "未填寫標題",
                            begin: {
                                article: "未選擇上架時間",
                                notification: "未選擇排程時間",
                                other: "未選擇開始時間"
                            },
                            end: {
                                article: "未選擇下架時間",
                                other: "未選擇結束時間"
                            }
                        },
                        target: "請選擇項目"
                    },
                    handler: {
                        store: {
                            subject: "即將新增循環",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇類型",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇主分類",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇次分類",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇項目",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇指定教會",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "請注意, 這筆循環已經停用",
                        create: "建立後類型與目標無法修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "循環"
                        },
                        2: {
                            subject: "歷程紀錄"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        history: {
                            subject: "循環紀錄"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        kind: "類型",
                        subject: "名稱",
                        selection: {
                            article: {
                                main: "主分類",
                                sub: "次分類"
                            }
                        },
                        item: {
                            subject: "標題",
                            begin: {
                                article: "上架時間",
                                notification: "排程時間",
                                other: "開始時間"
                            },
                            end: {
                                article: "下架時間",
                                other: "結束時間"
                            }
                        },
                        target: "項目"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        kind: "請選擇類型",
                        subject: "請輸入名稱",
                        selection: {
                            article: {
                                main: "請選擇主分類",
                                sub: "請選擇次分類"
                            }
                        },
                        item: {
                            subject: "未填寫標題",
                            begin: {
                                article: "未選擇上架時間",
                                notification: "未選擇排程時間",
                                other: "未選擇開始時間"
                            },
                            end: {
                                article: "未選擇下架時間",
                                other: "未選擇結束時間"
                            }
                        },
                        target: "請選擇項目"
                    },
                    handler: {
                        update: {
                            subject: "即將更新循環內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                }
            },
            article: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分類"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            avatar: "封面圖",
                            name: "名稱",
                            quantity: "文章數量",
                            sort: "排序",
                            created: "建立時間"
                        },
                        holder: {
                            head: {
                                church: "請選擇教會"
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            enable: {
                                subject: "即將啟用分類",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將停用分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將刪除分類, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    create: {
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        help: {
                            church: "請謹慎使用指定教會功能"
                        },
                        field: {
                            church: {
                                subject: "指定教會",
                                text: "僅能指定自行維護心靈捕手的教會"
                            },
                            avatar: {
                                subject: "封面圖",
                                text: "(尺寸為 %sx%s 像素)"
                            },
                            name: "名稱"
                        },
                        holder: {
                            church: "請選擇指定教會",
                            name: "請輸入名稱"
                        },
                        handler: {
                            store: {
                                subject: "即將新增分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請選擇封面圖",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            1004: {
                                subject: "請指定教會",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "請注意, 這個分類已經停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "排序"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            belong: {
                                no: "所屬教會ID",
                                name: "所屬教會名稱"
                            },
                            avatar: {
                                subject: "封面圖",
                                text: "(尺寸為 %sx%s 像素)"
                            },
                            name: "名稱"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            update: {
                                subject: "即將更新分類內容",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請選擇封面圖",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    integrate: {
                        badge: {
                            subject: "文章整合"
                        }
                    },
                    mindcatcher: {
                        badge: {
                            subject: "心靈捕手"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "查看留言紀錄"
                        },
                        notification: {
                            subject: "查看推播紀錄"
                        }
                    },
                    feature: {
                        create: {
                            subject: "新增文章"
                        },
                        mindcatcher: {
                            subject: "分享連結"
                        }
                    },
                    top: [
                        "未置頂",
                        "已置頂"
                    ],
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        category: "分類",
                        created: "建立時間"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        top: "請選擇置頂",
                        begin: "請選擇建立日期(起)",
                        end: "請選擇建立日期(迄)",
                        category: {
                            subject: "請選擇分類",
                            empty: "請先選擇指定教會"
                        },
                        kind: "請選擇類型"
                    },
                    handler: {
                        mindcatcher: {
                            subject: "我的教會x心靈捕手",
                            text: "<span class=\"text-success\">請貼上您的分享連結</span>",
                            description: "請輸入分享連結"
                        },
                        top: {
                            enable: {
                                subject: "即將文章置頂",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將文章取消置頂",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        enable: {
                            subject: "即將啟用文章",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用文章",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除文章, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "圖片"
                        },
                        2: {
                            subject: "分享"
                        },
                        3: {
                            subject: "活動"
                        },
                        4: {
                            subject: "直播"
                        }
                    },
                    tip: {
                        live: "類型為直播, 無法使用活動次分類"
                    },
                    help: {
                        church: "請謹慎使用指定教會功能",
                        share: "前往設定分享描述"
                    },
                    field: {
                        church: {
                            my: {
                                subject: "指定教會",
                                text: ""
                            },
                            soul: {
                                subject: "指定教會",
                                text: "僅能指定自行維護心靈捕手的教會"
                            },
                            daily: {
                                subject: "指定教會",
                                text: "僅能指定自行維護每日靈糧的教會"
                            }
                        },
                        kind: "類型",
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        category: "分類",
                        subject: "標題",
                        publish: {
                            on: "上架時間",
                            off: "下架時間"
                        },
                        cycle: {
                            kind: "循環方式",
                            day: "循環日",
                            date: "日期",
                            time: "時間"
                        },
                        hyperlink: {
                            media: "Youtube連結",
                            music: "Google雲端MP3",
                            live: "直播連結",
                            activity: "外部活動連結",
                            web: "外部網站連結"
                        },
                        content: "內容",
                        collection: "文章圖片",
                        description: "分享內文",
                        status: "狀態",
                        share: "轉發分享"
                    },
                    holder: {
                        church: "請選擇指定教會",
                        kind: "請選擇類型",
                        category: {
                            subject: "請選擇分類",
                            empty: "請先選擇指定教會"
                        },
                        subject: "請輸入標題",
                        publish: {
                            begin: "請選擇上架時間",
                            end: "請選擇下架時間"
                        },
                        cycle: {
                            kind: "請選擇循環方式",
                            day: "請選擇循環日",
                            date: "請選擇日期",
                            time: "請選擇時間"
                        },
                        hyperlink: {
                            media: "請輸入Youtube連結",
                            music: "請輸入Google雲端MP3",
                            live: "請輸入直播連結",
                            activity: "請輸入外部活動連結",
                            web: "請輸入外部網站連結"
                        },
                        content: "請輸入內容",
                        collection: "",
                        description: "請輸入分享內文",
                        status: "請選擇狀態",
                        share: "請選擇轉發分享"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即將新增文章",
                            text: "請確認後執行",
                            description: ""
                        },
                        copy: {
                            subject: "即將複製文章",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇類型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇上架時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "下架時間必須大於上架時間",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請輸入外部活動連結",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入Youtube連結",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入Google雲端MP3",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請輸入直播連結",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入外部網站連結",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請選擇轉發分享",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "請指定教會",
                            text: "",
                            description: ""
                        },
                        3001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "此文章無法複製",
                            text: "已經啟用整合功能",
                            description: ""
                        }
                    }
                },
                edit: {
                    comment: {
                        feature: {
                            calculate: {
                                subject: "重計留言數量"
                            }
                        },
                        subject: "留言紀錄",
                        th: {
                            0: "頭像",
                            1: "姓名",
                            2: "內容",
                            3: "時間"
                        },
                        delete: {
                            empty: {
                                subject: "請先選擇需要刪除的留言"
                            }
                        },
                        handler: {
                            calculate: {
                                subject: "即將重新計算文章留言數量",
                                text: "請確認後執行",
                                description: ""
                            },
                            delete: {
                                subject: "即將刪除文章留言",
                                text: "請確認後執行",
                                description: ""
                            }
                        }
                    },
                    tip: {
                        disabled: "請注意, 這篇文章已經停用",
                        live: "類型為直播, 無法使用活動次分類"
                    },
                    help: {
                        share: "前往設定分享描述"
                    },
                    integrate: {
                        live: {
                            subject: "已啟用直播文章整合, 無法編輯"
                        },
                        activity: {
                            subject: "已啟用活動文章整合, 無法編輯"
                        },
                        mindcatcher: {
                            subject: "心靈捕手外部文章整合, 無法編輯"
                        },
                        hyperlink: "前往修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "圖片"
                        },
                        2: {
                            subject: "分享"
                        },
                        3: {
                            subject: "活動"
                        },
                        4: {
                            subject: "直播"
                        },
                        5: {
                            subject: "統計資訊"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "留言紀錄"
                        },
                        notification: {
                            subject: "推播紀錄"
                        },
                        visit: {
                            subject: "觀看次數"
                        },
                        social: {
                            subject: "外連觀看"
                        },
                        favorite: {
                            subject: "收藏次數"
                        }
                    },
                    field: {
                        belong: {
                            no: "所屬教會編號",
                            name: "所屬教會名稱"
                        },
                        kind: "類型",
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        category: "分類",
                        subject: "標題",
                        publish: {
                            on: "上架時間",
                            off: "下架時間"
                        },
                        cycle: {
                            kind: "循環",
                            day: "循環日",
                            date: "日期",
                            time: "時間"
                        },
                        content: "內容",
                        collection: "文章圖片",
                        description: "分享內文",
                        status: "狀態",
                        share: "轉發分享"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "請選擇類型",
                        category: "請選擇分類",
                        subject: "請輸入標題",
                        publish: {
                            on: "請選擇上架時間",
                            off: "請選擇下架時間"
                        },
                        cycle: {
                            kind: "請選擇循環方式",
                            day: "請選擇循環日",
                            date: "請選擇日期",
                            time: "請選擇時間"
                        },
                        content: "請輸入內容",
                        collection: "",
                        description: "請輸入分享內文",
                        status: "請選擇狀態",
                        share: "請選擇轉發分享"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即將更新文章內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇類型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇上架時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "下架時間必須大於上架時間",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請輸入外部活動連結",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入Youtube連結",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入Google雲端MP3",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請輸入直播連結",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入外部網站連結",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請選擇轉發分享",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        },
                        3001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            gallery: {
                accept: {
                    empty: "此分類下找不到圖片",
                    disable: "請先選擇需要處理的圖片",
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 張圖片",
                    handler: {
                        reload: {
                            subject: "即將重新整理",
                            text: "已選擇的項目將會重設, 請確認後執行",
                            description: ""
                        },
                        approved: {
                            subject: "即將核准指定的圖片",
                            text: "請確認後執行",
                            description: ""
                        },
                        reject: {
                            subject: "即將拒絕指定的圖片",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                },
                alarm: {
                    empty: "此分類下找不到圖片",
                    disable: "請先選擇需要處理的圖片",
                    submit: {
                        reject: "核實檢舉",
                        revoke: "撤銷檢舉"
                    },
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 張圖片",
                    handler: {
                        reload: {
                            subject: "即將重新整理",
                            text: "已選擇的項目將會重設, 請確認後執行",
                            description: ""
                        },
                        reject: {
                            subject: "即將核實檢舉指定的圖片",
                            text: "請確認後執行",
                            description: ""
                        },
                        revoke: {
                            subject: "即將撤銷檢舉指定的圖片",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                },
                index: {
                    empty: "此分類下找不到圖片",
                    disable: "請先選擇需要處理的圖片",
                    summary: "全部 <b class=\"ft-16 cgBase\">%s</b> 張圖片",
                    handler: {
                        reload: {
                            subject: "即將重新整理",
                            text: "已選擇的項目將會重設, 請確認後執行",
                            description: ""
                        },
                        destroy: {
                            subject: "即將拒絕指定的圖片",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                }
            },
            notification: {
                v2: {
                    create: {
                        membership: {
                            tag: {
                                field: {
                                    keyword: {
                                        subject: "關鍵字",
                                        holder: "請輸入關鍵字",
                                        text: "同時搜尋名字/郵件/會友編號"
                                    },
                                    tag: {
                                        subject: "標籤",
                                        holder: "請選擇標籤"
                                    }
                                }
                            },
                            editor: {
                                subject: "請選擇會友進行推播通知",
                                submit: {
                                    maximum: {
                                        subject: "最多只能選擇%s位會友進行推播通知"
                                    },
                                    minimum: {
                                        subject: "請選擇至少%s位會友進行推播通知"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "關鍵字",
                                    holder: "請輸入關鍵字",
                                    text: "同時搜尋名字/郵件/會友編號"
                                },
                                tag: {
                                    subject: "標籤",
                                    holder: "請選擇標籤"
                                }
                            },
                            empty: {
                                subject: "請選擇會友進行推播通知"
                            },
                            not: {
                                found: {
                                    subject: "搜尋不到指定的推播通知會友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            }
                        },
                        field: {
                            type: {
                                subject: "類型",
                                holder: "請選擇類型",
                                help: "類型於建立之後不能變更"
                            },
                            subject: {
                                subject: "標題",
                                holder: "請輸入標題"
                            },
                            begin: {
                                subject: "排程時間",
                                holder: "請選擇排程時間"
                            },
                            content: {
                                subject: "簡述",
                                holder: "請輸入簡述"
                            },
                            description: {
                                subject: "內容",
                                holder: "請輸入內容"
                            },
                            status: {
                                subject: "狀態",
                                holder: "請選擇狀態"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "推播紀錄"
                            }
                        },
                        alert: {
                            0: {
                                subject: "推播目前正在排程中"
                            },
                            1: {
                                subject: "目前推播正在進行中無法修改, 進度 %s"
                            },
                            2: {
                                subject: "推播已經全部完成無法修改"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將儲存內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "編輯名單"
                            },
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請選擇類型",
                                text: ""
                            },
                            1020: {
                                subject: "請選擇指定教會",
                                text: ""
                            },
                            1030: {
                                subject: "請選擇排除機構",
                                text: ""
                            },
                            1031: {
                                subject: "不能排除所有機構",
                                text: ""
                            },
                            1040: {
                                subject: "請輸入標題",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇開始時間",
                                text: ""
                            },
                            1051: {
                                subject: "排程時間必須大於現在時間",
                                text: ""
                            },
                            1060: {
                                subject: "請輸入簡述",
                                text: ""
                            },
                            1070: {
                                subject: "請輸入內容",
                                text: ""
                            },
                            2010: {
                                subject: "請至少選擇1位會友進行推播",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        membership: {
                            tag: {
                                field: {
                                    keyword: {
                                        subject: "關鍵字",
                                        holder: "請輸入關鍵字",
                                        text: "同時搜尋名字/郵件/會友編號"
                                    },
                                    tag: {
                                        subject: "標籤",
                                        holder: "請選擇標籤"
                                    }
                                }
                            },
                            editor: {
                                subject: "請選擇會友進行推播通知",
                                submit: {
                                    maximum: {
                                        subject: "最多只能選擇%s位會友進行推播通知"
                                    },
                                    minimum: {
                                        subject: "請選擇至少%s位會友進行推播通知"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "關鍵字",
                                    holder: "請輸入關鍵字",
                                    text: "同時搜尋名字/郵件/會友編號"
                                },
                                tag: {
                                    subject: "標籤",
                                    holder: "請選擇標籤"
                                }
                            },
                            empty: {
                                subject: "請選擇會友進行推播通知"
                            },
                            not: {
                                found: {
                                    subject: "搜尋不到指定的推播通知會友"
                                }
                            }
                        },
                        history: {
                            field: {
                                recipient: {
                                    subject: "收件人"
                                },
                                read: {
                                    subject: "讀取",
                                    value: {
                                        0: {
                                            subject: "未讀取"
                                        },
                                        1: {
                                            subject: "已讀取"
                                        }
                                    }
                                },
                                status: {
                                    subject: "狀態",
                                    value: {
                                        0: {
                                            subject: "準備中"
                                        },
                                        1: {
                                            subject: "已送達"
                                        },
                                        2: {
                                            subject: "無法推播"
                                        },
                                        9: {
                                            subject: "用戶刪除"
                                        }
                                    }
                                },
                                created: {
                                    subject: "送達時間"
                                }
                            },
                            search: {
                                read: {
                                    subject: "讀取",
                                    holder: "請選擇讀取"
                                },
                                status: {
                                    subject: "狀態",
                                    holder: "請選擇狀態"
                                }
                            }
                        },
                        automatic: {
                            edit: {
                                tab: {
                                    0: {
                                        subject: "基本"
                                    },
                                    99: {
                                        subject: "相關資訊"
                                    }
                                },
                                field: {
                                    created: {
                                        subject: "建立時間",
                                        holder: ""
                                    },
                                    stamp: {
                                        subject: "執行時間",
                                        holder: ""
                                    },
                                    received: {
                                        subject: "生成時間",
                                        holder: ""
                                    }
                                }
                            },
                            field: {
                                recipient: {
                                    subject: "收件人"
                                },
                                id: {
                                    subject: "識別ID"
                                },
                                supplier: {
                                    subject: "支援廠商",
                                    value: {
                                        1: {
                                            subject: "APNS"
                                        },
                                        2: {
                                            subject: "FCM"
                                        }
                                    }
                                },
                                status: {
                                    subject: "狀態",
                                    value: {
                                        0: {
                                            subject: "未對應"
                                        },
                                        1: {
                                            subject: "已對應"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立時間"
                                },
                                stamp: {
                                    subject: "執行時間"
                                },
                                received: {
                                    subject: "生成時間"
                                }
                            },
                            search: {
                                supplier: {
                                    subject: "推播類型",
                                    holder: "請選擇推播類型"
                                },
                                status: {
                                    subject: "狀態",
                                    holder: "請選擇狀態"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            },
                            98: {
                                subject: "統計資訊"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            type: {
                                subject: "類型",
                                holder: "請選擇類型",
                                help: "類型於建立之後不能變更"
                            },
                            subject: {
                                subject: "標題",
                                holder: "請輸入標題"
                            },
                            begin: {
                                subject: "排程時間",
                                holder: "請選擇排程時間"
                            },
                            content: {
                                subject: "簡述",
                                holder: "請輸入簡述"
                            },
                            description: {
                                subject: "內容",
                                holder: "請輸入內容"
                            },
                            status: {
                                subject: "狀態",
                                holder: "請選擇狀態"
                            }
                        },
                        statistics: {
                            automatic: {
                                subject: "自動化紀錄"
                            },
                            history: {
                                subject: "推播紀錄"
                            }
                        },
                        alert: {
                            0: {
                                subject: "推播目前正在排程中"
                            },
                            1: {
                                subject: "目前推播正在進行中無法修改, 進度 %s"
                            },
                            2: {
                                subject: "推播已經全部完成無法修改"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將更新內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "編輯名單"
                            },
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請選擇類型",
                                text: ""
                            },
                            1030: {
                                subject: "請選擇排除機構",
                                text: ""
                            },
                            1031: {
                                subject: "不能排除所有機構",
                                text: ""
                            },
                            1040: {
                                subject: "請輸入標題",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇開始時間",
                                text: ""
                            },
                            1051: {
                                subject: "排程時間必須大於現在時間",
                                text: ""
                            },
                            1060: {
                                subject: "請輸入簡述",
                                text: ""
                            },
                            1070: {
                                subject: "請輸入內容",
                                text: ""
                            },
                            2010: {
                                subject: "請至少選擇1位會友進行推播",
                                text: ""
                            }
                        }
                    },
                    field: {
                        type: {
                            subject: "對象",
                            value: {
                                1: {
                                    subject: "指定推播"
                                },
                                2: {
                                    subject: "全體推播"
                                }
                            }
                        },
                        subject: {
                            subject: "標題"
                        },
                        summary: {
                            total: {
                                subject: "推播數量"
                            }
                        },
                        status: {
                            subject: "狀態",
                            progress: {
                                subject: "<br/>進度 %s"
                            },
                            value: {
                                0: {
                                    subject: "排程中"
                                },
                                1: {
                                    subject: "發送中"
                                },
                                2: {
                                    subject: "已完成"
                                }
                            }
                        },
                        begin: {
                            subject: "排程時間"
                        },
                        created: {
                            subject: "建立時間"
                        }
                    },
                    search: {
                        type: {
                            subject: "對象",
                            holder: "請選擇對象"
                        },
                        subject: {
                            subject: "標題",
                            holder: "請輸入標題"
                        },
                        on: {
                            subject: "排程時間(起)",
                            holder: "請選擇排程時間(起)",
                            end: {
                                subject: "排程時間(迄)",
                                holder: "請選擇排程時間(迄)"
                            }
                        },
                        status: {
                            subject: "狀態",
                            holder: "請選擇狀態"
                        }
                    },
                    handler: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除推播通知, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增推播"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        create: {
                            begin: "建立日期(起)",
                            end: "建立日期(迄)"
                        },
                        send: {
                            begin: "排程日期(起)",
                            end: "排程日期(迄)"
                        },
                        subject: "標題",
                        quantity: "數量",
                        schedule: "排程時間",
                        ref: "類型",
                        kind: "對象",
                        progress: "進度"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        subject: "請輸入標題",
                        create: {
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)"
                        },
                        send: {
                            begin: "請選擇排程日期(起)",
                            end: "請選擇排程日期(迄)"
                        },
                        ref: "請選擇類型",
                        kind: "請選擇對象"
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除推播, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tip: {
                        kind: "請注意, 推播類型建立後將無法變更"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名單"
                        }
                    },
                    help: {
                        church: "請謹慎使用指定教會功能"
                    },
                    option: {
                        all: "全部會友名單"
                    },
                    field: {
                        church: "指定教會",
                        excluded: "排除機構",
                        belong: {
                            no: "所屬教會ID",
                            name: "所屬教會名稱"
                        },
                        kind: "對象",
                        subject: "標題",
                        schedule: "排程時間",
                        text: "簡述",
                        content: "內容",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        church: "請選擇指定教會",
                        excluded: "選中的機構將不會發送推播訊息",
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "請選擇對象",
                        subject: "請輸入標題",
                        schedule: "請選擇排程時間",
                        text: "請輸入簡述",
                        content: "請輸入內容",
                        keyword: "搜尋範圍: 名字/郵件/會友編號"
                    },
                    handler: {
                        store: {
                            subject: "即將新增推播內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇對象",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇排程時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入簡述",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請指定教會",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請至少選擇 <b class=\"ft-24 text-danger\">1</b> 位會友進行推播",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名單"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    field: {
                        belong: {
                            no: "所屬教會ID",
                            name: "所屬教會名稱"
                        },
                        excluded: "排除機構",
                        kind: "對象",
                        subject: "標題",
                        schedule: "排程時間",
                        text: "簡述",
                        content: "內容",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        excluded: "選中的機構將不會發送推播訊息",
                        kind: "請選擇對象",
                        subject: "請輸入標題",
                        schedule: "請選擇排程時間",
                        text: "請輸入簡述",
                        content: "請輸入內容",
                        keyword: "搜尋範圍: 名字/郵件/會友編號"
                    },
                    handler: {
                        update: {
                            subject: "即將更新推播內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇對象",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入標題",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇排程時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入簡述",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入內容",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請指定教會",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請至少選擇 <b class=\"ft-24 text-danger\">1</b> 位會友進行推播",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            event: {
                v2: {
                    create: {
                        membership: {
                            team: {
                                field: {
                                    keyword: {
                                        subject: "關鍵字",
                                        holder: "請輸入關鍵字",
                                        text: "同時搜尋名字/郵件/會友編號"
                                    },
                                    team: {
                                        subject: "服事群組",
                                        holder: "請選擇服事群組"
                                    }
                                },
                                help: {
                                    team: {
                                        empty: {
                                            subject: "請先建立服事群組"
                                        }
                                    }
                                }
                            },
                            editor: {
                                subject: "選擇會友參與服事",
                                submit: {
                                    maximum: {
                                        subject: "最多只能選擇%s位會友參與服事"
                                    },
                                    minimum: {
                                        subject: "請選擇至少%s位會友參與服事"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "關鍵字",
                                    holder: "請輸入關鍵字",
                                    text: "同時搜尋名字/郵件/會友編號"
                                },
                                team: {
                                    subject: "參與群組",
                                    holder: "請選擇參與群組"
                                }
                            },
                            empty: {
                                subject: "請選擇會友參與服事"
                            },
                            not: {
                                found: {
                                    subject: "搜尋不到指定的參與服事會友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            },
                            2: {
                                subject: "提醒"
                            },
                            3: {
                                subject: "循環"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將儲存內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "編輯名單"
                            },
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "請選擇開始時間",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "請選擇結束時間",
                                text: ""
                            },
                            1031: {
                                subject: "結束時間必須大於開始時間",
                                text: ""
                            },
                            1040: {
                                subject: "請輸入說明",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇狀態",
                                text: ""
                            },
                            2010: {
                                subject: "請至少選擇1位會友參與服事",
                                text: ""
                            },
                            3010: {
                                subject: "請完善提醒的參數設定",
                                text: ""
                            },
                            4010: {
                                subject: "請完善循環的參數設定",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        membership: {
                            team: {
                                field: {
                                    keyword: {
                                        subject: "關鍵字",
                                        holder: "請輸入關鍵字",
                                        text: "同時搜尋名字/郵件/會友編號"
                                    },
                                    team: {
                                        subject: "服事群組",
                                        holder: "請選擇服事群組"
                                    }
                                },
                                help: {
                                    team: {
                                        empty: {
                                            subject: "請先建立服事群組"
                                        }
                                    }
                                }
                            },
                            editor: {
                                subject: "選擇會友參與服事",
                                submit: {
                                    maximum: {
                                        subject: "最多只能選擇%s位會友參與服事"
                                    },
                                    minimum: {
                                        subject: "請選擇至少%s位會友參與服事"
                                    }
                                }
                            },
                            field: {
                                keyword: {
                                    subject: "關鍵字",
                                    holder: "請輸入關鍵字",
                                    text: "同時搜尋名字/郵件/會友編號"
                                },
                                team: {
                                    subject: "參與群組",
                                    holder: "請選擇參與群組"
                                }
                            },
                            empty: {
                                subject: "請選擇會友參與服事"
                            },
                            not: {
                                found: {
                                    subject: "搜尋不到指定的參與服事會友"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            },
                            2: {
                                subject: "提醒"
                            },
                            3: {
                                subject: "循環"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前服事處於僅讀取狀態無法更新"
                            },
                            disable: {
                                subject: "目前服事處於停用狀態"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將更新內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            membership: {
                                subject: "編輯名單"
                            },
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "請選擇開始時間",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "請選擇結束時間",
                                text: ""
                            },
                            1031: {
                                subject: "結束時間必須大於開始時間",
                                text: ""
                            },
                            1040: {
                                subject: "請輸入說明",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇狀態",
                                text: ""
                            },
                            2010: {
                                subject: "請至少選擇1位會友參與服事",
                                text: ""
                            },
                            3010: {
                                subject: "請完善提醒的參數設定",
                                text: ""
                            },
                            4010: {
                                subject: "請完善循環的參數設定",
                                text: ""
                            }
                        }
                    },
                    field: {
                        subject: {
                            subject: "名稱"
                        },
                        summary: {
                            partake: {
                                subject: "參與人數"
                            }
                        },
                        status: {
                            subject: "狀態",
                            value: {
                                0: {
                                    subject: "已停用"
                                },
                                1: {
                                    subject: "啟用中"
                                }
                            }
                        },
                        begin: {
                            subject: "聚會開始時間"
                        },
                        created: {
                            subject: "建立時間"
                        }
                    },
                    search: {
                        subject: {
                            subject: "名稱",
                            holder: "請輸入名稱"
                        },
                        status: {
                            subject: "狀態",
                            holder: "請選擇狀態"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用服事",
                            text: "請確認後執行"
                        },
                        disable: {
                            subject: "即將停用服事",
                            text: "請確認後執行"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除服事, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                team: {
                    index: {
                        tip: {
                            readonly: "總教會僅供查看資料, 無法新增修改"
                        },
                        feature: {
                            create: {
                                subject: "新增群組"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            name: "名稱",
                            quantity: "人數"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            enable: {
                                subject: "即將啟用群組",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將停用群組",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將刪除群組, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    create: {
                        tip: {
                            readonly: "總教會僅供查看資料, 無法新增修改"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            }
                        },
                        field: {
                            name: "名稱",
                            keyword: "搜尋名單"
                        },
                        holder: {
                            name: "請輸入名稱",
                            keyword: "搜尋範圍: 名字/郵件/會友編號"
                        },
                        handler: {
                            store: {
                                subject: "即將新增群組",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            2001: {
                                subject: "請至少選擇1位人員參與服事",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            readonly: "總教會僅供查看資料, 無法新增修改",
                            disabled: "請注意, 這個群組已經停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            },
                            2: {
                                subject: "統計資訊"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        summary: {
                            history: {
                                subject: "歷史紀錄"
                            }
                        },
                        field: {
                            belong: {
                                no: "所屬教會ID",
                                name: "所屬教會名稱"
                            },
                            name: "名稱",
                            keyword: "搜尋名單"
                        },
                        holder: {
                            head: "未輸入",
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "請輸入名稱",
                            keyword: "搜尋範圍: 名字/郵件/會友編號"
                        },
                        handler: {
                            update: {
                                subject: "即將更新群組內容",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            2001: {
                                subject: "請至少選擇1位人員參與服事",
                                text: "",
                                description: ""
                            }
                        },
                        history: {
                            advance: {
                                week: "周內新增",
                                month: "月內新增",
                                leave: "離開人數"
                            },
                            description: {
                                created: "群組建立",
                                partake: "加入群組",
                                leave: "從群組中離開"
                            },
                            field: {
                                begin: "開始時間(起)",
                                end: "結束時間(迄)",
                                description: "描述",
                                created: "時間"
                            },
                            holder: {
                                begin: "請選擇開始時間(起)",
                                end: "請選擇結束時間(迄)"
                            }
                        }
                    }
                },
                index: {
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改"
                    },
                    feature: {
                        create: {
                            subject: "新增服事"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        subject: "名稱",
                        start: "開始時間",
                        begin: {
                            begin: "開始日期(起)",
                            end: "開始日期(迄)"
                        },
                        end: {
                            begin: "結束日期(起)",
                            end: "結束日期(迄)"
                        },
                        create: {
                            begin: "建立日期(起)",
                            end: "建立日期(迄)"
                        },
                        quantity: "參與人數"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        subject: "請輸入名稱",
                        begin: {
                            begin: "請選擇開始日期(起)",
                            end: "請選擇開始日期(迄)"
                        },
                        end: {
                            begin: "請選擇結束日期(起)",
                            end: "請選擇結束日期(迄)"
                        },
                        create: {
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用服事",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用服事",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除服事, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名單"
                        },
                        2: {
                            subject: "提醒"
                        }
                    },
                    field: {
                        subject: "標題",
                        begin: "開始時間",
                        end: "結束時間",
                        text: "說明",
                        location: "地點",
                        note: "備註",
                        description: "其他資訊",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        subject: "請輸入標題",
                        begin: "請選擇開始時間",
                        end: "請選擇結束時間",
                        text: "請輸入說明",
                        location: "請輸入地點",
                        note: "請輸入備註",
                        description: "請輸入其他資訊",
                        keyword: "搜尋範圍: 名字/郵件/會友編號"
                    },
                    handler: {
                        store: {
                            subject: "即將新增服事",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入說明",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請至少選擇1位人員參與服事",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改",
                        disabled: "請注意, 這個服事已經停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "名單"
                        },
                        2: {
                            subject: "提醒"
                        },
                        98: {
                            subject: "循環資訊"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    detail: {
                        cycle: {
                            subject: "循環代號"
                        },
                        remind: {
                            subject: "提醒代號"
                        }
                    },
                    field: {
                        belong: {
                            no: "所屬教會ID",
                            name: "所屬教會名稱"
                        },
                        subject: "標題",
                        begin: "開始時間",
                        end: "結束時間",
                        text: "說明",
                        location: "地點",
                        note: "備註",
                        description: "其他資訊",
                        keyword: "搜尋名單"
                    },
                    holder: {
                        head: "未輸入",
                        belong: {
                            no: "",
                            name: ""
                        },
                        subject: "請輸入標題",
                        begin: "請選擇開始時間",
                        end: "請選擇結束時間",
                        text: "請輸入說明",
                        location: "請輸入地點",
                        note: "請輸入備註",
                        description: "請輸入其他資訊",
                        keyword: "搜尋範圍: 名字/郵件/會友編號"
                    },
                    handler: {
                        update: {
                            subject: "即將更新服事內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入說明",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        2001: {
                            subject: "請至少選擇1位人員參與服事",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            donate: {
                index: {
                    download: {
                        name: "奉獻匯出_會友管理"
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "奉獻日期(起)",
                        end: "奉獻日期(迄)",
                        avatar: "頭像",
                        name: "名字",
                        order: "訂單號",
                        amount: "奉獻金額"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        begin: "請選擇奉獻日期(起)",
                        end: "請選擇奉獻日期(迄)",
                        name: "請輸入名字",
                        order: "請輸入訂單號"
                    },
                    factory: {
                        export: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行奉獻匯出, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "回應"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        avatar: "頭像",
                        created: "建立時間",
                        payed: "付款時間",
                        order: "訂單號",
                        id: "會友ID",
                        no: "會友編號",
                        name: "會友姓名",
                        account: "會友帳號",
                        amount: "奉獻金額",
                        phone: "聯絡電話",
                        payload: "結果",
                        status: "金流狀態"
                    },
                    holder: {
                        phone: "未填寫聯絡電話"
                    }
                }
            },
            teach: {
                index: {
                    feature: {
                        create: {
                            subject: "新增教學"
                        }
                    },
                    field: {
                        avatar: "封面圖",
                        name: "名稱",
                        sort: "排序",
                        created: "建立時間"
                    },
                    holder: {
                        name: "請輸入名字"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用教學",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用教學",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除教學, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        }
                    },
                    field: {
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        name: "名稱"
                    },
                    holder: {
                        name: "請輸入名稱"
                    },
                    handler: {
                        store: {
                            subject: "即將新增分類",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        disabled: "請注意, 這個教學已經停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "排序"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    field: {
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        name: "名稱"
                    },
                    holder: {
                        name: "請輸入名稱"
                    },
                    handler: {
                        update: {
                            subject: "即將更新分類內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            course: {
                access: {
                    index: {
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "頭像",
                            name: "名字",
                            role: "來源"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)",
                            name: "請輸入名字",
                            empty: {
                                email: "未輸入"
                            }
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        tip: {
                            unknown: "此紀錄為未知來源"
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            avatar: "頭像",
                            seq: "流水號",
                            name: "名字",
                            account: "帳號",
                            role: "來源",
                            method: "存取方式",
                            address: "遠端位置",
                            uri: "存取路徑",
                            created: "建立時間"
                        },
                        holder: {},
                        handler: {}
                    }
                },
                authorize: {
                    index: {
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "頭像",
                            name: "名字",
                            role: "來源",
                            method: "方式",
                            result: "結果"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)",
                            name: "請輸入名字",
                            method: "請輸入方式",
                            result: "請選擇結果",
                            empty: {
                                email: "未輸入"
                            }
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        tip: {
                            unknown: "此紀錄為未知來源"
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            avatar: "頭像",
                            seq: "流水號",
                            name: "名字",
                            account: "帳號",
                            role: "來源",
                            method: "方式",
                            result: "結果",
                            address: "遠端位置",
                            created: "建立時間"
                        },
                        holder: {},
                        handler: {}
                    }
                },
                exception: {
                    index: {
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "頭像",
                            name: "名字",
                            role: "來源",
                            level: "等級",
                            code: "結果代碼",
                            exception: "攔截",
                            module: "模組"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)",
                            name: "請輸入名字",
                            empty: {
                                email: "未輸入"
                            },
                            module: "請選擇模組"
                        },
                        handler: {}
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        tip: {
                            unknown: "此紀錄為未知來源"
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            avatar: "頭像",
                            seq: "流水號",
                            name: "名字",
                            account: "帳號",
                            role: "來源",
                            level: "等級",
                            ref: {
                                name: "攔截呼叫",
                                church: "標的教會",
                                seq: "標的物件流水號"
                            },
                            module: "攔截類",
                            method: "攔截函式",
                            code: "結果代碼",
                            rollback: "回滾事務",
                            subject: "標題",
                            text: "副標題",
                            description: "描述",
                            payload: "提交參數",
                            created: "建立時間"
                        },
                        holder: {
                            subject: "無標題",
                            text: "無副標題",
                            description: "無描述"
                        },
                        handler: {}
                    }
                }
            },
            support: {
                v2: {
                    worker: {
                        create: {
                            tab: {
                                0: {
                                    subject: "基本"
                                },
                                1: {
                                    subject: "帳號"
                                },
                                2: {
                                    subject: "附件"
                                }
                            },
                            field: {
                                sample: {
                                    subject: "樣本ID",
                                    holder: "請輸入樣本ID"
                                },
                                subject: {
                                    subject: "標題",
                                    holder: "請輸入標題"
                                },
                                description: {
                                    subject: "描述",
                                    holder: "請輸入描述"
                                },
                                role: {
                                    subject: "角色",
                                    holder: "請選擇角色"
                                },
                                type: {
                                    subject: "類型",
                                    holder: "請選擇類型"
                                }
                            },
                            handler: {
                                submit: {
                                    subject: "即將新增工單",
                                    text: "請確認後執行"
                                }
                            },
                            error: {
                                1010: {
                                    subject: "請選擇角色",
                                    text: ""
                                },
                                1020: {
                                    subject: "請輸入樣本ID",
                                    text: ""
                                },
                                1021: {
                                    subject: "樣本ID請輸入數字",
                                    text: ""
                                },
                                1030: {
                                    subject: "請選擇類型",
                                    text: ""
                                },
                                1040: {
                                    subject: "請輸入標題",
                                    text: ""
                                },
                                1050: {
                                    subject: "請輸入描述",
                                    text: ""
                                }
                            }
                        },
                        edit: {
                            history: {
                                create: {
                                    tab: {
                                        0: {
                                            subject: "基本"
                                        },
                                        1: {
                                            subject: "附件"
                                        }
                                    },
                                    field: {
                                        subject: {
                                            subject: "標題",
                                            holder: "請輸入標題"
                                        },
                                        description: {
                                            subject: "描述",
                                            holder: "請輸入描述"
                                        },
                                        release: {
                                            subject: "發佈App",
                                            holder: "請選擇發佈App"
                                        },
                                        status: {
                                            subject: "狀態",
                                            holder: "請選擇狀態"
                                        }
                                    },
                                    handler: {
                                        submit: {
                                            subject: "即將新增紀錄",
                                            text: "請確認後執行"
                                        }
                                    },
                                    error: {
                                        1010: {
                                            subject: "請選擇狀態",
                                            text: ""
                                        },
                                        1020: {
                                            subject: "請輸入標題",
                                            text: ""
                                        },
                                        1030: {
                                            subject: "請輸入描述",
                                            text: ""
                                        },
                                        1040: {
                                            subject: "請選擇發佈App",
                                            text: ""
                                        }
                                    }
                                },
                                edit: {
                                    tab: {
                                        0: {
                                            subject: "基本"
                                        },
                                        1: {
                                            subject: "附件"
                                        },
                                        99: {
                                            subject: "相關資訊"
                                        }
                                    },
                                    field: {
                                        subject: {
                                            subject: "標題",
                                            holder: "請輸入標題"
                                        },
                                        description: {
                                            subject: "描述",
                                            holder: "請輸入描述"
                                        },
                                        release: {
                                            subject: "發佈App",
                                            holder: "請選擇發佈App"
                                        },
                                        status: {
                                            subject: "狀態",
                                            holder: "請選擇狀態"
                                        }
                                    },
                                    handler: {
                                        submit: {
                                            subject: "即將更新紀錄",
                                            text: "請確認後執行"
                                        }
                                    },
                                    error: {
                                        1010: {
                                            subject: "請選擇狀態",
                                            text: ""
                                        },
                                        1020: {
                                            subject: "請輸入標題",
                                            text: ""
                                        },
                                        1030: {
                                            subject: "請輸入描述",
                                            text: ""
                                        },
                                        1040: {
                                            subject: "請選擇發佈App",
                                            text: ""
                                        }
                                    }
                                },
                                field: {
                                    subject: {
                                        subject: "標題",
                                        holder: "請輸入標題"
                                    },
                                    description: {
                                        subject: "描述",
                                        holder: "請輸入描述"
                                    },
                                    keyword: {
                                        subject: "關鍵字",
                                        holder: "請輸入關鍵字",
                                        help: "同時搜尋標題與描述"
                                    },
                                    begin: {
                                        subject: "開始時間(起)",
                                        holder: "請選擇開始時間"
                                    },
                                    end: {
                                        subject: "結束時間(迄)",
                                        holder: "請選擇結束時間"
                                    },
                                    invalid: {
                                        subject: "作廢",
                                        holder: "請選擇作廢"
                                    },
                                    creator: {
                                        subject: "建立人",
                                        holder: "請選擇建立人"
                                    },
                                    editor: {
                                        subject: "上一次編輯人",
                                        holder: "請選擇上一次編輯人"
                                    },
                                    status: {
                                        subject: "狀態",
                                        holder: "請選擇狀態"
                                    }
                                },
                                alert: {
                                    readonly: {
                                        subject: "工單已經標示完成無法進行修改"
                                    }
                                },
                                handler: {
                                    destroy: {
                                        subject: "安全性二級驗證",
                                        text: "<span class=\"cgRed\">即將作廢紀錄, 請確認後執行</span>",
                                        description: "請輸入您目前的登入密碼"
                                    }
                                }
                            },
                            tab: {
                                0: {
                                    subject: "基本"
                                },
                                1: {
                                    subject: "帳號"
                                },
                                2: {
                                    subject: "附件"
                                },
                                98: {
                                    subject: "統計資訊"
                                },
                                99: {
                                    subject: "相關資訊"
                                }
                            },
                            action: {
                                history: {
                                    create: {
                                        subject: "新增紀錄"
                                    }
                                }
                            },
                            field: {
                                no: {
                                    subject: "單號",
                                    holder: ""
                                },
                                sample: {
                                    subject: "樣本ID",
                                    holder: "請輸入樣本ID"
                                },
                                subject: {
                                    subject: "標題",
                                    holder: "請輸入標題"
                                },
                                description: {
                                    subject: "描述",
                                    holder: "請輸入描述"
                                },
                                role: {
                                    subject: "角色",
                                    holder: "請選擇角色"
                                },
                                type: {
                                    subject: "類型",
                                    holder: "請選擇類型"
                                }
                            },
                            statistics: {
                                history: {
                                    subject: "歷史紀錄"
                                },
                                record: {
                                    subject: "異動紀錄"
                                }
                            },
                            alert: {
                                readonly: {
                                    subject: "工單已經標示完成無法進行修改"
                                }
                            },
                            handler: {
                                submit: {
                                    subject: "即將更新工單",
                                    text: "請確認後執行"
                                }
                            },
                            error: {
                                1010: {
                                    subject: "請選擇角色",
                                    text: ""
                                },
                                1020: {
                                    subject: "請輸入樣本ID",
                                    text: ""
                                },
                                1021: {
                                    subject: "樣本ID請輸入數字",
                                    text: ""
                                },
                                1030: {
                                    subject: "請選擇類型",
                                    text: ""
                                },
                                1040: {
                                    subject: "請輸入標題",
                                    text: ""
                                },
                                1050: {
                                    subject: "請輸入描述",
                                    text: ""
                                }
                            }
                        },
                        field: {
                            role: {
                                subject: "角色"
                            },
                            type: {
                                subject: "類型"
                            },
                            no: {
                                subject: "單號"
                            },
                            subject: {
                                subject: "標題"
                            },
                            summary: {
                                account: {
                                    subject: "異常帳號"
                                },
                                attachment: {
                                    subject: "附件"
                                },
                                history: {
                                    subject: "歷史紀錄"
                                }
                            },
                            expire: {
                                subject: "超時"
                            },
                            back: {
                                subject: "返工"
                            },
                            status: {
                                subject: "狀態"
                            },
                            created: {
                                subject: "建立時間"
                            }
                        },
                        search: {
                            role: {
                                subject: "角色",
                                holder: "請輸入角色"
                            },
                            type: {
                                subject: "類型",
                                holder: "請輸入類型"
                            },
                            no: {
                                subject: "單號",
                                holder: "請輸入單號"
                            },
                            subject: {
                                subject: "標題",
                                holder: "請輸入標題"
                            },
                            description: {
                                subject: "描述",
                                holder: "請輸入描述"
                            },
                            keyword: {
                                subject: "關鍵字",
                                holder: "請輸入關鍵字",
                                help: "同時搜尋標題與描述"
                            },
                            begin: {
                                subject: "開始時間(起)",
                                holder: "請選擇開始時間"
                            },
                            end: {
                                subject: "結束時間(迄)",
                                holder: "請選擇結束時間"
                            },
                            creator: {
                                subject: "建立人",
                                holder: "請選擇建立人"
                            },
                            editor: {
                                subject: "上一次編輯人",
                                holder: "請選擇上一次編輯人"
                            },
                            expire: {
                                subject: "超時",
                                holder: "請選擇超時"
                            },
                            back: {
                                subject: "返工",
                                holder: "請選擇返工"
                            },
                            status: {
                                subject: "狀態",
                                holder: "請選擇狀態"
                            }
                        }
                    }
                },
                feedback: {
                    index: {
                        text: {
                            group: "管理團隊"
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            avatar: "頭像",
                            name: "名字",
                            content: "內容",
                            level: "層級"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            name: "請輸入名字",
                            level: "請選擇層級"
                        },
                        handler: {
                            raise: {
                                subject: "即將反饋至愛無界管理團隊",
                                text: "請認後執行",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            unread: "請注意, 這個反饋還未標示已讀取"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        text: {
                            group: "管理團隊"
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            avatar: "頭像",
                            level: "層級",
                            id: "會友ID",
                            no: "會友編號",
                            name: "會友姓名",
                            account: "會友帳號",
                            content: "反饋內容",
                            created: "建立時間",
                            status: "狀態"
                        },
                        holder: {},
                        handler: {
                            update: {
                                subject: "即將更新反饋內容",
                                text: "請確認後執行",
                                description: "",
                                raise: {
                                    subject: "即將更新反饋內容",
                                    text: "<b class=\"ft-20 text-danger\">即將反饋至愛無界管理團隊, 請認後執行</b>",
                                    description: ""
                                }
                            }
                        },
                        error: {
                            1001: {
                                subject: "請選擇層級",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                coffee: {
                    index: {
                        download: {
                            name: "請我們喝咖啡匯出_客服管理"
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            begin: "贊助日期(起)",
                            end: "贊助日期(迄)",
                            avatar: "頭像",
                            name: "名字",
                            order: "訂單號",
                            amount: "贊助金額"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            begin: "請選擇贊助日期(起)",
                            end: "請選擇贊助日期(迄)",
                            name: "請輸入名字",
                            order: "請輸入訂單號"
                        },
                        factory: {
                            export: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將進行請我們喝咖啡匯出, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    edit: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "回應"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            avatar: "頭像",
                            created: "建立時間",
                            payed: "付款時間",
                            order: "訂單號",
                            id: "會友ID",
                            no: "會友編號",
                            name: "會友姓名",
                            account: "會友帳號",
                            amount: "贊助金額",
                            phone: "聯絡電話",
                            payload: "結果",
                            status: "金流狀態"
                        },
                        holder: {
                            phone: "未填寫聯絡電話"
                        }
                    }
                },
                history: {
                    index: {
                        tabs: [
                            {
                                subject: "查詢"
                            }
                        ],
                        help: {
                            query: "請輸入完整的電子郵件",
                            disabled: "會友已經停用",
                            destroy: "會友已於 <b>%s</b> 自行刪除",
                            church: {
                                disabled: "教會已經停用",
                                destroy: "教會已經刪除",
                                organization: {
                                    disabled: "機構已經停用",
                                    destroy: "機構已經刪除"
                                }
                            }
                        },
                        notification: {
                            status: {
                                waiting: "排程中",
                                progress: "發送中",
                                complete: "已送達"
                            }
                        },
                        result: {
                            success: "查詢到 <b class=\"ft-18 text-success\">%s</b> 筆紀錄",
                            empty: "查詢不到任何結果"
                        },
                        field: {
                            notification: {
                                subject: "最後 <b class=\"text-success ft-18\">10</b> 筆推播通知",
                                text: "(依照預定推播時間降冪排序)",
                                empty: "沒有推播通知紀錄",
                                field: {
                                    id: "推播ID",
                                    schedule: "排定發送時間",
                                    delivery: "送達時間"
                                }
                            },
                            device: {
                                supplier: "裝置支援商",
                                brand: "裝置製造商",
                                model: "裝置型號",
                                os: "裝置操作系統",
                                version: "裝置App版本",
                                token: {
                                    subject: "登入權杖",
                                    text: "(sys_token.token)",
                                    push: {
                                        subject: "推播識別碼",
                                        text: "(core_admin_client_notification.key)"
                                    }
                                }
                            },
                            belong: {
                                organization: {
                                    id: "所屬機構ID",
                                    name: "所屬機構名稱"
                                },
                                id: "所屬教會ID",
                                no: "所屬教會編號",
                                name: "所屬教會名稱"
                            },
                            id: "會友ID",
                            no: "會友編號",
                            name: "會友姓名",
                            account: "會友帳號",
                            email: "電子郵件",
                            avatar: "頭像",
                            status: "狀態",
                            created: "建立時間",
                            updated: "更新時間"
                        },
                        holder: {
                            email: "請輸入完整的電子郵件進行查詢"
                        },
                        error: {
                            1001: {
                                subject: "請輸入電子郵件",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "電子郵件格式錯誤",
                                text: "請輸入正確的電子郵件格式",
                                description: ""
                            }
                        }
                    }
                }
            },
            live: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分類"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            name: "名稱",
                            quantity: "直播數量",
                            sort: "排序",
                            created: "建立時間"
                        },
                        holder: {
                            head: {
                                church: "請選擇教會"
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            enable: {
                                subject: "即將啟用分類",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將停用分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將刪除分類, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "請謹慎使用指定教會功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教會",
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            church: "請選擇指定教會",
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            store: {
                                subject: "即將新增分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇指定教會",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "請注意, 這個分類已經停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            belong: {
                                no: "所屬教會編號",
                                name: "所屬教會名稱"
                            },
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            update: {
                                subject: "即將更新分類內容",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增直播"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        subject: "標題",
                        avatar: "封面圖",
                        quantity: "直播數量",
                        category: "分類",
                        integrate: "文章整合",
                        sort: "排序",
                        created: "建立時間"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        begin: "請選擇建立日期(起)",
                        end: "請選擇建立日期(迄)",
                        integrate: "請選擇文章整合",
                        subject: "請輸入名稱",
                        category: "請選擇分類"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用直播",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用直播",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除直播, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    help: {
                        church: "請謹慎使用指定教會功能"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "文章"
                        },
                        2: {
                            subject: "圖片"
                        },
                        3: {
                            subject: "分享"
                        }
                    },
                    field: {
                        kind: "類型",
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        church: "指定教會",
                        category: "分類",
                        subject: "標題",
                        hyperlink: "YouTube網址",
                        activity: {
                            on: "開始時間",
                            off: "結束時間"
                        },
                        tab: {
                            lobby: "標籤 - 大廳",
                            prayer: "標籤 - 代禱",
                            about: "標籤 - 關於"
                        },
                        welcome: {
                            lobby: "歡迎詞 - 大廳",
                            prayer: "歡迎詞 - 代禱"
                        },
                        reply: {
                            prayer: "回應詞 - 代禱"
                        },
                        collection: "文章圖片",
                        description: "描述",
                        status: "狀態"
                    },
                    holder: {
                        kind: "請選擇類型",
                        church: "請選擇指定教會",
                        category: "請選擇分類",
                        subject: "請輸入名稱",
                        hyperlink: "請輸入YouTube網址",
                        activity: {
                            on: "請輸入開始時間",
                            off: "請輸入結束時間"
                        },
                        tab: {
                            lobby: "請輸入標籤(大廳)",
                            prayer: "請輸入標籤(代禱)",
                            about: "請輸入標籤(關於)"
                        },
                        welcome: {
                            lobby: "請輸入歡迎詞(大廳)",
                            prayer: "請輸入歡迎詞(代禱)"
                        },
                        reply: {
                            prayer: "請輸入回應詞(代禱)"
                        },
                        collection: "",
                        description: "請輸入描述",
                        status: "請選擇狀態"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即將新增直播",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇類型",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入標籤(大廳)",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入標籤(代禱)",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請輸入標籤(關於)",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入歡迎詞(大廳)",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請輸入歡迎詞(代禱)",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請輸入回應詞(代禱)",
                            text: "",
                            description: ""
                        },
                        1014: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1015: {
                            subject: "請輸入描述",
                            text: "",
                            description: ""
                        },
                        1016: {
                            subject: "請選擇指定教會",
                            text: "",
                            description: ""
                        },
                        1017: {
                            subject: "請輸入YouTube連結",
                            text: "",
                            description: ""
                        },
                        4001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    comment: {
                        subject: "聊天室發言內容",
                        th: {
                            0: "頭像",
                            1: "姓名",
                            2: "內容",
                            3: "時間"
                        },
                        passenger: {
                            subject: "訪客"
                        }
                    },
                    secure: {
                        subject: "私密代禱內容",
                        th: {
                            0: "頭像",
                            1: "姓名",
                            2: "內容",
                            3: "時間"
                        },
                        passenger: {
                            subject: "訪客"
                        },
                        handler: {
                            secure: {
                                subject: "您即將查看會友私密個人資訊",
                                text: "我將妥善與謹慎使用相關資訊"
                            }
                        }
                    },
                    tip: {
                        disabled: "請注意, 這個直播已經停用"
                    },
                    feature: {
                        0: "複製直播網址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "文章"
                        },
                        2: {
                            subject: "圖片"
                        },
                        3: {
                            subject: "分享"
                        },
                        4: {
                            subject: "統計資訊"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        comment: {
                            subject: "聊天室發言"
                        },
                        secure: {
                            subject: "私密代禱"
                        }
                    },
                    field: {
                        belong: {
                            no: "所屬教會編號",
                            name: "所屬教會名稱"
                        },
                        kind: "類型",
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        category: "分類",
                        subject: "標題",
                        hyperlink: "YouTube網址",
                        activity: {
                            on: "開始時間",
                            off: "結束時間"
                        },
                        tab: {
                            lobby: "標籤 - 大廳",
                            prayer: "標籤 - 代禱",
                            about: "標籤 - 關於"
                        },
                        welcome: {
                            lobby: "歡迎詞 - 大廳",
                            prayer: "歡迎詞 - 代禱"
                        },
                        reply: {
                            prayer: "回應詞 - 代禱"
                        },
                        collection: "文章圖片",
                        description: "描述",
                        status: "狀態"
                    },
                    holder: {
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "請選擇類型",
                        category: "請選擇分類",
                        subject: "請輸入名稱",
                        hyperlink: "請輸入YouTube網址",
                        activity: {
                            on: "請輸入開始時間",
                            off: "請輸入結束時間"
                        },
                        tab: {
                            lobby: "請輸入標籤(大廳)",
                            prayer: "請輸入標籤(代禱)",
                            about: "請輸入標籤(關於)"
                        },
                        welcome: {
                            lobby: "請輸入歡迎詞(大廳)",
                            prayer: "請輸入歡迎詞(代禱)"
                        },
                        reply: {
                            prayer: "請輸入回應詞(代禱)"
                        },
                        collection: "",
                        description: "請輸入描述",
                        status: "請選擇狀態"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即將更新直播內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇類型",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請輸入標籤(大廳)",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請輸入標籤(代禱)",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請輸入標籤(關於)",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請輸入歡迎詞(大廳)",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入歡迎詞(代禱)",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請輸入回應詞(代禱)",
                            text: "",
                            description: ""
                        },
                        1010: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1011: {
                            subject: "請輸入描述",
                            text: "",
                            description: ""
                        },
                        1012: {
                            subject: "請選擇指定教會",
                            text: "",
                            description: ""
                        },
                        1013: {
                            subject: "請輸入YouTube連結",
                            text: "",
                            description: ""
                        },
                        4001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            activity: {
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增分類"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            name: "名稱",
                            quantity: "活動數量",
                            sort: "排序",
                            created: "建立時間"
                        },
                        holder: {
                            head: {
                                church: "請選擇教會"
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            enable: {
                                subject: "即將啟用分類",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將停用分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將刪除分類, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "請謹慎使用指定教會功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教會",
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            church: "請選擇指定教會",
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            store: {
                                subject: "即將新增分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇指定教會",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            disabled: "請注意, 這個分類已經停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            belong: {
                                no: "所屬教會編號",
                                name: "所屬教會名稱"
                            },
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            update: {
                                subject: "即將更新分類內容",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增活動"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        begin: "建立日期(起)",
                        end: "建立日期(迄)",
                        subject: "標題",
                        avatar: "封面圖",
                        quantity: "活動數量",
                        category: "分類",
                        integrate: "文章整合",
                        registered: {
                            subject: "已報名",
                            text: "含未付費"
                        },
                        sort: "排序",
                        created: "建立時間"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        begin: "請選擇建立日期(起)",
                        end: "請選擇建立日期(迄)",
                        integrate: "請選擇文章整合",
                        subject: "請輸入名稱",
                        category: "請選擇分類"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用活動",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用活動",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除活動, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tip: {
                        signature: "活動建立人員將會自動擁有本活動App簽到功能, 可不指定其他協助簽到人員"
                    },
                    help: {
                        church: "請謹慎使用指定教會功能",
                        discount: {
                            enable: "已經開啟報名優惠功能, 無法修改日期"
                        }
                    },
                    field: {
                        keyword: "搜尋名單",
                        kind: "類型",
                        subject: "標題",
                        activity: {
                            on: "活動開始時間",
                            off: "活動結束時間"
                        },
                        respond: {
                            avatar: {
                                subject: "封面圖",
                                text: "(尺寸為 %sx%s 像素)"
                            },
                            description: "描述"
                        },
                        collection: "文章圖片",
                        description: "內容",
                        status: "狀態"
                    },
                    holder: {
                        keyword: "搜尋範圍: 名字/郵件/會友編號",
                        kind: "請選擇類型",
                        church: "請選擇指定教會",
                        category: "請選擇分類",
                        subject: "請輸入名稱",
                        activity: {
                            on: "請輸入活動開始時間",
                            off: "請輸入活動結束時間"
                        },
                        respond: {
                            description: "請輸入內容"
                        },
                        collection: "",
                        description: "請輸入描述",
                        status: "請選擇狀態"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        store: {
                            subject: "即將新增活動",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入描述",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請選擇指定教會",
                            text: "",
                            description: ""
                        },
                        4002: {
                            subject: "請輸入回條描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    register: {
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "團報"
                            },
                            2: {
                                subject: "表單"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        tip: {
                            organization: {
                                subject: "團體報名人數最少 <b class=\"ft-16\">%s</b> 位, 最多 <b class=\"ft-16\">%s</b> 位",
                                left: {
                                    subject: ", 還需要 <b class=\"ft-16\">%s</b> 位報名"
                                },
                                satisfy: {
                                    minimum: {
                                        subject: ", 最多還可以報名 <b class=\"ft-16\">%s</b> 位"
                                    },
                                    negative: {
                                        subject: ", 已經達到活動報名人數上限"
                                    },
                                    maximum: {
                                        subject: ", 已達到團報最高人數限制"
                                    }
                                }
                            },
                            summary: {
                                subject: "報名總覽"
                            }
                        },
                        form: {
                            empty: "請先增加團體報名人員",
                            rank: "合計 <b class=\"ft-16 text-success\">%s</b> 位報名",
                            th: {
                                0: "#",
                                1: "姓名",
                                2: "電子郵件",
                                3: "操作"
                            },
                            field: {
                                name: "姓名",
                                email: "電子郵件"
                            }
                        },
                        discount: {
                            original: "原價",
                            before: "%s 之前",
                            between: "%s 至 %s"
                        },
                        canvas: {
                            create: {
                                subject: "新增團報人員"
                            },
                            edit: {
                                subject: "編輯團報人員"
                            }
                        },
                        field: {
                            method: "報名方式",
                            discount: "費用",
                            echelon: "活動場次",
                            payment: "支付狀態",
                            organization: {
                                name: "教會(機構)名稱",
                                city: "教會(機構)所在城市",
                                contact: {
                                    name: "聯絡人姓名",
                                    phone: "聯絡人電話",
                                    email: "聯絡人電子郵件"
                                },
                                zip: "郵遞區號",
                                address: "通訊地址"
                            }
                        },
                        holder: {
                            organization: {
                                name: "請輸入教會(機構)名稱",
                                city: "請輸入教會(機構)所在城市",
                                contact: {
                                    name: "請輸入聯絡人姓名",
                                    phone: "請輸入聯絡人電話",
                                    email: "請輸入聯絡人電子郵件"
                                },
                                zip: "請輸入郵遞區號",
                                address: "請輸入通訊地址"
                            }
                        },
                        handler: {
                            store: {
                                subject: "即將儲存後台報名",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請選擇報名方式",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "異常發生",
                                text: "指定的報名方式不存在",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇活動場次",
                                text: "",
                                description: ""
                            },
                            1004: {
                                subject: "異常發生",
                                text: "指定的活動場次不存在",
                                description: ""
                            },
                            1005: {
                                subject: "異常發生",
                                text: "指定的價格不存在",
                                description: ""
                            },
                            1006: {
                                subject: "請選擇支付狀態",
                                text: "",
                                description: ""
                            },
                            1007: {
                                subject: "異常發生",
                                text: "指定的付款狀態不存在",
                                description: ""
                            },
                            1998: {
                                subject: "總教會不能進行後台報名",
                                text: "即將返回報名內容頁面",
                                description: ""
                            },
                            1999: {
                                subject: "活動報名已經額滿",
                                text: "即將返回報名內容頁面",
                                description: ""
                            },
                            2001: {
                                subject: "請輸入教會(機構)名稱",
                                text: "",
                                description: ""
                            },
                            2002: {
                                subject: "請輸入教會(機構)所在城市",
                                text: "",
                                description: ""
                            },
                            2003: {
                                subject: "請輸入聯絡人姓名",
                                text: "",
                                description: ""
                            },
                            2004: {
                                subject: "請輸入聯絡人電話",
                                text: "",
                                description: ""
                            },
                            2005: {
                                subject: "請輸入聯絡人電子郵件",
                                text: "",
                                description: ""
                            },
                            2006: {
                                subject: "聯絡人電子郵件格式錯誤",
                                text: "",
                                description: ""
                            },
                            2007: {
                                subject: "請輸入郵遞區號",
                                text: "",
                                description: ""
                            },
                            2008: {
                                subject: "請輸入通訊地址",
                                text: "",
                                description: ""
                            },
                            3001: {
                                subject: "請完善表單內容",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    registered: {
                        advance: {
                            charge: "需要收費",
                            restrict: "限制人數",
                            available: "報名上限",
                            wait: "等待付款",
                            paid: "報名完成",
                            remain: "剩餘名額"
                        },
                        edit: {
                            help: {
                                backend: "後台編輯"
                            },
                            field: {
                                group: {
                                    name: "教會(機構)名稱",
                                    city: "教會(機構)所在城市",
                                    zip: "郵遞區號",
                                    address: "通訊地址",
                                    contact: {
                                        name: "聯絡人姓名",
                                        phone: "聯絡人電話號碼",
                                        email: "聯絡人電子郵件"
                                    }
                                },
                                respond: "結果",
                                avatar: "頭像",
                                qrcode: "報到二維碼",
                                echelon: {
                                    subject: "報名場次",
                                    begin: {
                                        subject: "場次開始"
                                    },
                                    end: {
                                        subject: "場次結束"
                                    },
                                    total: {
                                        subject: "全部 <b>%s</b> 場"
                                    },
                                    edit: {
                                        subject: "變更場次"
                                    }
                                },
                                external: "報名來源",
                                organization: "報名方式",
                                no: "訂單號",
                                name: "姓名",
                                email: "電子郵件",
                                paid: "已付款",
                                status: "狀態"
                            },
                            holder: {
                                group: {
                                    name: "請輸入教會(機構)名稱",
                                    city: "請輸入教會(機構)所在城市",
                                    zip: "請輸入郵遞區號",
                                    address: "請輸入通訊地址",
                                    contact: {
                                        name: "請輸入聯絡人姓名",
                                        phone: "請輸入聯絡人電話號碼",
                                        email: "請輸入聯絡人電子郵件"
                                    }
                                },
                                organization: "請選擇報名方式",
                                external: "請選擇報名來源",
                                no: "請輸入訂單號",
                                name: "請輸入姓名",
                                email: "請輸入電子郵件",
                                paid: "請選擇已付款",
                                status: "請選擇狀態"
                            },
                            handler: {
                                update: {
                                    subject: "即將更新報名內容",
                                    text: "請確認後執行",
                                    description: ""
                                }
                            },
                            error: {
                                1001: {
                                    subject: "請選擇已付款",
                                    text: "",
                                    description: ""
                                },
                                1002: {
                                    subject: "請選擇狀態",
                                    text: "",
                                    description: ""
                                }
                            }
                        },
                        field: {
                            no: "訂單號",
                            begin: "建立日期(起)",
                            end: "建立日期(迄)",
                            avatar: "頭像",
                            organization: "報名方式",
                            registered: {
                                subject: "資訊",
                                organization: {
                                    group: {
                                        subject: "團體報名"
                                    },
                                    subject: "個人報名"
                                },
                                external: {
                                    0: {
                                        subject: "App"
                                    },
                                    1: {
                                        subject: "外部"
                                    },
                                    2: {
                                        subject: "後台"
                                    }
                                },
                                paid: {
                                    not: {
                                        subject: "未付款"
                                    },
                                    subject: "已付款"
                                }
                            },
                            external: "報名來源",
                            paid: "已付款",
                            sort: "排序",
                            created: "建立時間"
                        },
                        holder: {
                            no: "請輸入訂單號",
                            organization: "請選擇報名方式",
                            external: "請選擇報名來源",
                            paid: "請選擇已付款",
                            begin: "請選擇建立日期(起)",
                            end: "請選擇建立日期(迄)"
                        },
                        factory: {
                            paid: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將手動標記付款, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    tip: {
                        signature: "活動建立人員將會自動擁有本活動App簽到功能, 可不指定其他協助簽到人員",
                        disabled: "請注意, 這個活動已經停用",
                        backend: "請注意, 總教會登入無法使用後台報名",
                        full: "請注意, 這個活動報名已經額滿",
                        summary: "請注意, 這個活動已經有 <b class=\"ft-15\">%s</b> 名會友已付費報名, 部分欄位<b>無法修改</b>, 請注意"
                    },
                    help: {
                        discount: {
                            enable: "已經開啟報名優惠功能, 無法修改日期"
                        },
                        activity: {
                            register: {
                                expire: {
                                    subject: "活動已經開始, 報名截止"
                                }
                            }
                        }
                    },
                    feature: {
                        0: "下載二維碼",
                        1: "後台報名",
                        2: "報到工具",
                        3: "顯示二維碼",
                        4: "複製報名網址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "報名"
                        },
                        2: {
                            subject: "場次"
                        },
                        3: {
                            subject: "回條"
                        },
                        4: {
                            subject: "欄位"
                        },
                        5: {
                            subject: "提醒"
                        },
                        6: {
                            subject: "文章"
                        },
                        7: {
                            subject: "圖片"
                        },
                        8: {
                            subject: "分享"
                        },
                        9: {
                            subject: "報名資訊"
                        },
                        10: {
                            subject: "簽到"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        url: {
                            subject: "報名網址"
                        },
                        register: {
                            subject: "後台報名"
                        },
                        summary: {
                            subject: "報名清單",
                            text: "含未付費"
                        }
                    },
                    field: {
                        keyword: "搜尋名單",
                        belong: {
                            no: "所屬教會編號",
                            name: "所屬教會名稱"
                        },
                        kind: "類型",
                        avatar: {
                            subject: "封面圖",
                            text: "(尺寸為 %sx%s 像素)"
                        },
                        category: "分類",
                        subject: "標題",
                        activity: {
                            on: "活動開始時間",
                            off: "活動結束時間"
                        },
                        respond: {
                            avatar: {
                                subject: "封面圖",
                                text: "(尺寸為 %sx%s 像素)"
                            },
                            description: "描述"
                        },
                        collection: "文章圖片",
                        description: "內容",
                        status: "狀態"
                    },
                    holder: {
                        keyword: "搜尋範圍: 名字/郵件/會友編號",
                        belong: {
                            no: "",
                            name: ""
                        },
                        kind: "請選擇類型",
                        category: "請選擇分類",
                        subject: "請輸入名稱",
                        activity: {
                            on: "請輸入活動開始時間",
                            off: "請輸入活動結束時間"
                        },
                        respond: {
                            description: "請輸入內容"
                        },
                        collection: "",
                        description: "請輸入描述",
                        status: "請選擇狀態"
                    },
                    handler: {
                        clear: {
                            all: {
                                subject: "即將清除所有文章圖片",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        update: {
                            subject: "即將更新活動內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇封面圖",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請輸入描述",
                            text: "",
                            description: ""
                        },
                        1009: {
                            subject: "請選擇指定教會",
                            text: "",
                            description: ""
                        },
                        4002: {
                            subject: "請輸入回條描述",
                            text: "",
                            description: ""
                        },
                        9001: {
                            subject: "請輸入分享描述",
                            text: "",
                            description: ""
                        }
                    }
                }
            },
            personal: {
                edit: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "密碼"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    handler: {
                        update: {
                            subject: "即將更新個人內容",
                            text: "請確認後執行",
                            description: "",
                            passwd: {
                                subject: "即將更新個人內容",
                                text: "<b class=\"text-danger\">更新密碼成功後將自動登出<br/>請您使用新密碼登入, 請確認後執行</b>",
                                description: ""
                            }
                        }
                    },
                    error: {
                        1001: {
                            subject: "請輸入名字",
                            text: ""
                        },
                        1002: {
                            subject: "請輸入電子郵件",
                            text: ""
                        },
                        1003: {
                            subject: "電子郵件格式錯誤",
                            text: "請輸入正確的電子郵件格式"
                        },
                        1004: {
                            subject: "請輸入電話",
                            text: ""
                        },
                        2001: {
                            subject: "請輸入當前密碼",
                            text: ""
                        },
                        2002: {
                            subject: "當前密碼錯誤",
                            text: ""
                        },
                        2003: {
                            subject: "請輸入登入密碼",
                            text: ""
                        },
                        2004: {
                            subject: "登入密碼強度不足",
                            text: ""
                        },
                        2005: {
                            subject: "請再輸入登入密碼進行確認",
                            text: ""
                        },
                        2006: {
                            subject: "密碼確認失敗",
                            text: "兩次密碼輸入不一致"
                        }
                    }
                }
            }
        },
        shepherd: {
            dashboard: {
                help: {
                    updated: "最後更新時間",
                    original: "預設區間",
                    loading: "正在計算統計數據..."
                },
                field: {
                    church: "指定教會",
                    begin: "開始時間",
                    end: "結束時間",
                    statistics: {
                        friend: "新朋友數量",
                        client: {
                            total: "會友人數",
                            track: "已跟進會友",
                            stranger: "未跟進會友",
                            archive: "不跟進會友"
                        },
                        team: "族群數量",
                        attend: "聚會數量"
                    }
                },
                holder: {
                    church: "請選擇指定教會"
                }
            },
            friend: {
                index: {
                    feature: {
                        create: {
                            subject: "新增新朋友"
                        }
                    },
                    text: {
                        register: "自行註冊"
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        avatar: "頭像",
                        name: "名字",
                        email: "電子郵件",
                        from: "來源",
                        relationship: "關係"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        name: "請輸入名字",
                        email: "請輸入電子郵件"
                    },
                    handler: {
                        add: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">標記為待轉入</span>",
                            text: "請確認後執行"
                        },
                        clear: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">標記為不轉入</span>",
                            text: "請確認後執行"
                        },
                        convert: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">轉入至牧養會友</span>",
                            text: "請確認後執行"
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改",
                        disabled: "請注意, 這名新朋友已經標記為不轉入"
                    },
                    commitment: {
                        empty: "未填寫"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "來源"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        avatar: "頭像",
                        email: "電子郵件",
                        name: "名字",
                        title: "稱謂",
                        birthday: "生日",
                        phone: "聯絡電話",
                        location: {
                            country: "所在地區",
                            city: "所在城市"
                        },
                        address: "聯絡地址",
                        occupation: "職業",
                        baptized: "受洗時間",
                        commitment: "委身編號",
                        created: "建立時間",
                        relationship: "關係"
                    },
                    holder: {
                        head: "未輸入",
                        belong: {
                            name: "",
                            no: ""
                        },
                        email: "請輸入電子郵件",
                        name: "請輸入名字",
                        title: "未輸入稱謂",
                        birthday: "未輸入生日",
                        phone: "未輸入聯絡電話",
                        location: {
                            country: "未選擇所在地區",
                            city: "未選擇所在城市"
                        },
                        address: "未輸入聯絡地址",
                        occupation: "未輸入職業",
                        baptized: "未輸入受洗時間",
                        commitment: "請輸入委身編號",
                        created: "",
                        accessed: ""
                    },
                    handler: {
                        add: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">標記為待轉入</span>",
                            text: "請確認後執行"
                        },
                        clear: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">標記為不轉入</span>",
                            text: "請確認後執行"
                        },
                        convert: {
                            subject: "請確認將指定的會友<span class=\"text-danger\">轉入至牧養會友</span>",
                            text: "請確認後執行"
                        }
                    }
                }
            },
            client: {
                index: {
                    download: {
                        name: "檔案匯出_會友管理"
                    },
                    more: {
                        on: "更多選項",
                        off: "收起選項"
                    },
                    help: {
                        manager: "為該族群的託管人員",
                        stranger: "未跟進",
                        archive: "不跟進"
                    },
                    feature: {
                        create: {
                            subject: "新增會友"
                        },
                        advance: {
                            subject: "進階功能"
                        }
                    },
                    advance: {
                        track: "已跟進會友",
                        stranger: "未跟進會友",
                        mark: "已標註會友",
                        archive: "不跟進會友"
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        avatar: "頭像",
                        name: "名字",
                        email: "電子郵件",
                        team: "族群",
                        mark: "加註",
                        connect: "連結",
                        title: "稱謂",
                        country: "所在區域",
                        city: "所在城市",
                        occupation: "職業",
                        education: "教育程度",
                        deacon: "五重執事",
                        marital: "婚姻狀況"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        name: "請輸入名字",
                        email: "請輸入電子郵件",
                        team: "請選擇需要篩選的族群",
                        title: "請選擇稱謂",
                        country: "請選擇所在區域",
                        city: "請選擇所在城市",
                        occupation: "請選擇職業",
                        education: "請選擇教育程度",
                        deacon: "請選擇五重執事",
                        marital: "請選擇婚姻狀況"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用會友",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用會友",
                            text: "請確認後執行",
                            description: ""
                        },
                        mark: {
                            add: {
                                subject: "即將對此會友進行加註",
                                text: "請確認後執行",
                                description: ""
                            },
                            remove: {
                                subject: "即將對此會友移除加註",
                                text: "請確認後執行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行會友匯出, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除會友, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                edit: {
                    attend: {
                        index: {
                            field: {
                                attend: "聚會",
                                team: "族群",
                                begin: "開始時間",
                                end: "結束時間"
                            },
                            holder: {
                                attend: "請選擇聚會",
                                team: "請選擇族群",
                                begin: "請輸入開始時間",
                                end: "請輸入結束時間"
                            }
                        }
                    },
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改",
                        disabled: "請注意, 這名會友已經停用"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "族群"
                        },
                        2: {
                            subject: "相關歷程"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    history: {
                        course: {
                            subject: "課程"
                        },
                        reunion: {
                            subject: "聚會"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        avatar: "頭像",
                        name: "名字",
                        nickname: "英文名字/暱稱",
                        birthday: "生日",
                        email: "電子郵件",
                        deacon: "五重執事",
                        title: "稱謂",
                        education: "教育程度",
                        marital: "婚姻狀況",
                        occupation: "職業",
                        mobile: "手機",
                        contact: "其他電話",
                        country: "所在區域",
                        city: "所在城市",
                        address: "居住地址",
                        school: "最高學校名稱",
                        consort: {
                            name: "配偶名字",
                            contact: "配偶聯絡方式"
                        },
                        commitment: "委身編號",
                        guardianship: {
                            name: "監護人名字",
                            relationship: "監護人關係",
                            contact: "監護人聯絡方式"
                        },
                        church: {
                            enter: {
                                date: "進入教會日期"
                            },
                            leave: {
                                date: "離開教會日期",
                                reason: "離開教會原因"
                            }
                        },
                        baptized: {
                            is: "是否受洗",
                            date: "受洗日期",
                            church: "受洗教會"
                        },
                        note: "備註"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        head: "未輸入",
                        name: "請輸入名字",
                        nickname: "請輸入英文名字/暱稱",
                        birthday: "請選擇生日",
                        email: "請輸入電子郵件",
                        deacon: "請選擇五重執事",
                        title: "請選擇稱謂",
                        education: "請選擇教育程度",
                        marital: "請選擇婚姻狀況",
                        occupation: "請選擇職業",
                        mobile: "請輸入手機",
                        commitment: "請輸入委身編號",
                        contact: "請輸入其他電話",
                        country: "請選擇所在區域",
                        city: "請選擇所在城市",
                        address: "請輸入居住地址",
                        school: "請輸入最高學校名稱",
                        consort: {
                            name: "請輸入配偶名字",
                            contact: "請輸入配偶聯絡方式"
                        },
                        guardianship: {
                            name: "請輸入監護人名字",
                            relationship: "請輸入監護人關係",
                            contact: "請輸入監護人聯絡方式"
                        },
                        church: {
                            enter: {
                                date: "請選擇進入教會日期"
                            },
                            leave: {
                                date: "請選擇離開教會日期",
                                reason: "請輸入離開教會原因"
                            }
                        },
                        baptized: {
                            is: "請填寫是否受洗",
                            date: "請選擇受洗日期",
                            church: "請輸入受洗教會"
                        },
                        note: "請輸入備註"
                    },
                    handler: {
                        update: {
                            subject: "即將更新會友內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    }
                },
                advance: {
                    tabs: {
                        0: {
                            subject: "批次委派"
                        },
                        1: {
                            subject: "會友匯入"
                        },
                        2: {
                            subject: "會友匯出"
                        },
                        3: {
                            subject: "全部刪除"
                        }
                    },
                    step: {
                        delegate: [
                            {
                                subject: "步驟1",
                                text: "選擇前往族群",
                                description: "請先指定一個需要前往的族群"
                            },
                            {
                                subject: "步驟2",
                                text: "選擇會友",
                                description: "請選擇需要前往的族群的會友"
                            },
                            {
                                subject: "步驟3",
                                text: "完成委派",
                                description: ""
                            }
                        ]
                    },
                    submit: {
                        delegate: {
                            prev: "上一步",
                            next: {
                                off: "請先選擇族群",
                                subject: "下一步"
                            },
                            accept: {
                                off: "請先選擇會友",
                                subject: "確認委派"
                            },
                            reset: "重新委派"
                        },
                        import: {
                            name: "匯入會友檔案範例_完整",
                            template: "下載範例",
                            off: "請先匯入會友資訊",
                            refresh: "重新匯入",
                            accept: "執行匯入"
                        },
                        export: {
                            accept: "執行匯出"
                        },
                        destroy: {
                            accept: "執行刪除"
                        }
                    },
                    field: {
                        category: "類別",
                        keyword: "關鍵字"
                    },
                    holder: {
                        category: "請選擇類別",
                        keyword: "請輸入關鍵字"
                    },
                    handler: {
                        delegate: {
                            subject: "即將進行批次委派",
                            text: "請確認後執行",
                            description: ""
                        },
                        import: {
                            reset: {
                                subject: "即將進行重新匯入",
                                text: "請確認後執行",
                                description: ""
                            },
                            upload: {
                                subject: "即將進行會友匯入",
                                text: "請確認後執行",
                                description: ""
                            }
                        }
                    },
                    factory: {
                        export: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行會友匯出, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將進行全部會友刪除, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                }
            },
            attend: {
                v2: {
                    create: {
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "選項"
                            },
                            2: {
                                subject: "名單"
                            },
                            3: {
                                subject: "提醒"
                            },
                            4: {
                                subject: "循環"
                            }
                        },
                        field: {
                            category: {
                                subject: "分類",
                                holder: "請選擇分類",
                                help: "請先建立聚會分類"
                            },
                            name: {
                                subject: "名稱",
                                holder: "請輸入名稱"
                            },
                            should: {
                                subject: "簽到工具顯示應到會友",
                                holder: "請選擇簽到工具顯示應到會友"
                            },
                            register: {
                                subject: "允許會友自行簽到",
                                holder: "請選擇允許會友自行簽到",
                                help: {
                                    subject: "會友可自行於牧養對話中簽到",
                                    off: {
                                        subject: "需要透過簽到工具或小組長出示掃描會友二維碼進行簽到"
                                    }
                                }
                            },
                            place: {
                                subject: "地點",
                                holder: "請輸入地點"
                            },
                            note: {
                                subject: "備註",
                                holder: "請輸入備註"
                            },
                            status: {
                                subject: "狀態",
                                holder: "請選擇狀態"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "聚會紀錄"
                            },
                            schedule: {
                                subject: "聚會排程"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前聚會處於僅讀取狀態無法更新"
                            },
                            disable: {
                                subject: "目前聚會處於停用狀態"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將儲存內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請選擇分類",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "請選擇開始時間",
                                text: "",
                                description: ""
                            },
                            1040: {
                                subject: "請選擇結束時間",
                                text: ""
                            },
                            1041: {
                                subject: "結束時間必須大於開始時間",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇簽到工具顯示應到會友",
                                text: ""
                            },
                            1060: {
                                subject: "請選擇自行簽到",
                                text: ""
                            },
                            1070: {
                                subject: "請選擇狀態",
                                text: ""
                            },
                            2010: {
                                subject: "請完善選項的參數設定",
                                text: ""
                            },
                            3010: {
                                subject: "請至少選擇1個族群",
                                text: ""
                            },
                            4010: {
                                subject: "請完善提醒的參數設定",
                                text: ""
                            },
                            5010: {
                                subject: "請完善循環的參數設定",
                                text: ""
                            }
                        }
                    },
                    edit: {
                        history: {
                            field: {
                                name: {
                                    subject: "族群名稱"
                                },
                                begin: {
                                    subject: "開始時間"
                                },
                                end: {
                                    subject: "結束時間"
                                },
                                status: {
                                    subject: "狀態",
                                    value: {
                                        0: {
                                            subject: "未開始"
                                        },
                                        1: {
                                            subject: "進行中"
                                        },
                                        2: {
                                            subject: "已結束"
                                        },
                                        3: {
                                            subject: "無聚會"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立時間"
                                }
                            },
                            search: {
                                name: {
                                    subject: "群族名稱",
                                    holder: "請輸入群族名稱"
                                },
                                status: {
                                    subject: "狀態",
                                    holder: "請選擇狀態"
                                }
                            }
                        },
                        schedule: {
                            field: {
                                begin: {
                                    subject: "開始時間"
                                },
                                end: {
                                    subject: "結束時間"
                                },
                                status: {
                                    subject: "狀態",
                                    value: {
                                        0: {
                                            subject: "等待生成"
                                        },
                                        1: {
                                            subject: "已生成"
                                        },
                                        2: {
                                            subject: "生成逾期"
                                        }
                                    }
                                },
                                created: {
                                    subject: "建立時間"
                                },
                                stamp: {
                                    subject: "預計生成時間"
                                },
                                completed: {
                                    subject: "實際生成時間"
                                },
                                notified: {
                                    subject: "推送時間"
                                },
                                mailed: {
                                    subject: "寄送郵件時間"
                                }
                            },
                            search: {
                                status: {
                                    subject: "狀態",
                                    holder: "請選擇狀態"
                                }
                            }
                        },
                        tab: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "選項"
                            },
                            2: {
                                subject: "名單"
                            },
                            3: {
                                subject: "提醒"
                            },
                            4: {
                                subject: "循環"
                            },
                            98: {
                                subject: "統計資訊"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            category: {
                                subject: "分類",
                                holder: "請選擇分類",
                                help: "請先建立聚會分類"
                            },
                            name: {
                                subject: "名稱",
                                holder: "請輸入名稱"
                            },
                            should: {
                                subject: "簽到工具顯示應到會友",
                                holder: "請選擇簽到工具顯示應到會友"
                            },
                            register: {
                                subject: "允許會友自行簽到",
                                holder: "請選擇允許會友自行簽到",
                                help: {
                                    subject: "會友可自行於牧養對話中簽到",
                                    off: {
                                        subject: "需要透過簽到工具或小組長出示掃描會友二維碼進行簽到"
                                    }
                                }
                            },
                            place: {
                                subject: "地點",
                                holder: "請輸入地點"
                            },
                            note: {
                                subject: "備註",
                                holder: "請輸入備註"
                            },
                            status: {
                                subject: "狀態",
                                holder: "請選擇狀態"
                            }
                        },
                        statistics: {
                            history: {
                                subject: "聚會紀錄"
                            },
                            schedule: {
                                subject: "聚會排程"
                            }
                        },
                        alert: {
                            readonly: {
                                subject: "目前聚會處於僅讀取狀態無法更新"
                            },
                            disable: {
                                subject: "目前聚會處於停用狀態"
                            }
                        },
                        handler: {
                            submit: {
                                subject: "即將更新內容",
                                text: "請確認後執行"
                            }
                        },
                        submit: {
                            cycle: {
                                subject: "預覽排程"
                            }
                        },
                        error: {
                            1010: {
                                subject: "請選擇分類",
                                text: "",
                                description: ""
                            },
                            1020: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1030: {
                                subject: "請選擇開始時間",
                                text: "",
                                description: ""
                            },
                            1040: {
                                subject: "請選擇結束時間",
                                text: ""
                            },
                            1041: {
                                subject: "結束時間必須大於開始時間",
                                text: ""
                            },
                            1050: {
                                subject: "請選擇簽到工具顯示應到會友",
                                text: ""
                            },
                            1060: {
                                subject: "請選擇自行簽到",
                                text: ""
                            },
                            1070: {
                                subject: "請選擇狀態",
                                text: ""
                            },
                            2010: {
                                subject: "請完善選項的參數設定",
                                text: ""
                            },
                            3010: {
                                subject: "請至少選擇1個族群",
                                text: ""
                            },
                            4010: {
                                subject: "請完善提醒的參數設定",
                                text: ""
                            },
                            5010: {
                                subject: "請完善循環的參數設定",
                                text: ""
                            }
                        }
                    },
                    field: {
                        name: {
                            subject: "名稱"
                        },
                        summary: {
                            team: {
                                subject: "族群數量"
                            }
                        },
                        status: {
                            subject: "狀態",
                            value: {
                                0: {
                                    subject: "已停用"
                                },
                                1: {
                                    subject: "啟用中"
                                }
                            }
                        },
                        begin: {
                            subject: "開始時間"
                        },
                        created: {
                            subject: "建立時間"
                        }
                    },
                    more: {
                        history: {
                            subject: "聚會紀錄"
                        },
                        schedule: {
                            subject: "聚會排程"
                        }
                    },
                    search: {
                        category: {
                            subject: "分類",
                            holder: "請選擇分類",
                            help: "請先選擇所屬教會"
                        },
                        name: {
                            subject: "名稱",
                            holder: "請輸入名稱"
                        },
                        status: {
                            subject: "狀態",
                            holder: "請選擇狀態"
                        }
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用聚會",
                            text: "請確認後執行"
                        },
                        disable: {
                            subject: "即將停用聚會",
                            text: "請確認後執行"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除聚會, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                category: {
                    index: {
                        feature: {
                            create: {
                                subject: "新增類別"
                            }
                        },
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            name: "名稱",
                            quantity: "聚會數量"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            name: "請輸入名稱"
                        },
                        handler: {
                            enable: {
                                subject: "即將啟用分類",
                                text: "請確認後執行",
                                description: ""
                            },
                            disable: {
                                subject: "即將停用分類",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        factory: {
                            destroy: {
                                subject: "安全性二級驗證",
                                text: "<span class=\"cgRed\">即將刪除分類, 請確認後執行</span>",
                                description: "請輸入您目前的登入密碼"
                            }
                        }
                    },
                    create: {
                        help: {
                            church: "請謹慎使用指定教會功能"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            }
                        },
                        field: {
                            church: "指定教會",
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            church: "請選擇指定教會",
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            store: {
                                subject: "即將新增類別",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            },
                            1003: {
                                subject: "請選擇指定教會",
                                text: "",
                                description: ""
                            }
                        }
                    },
                    edit: {
                        tip: {
                            readonly: "總教會僅供查看資料, 無法新增修改",
                            disabled: "請注意, 這個分類已經停用"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        field: {
                            belong: {
                                no: "所屬教會編號",
                                name: "所屬教會名稱"
                            },
                            name: "名稱",
                            status: "狀態"
                        },
                        holder: {
                            belong: {
                                no: "",
                                name: ""
                            },
                            name: "請輸入名稱",
                            status: "請選擇狀態"
                        },
                        handler: {
                            update: {
                                subject: "即將更新類別內容",
                                text: "請確認後執行",
                                description: ""
                            }
                        },
                        error: {
                            1001: {
                                subject: "請輸入名稱",
                                text: "",
                                description: ""
                            },
                            1002: {
                                subject: "請選擇狀態",
                                text: "",
                                description: ""
                            }
                        }
                    }
                },
                index: {
                    feature: {
                        create: {
                            subject: "新增聚會"
                        }
                    },
                    field: {
                        head: {
                            church: "指定教會"
                        },
                        name: "名稱",
                        category: "分類",
                        cycle: "循環",
                        team: "族群數量"
                    },
                    holder: {
                        head: {
                            church: "請選擇指定教會"
                        },
                        name: "請輸入名稱",
                        category: "請選擇分類",
                        cycle: "請選擇循環方式"
                    },
                    handler: {
                        enable: {
                            subject: "即將啟用聚會",
                            text: "請確認後執行",
                            description: ""
                        },
                        disable: {
                            subject: "即將停用聚會",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    factory: {
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除聚會, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                create: {
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "選項"
                        },
                        2: {
                            subject: "名單"
                        },
                        3: {
                            subject: "提醒"
                        },
                        4: {
                            subject: "循環"
                        }
                    },
                    help: {
                        church: "請謹慎使用指定教會功能",
                        register: {
                            off: "需要透過簽到工具或小組長出示/掃描會友二維碼進行簽到",
                            on: "會友可自行於牧養對話中簽到"
                        }
                    },
                    field: {
                        church: "指定教會",
                        name: "名稱",
                        category: "分類",
                        begin: {
                            subject: "開始時間",
                            text: "首次"
                        },
                        end: {
                            subject: "結束時間",
                            text: "首次"
                        },
                        location: "地點",
                        note: "備註",
                        register: "允許會友自行簽到",
                        should: "簽到工具顯示應到會友",
                        status: "狀態"
                    },
                    holder: {
                        church: "請選擇指定教會",
                        name: "請輸入名稱",
                        category: {
                            subject: "請選擇分類",
                            empty: "請先選擇指定教會"
                        },
                        begin: "請選擇開始時間",
                        end: "請選擇結束時間",
                        location: "請輸入地點",
                        note: "請輸入備註",
                        register: "請選擇是否允許會友自行簽到",
                        should: "請選擇簽到工具是否顯示應到會友",
                        status: "請選擇狀態"
                    },
                    handler: {
                        store: {
                            subject: "即將新增聚會",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇是否允許會友自行簽到",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請選擇簽到工具是否顯示應到會友",
                            text: "",
                            description: ""
                        }
                    }
                },
                edit: {
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改",
                        disabled: "請注意, 這個聚會已經停用"
                    },
                    feature: {
                        0: "下載報到介面二維碼",
                        2: "前往報到介面",
                        3: "顯示報到介面二維碼",
                        4: "複製報到介面網址"
                    },
                    tabs: {
                        0: {
                            subject: "基本"
                        },
                        1: {
                            subject: "選項"
                        },
                        2: {
                            subject: "名單"
                        },
                        3: {
                            subject: "提醒"
                        },
                        4: {
                            subject: "循環"
                        },
                        5: {
                            subject: "統計資訊"
                        },
                        98: {
                            subject: "循環資訊"
                        },
                        99: {
                            subject: "相關資訊"
                        }
                    },
                    summary: {
                        schedule: {
                            subject: "聚會排程"
                        },
                        history: {
                            subject: "聚會紀錄"
                        }
                    },
                    detail: {
                        cycle: {
                            subject: "循環代號"
                        },
                        remind: {
                            subject: "提醒代號"
                        }
                    },
                    help: {
                        register: {
                            off: "需要透過簽到工具或小組長出示/掃描會友二維碼進行簽到",
                            on: "會友可自行於牧養對話中簽到"
                        }
                    },
                    field: {
                        belong: {
                            name: "所屬教會名稱",
                            no: "所屬教會ID"
                        },
                        name: "名稱",
                        category: "分類",
                        begin: {
                            subject: "開始時間",
                            text: "首次"
                        },
                        end: {
                            subject: "結束時間",
                            text: "首次"
                        },
                        location: "地點",
                        note: "備註",
                        register: "允許會友自行簽到",
                        should: "簽到工具顯示應到會友",
                        status: "狀態"
                    },
                    holder: {
                        belong: {
                            name: "",
                            no: ""
                        },
                        name: "請輸入名稱",
                        category: "請選擇分類",
                        begin: "請選擇開始時間",
                        end: "請選擇結束時間",
                        location: "請輸入地點",
                        note: "請輸入備註",
                        register: "請選擇是否允許會友自行簽到",
                        should: "請選擇簽到工具是否顯示應到會友",
                        status: "請選擇狀態"
                    },
                    handler: {
                        update: {
                            subject: "即將更新聚會內容",
                            text: "請確認後執行",
                            description: ""
                        }
                    },
                    error: {
                        1001: {
                            subject: "請選擇分類",
                            text: "",
                            description: ""
                        },
                        1002: {
                            subject: "請輸入名稱",
                            text: "",
                            description: ""
                        },
                        1003: {
                            subject: "請選擇開始時間",
                            text: "",
                            description: ""
                        },
                        1004: {
                            subject: "請選擇結束時間",
                            text: "",
                            description: ""
                        },
                        1005: {
                            subject: "結束時間必須大於開始時間",
                            text: "",
                            description: ""
                        },
                        1006: {
                            subject: "請選擇狀態",
                            text: "",
                            description: ""
                        },
                        1007: {
                            subject: "請選擇是否允許會友自行簽到",
                            text: "",
                            description: ""
                        },
                        1008: {
                            subject: "請選擇簽到工具是否顯示應到會友",
                            text: "",
                            description: ""
                        }
                    }
                },
                history: {
                    index: {
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            name: "族群名稱",
                            subject: "聚會標題",
                            begin: "開始時間",
                            end: "結束時間"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            },
                            name: "請輸入名字"
                        }
                    },
                    edit: {
                        tip: {
                            yet: {
                                subject: "聚會尚未開始"
                            },
                            ing: {
                                subject: "聚會正在進行"
                            },
                            ended: {
                                subject: "聚會已經結束"
                            }
                        },
                        feature: {
                            0: "下載報到介面二維碼",
                            1: "重製點名單",
                            2: "前往報到介面",
                            3: "顯示報到介面二維碼",
                            4: "複製報到介面網址",
                            11: "聚會已經結束, 無法重製點名單"
                        },
                        download: {
                            subject: "%s_報到介面二維碼"
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            1: {
                                subject: "名單"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        summary: {
                            history: {
                                subject: "聚會紀錄"
                            }
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            qrcode: {
                                subject: "APP專用報到二維碼",
                                text: "點擊下載"
                            },
                            name: "名稱",
                            category: "分類",
                            begin: "開始時間",
                            end: "結束時間",
                            location: "地點",
                            note: "備註",
                            status: "狀態"
                        },
                        holder: {
                            belong: {
                                name: "",
                                no: ""
                            },
                            name: "請輸入名稱",
                            category: "請選擇分類",
                            begin: "請選擇開始時間",
                            end: "請選擇結束時間",
                            location: "請輸入地點",
                            note: "請輸入備註",
                            status: "請選擇狀態"
                        },
                        handler: {
                            rebuild: {
                                subject: "重製點名單",
                                text: "若您發現點名單成員不正確<br />可進行點名單重製, 請確認後執行"
                            }
                        }
                    }
                },
                schedule: {
                    index: {
                        field: {
                            head: {
                                church: "指定教會"
                            },
                            begin: "開始時間",
                            end: "結束時間"
                        },
                        holder: {
                            head: {
                                church: "請選擇指定教會"
                            }
                        }
                    },
                    edit: {
                        help: {
                            remind: {
                                subject: "請參考提醒設定值"
                            }
                        },
                        tabs: {
                            0: {
                                subject: "基本"
                            },
                            99: {
                                subject: "相關資訊"
                            }
                        },
                        boolean: {
                            false: {
                                subject: "否"
                            },
                            true: {
                                subject: "是"
                            }
                        },
                        remind: {
                            0: {
                                subject: "不發送",
                                text: "不發送"
                            },
                            1: {
                                subject: "等待發送",
                                text: "正在準備發送"
                            },
                            2: {
                                subject: "發送完成"
                            }
                        },
                        field: {
                            belong: {
                                name: "所屬教會名稱",
                                no: "所屬教會ID"
                            },
                            created: "建立時間",
                            begin: "開始時間",
                            end: "結束時間",
                            stamp: "預定生成時間",
                            completed: "實際生成時間",
                            beforehand: "預設提前生成",
                            remind: {
                                notification: {
                                    status: "推送狀態",
                                    stamp: "推送完成時間"
                                },
                                email: {
                                    status: "寄送郵件狀態",
                                    stamp: "寄送郵件完成時間"
                                }
                            },
                            status: "狀態"
                        },
                        holder: {
                            belong: {
                                name: "",
                                no: ""
                            },
                            begin: "請選擇開始時間",
                            end: "請選擇結束時間",
                            stamp: "請選擇預定生成時間",
                            status: "請選擇狀態"
                        }
                    }
                }
            },
            team: {
                index: {
                    feature: {
                        statistics: "牧養跟進",
                        expand: {
                            on: "展開全部統計",
                            off: "收起全部統計"
                        },
                        root: "新增族群",
                        add: "新增同階族群",
                        children: "新增子族群",
                        undo: "回上一階",
                        architecture: {
                            active: "組織圖",
                            inactive: "無子族群供顯示組織圖"
                        },
                        hierarchy: {
                            off: "無下一階",
                            subject: "下一階"
                        },
                        edit: {
                            subject: "編輯",
                            text: "該族群缺少託管人員, 請盡快編輯設定"
                        },
                        attend: "查看聚會",
                        view: "查看",
                        destroy: {
                            subject: "刪除",
                            attend: "已設定聚會, 無法刪除<br/>請先刪除已設定聚會",
                            children: "已設定有子族群, 無法刪除<br/>請先刪除所有子族群"
                        }
                    },
                    tip: {
                        readonly: "總教會僅供查看資料, 無法新增修改"
                    },
                    help: {
                        updated: "最後更新時間"
                    },
                    selection: "請先選擇指定教會",
                    quantity: {
                        attend: "聚會數量",
                        children: "族群數量",
                        manager: {
                            current: "託管人員",
                            recursive: "託管人員 - 合計"
                        },
                        partake: {
                            current: "族群成員",
                            recursive: "族群成員 - 合計"
                        }
                    },
                    field: {
                        church: "指定教會"
                    },
                    holder: {
                        church: "請選擇指定教會"
                    },
                    factory: {
                        reset: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將重設該教會牧養相關功能<br/>包括牧養對話群組與聚會等相關功能<br/>重設將無法恢復, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        },
                        destroy: {
                            subject: "安全性二級驗證",
                            text: "<span class=\"cgRed\">即將刪除族群, 刪除後牧養對話群組將會解散<br/>並無法恢復, 請確認後執行</span>",
                            description: "請輸入您目前的登入密碼"
                        }
                    }
                },
                statistics: {
                    item: {
                        attend: {
                            subject: "聚會紀錄",
                            field: {
                                subject: "族群",
                                total: "合計次數",
                                last: "最後更新時間"
                            }
                        },
                        event: {
                            subject: "服事參與",
                            help: {
                                updated: "最後更新時間"
                            },
                            empty: {
                                subject: "暫無資料"
                            },
                            tip: {
                                team: "列表對象為群組第一層全教會各佔比，點選群組名稱可查閱下一級群組<br/><span class=\"text-danger\">該牧區計算: 參與服事人數 / 總人數 = 全教會服事比例</span>"
                            },
                            header: {
                                team: {
                                    subject: "統計服事對象"
                                },
                                temperature: {
                                    subject: "溫度服事對象",
                                    info: "30天以上未參加",
                                    warning: "60天以上未參加",
                                    danger: "90天以上未參加",
                                    total: {
                                        all: {
                                            subject: "合計 <b class=\"text-success ft-15\">%s</b> 位"
                                        },
                                        section: {
                                            subject: "共 <b class=\"text-%s ft-15\">%s</b> 位"
                                        }
                                    }
                                },
                                leave: {
                                    subject: "離開服事對象",
                                    total: {
                                        all: {
                                            subject: "合計 <b class=\"text-danger ft-15\">%s</b> 位"
                                        }
                                    }
                                },
                                join: {
                                    month: {
                                        subject: "新增服事人數 <span class=\"ft-14\">(月)</span>",
                                        total: {
                                            all: {
                                                subject: "合計 <b class=\"text-success ft-15\">%s</b> 位"
                                            }
                                        }
                                    },
                                    week: {
                                        subject: "新增服事人數 <span class=\"ft-14\">(週)</span>",
                                        total: {
                                            all: {
                                                subject: "合計 <b class=\"text-success ft-15\">%s</b> 位"
                                            }
                                        }
                                    }
                                },
                                event: {
                                    team: {
                                        subject: "參與群組",
                                        total: {
                                            all: {
                                                subject: "合計 <b class=\"text-info ft-15\">%s</b> 位"
                                            }
                                        }
                                    }
                                }
                            },
                            summary: {
                                total: {
                                    subject: "總人數"
                                },
                                having: {
                                    subject: "參與服事人數"
                                },
                                nothing: {
                                    subject: "未參與服事人數"
                                },
                                percent: {
                                    current: {
                                        subject: "參與服事比例 <span class=\"ft-12\">(%)</span>"
                                    },
                                    prev: {
                                        subject: "前月參與服事比例 <span class=\"ft-12\">(%)</span>"
                                    }
                                },
                                join: {
                                    month: {
                                        subject: "新增服事人數 <span class=\"ft-12\">(月)</span>"
                                    },
                                    week: {
                                        subject: "新增服事人數 <span class=\"ft-12\">(週)</span>"
                                    }
                                },
                                leave: {
                                    subject: "離開服事人數"
                                }
                            }
                        },
                        gender: {
                            subject: "男女比例",
                            field: {
                                subject: "性別",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                1: "弟兄",
                                2: "姐妹",
                                9999: "未登記"
                            }
                        },
                        year: {
                            subject: "年齡分佈",
                            field: {
                                subject: "年齡",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                0: "0-9歲",
                                1: "10-19歲",
                                2: "20-29歲",
                                3: "30-39歲",
                                4: "40-49歲",
                                5: "50-59歲",
                                6: "60-69歲",
                                7: "70-79歲",
                                8: "80-89歲",
                                9: "90-99歲",
                                10: "100-109歲",
                                11: "110-119歲",
                                12: "120-129歲",
                                9999: "未登記"
                            }
                        },
                        deacon: {
                            subject: "五重執事",
                            field: {
                                subject: "執事",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                1: "使徒",
                                2: "先知",
                                3: "傳福音",
                                4: "牧師",
                                5: "教師",
                                9999: "未登記"
                            }
                        },
                        education: {
                            subject: "教育程度",
                            field: {
                                subject: "程度",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                1: "小學",
                                2: "中學",
                                3: "專科",
                                4: "高中",
                                5: "高職/高商",
                                6: "大學",
                                7: "碩士",
                                8: "博士",
                                9999: "未登記"
                            }
                        },
                        occupation: {
                            subject: "職業分佈",
                            field: {
                                subject: "職業",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                1: "行政經營",
                                2: "業務行銷",
                                3: "人事法務",
                                4: "財會金融",
                                5: "廣告美編",
                                6: "客戶服務",
                                7: "電腦硬體",
                                8: "資訊軟體",
                                9: "品管製造",
                                10: "技術服務",
                                11: "營建職業",
                                12: "傳播媒體",
                                13: "娛樂演藝",
                                14: "教育學術",
                                15: "醫療美容",
                                16: "保全警衛",
                                17: "家事服務",
                                18: "農林魚牧",
                                19: "慈善宗教",
                                20: "交通及物流服務",
                                21: "餐飲旅遊運動",
                                22: "美容美髮",
                                23: "軍公教",
                                56: "家管",
                                57: "兒童",
                                58: "待業",
                                59: "學生",
                                9999: "未登記"
                            }
                        },
                        marital: {
                            subject: "婚姻狀況",
                            field: {
                                subject: "狀況",
                                quantity: "數量",
                                percent: "佔比",
                                total: "總和",
                                last: "最後更新時間"
                            },
                            option: {
                                1: "已婚",
                                2: "未婚",
                                3: "喪偶",
                                4: "再婚",
                                5: "離婚",
                                9999: "未登記"
                            }
                        },
                        baptized: {
                            subject: "受洗人數"
                        }
                    }
                }
            }
        }
    },
    handler: {
        session: {
            repair: {
                subject: "安全性二級驗證",
                text: "<span class=\"cgRed\">即將進行重建模擬, 請確認後執行</span>",
                description: "請輸入您目前的登入密碼"
            },
            simulation: {
                subject: "安全性二級驗證",
                text: "<span class=\"cgRed\">即將進行模擬登入, 請確認後執行</span>",
                description: "請輸入您目前的登入密碼"
            },
            head: {
                subject: "即將返回管理介面",
                text: "",
                description: ""
            },
            destroy: {
                subject: "即將登出系統",
                text: "請確認登出",
                description: ""
            }
        }
    },
    error: {
        400: {
            subject: "連線權杖逾時",
            text: "Oops! 🤕🤕 請您重新登入系統",
            description: "請稍後再試或請聯絡管理人員"
        },
        401: {
            subject: "未授權的存取",
            text: "Oops! 😳😳 您可能沒有存取的權限",
            description: "請稍後再試或請聯絡管理人員"
        },
        404: {
            subject: "頁面不存在",
            text: "Oops! 😱😱 您訪問的頁面不存在或暫時不可用",
            description: "請稍後再試或請聯絡管理人員"
        },
        405: {
            subject: "這出了點小問題",
            text: "Oops! 😵😵 無法正確連結至伺服器",
            description: "請稍後再試或請聯絡管理人員"
        },
        413: {
            subject: "這出了點小問題",
            text: "Oops! 🙁🙁 您上傳的檔案容量超出限制",
            description: "請稍後再試或請聯絡管理人員"
        },
        500: {
            subject: "這出了點小問題",
            text: "Oops! 🥺🥺 伺服器好像罷工了",
            description: "請稍後再試或請聯絡管理人員"
        },
        502: {
            subject: "這出了點小問題",
            text: "Oops! 😵😵 無法正確連結至伺服器",
            description: "請稍後再試或請聯絡管理人員"
        },
        504: {
            subject: "這出了點小問題",
            text: "Oops! 😵‍💫😵‍💫 連結到伺服器超出預期時間",
            description: "請稍後再試或請聯絡管理人員"
        },
        901: {
            subject: "上傳檔案發生錯誤",
            text: "單個檔案超過 <b class=\"ft-20 text-danger\">%s</b> %s限制",
            description: ""
        },
        902: {
            subject: "上傳檔案發生錯誤",
            text: "檔案合計超過 <b class=\"ft-20 text-danger\">%s</b> %s限制",
            description: ""
        },
        903: {
            subject: "產生預覽圖失敗",
            text: "上傳檔案中包含不支援的圖片編碼",
            description: ""
        },
        904: {
            subject: "分享連結出現錯誤",
            text: "請確認您的分享連結是否正確",
            description: ""
        },
        998: {
            subject: "二級驗證失敗",
            text: "請確認您的登入密碼是否正確",
            description: "請確認您的登入密碼是否正確"
        },
        999: {
            subject: "這出了點小問題",
            text: "Oops! 😲😲 發生未知錯誤",
            description: "請稍後再試或請聯絡管理人員"
        }
    },
    submit: {
        status: {
            enable: "啟用",
            disable: "停用"
        },
        simulation: "模擬登入",
        repair: "重建模擬",
        download: "下載",
        recipient: "編輯名單",
        approved: "核准",
        reject: "拒絕",
        preview: "預覽",
        copy: "複製",
        insert: "插入",
        back: "返回",
        join: "加入",
        remove: "移除",
        select: "選擇",
        attachment: "附件",
        home: "返回首頁",
        toEntry: "回列表",
        reLogin: "重新登入",
        login: "登入",
        forgot: {
            found: "找回密碼",
            verification: "確認送出",
            reset: "重設密碼"
        },
        waiting: "登入中",
        success: "登入成功...請稍候",
        ok: "好",
        top: "置頂",
        toTop: "回到頂部",
        save: "儲存",
        change: "變更",
        search: "搜尋",
        query: "查詢",
        close: "關閉",
        accept: "確定",
        refresh: "刷新",
        reload: "重新讀取",
        upload: "上傳",
        clear: "清除",
        reset: "重設",
        cancel: "取消",
        send: "送出",
        add: "加入",
        edit: "編輯",
        delete: "刪除",
        done: "完成",
        fetch: "立即更新",
        relocation: "重新整理",
        replace: "取代",
        schedule: "排程預覽",
        view: "查看",
        segue: "前往"
    },
    bool: {
        basic: [
            "否",
            "是"
        ],
        result: [
            "失敗",
            "成功"
        ]
    },
    belong: {
        belong: {
            no: {
                subject: "所屬教會編號"
            },
            name: {
                subject: "所屬教會名稱"
            }
        },
        delegate: {
            no: {
                subject: "執行教會編號"
            },
            name: {
                subject: "執行教會名稱"
            }
        }
    },
    ago: {
        just: "剛剛",
        minute: "%s分鐘前",
        hour: "%s小時前",
        day: "%s天前"
    },
    datepicker: {
        panel: {
            year: "選擇年",
            month: "選擇月"
        },
        prev: {
            year: "往前 1 年",
            month: "往前 1 個月",
            ten: {
                year: "往前 10 年"
            }
        },
        next: {
            year: "往後 1 年",
            month: "往後 1 個月",
            ten: {
                year: "往後 10 年"
            }
        },
        timer: {
            subject: "點擊使用滑桿模式",
            increase: {
                hour: "增加 1 小時",
                minute: "增加 %s 分鐘"
            },
            decrease: {
                hour: "減少 1 小時",
                minute: "減少 %s 分鐘"
            }
        },
        error: {
            1001: {
                subject: "小於最早時間限制",
                text: "最早時間限制為 <b class=\"ft-20 text-danger\">%s</b><br />請您重新選擇",
                description: ""
            },
            1002: {
                subject: "大於最晚時間限制",
                text: "最晚時間限制為 <b class=\"ft-20 text-danger\">%s</b><br />請您重新選擇",
                description: ""
            }
        },
        day: [
            "日",
            "一",
            "二",
            "三",
            "四",
            "五",
            "六"
        ],
        month: [
            "01月",
            "02月",
            "03月",
            "04月",
            "05月",
            "06月",
            "07月",
            "08月",
            "09月",
            "10月",
            "11月",
            "12月"
        ]
    },
    maintenance: {
        701: {
            subject: "牧養相關功能暫停使用",
            text: "該功能目前處於維護狀態暫時無法使用<br/>開放時間將另行通知, 造成您的不便請多見諒"
        }
    },
    select: {
        v2: {
            all: {
                subject: "全部"
            }
        }
    },
    options: {
        baptized: [
            {
                value: 0,
                label: "否"
            },
            {
                value: 1,
                label: "是"
            }
        ],
        schedule: [
            {
                value: 1,
                label: "等待上架"
            },
            {
                value: 2,
                label: "已上架"
            },
            {
                value: 3,
                label: "已下架"
            },
            {
                value: 99,
                label: "全部"
            }
        ],
        invitation: {
            customize: [
                {
                    value: 1,
                    label: "自行輸入"
                },
                {
                    value: 0,
                    label: "隨機產生"
                }
            ]
        },
        share: [
            {
                value: 1,
                label: "允許"
            },
            {
                value: 0,
                label: "禁止"
            }
        ],
        gender: [
            {
                value: 0,
                label: "女性"
            },
            {
                value: 1,
                label: "男性"
            },
            {
                value: 99,
                label: "不願意透露"
            }
        ],
        entrust: [
            {
                value: 0,
                label: "委託管理"
            },
            {
                value: 1,
                label: "自行維護"
            },
            {
                value: 99,
                label: "全部"
            }
        ],
        donate: [
            {
                value: 0,
                label: "關閉奉獻"
            },
            {
                value: 1,
                label: "線上奉獻"
            },
            {
                value: 2,
                label: "奉獻連結"
            }
        ],
        unsplash: {
            category: [
                {
                    label: "全部",
                    value: 0
                },
                {
                    label: "基督信仰",
                    value: 1
                },
                {
                    label: "自然生態",
                    value: 2
                },
                {
                    label: "日常生活",
                    value: 3
                },
                {
                    label: "紋理圖案",
                    value: 4
                },
                {
                    label: "人事物景",
                    value: 5
                },
                {
                    label: "藝術色彩",
                    value: 6
                },
                {
                    label: "健康食物",
                    value: 7
                },
                {
                    label: "動物植物",
                    value: 8
                },
                {
                    label: "街頭攝影",
                    value: 9
                }
            ],
            status: [
                {
                    value: 0,
                    label: "未核准"
                },
                {
                    value: 1,
                    label: "已核准"
                },
                {
                    value: 2,
                    label: "已拒絕"
                }
            ]
        }
    },
    status: {
        code: {
            200: {
                subject: "操作成功",
                text: "",
                description: ""
            },
            201: {
                subject: "服務降級保護中",
                text: "服務目前處於降級保護的狀態, 請稍後再試",
                description: ""
            },
            202: {
                subject: "服務流控管制中",
                text: "服務目前處於流控管制的狀態, 請稍後再試",
                description: ""
            },
            203: {
                subject: "服務熱點限制中",
                text: "服務目前處於熱點限制的狀態, 請稍後再試",
                description: ""
            },
            204: {
                subject: "系統規則不滿足要求",
                text: "請稍後再試",
                description: ""
            },
            205: {
                subject: "授權規則不通過",
                text: "請稍後再試",
                description: ""
            },
            401: {
                subject: "連線權杖逾時或不正確",
                text: "Oops! 🤕🤕 請您重新登入系統",
                description: "請稍後再試或請聯絡管理人員"
            },
            402: {
                subject: "公司授權已經到期",
                text: "請向我們的銷售人員聯絡, 謝謝",
                description: ""
            },
            403: {
                subject: "無存取權限",
                text: "Oops! 😳😳 您可能沒有存取的權限",
                description: "請稍後再試或請聯絡管理人員"
            },
            404: {
                subject: "頁面不存在",
                text: "Oops! 😱😱 您訪問的頁面不存在或暫時不可用",
                description: "請稍後再試或請聯絡管理人員"
            },
            405: {
                subject: "這出了點小問題",
                text: "Oops! 😵😵 無法正確連結至伺服器",
                description: "請稍後再試或請聯絡管理人員"
            },
            413: {
                subject: "這出了點小問題",
                text: "Oops! 🙁🙁 您上傳的檔案容量超出限制",
                description: "請稍後再試或請聯絡管理人員"
            },
            500: {
                subject: "這出了點小問題",
                text: "Oops! 🥺🥺 伺服器好像罷工了",
                description: "請稍後再試或請聯絡管理人員"
            },
            502: {
                subject: "這出了點小問題",
                text: "Oops! 😵😵 無法正確連結至伺服器",
                description: "請稍後再試或請聯絡管理人員"
            },
            503: {
                subject: "這出了點小問題",
                text: "Oops! 😵😵 存取的服務存在異常",
                description: "請稍後再試或請聯絡管理人員"
            },
            504: {
                subject: "這出了點小問題",
                text: "Oops! 😵😵 連結到伺服器超出預期時間",
                description: "請稍後再試或請聯絡管理人員"
            },
            700: {
                subject: "這出了點小問題",
                text: "Oops! 😵😵 系統模組連接異常",
                description: "請稍後再試或請聯絡管理人員"
            },
            901: {
                subject: "上傳檔案發生錯誤",
                text: "單個檔案超過 <b class=\"ft-20 text-danger\">%s</b> %s限制",
                description: ""
            },
            902: {
                subject: "上傳檔案發生錯誤",
                text: "檔案合計超過 <b class=\"ft-20 text-danger\">%s</b> %s限制",
                description: ""
            },
            903: {
                subject: "產生預覽圖失敗",
                text: "上傳檔案中包含不支援的圖片編碼",
                description: ""
            },
            904: {
                subject: "分享連結出現錯誤",
                text: "請確認您的分享連結是否正確",
                description: ""
            },
            998: {
                subject: "二級驗證失敗",
                text: "請確認您的登入密碼是否正確",
                description: "請確認您的登入密碼是否正確"
            },
            999: {
                subject: "這出了點小問題",
                text: "Oops! 😲😲 發生未知錯誤",
                description: "請稍後再試或請聯絡管理人員"
            }
        }
    }
}
